import React, { useState } from "react";
import update from "immutability-helper";

import LinkingBox, {
  LinkingBoxes,
  LinkingBoxesPopup,
} from "../../../../Common/Linking/LinkingBox";
import RichTextData from "../../Common/DataProperties/RichTextData";
import ImageData from "../image/ImageData";
import ToggleButton from "../../../../../Components/Input/ToggleButton";

const FixedMark = (props) => {
  const { value, onChange, markerNo, remove } = props;

  const locationObj = value || {};
  const setLocationObj = (obj) => {
    onChange(update(locationObj, { $merge: obj }));
  };

  return (
    <div className="linkingComponent" key={value?.uid}>
      <div className="databaseItemHead">
        <div className="databaseItemHeadOrder">{markerNo + 1}</div>

        <div className="calcDropdown add">
          <div className="calcDropdownLabel" style={{ display: "block" }}>
            <MarkerLocation
              {...props}
              value={locationObj.origin}
              onChange={(value) => setLocationObj({ origin: value })}
            />
          </div>
        </div>

        {remove ? (
          <div className="jointActionRow">
            <div
              style={{
                width: "35px",
                height: "35px",
                display: "flex",
                alignItems: "center",
                borderRadius: "11px",
                position: "relative",
                justifyContent: "center",
                backgroundColor: "#f5f5f5",
                marginLeft: "10px",
              }}
              onClick={remove}
            >
              <div className="jointActionCloseItem one"></div>
              <div className="jointActionCloseItem two"></div>
            </div>
          </div>
        ) : null}
      </div>

      <div className="linkingBoxBody">
        <div className="linkingBoxDecor"></div>
        <div className="linkingBoxItem">
          <div className="optionItemBody">
            <div className="calcItemDatabaseFilterBox mapIconBox">
              <div className="calcItemDatabaseFilterBoxInner">
                <div className="calcItemDatabaseFilterBoxInnerRow">
                  <div className="calcItemDBLabel">Map Icon</div>
                </div>

                <IconReference
                  {...props}
                  value={locationObj.icon}
                  onChange={(x) => {
                    setLocationObj({
                      icon: {
                        ...locationObj.icon,
                        ...x,
                      },
                    });
                  }}
                  fixedMarkValue={value || {}}
                  onChangeFixedMark={onChange}
                  markerNo={markerNo}
                />
              </div>
            </div>

            {/* <LinkingBoxesPopup
              {...{
                ...props,
                value: locationObj.linkings,
                onChange: (linkings) => setLocationObj({ linkings }),
                autoAddOneEmptyLinking: false,
                linkBoxOptions: {},
              }}
            /> */}
            <div className="mapLinkingContainer">
            <LinkingBox
              {...{
                ...props,
                value: locationObj.linkings?.[0] || {},
                onChange: (link) => setLocationObj({ linkings: [link] }),
                linkBoxOptions: { mode: "mapMarker", hideLinkDropdown: false },
              }}
            />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const IconReference = (props) => {
  const [visible, setVisible] = useState(false);

  const { value, onChange } = props;
  const mergeChange = (obj) => {
    onChange({ ...(value || {}), ...obj });
  };

  const isReferenceSelected =
    !value?.valueType || value?.valueType === "textParts";

  return (
    <>
      <div
        className="calcItemDatabaseFilterBoxInnerRow"
        style={{ position: "relative" }}
      >
        <RichTextData
          {...{
            ...props,
            key: "icon-ref" + isReferenceSelected.toString(),
            placeholder:
              isReferenceSelected || value?.valueType !== "staticUrls"
                ? "Reference to image"
                : value?.valueObj?.staticUrls?.[0] || "",
            value: isReferenceSelected ? value?.valueObj : null,
            onChange: (valueObj) =>
              mergeChange({
                valueObj: { ...(value?.valueObj || {}), ...valueObj },
                valueType: "textParts",
              }),
            immediateProps: {
              fileButton: true,
              classNames: {
                wrapper: " ",
                editor: "calcDatabaseFilterMultiBox",
                paramListRow: "",
              },
            },
          }}
        />
        <div
          className="optionItemListLabelIco displayNone"
          style={{ top: "9px", right: "32px", zIndex: 1 }}
          onClick={() => setVisible(!visible)}
        >
          i
        </div>
      </div>

      <div
        className="calculationOutside"
        style={{
          display: visible ? "block" : "none",
          position: "fixed",
          inset: 0,
          backgroundColor: "#0003",
        }}
      >
        <div className="calculationWindow">
          <div className="calcHead">
            <div className="calcHeadAction" onClick={() => setVisible(false)}>
              <div className="calculationHeadBackLine one"></div>
              <div className="calculationHeadBackLine two"></div>
              <div className="calculationHeadBackLineHorizontal"></div>
            </div>
            <div className="calcHeadMeta">
              <div className="calcHeadLabel">Icon Data</div>
            </div>
          </div>

          <div className="calcBody">
            <MarkerIconDetails
              {...{
                ...props,
                value: props.fixedMarkValue,
                onChange: props.onChangeFixedMark,
              }}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export const MarkerIconDetails = (props) => {
  const { value, onChange } = props;

  const locationObj = value || {};
  const setLocationObj = (obj) => {
    onChange(update(locationObj, { $merge: obj }));
  };

  return (
    <div className="optionDatabaseFilters">
      <div className="addressSelector mapIconSection">
        <div className="optionDatabaseBoxLabelMultiUnit">
          <div className="optionDatabaseBoxLabel">Icon</div>
          <ToggleButton
            classNames={{
              container: "optionHeadTabs headTabsDark",
              item: "optionHeadTabItem",
            }}
            overideDefaultStyle
            value={locationObj.icon?.valueType || "staticUrls"}
            onChange={(x) =>
              setLocationObj({
                icon: {
                  ...(locationObj.icon || {}),
                  valueType: x,
                },
              })
            }
            setDefaultOption="staticUrls"
            options={[
              { value: "staticUrls", label: "File" },
              { value: "textParts", label: "Reference" },
            ]}
          />
        </div>

        <div className="optionItemRow optionItemIconDimension">
          <div className="optionItemBox">
            <div className="optionInputIconBox">
              <img
                className="optionInputIconBoxArrowIco"
                src={require("../../../../../Assets/img/options/container/widthIco.png")}
              ></img>
              <input
                type="text"
                className="optionInputIconBoxSpacingField"
                placeholder="25px"
                value={locationObj.icon?.width || ""}
                onChange={(e) =>
                  setLocationObj({
                    icon: {
                      ...(locationObj.icon || {}),
                      width: e.target.value,
                    },
                  })
                }
              />
            </div>
          </div>
          <div className="optionItemBox">
            <div className="optionInputIconBox">
              <img
                className="optionInputIconBoxArrowIco rotate90"
                src={require("../../../../../Assets/img/options/container/widthIco.png")}
              ></img>
              <input
                type="text"
                className="optionInputIconBoxSpacingField"
                placeholder="25px"
                value={locationObj.icon?.height || ""}
                onChange={(e) =>
                  setLocationObj({
                    icon: {
                      ...(locationObj.icon || {}),
                      height: e.target.value,
                    },
                  })
                }
              />
            </div>
          </div>
        </div>

        <IconData
          {...props}
          value={locationObj.icon}
          onChange={(x) => {
            setLocationObj({
              icon: {
                ...locationObj.icon,
                ...x,
              },
            });
          }}
        />
      </div>
    </div>
  );
};

export class IconData extends ImageData {
  render() {
    return this.props.value?.valueType === "textParts"
      ? this.textPartsConfig()
      : this.staticUrlConfig();
  }
}

export const MarkerLocation = (props) => {
  const { value = {}, onChange } = props;

  return (
    <RichTextData
      {...{
        ...props,
        key: "location",
        placeholder: "Enter Location",
        value: value?.valueObj || {},
        onChange: (valueObj) =>
          onChange({
            ...value,
            valueObj,
            valueType: "textParts",
          }),
        immediateProps: {
          classNames: {
            wrapper: " ",
            editor: "calcDatabaseFilterMultiBox",
            paramListRow: "",
          },
        },
      }}
    />
  );
};

export default FixedMark;
