import React, { useEffect, useRef, useState } from "react";
import update from "immutability-helper";
import _ from "lodash";

import DatabaseDataSelector from "../DatabaseOperations_v2/DatabaseDataSelector";
import StylesOfElementSelector from "./StylesOfElementSelector";
import LocationSelector from "./LocationSelector";
import TimeInput from "../../../Components/Input/TimeInput";
import DateInput from "../../../Components/Input/DateInput";
import ValueOfElementSelector from "./ValueOfElementSelector";
import PassedParamSelector, {
  PassedParameterFromParentSelector,
} from "./PassedParamSelector";
import DateTimeInput from "../../../Components/Input/DateTimeInput";
import ExternalApiDataSelector from "../ExternalApiOperations/ExternalApiDataSelector";
import GetApiResponse from "./GetApiResponse";
import CustomSelect from "../../../Components/etc/CustomSelect";
import RichTextData from "../../Builder/Properties/Common/DataProperties/RichTextData";
import { allBuilderValueTypes } from "../../../Modules/calculation/builder-value-types";

const SelectorBox = ({ title, children, footer }) => {
  return (
    <div className="calcItemUnit">
      <div className="calcItemUnitBox">
        <div className="calcItemUnitDecor"></div>
        <div className="calcItemUnitMain">
          <div className="calcItemUnitMainLabel">{title}</div>
          {children}
        </div>
      </div>
      {footer}
    </div>
  );
};

const DateTimeSelector = (props) => {
  const value = props.value?.[props.valueType] || {};
  const mergeChange = (x) =>
    props.mergeChange({ [props.valueType]: { ...value, ...x } });

  useEffect(() => {
    if (!value?.currentOrCustom) {
      mergeChange({
        currentOrCustom: "current",
        formatType: "time",
        customFormat: "HH:mm",
      });
    }
  }, [value?.currentOrCustom]);

  return (
    <div className="calcItemUnit">
      <div className="calcItemUnitBox">
        <div className="calcItemUnitDecor"></div>

        <div className="calcItemUnitMain">
          <div className="calcItemUnitMainCombo">
            <div className="calcItemUnitMainLabel">
              {props.builderValueType.label}
            </div>
          </div>

          <CustomSelect
            classNames={{
              head: "calcItemUnitMainDropdown adjust",
              label: "calcItemUnitMainDropdownLabel",
            }}
            onChange={(option) =>
              mergeChange({
                formatType: option.value,
                customFormat: option.format,
              })
            }
            value={value?.formatType || ""}
            options={[
              { value: "time", label: "Current Time", format: "HH:mm" },
              { value: "date", label: "Current Date", format: "MM/dd/yyyy" },
              {
                value: "ISO",
                label: "International Date Time",
                format: "",
              },
            ]}
            jointActionRow={
              <div className="calcItemUnitMainDropdownFooter">
                <div className="calcItemUnitMainDropdownFooterArrow">
                  <div className="one"></div>
                  <div className="two"></div>
                </div>
              </div>
            }
          />
        </div>
      </div>

      {/* {value?.currentOrCustom === "custom" ? (
        <div className="calcItemUnitBox">
          <div className="calcItemDatabaseFilter">
            <div className="calcItemDatabaseFilterBox">
              <div className="calcItemDatabaseFilterBoxInner">
                <div className="calcItemDatabaseFilterBoxInnerRow">
                  <div className="calcItemDBLabel">Value Format</div>
                </div>
                <RichTextData
                  {...{
                    ...props,
                    immediateProps: {
                      classNames: {
                        wrapper: "",
                        editor: "calcDatabaseFilterMultiBox",
                        paramListRow: "",
                      },
                    },
                    placeholder: "Value Format",
                    value: value?.inputFormat?.valueObj,
                    onChange: (valueObj) =>
                      mergeChange({
                        inputFormat: { valueType: "textParts", valueObj },
                      }),
                  }}
                />
              </div>
            </div>

            <div className="calcItemDatabaseFilterBox">
              <div className="calcItemDatabaseFilterBoxInner">
                <div className="calcItemDatabaseFilterBoxInnerRow">
                  <div className="calcItemDBLabel">Value</div>
                </div>
                <RichTextData
                  {...{
                    ...props,
                    immediateProps: {
                      classNames: {
                        wrapper: "",
                        editor: "calcDatabaseFilterMultiBox",
                        paramListRow: "",
                      },
                    },
                    placeholder: "Custom date time value",
                    value: value?.datetime?.valueObj,
                    onChange: (valueObj) =>
                      mergeChange({
                        datetime: { valueType: "textParts", valueObj },
                      }),
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      ) : null} */}

      {/* <div className="calcItemUnitFooter">
        <div className="calcItemUnitMain">
          <div className="calcItemUnitMainLabel">Format</div>

          <CustomSelect
            classNames={{
              head: "calcItemUnitMainDropdown",
              label: "calcItemUnitMainDropdownLabel",
            }}
            onChange={(option) =>
              mergeChange({
                formatType: option.value,
                customFormat: option.format,
              })
            }
            value={value?.formatType || "timestamp"}
            options={[
              {
                value: "timestamp",
                label: "Timestamp",
                format: "1234567890",
              },
              { value: "time", label: "Time", format: "HH:mm" },
              { value: "date", label: "Date", format: "MM/dd/yyyy" },
              {
                value: "dateAndTime",
                label: "Date And Time",
                format: "MM/dd/yyyy hh:mm a",
              },
              { value: "custom", label: "Custom Format", format: "" },
            ]}
            jointActionRow={
              <div className="calcItemUnitMainDropdownFooter">
                <div className="calcItemUnitMainDropdownFooterArrow">
                  <div className="one"></div>
                  <div className="two"></div>
                </div>
              </div>
            }
          />
        </div>

        <div className="calcItemUnitMain">
          <div className="calcItemUnitMainDropdown">
            <input
              className="calcItemUnitMainDropdownLabel"
              {...{
                placeholder:
                  value?.formatType === "custom" ? "Enter date format" : " ",
                disabled: value?.formatType !== "custom",
                value: value?.customFormat || "",
                onChange: (e) =>
                  mergeChange({
                    customFormat: e.target.value,
                  }),
              }}
            />
          </div>
        </div>
      </div> */}
    </div>
  );
};

const CalculationValueSelector = (props) => {
  const [ts, setTs] = useState(0);

  const builderValueType = allBuilderValueTypes.find(
    (x) => x.value === props.valueType
  );

  const valueOfElementSelectorRef = useRef();

  const commonProps = {
    ...props,
    builderValueType,
    value: props.value || {},
    mergeChange: (obj) => props.onChange({ ...(props.value || {}), ...obj }),
  };

  const selectors = {
    passedParameter: (props) => (
      <PassedParameterFromParentSelector
        {...{
          ...props,
          key: props.valueType,
          value: props.value[props.valueType],
          onChange: (x) => props.mergeChange({ [props.valueType]: x }),
          mode: "showOnlyActive",
        }}
      />
    ),
    valueOfElement: (props) => {
      return (
        <div>
          <SelectorBox
            title={
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div>Element</div>
                {/* {valueOfElementSelectorRef.current?.renderToggle?.()} */}
              </div>
            }
            key={props.valueType}
          >
            <ValueOfElementSelector
              {...{
                ...props,
                ref: valueOfElementSelectorRef,
                elementType: null,
                elementFilter: null,
                styles: { dd: { width: "100%" } },
                value: props.value?.element,
                onChange: (x) => {
                  props.mergeChange({ element: x });
                  setTimeout(() => {
                    setTs(Date.now());
                  }, 100);
                },
                onLoad: () => {
                  setTimeout(() => {
                    setTs(Date.now());
                  }, 0);
                },
              }}
            />
          </SelectorBox>
          {valueOfElementSelectorRef.current?.renderConfigs?.()}
        </div>
      );
    },
    textParts: (props) => (
      <div className="calcItemUnit customValueBox">
        <div className="calcItemUnitBox">
          <div className="calcItemUnitDecor"></div>
          <div className="calcItemUnitMain">
            <div className="calcItemUnitMainLabel">
              {props.calculationValueSelectorTitle ||
                props.builderValueType.label}
            </div>
            <RichTextData
              {...{
                ...props,
                placeholder: "Custom Value",
                value: props.value[props.valueType]?.valueObj,
                onChange: (valueObj) =>
                  props.mergeChange({
                    [props.valueType]: { valueType: "textParts", valueObj },
                  }),
              }}
            />
          </div>
        </div>
      </div>
    ),
    valueOfRepeatingContainerRow: (props) => (
      <ValueOfRepeatingContainerRow {...props} />
    ),
    currentTableName: (props) => (
      <SelectorBox title={props.builderValueType.label} key={props.valueType} />
    ),
    valueOfAffectedColumn: (props) => (
      <SelectorBox title={props.builderValueType.label} key={props.valueType}>
        <input
          style={{ width: "100%" }}
          type="text"
          placeholder="Column Name"
          value={props.value?.valueOfAffectedColumn || ""}
          onChange={(event) =>
            props.mergeChange({ valueOfAffectedColumn: event.target.value })
          }
        />
      </SelectorBox>
    ),
    databaseValue: (props) => (
      <DatabaseDataSelector
        {...{
          ...props,
          key: props.valueType,
          value: props.value?.dbData,
          onChange: (x) => props.mergeChange({ dbData: x }),
        }}
      />
    ),
    valueFromApi: (props) => (
      <ExternalApiDataSelector
        {...{
          ...props,
          key: props.valueType,
          value: props.value?.valueFromApi,
          onChange: (x) => props.mergeChange({ valueFromApi: x }),
          mode: "valueFromApi",
        }}
      />
    ),
    dateAndTime: (props) => <DateTimeSelector {...props} />,
    screenInformation: (props) => {
      const value = props.value?.[props.valueType] || {};
      const mergeChange = (x) =>
        props.mergeChange({ [props.valueType]: { ...value, ...x } });

      return (
        <SelectorBox title={props.builderValueType.label} key={props.valueType}>
          <CustomSelect
            className="dropdownHeightAdjust"
            onChange={(option) => mergeChange({ action: option.value })}
            value={value?.action || ""}
            options={["screenWidth", "screenHeight"].map((x) => ({
              value: x,
              label: _.startCase(x),
            }))}
            placeholder={"Select"}
            classNames={{
              head: "calcDropdown",
              label: "calcDropdownLabel",
            }}
            jointActionRow={
              <div className="calcItemUnitMainDropdownFooterArrow">
                <div className="one"></div>
                <div className="two"></div>
              </div>
            }
          />
        </SelectorBox>
      );
    },

    deviceInformation: (props) => {
      const value = props.value?.[props.valueType] || {};
      const mergeChange = (x) =>
        props.mergeChange({ [props.valueType]: { ...value, ...x } });

      const footer = ["currentLocation"].includes(value?.action) ? (
        <div className="calcItemUnitFooter">
          <div className="calcItemUnitMain">
            <div className="calcItemUnitMainLabel">Value</div>

            <CustomSelect
              classNames={{
                head: "calcItemUnitMainDropdown",
                label: "calcItemUnitMainDropdownLabel",
              }}
              onChange={(option) =>
                mergeChange({
                  coordinateValueType: option.value,
                })
              }
              value={value?.coordinateValueType || "latAndLng"}
              options={[
                {
                  value: "latAndLng",
                  label: "Coordinates",
                },
                {
                  value: "latitude",
                  label: "Latitude",
                },
                {
                  value: "longitude",
                  label: "Longitude",
                },
                {
                  value: "accuracy",
                  label: "Accuracy",
                },
              ]}
              jointActionRow={
                <div className="calcItemUnitMainDropdownFooter">
                  <div className="calcItemUnitMainDropdownFooterArrow">
                    <div className="one"></div>
                    <div className="two"></div>
                  </div>
                </div>
              }
            />
          </div>
        </div>
      ) : null;

      return (
        <SelectorBox
          title={props.builderValueType.label}
          key={props.valueType}
          footer={footer}
        >
          <CustomSelect
            className="dropdownHeightAdjust"
            onChange={(option) => mergeChange({ action: option.value })}
            value={value?.action || ""}
            options={[
              "deviceModal",
              "deviceVersion",
              "deviceId",
              "notificationToken",
              "microphoneStatus",
              "cameraStatus",
              "cameraFacingMode",
              "currentLocation",
              "locationPermission",
              "notificationPermission",
            ].map((x) => ({ value: x, label: _.startCase(x) }))}
            placeholder={"Select"}
            classNames={{
              head: "calcDropdown",
              label: "calcDropdownLabel",
            }}
            jointActionRow={
              <div className="calcItemUnitMainDropdownFooterArrow">
                <div className="one"></div>
                <div className="two"></div>
              </div>
            }
          />
        </SelectorBox>
      );
    },

    distanceBetweenLocation: (props) => {
      return (
        <>
          <div className="calcItemUnit customValueBox">
            <div className="calcItemUnitBox">
              <div className="calcItemUnitDecor"></div>
              <div className="calcItemUnitMain">
                {[0, 1].map((i) => (
                  <div
                    key={i}
                    className={`linkingAdjustDropdown ${
                      i === 0 ? "pointAClass" : "pointBClass"
                    }`}
                  >
                    <div className="calcItemUnitMainLabel">
                      {i === 0 ? "Point A" : "Point B"}
                    </div>

                    <div className="calcRteItem">
                      <RichTextData
                        {...{
                          ...props,
                          key: i,
                          placeholder: "Custom Address",
                          value: props.value?.locations?.[i]?.valueObj,
                          onChange: (valueObj) =>
                            props.mergeChange({
                              locations: update(props.value?.locations || [], {
                                $merge: {
                                  [i]: {
                                    valueType: "textParts",
                                    valueObj,
                                  },
                                },
                              }),
                            }),
                        }}
                      />
                    </div>
                  </div>
                ))}

                <div className={`linkingAdjustDropdown`}>
                  <div className="calcItemUnitMainLabel">Action</div>

                  <CustomSelect
                    classNames={{
                      head: "calcItemUnitMainDropdown adjust",
                      label: "calcItemUnitMainDropdownLabel",
                    }}
                    value={
                      props.value?.action?.valueObj?.customText ||
                      "aerialDistance"
                    }
                    onChange={(option) =>
                      props.mergeChange({
                        action: {
                          valueType: "customText",
                          valueObj: { customText: option.value },
                        },
                      })
                    }
                    options={[
                      { value: "drivingTime", label: "Get driving time" },
                      { value: "walkingTime", label: "Get walking time" },
                      {
                        value: "drivingDistance",
                        label: "Get driving distance",
                      },
                      {
                        value: "walkingDistance",
                        label: "Get walking distance",
                      },
                      { value: "aerialDistance", label: "Get aerial distance" },
                      {
                        value: "aerialWalkingTime",
                        label: "Get aerial walking time",
                      },
                      {
                        value: "aerialDrivingTime",
                        label: "Get aerial driving time",
                      },
                    ]}
                    jointActionRow={
                      <div className="calcItemUnitMainDropdownFooter">
                        <div className="calcItemUnitMainDropdownFooterArrow">
                          <div className="one"></div>
                          <div className="two"></div>
                        </div>
                      </div>
                    }
                  />
                </div>
              </div>
            </div>
          </div>
        </>
      );
    },

    randomValue: (props) => {
      const mergeChange = props.mergeChange;
      return (
        <SelectorBox title={props.builderValueType.label} key={props.valueType}>
          <CustomSelect
            classNames={{
              head: "calcItemUnitMainDropdown adjust",
              label: "calcItemUnitMainDropdownLabel",
            }}
            jointActionRow={
              <div className="calcItemUnitMainDropdownFooter">
                <div className="calcItemUnitMainDropdownFooterArrow">
                  <div className="one"></div>
                  <div className="two"></div>
                </div>
              </div>
            }
            value={props.value?.randomValue?.method || "alphanumeric"}
            onChange={(option) =>
              mergeChange({
                randomValue: {
                  ...(props.value?.randomValue || {}),
                  method: option.value,
                },
              })
            }
            options={[
              { value: "alphanumeric", label: "Numbers And Letters" },
              { value: "numeric", label: "Numbers" },
              { value: "uuid", label: "UUID" },
            ]}
          />
          {["uuid"].includes(props.value?.randomValue?.method) ? null : (
            <div className="dualInfoBox">
              <div className="dualInfoLabel">Length</div>
              <input
                className="dualInfoInput"
                type="number"
                value={props.value?.randomValue?.length || 5}
                onChange={(e) =>
                  mergeChange({
                    randomValue: {
                      ...(props.value?.randomValue || {}),
                      length: e.target.value,
                    },
                  })
                }
              />
            </div>
          )}
        </SelectorBox>
      );
    },
  };

  return <>{selectors[builderValueType?.value]?.(commonProps) || null}</>;
};

const ValueOfRepeatingContainerRow = (props) => {
  const [columns, setColumns] = useState();

  const elementId = props.value?.valueOfRepeatingContainerRow?.element?.id;

  useEffect(() => {
    if (elementId) {
      const dom = props.dom;
      const res = dom.findNodeAndParent(elementId);
      const repeatingTab = res.node?.value?.data?.tabs?.find(
        (x) => x?.containerData?.repeating
      );

      const { dbId, tableId } = repeatingTab?.containerData || {};

      const columns = props.databases
        ?.find((x) => x._id === dbId)
        ?.tables?.find((x) => x._id === tableId)
        ?.columns?.map((x) => ({ label: x.name, value: x.name }));

      console.log({ dbId, tableId, databases: props.databases, columns });

      setColumns(columns);
    }
  }, [elementId]);

  const elementFilter = (x) => {
    const tabs =
      x.value?.data?.dataType === "repeating"
        ? x.value?.data?.tabs || []
        : [x.value?.data?.tabs?.[0]].filter((x) => x);

    for (let i = 0; i < tabs.length; i++) {
      const tab = tabs[i];

      if (tab?.containerData?.repeating) {
        return true;
      }
    }
    return false;
  };

  // console.log(props.value?.valueOfRepeatingContainerRow?.element)

  return (
    <SelectorBox title={props.builderValueType.label} key={props.valueType}>
      <ValueOfElementSelector
        {...{
          ...props,
          elementType: null,
          styles: { dd: { width: "100%" } },
          elementType: "container",
          elementFilter,
          value: props.value?.valueOfRepeatingContainerRow?.element,
          onChange: (x) =>
            props.mergeChange({
              valueOfRepeatingContainerRow: {
                ...(props.value?.valueOfRepeatingContainerRow || {}),
                element: x,
              },
            }),
        }}
      />
      <div>Column Name</div>
      {/* <RichTextData
        {...{
          ...props,
          placeholder: "Column Name",
          value:
            props.value?.valueOfRepeatingContainerRow?.columnName?.valueObj,
          onChange: (valueObj) =>
            props.mergeChange({
              valueOfRepeatingContainerRow: {
                ...(props.value?.valueOfRepeatingContainerRow || {}),
                columnName: {
                  valueType: "textParts",
                  valueObj,
                },
              },
            }),
        }}
      /> */}

      <CustomSelect
        {...{
          placeholder: "Column Name",
          value:
            props.value?.valueOfRepeatingContainerRow?.columnName?.valueObj
              ?.customText,
          onChange: (option) =>
            props.mergeChange({
              valueOfRepeatingContainerRow: {
                ...(props.value?.valueOfRepeatingContainerRow || {}),
                columnName: {
                  valueType: "customText",
                  valueObj: { customText: option.value },
                },
              },
            }),
          options: columns,
          classNames: {
            head: "optionInputIconBox",
            label: "optionInputIconBoxField",
            chevron: "optionDatabaseSelectChevron",
          },
        }}
      />

      <div>Column Value</div>
      <RichTextData
        {...{
          ...props,
          placeholder: "Column Value",
          value:
            props.value?.valueOfRepeatingContainerRow?.columnValue?.valueObj,
          onChange: (valueObj) =>
            props.mergeChange({
              valueOfRepeatingContainerRow: {
                ...(props.value?.valueOfRepeatingContainerRow || {}),
                columnValue: {
                  valueType: "textParts",
                  valueObj,
                },
              },
            }),
        }}
      />
    </SelectorBox>
  );
};

export default CalculationValueSelector;
