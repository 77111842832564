import React from "react";
import update from "immutability-helper";

import styleModule from "../../../../Modules/style/style-module";
import ImageUpload from "../../Properties/Common/ImageSources/ImageUpload";
import api from "../../../../Services/Api/api";
import etcModule from "../../../../Modules/etc/etc-module";
import AppModal from "../../../../Components/Models/app-modal";
import SearchIcons from "../../Properties/Common/ImageSources/SearchIcons";
import { FileManager } from "../../Properties/Common/DataProperties/RichTextData";
import JustifyContent from "../../Properties/Common/StyleProperties/JustifyContent";

class Image extends React.PureComponent {
  state = { files: "", src: null, iconModal: false };

  inputRef = React.createRef(null);

  renderImage(props) {
    return (
      <img
        {...{
          alt: "",
          ...props,
          style: props.src
            ? props.style
            : Object.assign({}, props.style, {
                backgroundColor: props.backgroundColor || "#dedede",
              }),
        }}
      />
    );
  }

  appendSrc(src) {
    const element = this.props.element;
    const staticUrls =
      element.value?.data?.tabs?.[element.value?.data?.activeTabIndex || 0]
        ?.imageData?.valueObj?.staticUrls || [];

    const updatedStaticUrls = update(staticUrls, { $push: [src] });

    const updatedValue = etcModule.mergeObjects(
      element.value,
      { staticUrls: updatedStaticUrls },
      [
        "data",
        "tabs",
        element.value?.data?.activeTabIndex || 0,
        "imageData",
        "valueObj",
      ]
    );

    this.props.dom.updateNodeValue(element.id, updatedValue);
  }

  uploadHandler = async (event) => {
    try {
      let file = event.target.files[0];
      if (!file) return;

      const fileRes = await api.media("v1/file", {
        file,
      });
      const staticUrl = api.getFileLink(fileRes?.file);

      this.appendSrc(staticUrl);
    } catch (e) {
      console.error("Error uploading file: " + e.message);
    }
  };

  // extractFirstUrl(data) {
  //   if (
  //     !data ||
  //     !data.imageData ||
  //     !data.imageData.valueObj ||
  //     !data.imageData.valueObj.textParts
  //   ) {
  //     return null; // Return null if the structure doesn't match
  //   }

  //   const textParts = data.imageData.valueObj.textParts;

  //   for (const part of textParts) {
  //     if (
  //       part.valueType === "calculation" &&
  //       part.valueObj &&
  //       part.valueObj.calculation
  //     ) {
  //       for (const calcItem of part.valueObj.calculation) {
  //         if (
  //           calcItem.valueType === "customText" &&
  //           calcItem.valueObj &&
  //           calcItem.valueObj.customText
  //         ) {
  //           const url = calcItem.valueObj.customText;
  //           if (url.startsWith("http")) {
  //             return {part, url}; // Return the first URL found
  //           }
  //         }
  //       }
  //     }
  //   }

  //   return null; // Return null if no URL is found
  // }

  extractFirstUrlAndModify(data) {
    if (
      !data ||
      !data.imageData ||
      !data.imageData.valueObj ||
      !data.imageData.valueObj.textParts
    ) {
      return null;
    }

    const textParts = data.imageData.valueObj.textParts;

    for (let index = 0; index < textParts.length; index++) {
      const part = textParts[index];

      if (
        part.valueType === "calculation" &&
        part.valueObj &&
        part.valueObj.calculation
      ) {
        for (const calcItem of part.valueObj.calculation) {
          if (
            calcItem.valueType === "customText" &&
            calcItem.valueObj &&
            calcItem.valueObj.customText
          ) {
            const url = calcItem.valueObj.customText;
            if (url.startsWith("http")) {
              return { index, textPart: part, url }; // Return the index and the textPart
            }
          }
        }
      }
    }

    return null;
  }

  render() {
    const element = this.props.element;
    const activeTab =
      element.value?.data?.tabs?.[element.value?.data?.activeTabIndex || 0];

    const {
      url,
      textPart,
      index: activePartIndex,
    } = this.extractFirstUrlAndModify(activeTab) || {};

    const style = styleModule.getElementStyleData(element);

    const setPart = (part, index) => {
      const id = Math.random();
      const imageData = {
        valueType: "textParts",
        valueObj: {
          textParts: [
            {
              ...part,
              valueType: "calculation",
              id,
              type: "custom",
              data: {
                id,
                itemTextContent: "Files",
                itemType: "files",
                valueType: "calculation",
              },
            },
          ],
          updatedAt: Date.now(),
        },
      };

      const updatedValue = etcModule.mergeObjects(
        element.value,
        { imageData },
        ["data", "tabs", element.value?.data?.activeTabIndex || 0]
      );

      this.props.dom.updateNodeValue(element.id, updatedValue);
    };

    return !url ? (
      <>
        <div className="imageInputWrapper"
          style={Object.assign({}, style, { })}
        >
          <img
            className="imageUploadIco"
            src={require("../../../../Assets/img/elements/icons/image.png")}
          />
          <div className="imageUploadButton"
            onClick={() => this.setState({ iconModal: !this.state.iconModal })}
          >
            Upload File
          </div>
        </div>
        {this.state.iconModal ? (
          <FileManager
            {...{
              ...this.props,
              immediateProps: null,
              value: textPart,
              onChange: (part) => setPart(part, activePartIndex),
              close: () => this.setState({ iconModal: null }),
            }}
          />
        ) : null}
      </>
    ) : (
      this.renderImage({
        style,
        src: url,
      })
    );
  }
}

export default Image;
