import React from "react";

import _dom from "../../../appxolo-engine/modules/dom/Dom";
import CustomSelect from "../../../Components/etc/CustomSelect";
import RichTextData from "../../Builder/Properties/Common/DataProperties/RichTextData";
import ToggleButton from "../../../Components/Input/ToggleButton";
import { Checkbox } from "../../../appxolo-engine/components/react/Toggle";
import { CheckBoxHorizontal } from "../../../Components/etc/RadioBox";
const { DomTree, DomTreeNode } = _dom;

class ValueOfElementSelector extends React.Component {
  state = {
    elementIds: [],
    parentRepeatingContainers: null,
    elementIsRepeatingContainer: null,
    ofSameRepatingContainer: false,
  };

  componentDidMount() {
    if (this.props.onLoad) this.props.onLoad();
    this.load();
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      this.props.elementType != prevProps.elementType ||
      (this.props.dom && !prevProps.dom)
    ) {
      this.load();
    } else if (this.state.isDatePicker && !prevState.isDatePicker) {
      const options = this.datePickerOptins;
      if (!this.props.value?.datePickerSelector) {
        this.props.onChange({
          ...(this.props.value || {}),
          datePickerSelector: options?.[0]?.value,
        });
      }
    }
  }

  load() {
    if (this.props.dom) {
      const elementIds = this.getElementIds(this.props.dom);
      this.setState({ elementIds });

      this.checkForRepeatingContainers(this.props.value?.id);
      this.checkElementTypeData(this.props.value?.id);
    }
  }

  getElementIds(dom, options = {}) {
    const {
      props: { elementType, components, elementFilter },
    } = this;

    const elementIds = [];

    const screenContainerIds = options.screenContainerIds || [];

    const addElementId = (x, options) => {
      if (!elementFilter || elementFilter(x)) {
        elementIds.push({
          value: [...screenContainerIds, x.id].join("-"),
          label:
            "#" +
            [...screenContainerIds, x.id].join("-") +
            " " +
            (x.value?.elementType || ""),
          options: options,
        });
      }
    };

    const fn = (x) => {
      if (elementType && elementType !== "container") {
        if (x.value?.elementType === elementType) {
          addElementId(x);
        }
      } else {
        if (x.value?.elementType === "container") {
          const tabs =
            x.value?.data?.dataType === "conditional"
              ? x.value?.data?.tabs || []
              : [x.value?.data?.tabs?.[0]].filter((x) => x);

          let innerElements = [];

          for (let i = 0; i < tabs.length; i++) {
            const tab = tabs[i];

            if (
              ["containerTabs", "slider"].includes(tab?.containerType?.type)
            ) {
              addElementId(x);
            } else if (tab?.containerType?.type === "screen") {
              const component = components?.find(
                (x) => x._id === tab?.containerType?.screenConfig?.to
              );

              if (component?.data?.dom?.children?.length) {
                const domTreeNode = new DomTreeNode(
                  "ROOT",
                  { elementType: "container" },
                  component?.data?.dom?.children
                );
                const dom = new DomTree(domTreeNode);

                const screenElementIds = this.getElementIds(dom, {
                  ...options,
                  screenContainerIds: [
                    ...(options.screenContainerIds || []),
                    x.id,
                  ],
                });
                innerElements = [...innerElements, ...screenElementIds];
              }
            } else if (elementFilter) {
              addElementId(x);
            } else if (tab?.containerData?.repeating) {
              addElementId(x);
            }
          }

          if (innerElements.length) {
            const uniqueInnerElements = innerElements.filter(
              (item, index, self) =>
                index === self.findIndex((t) => t.value === item.value)
            );

            addElementId(x, uniqueInnerElements);
          }
        } else {
          addElementId(x);
        }
      }
    };

    dom.loopOverAllNodes(fn);

    const uniqueElementIds = elementIds.filter(
      (item, index, self) =>
        index === self.findIndex((t) => t.value === item.value)
    );

    return uniqueElementIds;
  }

  onElementSelect(x) {
    let obj = { id: x.value };

    obj = { ...obj, repeatingContainerInfo: null };

    this.setState({
      parentRepeatingContainers: null,
      elementIsRepeatingContainer: null,
    });
    const { ofSameRepatingContainer } = this.checkForRepeatingContainers(
      x.value
    );
    this.checkElementTypeData(x.value);

    this.props.onChange({
      ...(this.props.value || {}),
      id: x.value,
      interveneGetClosest: !ofSameRepatingContainer,
      setValueType: null,
    });
  }

  checkForRepeatingContainers(elementIdsStr) {
    let ofSameRepatingContainer = false;
    const parentRepeatingContainers =
      this.findRepeatingContainers(elementIdsStr);

    if (parentRepeatingContainers?.length) {
      const selfRepeatingParents =
        this.findRepeatingContainers(this.props.focusedElement.element.id) ||
        [];

      let matching = !!selfRepeatingParents?.length;
      for (let i = 0; i < selfRepeatingParents.length && matching; i++) {
        const parent = selfRepeatingParents[i];
        if (!parentRepeatingContainers.find((x) => x.id === parent.id))
          matching = false;
      }

      if (matching) {
        ofSameRepatingContainer = true;
      }
    }

    this.setState({ parentRepeatingContainers, ofSameRepatingContainer });

    return { ofSameRepatingContainer, parentRepeatingContainers };
  }

  findRepeatingContainers(elementIdsStr) {
    if (!elementIdsStr) return;

    const elementIds = elementIdsStr.split("-");

    const elementId = elementIds[elementIds.length - 1];
    const screenContainers = elementIds.slice(0, -1);

    let dom = this.props.dom;

    for (let i = 0; i < screenContainers.length; i++) {
      // const screenContainer = screenContainers[i];
      // TODO handle when element id is inside container type screen
    }

    const indices = dom.findRecursiveIndices(elementId);
    const parents = dom.findNodeAndParentsByIndices(indices);
    parents.pop();
    const parentRepeatingContainers = parents.filter(
      (x) => x.value?.data?.tabs?.[0]?.containerData?.repeating
    );
    return parentRepeatingContainers;
  }

  checkElementTypeData(elementIdsStr) {
    if (!elementIdsStr) return;

    const elementIds = elementIdsStr.split("-");
    const elementId = elementIds[elementIds.length - 1];

    let dom = this.props.dom;
    const res = dom.findNodeAndParent(elementId);

    let tabs = res.node?.value?.data?.tabs || [];
    if (!res.node?.value?.data?.dataType === "conditional") {
      tabs = tabs.filter((x, i) => !i && x);
    }

    const repeatingTab = tabs?.find((x) => x?.containerData?.repeating);

    const { dbId, tableId } = repeatingTab?.containerData || {};

    const columns = this.props.databases
      ?.find((x) => x._id === dbId)
      ?.tables?.find((x) => x._id === tableId)
      ?.columns?.map((x) => ({ label: x.name, value: x.name }));

    const isContainerTab = tabs.find(
      (tab) => tab?.containerType?.type === "containerTabs"
    );

    const isSlider = tabs.find((tab) => tab?.containerType?.type === "slider");

    const isLocationInput =
      res.node?.value?.elementType === "input" &&
      tabs.find((tab) => tab?.inputType?.group === "locationInput");

    const isDatePicker =
      res.node?.value?.elementType === "input" && this.checkDatePicker(tabs);

    this.setState({
      elementIsRepeatingContainer: {
        isRepeating: !!repeatingTab,
        dbId,
        tableId,
        columns,
        element: res.node,
        isContainerTab,
        isSlider,
      },
      isLocationInput,
      isDatePicker,
    });
  }

  checkDatePicker(tabs) {
    let pickerType = null;

    for (let i = 0; i < tabs.length; i++) {
      const tab = tabs[i];
      if (tab?.inputType?.group === "dateTimePicker") {
        let type = tab?.inputData?.datetimePicker?.selectType || "singleSelect";
        if (pickerType && pickerType !== type) pickerType = "mixed";
        else pickerType = type;
      }
    }

    return pickerType;
  }

  get datePickerOptins() {
    const options = {
      singleSelect: [
        { value: "date", label: "Get Date" },
        { value: "month", label: "Get Month" },
        { value: "day", label: "Get Day" },
        { value: "weekday", label: "Get Weekday" },
        { value: "year", label: "Get Year" },
      ],
      rangeSelect: [
        { value: "dateRange", label: "Get Date Range" },
        { value: "dayCount", label: "Get Date Range Count" },
        { value: "startDate", label: "Get Start Date" },
        { value: "startMonth", label: "Get Start Month" },
        { value: "startDay", label: "Get Start Day" },
        { value: "startWeekday", label: "Get Start Weekday" },
        { value: "startYear", label: "Get Start Year" },
        { value: "endDate", label: "Get End Date" },
        { value: "endMonth", label: "Get End Month" },
        { value: "endDay", label: "Get End Day" },
        { value: "endWeekday", label: "Get End Weekday" },
        { value: "endYear", label: "Get End Year" },
      ],
    };

    return this.state.isDatePicker === "mixed"
      ? [...options.singleSelect, ...options.rangeSelect]
      : options[this.state.isDatePicker];
  }

  xyz = 23;
  yz() {
    console.log("yz");
  }

  renderToggle() {
    const {
      props: { value = {}, onChange },
      state: {
        elementIds,
        parentRepeatingContainers,
        elementIsRepeatingContainer,
        isLocationInput,
        isDatePicker,
      },
    } = this;

    let options = [];
    let dropdownValue = "";

    if (elementIsRepeatingContainer?.isRepeating) {
      dropdownValue = value?.returnRepeatingCotainerData || "order";
      options = [
        ...options,
        {
          value: "order",
          label: "Get Order",
          field: "returnRepeatingCotainerData",
        },
      ];
    }

    if (
      elementIsRepeatingContainer?.isContainerTab ||
      elementIsRepeatingContainer?.isSlider
    ) {
      dropdownValue = value?.containerValueSelector || dropdownValue;
      options = [
        ...options,
        {
          value: "activeContainerTabValue",
          label: `Get Active ${
            elementIsRepeatingContainer?.isSlider ? "Slider" : "Tab"
          } Value`,
          field: "containerValueSelector",
        },
        {
          value: "activeContainerTabOrder",
          label: `Get Active  ${
            elementIsRepeatingContainer?.isSlider ? "Slider" : "Tab"
          }  Order`,
          field: "containerValueSelector",
        },
      ];
    }

    if (!options.length) return null;

    return (
      <div className="linkingAdjustDropdown">
        <div className="secondValueLabel">Get</div>
        <CustomSelect
          {...{
            placeholder: "Select",
            value: dropdownValue,

            onChange: (option) =>
              onChange({ ...value, [option.field]: option.value }),
            options: options,
            classNames: {
              head: "optionInputIconBox",
              label: "optionInputIconBoxField",
              chevron: "optionDatabaseSelectChevron",
            },
          }}
        />
      </div>
    );

    return elementIsRepeatingContainer?.isContainerTab ||
      elementIsRepeatingContainer?.isSlider ? (
      <div>
        <ToggleButton
          classNames={{
            container: "bareGreenTabs",
            item: "",
          }}
          overideDefaultStyle
          value={value?.containerValueSelector || "activeContainerTabValue"}
          onChange={(x) => onChange({ ...value, containerValueSelector: x })}
          options={[
            {
              value: "activeContainerTabValue",
              label: `Active ${
                elementIsRepeatingContainer?.isSlider ? "slider" : "tab"
              } value`,
            },
            {
              value: "activeContainerTabOrder",
              label: `Active  ${
                elementIsRepeatingContainer?.isSlider ? "slider" : "tab"
              }  order`,
            },
          ]}
        />
      </div>
    ) : elementIsRepeatingContainer?.isRepeating ? (
      <div>
        <ToggleButton
          classNames={{
            container: "bareGreenTabs",
            item: "",
          }}
          overideDefaultStyle
          value={value?.returnRepeatingCotainerData || "order"}
          onChange={(x) => {
            console.log("onchange: ", x);
            onChange({
              ...value,
              returnRepeatingCotainerData: x,
            });
          }}
          options={[
            { value: "value", label: "Value" },
            { value: "order", label: "Order" },
          ].filter((x) => x)}
        />
      </div>
    ) : null;
  }

  renderConfigs(opt) {
    const {
      props: { value = {}, onChange },
      state: {
        elementIds,
        parentRepeatingContainers,
        elementIsRepeatingContainer,
        isLocationInput,
        isDatePicker,
        ofSameRepatingContainer,
      },
    } = this;

    if (
      elementIsRepeatingContainer?.isRepeating ||
      parentRepeatingContainers?.length ||
      isLocationInput ||
      isDatePicker
    ) {
      const content = (
        <>
          {elementIsRepeatingContainer?.isRepeating ? (
            <>
              <div className="calcItemUnitMainLabel">Column Name</div>
              <CustomSelect
                {...{
                  placeholder: "Column Name",
                  value: value?.repeatingContainerData?.columnName,

                  onChange: (option) =>
                    onChange({
                      ...value,
                      returnRepeatingCotainerData:
                        value?.returnRepeatingCotainerData || "order",
                      repeatingContainerData: {
                        ...(value?.repeatingContainerData || {}),
                        columnName: option.value,
                      },
                    }),
                  options: elementIsRepeatingContainer?.columns || [],
                  classNames: {
                    head: "optionInputIconBox",
                    label: "optionInputIconBoxField",
                    chevron: "optionDatabaseSelectChevron",
                  },
                }}
              />

              <div className="calcItemUnitMainLabel adjustLabelSpacing">
                Column Value
              </div>
              <div className="calcRteItem">
                <RichTextData
                  {...{
                    ...this.props,
                    placeholder: "Column Value",
                    value: value?.repeatingContainerData?.columnValue?.valueObj,
                    onChange: (valueObj) =>
                      onChange({
                        ...value,
                        returnRepeatingCotainerData:
                          value?.returnRepeatingCotainerData || "order",
                        repeatingContainerData: {
                          ...(value?.repeatingContainerData || {}),
                          columnValue: {
                            valueType: "textParts",
                            valueObj,
                          },
                        },
                      }),
                  }}
                />
              </div>
            </>
          ) : null}

          {parentRepeatingContainers?.length ? (
            <div>
              {value.interveneGetClosest ? (
                <div>
                  {parentRepeatingContainers?.map((repeatingContainer) => {
                    return (
                      <div key={repeatingContainer.id}>
                        <div className="labelRowInfo">
                          <div>Repeating Order</div>
                          <div className="rightTag">
                            #{repeatingContainer.id}
                          </div>
                        </div>
                        <div className="calcRteItem">
                          <RichTextData
                            {...{
                              ...this.props,
                              immediateProps: null,
                              placeholder: "Repeating order",
                              value:
                                value?.repeatingContainerInfo?.[
                                  repeatingContainer.id
                                ]?.valueObj,
                              onChange: (valueObj) =>
                                onChange({
                                  ...value,
                                  repeatingContainerInfo: {
                                    ...(value?.repeatingContainerInfo || {}),
                                    [repeatingContainer.id]: {
                                      valueType: "textParts",
                                      valueObj,
                                    },
                                  },
                                }),
                            }}
                          />
                        </div>
                      </div>
                    );
                  })}
                </div>
              ) : null}

              {/* {ofSameRepatingContainer ? (
                      <div style={{ display: "flex" }}>
                        <input
                          type="checkbox"
                          checked={!value.interveneGetClosest}
                          onChange={() =>
                            onChange({
                              ...value,
                              interveneGetClosest: !value.interveneGetClosest,
                            })
                          }
                        />
                        <Checkbox />
                        Get Closest Element
                      </div>
                    ) : null} */}

              {ofSameRepatingContainer ? (
                <CheckBoxHorizontal
                  wrapperClass=" "
                  className="linkingOpen flexRow bgWhite flex1 br8 "
                  value={[!value.interveneGetClosest]}
                  onChange={(x) =>
                    onChange({
                      ...value,
                      interveneGetClosest: !value.interveneGetClosest,
                    })
                  }
                  options={[{ value: true, label: "Get Closest Element" }]}
                  isVertical
                />
              ) : null}
            </div>
          ) : null}

          {isLocationInput ? (
            <div>
              <CustomSelect
                onChange={(option) =>
                  onChange({
                    ...value,
                    locationSelector: option.value,
                  })
                }
                value={value?.locationSelector || ""}
                options={[
                  { value: "address", label: "Get Address" },
                  { value: "street", label: "Get Street" },
                  { value: "city", label: "Get City" },
                  { value: "state", label: "Get State" },
                  { value: "zipcode", label: "Get Zipcode" },
                  { value: "country", label: "Get Country" },
                ]}
                classNames={{
                  head: "optionInputIconBox",
                  label: "optionInputIconBoxField",
                  chevron: "optionDatabaseSelectChevron",
                }}
              />
            </div>
          ) : null}

          {isDatePicker ? (
            <div>
              <CustomSelect
                onChange={(option) =>
                  onChange({
                    ...value,
                    datePickerSelector: option.value,
                  })
                }
                value={value?.datePickerSelector || ""}
                options={this.datePickerOptins.map((x) => ({
                  ...x,
                  label: x.label?.replace("Get ", "Set "),
                }))}
                classNames={{
                  head: "optionInputIconBox",
                  label: "optionInputIconBoxField",
                  chevron: "optionDatabaseSelectChevron",
                }}
              />
            </div>
          ) : null}
        </>
      );

      if (opt?.wrapper) return opt?.wrapper(content);
      return (
        <div className="calcItemUnit">
          <div className="calcItemUnitBox">
            <div class="calcItemUnitDecor"></div>
            <div class="calcItemUnitMain">
              <div className="linkingAdjustDropdown">{content}</div>
            </div>
          </div>
        </div>
      );
    } else {
      return null;
    }
  }

  render() {
    const {
      props: { value = {}, onChange },
      state: {
        elementIds,
        parentRepeatingContainers,
        elementIsRepeatingContainer,
        isLocationInput,
        isDatePicker,
      },
    } = this;

    // <div className="calcItemUnitMainDropdown">
    //   <div className="calcItemUnitMainDropdownLabel">Container #OH839</div>
    //   <div className="calcItemUnitMainDropdownFooter">
    //     <div className="calcItemUnitMainDropdownFooterArrow">
    //       <div className="one"></div>
    //       <div className="two"></div>
    //     </div>
    //   </div>
    // </div>;

    return (
      <>
        <div className="linkingAdjustDropdown">
          <CustomSelect
            onChange={this.onElementSelect.bind(this)}
            value={value?.id || ""}
            options={elementIds}
            placeholder={
              this.props.immediateProps?.placeholder || "Select Element"
            }
            classNames={{
              head: "optionInputIconBox",
              label: "optionInputIconBoxField",
              chevron: "optionDatabaseSelectChevron",
              ...(this.props.immediateProps?.classNames || {}),
            }}
          />
        </div>

        {this.renderToggle()}
      </>
    );
  }
}

export class SetValueOfElementSelector extends ValueOfElementSelector {
  constructor(props) {
    super(props);
  }

  renderToggle() {
    const {
      props: { value = {}, onChange },
      state: {
        elementIds,
        parentRepeatingContainers,
        elementIsRepeatingContainer,
        isLocationInput,
        isDatePicker,
      },
    } = this;

    let options = [];
    let dropdownValue = "";

    if (elementIsRepeatingContainer?.isRepeating) {
      dropdownValue = value?.setValueType || "";
      options = [
        ...options,
        {
          value: "repeatingValue",
          label: "Set Value",
        },
      ];
    }

    if (
      elementIsRepeatingContainer?.isContainerTab ||
      elementIsRepeatingContainer?.isSlider
    ) {
      dropdownValue = value?.setValueType || "";
      options = [
        ...options,
        {
          value: "activeContainerTabValue",
          label: `Set Active ${
            elementIsRepeatingContainer?.isSlider ? "Slider" : "Tab"
          } Value`,
        },
        {
          value: "activeContainerTabOrder",
          label: `Set Active  ${
            elementIsRepeatingContainer?.isSlider ? "Slider" : "Tab"
          }  Order`,
          field: "containerValueSelector",
        },
      ];
    }

    if (!options.length) return null;

    return (
      <div className="linkingAdjustDropdown">
        <div className="secondValueLabel">Set</div>
        <CustomSelect
          {...{
            placeholder: "Select",
            value: dropdownValue,

            onChange: (option) =>
              onChange({ ...value, setValueType: option.value }),
            options: options,
            classNames: {
              head: "optionInputIconBox",
              label: "optionInputIconBoxField",
              chevron: "optionDatabaseSelectChevron",
            },
          }}
        />
      </div>
    );
  }

  renderConfigs() {
    const {
      props: { value = {}, onChange },
      state: {
        elementIds,
        parentRepeatingContainers,
        elementIsRepeatingContainer,
        isLocationInput,
        isDatePicker,
        ofSameRepatingContainer,
      },
    } = this;

    return (
      <>
        {elementIsRepeatingContainer?.isRepeating ||
        parentRepeatingContainers?.length ? (
          <>
            {elementIsRepeatingContainer?.isRepeating ? (
              <>
                <div className="calcItemDatabaseFilterBox">
                  <div className="calcItemDatabaseFilterBoxInner">
                    <div className="calcItemDatabaseFilterBoxInnerRow">
                      <div className="calcItemDBLabel">Order</div>
                    </div>
                    <RichTextData
                      {...{
                        ...this.props,
                        immediateProps: {
                          classNames: {
                            wrapper: " ",
                            editor: "calcDatabaseFilterMultiBox ",
                            paramListRow: "",
                          },
                        },
                        placeholder: "Type here",
                        value: value?.repeatingContainerData?.order?.valueObj,
                        onChange: (valueObj) => {
                          onChange({
                            ...value,
                            repeatingContainerData: {
                              ...(value?.repeatingContainerData || {}),
                              order: { valueObj, valueType: "textParts" },
                            },
                            setValueType: "repeatingValue",
                          });
                        },
                      }}
                    />
                  </div>
                </div>

                <div className="calcItemDatabaseFilterBox">
                  <div className="calcItemDatabaseFilterBoxInner">
                    <div className="calcItemDatabaseFilterBoxInnerRow">
                      <div className="calcItemDBLabel">Column Name</div>
                    </div>
                    <CustomSelect
                      {...{
                        placeholder: "Column Name",
                        value: value?.repeatingContainerData?.columnName,

                        onChange: (option) =>
                          onChange({
                            ...value,
                            repeatingContainerData: {
                              ...(value?.repeatingContainerData || {}),
                              columnName: option.value,
                            },
                            setValueType: "repeatingValue",
                          }),
                        options: elementIsRepeatingContainer?.columns || [],

                        classNames: {
                          head: "optionInputIconBox",
                          label: "optionInputIconBoxField",
                          chevron: "optionDatabaseSelectChevron",
                        },
                      }}
                    />
                  </div>
                </div>
              </>
            ) : null}

            {parentRepeatingContainers?.length ? (
              <div>
                {value.interveneGetClosest ? (
                  <div>
                    {parentRepeatingContainers?.map((repeatingContainer) => {
                      return (
                        <div key={repeatingContainer.id}>
                          <div className="labelRowInfo">
                            <div>Repeating Order</div>
                            <div className="rightTag">
                              #{repeatingContainer.id}
                            </div>
                          </div>
                          <div className="calcRteItem">
                            <RichTextData
                              {...{
                                ...this.props,
                                immediateProps: null,
                                placeholder: "Repeating order",
                                value:
                                  value?.repeatingContainerInfo?.[
                                    repeatingContainer.id
                                  ]?.valueObj,
                                onChange: (valueObj) =>
                                  onChange({
                                    ...value,
                                    repeatingContainerInfo: {
                                      ...(value?.repeatingContainerInfo || {}),
                                      [repeatingContainer.id]: {
                                        valueType: "textParts",
                                        valueObj,
                                      },
                                    },
                                  }),
                              }}
                            />
                          </div>
                        </div>
                      );
                    })}
                  </div>
                ) : null}

                {ofSameRepatingContainer ? (
                  <CheckBoxHorizontal
                    wrapperClass=" "
                    className="linkingOpen flexRow bgWhite flex1 br8 "
                    value={[!value.interveneGetClosest]}
                    onChange={(x) =>
                      onChange({
                        ...value,
                        interveneGetClosest: !value.interveneGetClosest,
                      })
                    }
                    options={[{ value: true, label: "Get Closest Element" }]}
                    isVertical
                  />
                ) : null}
              </div>
            ) : null}

            {/* {isLocationInput ? (
              <div>
                <CustomSelect
                  onChange={(option) =>
                    onChange({
                      ...value,
                      locationSelector: option.value,
                    })
                  }
                  value={value?.locationSelector || ""}
                  options={[
                    { value: "address", label: "Set Address" },
                    { value: "street", label: "Set Street" },
                    { value: "city", label: "Set City" },
                    { value: "state", label: "Set State" },
                    { value: "zipcode", label: "Set Zipcode" },
                    { value: "country", label: "Set Country" },
                  ]}
                  classNames={{
                    head: "optionInputIconBox",
                    label: "optionInputIconBoxField",
                    chevron: "optionDatabaseSelectChevron",
                  }}
                />
              </div>
            ) : null}

            {isDatePicker ? (
              <div>
                <CustomSelect
                  onChange={(option) =>
                    onChange({
                      ...value,
                      datePickerSelector: option.value,
                    })
                  }
                  value={value?.datePickerSelector || ""}
                  options={this.datePickerOptins}
                  classNames={{
                    head: "optionInputIconBox",
                    label: "optionInputIconBoxField",
                    chevron: "optionDatabaseSelectChevron",
                  }}
                />
              </div>
            ) : null} */}
          </>
        ) : null}
      </>
    );
  }

  render() {
    const {
      props: { value = {}, onChange },
      state: {
        elementIds,
        parentRepeatingContainers,
        elementIsRepeatingContainer,
        isLocationInput,
        isDatePicker,
      },
    } = this;

    return (
      <>
        <div className="linkingAdjustDropdown">
          <CustomSelect
            onChange={this.onElementSelect.bind(this)}
            value={value?.id || ""}
            options={elementIds}
            placeholder={
              this.props.immediateProps?.placeholder || "Select Element"
            }
            classNames={{
              head: "optionInputIconBox",
              label: "optionInputIconBoxField",
              chevron: "optionDatabaseSelectChevron",
              ...(this.props.immediateProps?.classNames || {}),
            }}
          />
        </div>

        {this.renderToggle()}
      </>
    );
  }
}

export default ValueOfElementSelector;
