import React, { useEffect, useState } from "react";
import OptionItem from "../../../../../Components/etc/OptionItem";
import ControlledInput from "../../../../../Components/Input/ControlledInput";
import CustomSelect from "../../../../../Components/etc/CustomSelect";
import ColorButton from "../../../../../Components/Input/ColorButton";
const MediaAppearance = (props) => {
  const {
    value = {},
    onChange,
    styles = {},
    changeStyles,
    getStyleValue,
  } = props;
  const mergeStyles = (obj) => changeStyles({ ...styles, ...obj });

  const mediaData = value.mediaData || {};
  const changeMediaData = (mediaData) => onChange({ ...value, mediaData });

  const mediaType = mediaData.mediaType;

  const mediaConfig = mediaData[mediaType] || {};
  const mergeMediaConfig = (obj) =>
    changeMediaData({ ...mediaData, [mediaType]: { ...mediaConfig, ...obj } });

  const playerConfig = mediaConfig.playerConfig || {};
  const mergePlayerConfig = (obj) =>
    mergeMediaConfig({ playerConfig: { ...playerConfig, ...obj } });

  const mode =
    mediaType === "video"
      ? playerConfig.showDuration === false &&
        playerConfig.showPlayButton === false &&
        playerConfig.showFullscreenButton === false
        ? "min"
        : playerConfig.showDuration !== false &&
          playerConfig.showPlayButton !== false &&
          playerConfig.showFullscreenButton !== false
        ? "full"
        : null
      : null;

  const setMode = (option) => {
    let updateObj = {
      showDuration: true,
      showPlayButton: true,
      showFullscreenButton: true,
    };

    if (option.value === "min") {
      updateObj = {
        showDuration: false,
        showPlayButton: false,
        showFullscreenButton: false,
      };
    }

    mergePlayerConfig(updateObj);
  };

  return (
    <>
      <OptionItem optionItemLabel={"Appearance"}>
        <div className="optionItemBody revealOffset">
          <div className="optionItemRow">
            <div className="optionItemBox">
              <div>
                <div className="optionInputIconBox">
                  <img
                    className="optionInputIconBoxIco"
                    src={require("../../../../../Assets/img/options/container/widthIco.png")}
                    alt="width"
                  ></img>
                  <ControlledInput
                    type="text"
                    value={getStyleValue(styles, "width")}
                    onChange={(event) =>
                      mergeStyles({ width: event.target.value })
                    }
                    className="optionInputIconBoxField"
                    placeholder="Auto"
                  />
                </div>
              </div>
            </div>
            <div className="optionItemBox">
              <div className="optionInputIconBox">
                <img
                  className="optionInputIconBoxIco rotate90"
                  src={require("../../../../../Assets/img/options/container/widthIco.png")}
                  alt="height"
                ></img>
                <ControlledInput
                  type="text"
                  onChange={(event) =>
                    mergeStyles({ height: event.target.value })
                  }
                  className="optionInputIconBoxField"
                  placeholder="Auto"
                  value={getStyleValue(styles, "height")}
                />
              </div>
            </div>
            {/* <div className="optionItemBox">
              <div>
                <div className="oibLabel">Type</div>
                <div>
                  <CustomSelect
                    onChange={(option) =>
                      onChange({
                        mediaData: {
                          ...(value.mediaData || {}),
                          mediaType: option.value,
                        },
                      })
                    }
                    value={value.mediaData?.mediaType || "video"}
                    options={[
                      { value: "video", label: "Video" },
                      { value: "audio", label: "Audio" },
                      { value: "chart", label: "Chart" },
                    ]}
                    placeholder={"Select"}
                    classNames={{
                      head: "optionInputIconBox",
                      label: "optionInputIconBoxField",
                      chevron: "optionDatabaseSelectChevron",
                    }}
                  />
                </div>
              </div>
            </div> */}
          </div>

          {["video"].includes(mediaType) ? (
            <div className="optionItemRow">
              <div className="optionItemBox">
                <div>
                  <div>
                    <CustomSelect
                      onChange={setMode}
                      value={mode}
                      options={[
                        { value: "full", label: "Full" },
                        { value: "min", label: "Minimal" },
                      ]}
                      placeholder={"Select Mode"}
                      classNames={{
                        head: "optionInputIconBox",
                        label: "optionInputIconBoxField",
                        chevron: "optionDatabaseSelectChevron",
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className="optionItemBox">
                <div>
                  <div>
                    <CustomSelect
                      onChange={(option) =>
                        mergePlayerConfig({ autoPlay: option.value })
                      }
                      value={playerConfig.autoPlay || false}
                      options={[
                        { value: true, label: "Autoplay On" },
                        { value: false, label: "Autoplay Off" },
                      ]}
                      placeholder={"Select"}
                      classNames={{
                        head: "optionInputIconBox",
                        label: "optionInputIconBoxField",
                        chevron: "optionDatabaseSelectChevron",
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          ) : null}
        </div>

        <div className="optionItemHead pad5t">
          <div className="optionItemLabel">Colors</div>
        </div>

        <div className="optionItemBody revealOffset colorUnitRow">
          {["video", "audio"].includes(mediaType) ? (
            <div className="optionItemRow">
              <div className="optionItemBox">
                <div className="optionColorItem">
                  <ColorButton
                    className="optionColorCircle"
                    value={styles?.backgroundColor || "#000"}
                    onChange={(val) => mergeStyles({ backgroundColor: val })}
                  />
                  <div className="optionInputIconBoxText">Player</div>
                </div>
              </div>
              <div className="optionItemBox">
                <div className="optionColorItem">
                  <ColorButton
                    className="optionColorCircle"
                    value={playerConfig?.trackColor || "#e15454ff"}
                    onChange={(val) => mergePlayerConfig({ trackColor: val })}
                  />
                  <div className="optionInputIconBoxText">Track</div>
                </div>
              </div>
              <div className="optionItemBox">
                <div className="optionColorItem">
                  <ColorButton
                    className="optionColorCircle"
                    value={playerConfig?.trackBarColor || "#594f4fff"}
                    onChange={(val) =>
                      mergePlayerConfig({ trackBarColor: val })
                    }
                  />
                  <div className="optionInputIconBoxText">Bar</div>
                </div>
              </div>
            </div>
          ) : (
            <div className="optionItemRow">
              <div className="optionItemBox">
                <div className="optionColorItem">
                  <ColorButton
                    className="optionColorCircle"
                    value={styles?.backgroundColor}
                    onChange={(val) => mergeStyles({ backgroundColor: val })}
                  />
                  <div className="optionInputIconBoxText">Background</div>
                </div>
              </div>
            </div>
          )}
        </div>
      </OptionItem>
    </>
  );
};

export default MediaAppearance;
