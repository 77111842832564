import React from "react";
import OptionItem from "../../../../../Components/etc/OptionItem";
import CustomSelect from "../../../../../Components/etc/CustomSelect";
import OptionToggle from "../../../../../Components/Input/OptionToggle";
import ToggleButton from "../../../../../Components/Input/ToggleButton";
import Switch from "../../../../../Components/Input/Switch";
import ColorButton from "../../../../../Components/Input/ColorButton";

export const VideoDataConfig = (props) => {
  const value = props.value || {};
  const onChange = (obj) => props.onChange({ ...value, ...obj });

  const defaultMap = (key, defaultValue = "") => ({
    key,
    value: value[key] === undefined ? defaultValue : value[key],
    onChange: (x) => onChange({ [key]: x }),
  });

  const itemMap = {
    trackColor: {
      ...defaultMap("trackColor", ""),
      label: "Track Color",
      type: "colorPicker",
    },
    trackBarColor: {
      ...defaultMap("trackBarColor", ""),
      label: "Track Bar Color",
      type: "colorPicker",
    },
    showDuration: {
      ...defaultMap("showDuration", true),
      label: "Duration",
      options: [
        { value: true, label: "Show" },
        { value: false, label: "Hide" },
      ],
      type: "dropdown",
    },
    showPlayButton: {
      ...defaultMap("showPlayButton", true),
      label: "Play Button",
      options: [
        { value: true, label: "Show" },
        { value: false, label: "Hide" },
      ],
      type: "dropdown",
    },
    showFullscreenButton: {
      ...defaultMap("showFullscreenButton", true),
      label: "Fullscreen",
      options: [
        { value: true, label: "Show" },
        { value: false, label: "Hide" },
      ],
      type: "dropdown",
    },
    autoPlay: {
      ...defaultMap("autoPlay", false),
      label: "Autoplay",
      type: "checkbox",
    },
  };

  return (
    <div className="optionItemBody">
      {[
        ["trackColor", "trackBarColor"],
        ["showDuration", "showPlayButton"],
        ["showFullscreenButton", "autoPlay"],
      ].map((row, index) => (
        <div key={index} className="optionItemRow bionicOptionsWrapper">
          {row
            .map((x) => itemMap[x])
            .map((item) =>
              item ? (
                <div key={item.key} className="bionicOptions">
                  <div className="optionItemListLabel">{item.label}</div>

                  <RenderItem {...{ item }} />
                </div>
              ) : null
            )}
        </div>
      ))}
    </div>
  );
};

export const AudioDataConfig = (props) => {
  const value = props.value || {};
  const onChange = (obj) => props.onChange({ ...value, ...obj });

  const defaultMap = (key, defaultValue = "") => ({
    key,
    value: value[key] === undefined ? defaultValue : value[key],
    onChange: (x) => onChange({ [key]: x }),
  });

  const itemMap = {
    trackColor: {
      ...defaultMap("trackColor", ""),
      label: "Track Color",
      type: "colorPicker",
    },
    trackBarColor: {
      ...defaultMap("trackBarColor", ""),
      label: "Track Bar Color",
      type: "colorPicker",
    },
    showDuration: {
      ...defaultMap("showDuration", true),
      label: "Duration",
      options: [
        { value: true, label: "Show" },
        { value: false, label: "Hide" },
      ],
      type: "dropdown",
    },
  };

  return (
    <div className="optionItemBody">
      {[["trackColor", "trackBarColor"], ["showDuration"]].map((row, index) => (
        <div key={index} className="optionItemRow bionicOptionsWrapper">
          {row
            .map((x) => itemMap[x])
            .map((item) =>
              item ? (
                <div key={item.key} className="bionicOptions">
                  <div className="optionItemListLabel">{item.label}</div>

                  <RenderItem {...{ item }} />
                </div>
              ) : null
            )}
        </div>
      ))}
    </div>
  );
};

const RenderItem = ({ item }) => {
  if (item.type === "colorPicker") {
    return <ColorButton className="optionColorCircle" {...item} />;
  } else if (item.type === "dropdown") {
    return (
      <CustomSelect
        {...{
          ...item,
          onChange: (option) => item.onChange(option.value),
          classNames: {
            head: "optionInputIconBox",
            label: "optionInputIconBoxField",
            chevron: "optionDatabaseSelectChevron",
          },
        }}
      />
    );
  } else if (item.type === "checkbox") {
    return (
      <div
        // TODO: temprorary style block, remove it after adding classNames
        style={{
          width: "30px",
          height: "30px",
          borderRadius: "4px",
          backgroundColor: item.value ? "green" : "white",
        }}
        className={"" + (item.value ? " active" : "")}
        onClick={() => item.onChange(!item.value)}
      ></div>
    );
  } else return null;
};
