import React from "react";
import _ from "lodash";

import { LinkingOptionItemBody } from "../../../Components/etc/OptionItem";
import CustomSelect from "../../../Components/etc/CustomSelect";
import ValueOfElementSelector from "../DataQueryOperations/ValueOfElementSelector";
import RichTextData from "../../Builder/Properties/Common/DataProperties/RichTextData";
import linkingObj from "../../../appxolo-engine/modules/linking/linking-obj";
import ToggleButton from "../../../Components/Input/ToggleButton";
import OptionToggle from "../../../Components/Input/OptionToggle";

const footerActions = [
  // {
  //   valueTypes: ["joinWebrtcRoom", "leaveWebrtcRoom"],
  //   value: "roomType",
  //   label: "Join With",
  //   options: [
  //     { value: "video", label: "Video" },
  //     { value: "audio", label: "Audio" },
  //   ],
  // },
  {
    valueTypes: ["camera"],
    value: "action",
    label: "Action",
    options: [
      { value: "toggleCamera", label: "Toggle" },
      { value: "flipCamera", label: "Flip Camera" },
    ],
  },
  {
    valueTypes: ["microphone"],
    value: "action",
    label: "Action",
    options: [{ value: "toggleMicrophone", label: "Toggle" }],
  },
];

const LinkToBroadcast = (props) => {
  const { value = {}, onChange, linkBoxOptions, triggerOption } = props;
  const mergeChange = (obj) => onChange({ ...value, ...obj });

  const valueTypes = linkingObj.valueTypes.find(
    (x) => x.value === "broadcast"
  ).valueTypes;

  const footerAction = footerActions.find((x) =>
    x.valueTypes.includes(value.valueType)
  );

  return (
    <>
      <LinkingOptionItemBody {...props}>
        <div className="databaseOperationModule">
          <div className="domHeader">
            <div className="domLabel">Behavior</div>

            <CustomSelect
              value={value.valueType || ""}
              onChange={(option) => mergeChange({ valueType: option.value })}
              options={valueTypes}
              placeholder={"Select"}
              classNames={{
                head: "calcItemUnitMainDropdown spaceTopify",
                label: "calcItemUnitMainDropdownLabel",
              }}
              jointActionRow={
                <div className="calcItemUnitMainDropdownFooter">
                  <div className="calcItemUnitMainDropdownFooterArrow">
                    <div className="one"></div>
                    <div className="two"></div>
                  </div>
                </div>
              }
            />
          </div>

          <div className="domBody">
            {["joinWebrtcRoom"].includes(value.valueType) ? (
              <>
                <div className="calcItemDatabaseFilterBox">
                  <div className="calcItemDatabaseFilterBoxInner">
                    <div className="calcItemDatabaseFilterBoxInnerRow">
                      <div className="calcItemDBLabel">Room ID</div>
                    </div>
                    <RichTextData
                      {...{
                        ...props,
                        immediateProps: {
                          classNames: {
                            wrapper: " ",
                            editor: "calcDatabaseFilterMultiBox ",
                            paramListRow: "",
                          },
                        },
                        placeholder: "Type here",
                        value: value?.roomId?.valueObj,
                        onChange: (valueObj) => {
                          mergeChange({
                            roomId: {
                              valueObj,
                              valueType: "textParts",
                            },
                          });
                        },
                      }}
                    />
                  </div>
                </div>
              </>
            ) : null}

            {["joinWebrtcRoom"].includes(value.valueType) ? (
              <>
                <div className="calcItemDatabaseFilterBox">
                  <div className="calcItemDatabaseFilterBoxInner">
                    <div className="calcItemDatabaseFilterBoxInnerRow">
                      <div className="calcItemDBLabel">Participant ID</div>
                    </div>
                    <RichTextData
                      {...{
                        ...props,
                        immediateProps: {
                          classNames: {
                            wrapper: " ",
                            editor: "calcDatabaseFilterMultiBox ",
                            paramListRow: "",
                          },
                        },
                        placeholder: "Type here",
                        value: value?.participantId?.valueObj,
                        onChange: (valueObj) => {
                          mergeChange({
                            participantId: {
                              valueObj,
                              valueType: "textParts",
                            },
                          });
                        },
                      }}
                    />
                  </div>
                </div>

                <div className="calcItemDatabaseFilterBox">
                  <div className="calcItemDatabaseFilterBoxInner">
                    <div className="calcItemDatabaseFilterBoxInnerRow">
                      <div className="calcItemDBLabel">Permission</div>
                    </div>

                    <CustomSelect
                      value={value?.permission?.valueObj?.customText || "video"}
                      onChange={(option) => {
                        mergeChange({
                          permission: {
                            valueObj: { customText: option.value },
                            valueType: "customText",
                          },
                        });
                      }}
                      options={[
                        { value: "video", label: "Video" },
                        { value: "audio", label: "Audio" },
                        { value: "viewer", label: "Off" },
                      ]}
                      placeholder={"Select"}
                      classNames={{
                        head: "calcItemUnitMainDropdown spaceTopify",
                        label: "calcItemUnitMainDropdownLabel",
                      }}
                      jointActionRow={
                        <div className="calcItemUnitMainDropdownFooter">
                          <div className="calcItemUnitMainDropdownFooterArrow">
                            <div className="one"></div>
                            <div className="two"></div>
                          </div>
                        </div>
                      }
                    />
                  </div>
                </div>

                <div className="calcItemDatabaseFilterBox">
                  <div className="calcItemDatabaseFilterBoxInner">
                    <div className="calcItemDatabaseFilterBoxInnerRow">
                      <div className="calcItemDBLabel">Stream Control</div>
                    </div>

                    <div
                      style={{
                        display: "flex",
                        backgroundColor: "#efefef",
                        borderRadius: "10px",
                      }}
                    >
                      <OptionToggle
                        value={
                          value?.streamControl?.valueObj?.customText === "on"
                            ? "on"
                            : ""
                        }
                        onChange={(option) => {
                          mergeChange({
                            streamControl: {
                              valueObj: { customText: option.value },
                              valueType: "customText",
                            },
                          });
                        }}
                        options={[
                          { value: "on", label: "On" },
                          { value: "", label: "Off" },
                        ]}
                      />
                    </div>
                    {/* <ToggleButton
                      classNames={{
                        container: "optionHeadTabs headTabsDark",
                        item: "optionHeadTabItem",
                      }}
                      overideDefaultStyle
                      value={
                        value?.streamControl?.valueObj?.customText === "on"
                          ? "on"
                          : ""
                      }
                      onChange={(x) => {
                        mergeChange({
                          streamControl: {
                            valueObj: { customText: x ? "on" : "off" },
                            valueType: "customText",
                          },
                        });
                      }}
                      options={[
                        { value: "on", label: "On" },
                        { value: "", label: "Off" },
                      ]}
                    /> */}
                  </div>
                </div>
              </>
            ) : null}
          </div>
        </div>
      </LinkingOptionItemBody>

      {linkBoxOptions.showTriggerOption || footerAction ? (
        <div className="linkingBoxFooter">
          {footerAction ? (
            <div className="optionItemBody">
              <div className="linkLabel">{footerAction.label}</div>
              <div className="optionItemRow">
                <CustomSelect
                  value={
                    value[footerAction.value] ||
                    footerAction.options?.[0]?.value ||
                    ""
                  }
                  onChange={(option) =>
                    mergeChange({ [footerAction.value]: option.value })
                  }
                  options={footerAction.options}
                  placeholder={"Select"}
                  className="linkingToBox flexColumn flex1 br10 pad6"
                  classNames={{
                    head: "pad6 flexRow aCenter",
                    label: "cDark flex1 cPurple",
                    chevron: "optionDatabaseSortKeyIco",
                  }}
                />
              </div>
            </div>
          ) : null}

          {linkBoxOptions.showTriggerOption ? triggerOption : null}
        </div>
      ) : null}
    </>
  );
};

export default LinkToBroadcast;
