import React from "react";

const renderIcon = (src) => (
  <img className="" style={{ width: "30px" }} src={src} alt="icon" />
);

const dateTimeToolOptions = [
  {
    options: [
      {
        value: "FORMAT_DATE",
        label: "Reformat",
        renderIcon: () =>
          renderIcon(require("../../Assets/img/dateTimeIcons/reformat.png")),
      },
      {
        value: "DAYS_BETWEEN",
        label: "Count Days",
        renderIcon: () =>
          renderIcon(require("../../Assets/img/dateTimeIcons/count.png")),
      },
    ],
  },
  {
    options: [
      {
        value: "ADD_DAYS",
        label: "Add Days",
        renderIcon: () =>
          renderIcon(require("../../Assets/img/dateTimeIcons/plusBoxIco.png")),
      },
      {
        value: "SUBTRACT_DAYS",
        label: "Minus Days",
        renderIcon: () =>
          renderIcon(require("../../Assets/img/dateTimeIcons/minusIco.png")),
      },
    ],
  },
  {
    options: [
      {
        value: "ADD_TIME",
        label: "Add Time",
        renderIcon: () =>
          renderIcon(require("../../Assets/img/dateTimeIcons/plusBoxIco.png")),
      },
      {
        value: "SUBTRACT_TIME",
        label: "Minus Time",
        renderIcon: () =>
          renderIcon(require("../../Assets/img/dateTimeIcons/minusIco.png")),
      },
    ],
  },
];
export const dateToolInfo = {
  dateTimeToolOptions,
  dateTimeToolOperators: dateTimeToolOptions.flatMap((x) =>
    x.options.map((x) => x.value)
  ),
  reformatOptions: [
    { label: "To Date", value: "MM/dd/yyyy" },
    { label: "To Time", value: "HH:mm" },
    {
      label: "To Date + Time",
      value: "MM/dd/yyyy hh:mm a",
    },
    { label: "To Duration", value: "DURATION" },
    {
      label: "To Global",
      value: "ISO",
    },
    {
      label: "To Custom",
      value: "CUSTOM:",
    },
  ],
  getReformatOptionValue: (value) => {
    if (/^CUSTOM:.*/.test(value || "")) return "CUSTOM:";
    else return value;
  },
};
