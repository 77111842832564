import React from "react";
import update from "immutability-helper";

import MapIcon from "./MapIcon";
import DatabaseDataSelector from "../../../../Common/DatabaseOperations/DatabaseDataSelector";
import {
  LinkingBoxes,
  LinkingBoxesPopup,
} from "../../../../Common/Linking/LinkingBox";
import InputSelector from "../../../../Common/DataQueryOperations/InputSelector";
import ToggleButton from "../../../../../Components/Input/ToggleButton";
import ImageData from "../image/ImageData";
import CustomSelect from "../../../../../Components/etc/CustomSelect";
import RichTextData from "../../Common/DataProperties/RichTextData";
import MapCenter from "./MapCenter";
import { RepeatingDatabaseSelector } from "../../../../Common/DatabaseOperations_v2/DatabaseDataSelector";

const RepeatingMark = (props) => {
  const { value, onChange } = props;

  const locationObj = value || {};
  const setLocationObj = (obj) => {
    onChange(update(locationObj, { $merge: obj }));
  };
  const setTableObj = (obj) => {
    setLocationObj({
      tableObj: update(locationObj.tableObj || {}, { $merge: obj }),
    });
  };

  const filterResults = {
    radius: locationObj.radius || "",
    origin: locationObj.origin || {},
  };

  let passedParameter = {
    uid: "repeatingMapMarker",
    dbData: {
      dbId: locationObj.tableObj?.dbId,
      tableId: locationObj.tableObj?.tableId,
      table: props.databases
        ?.find((x) => x._id === locationObj.tableObj?.dbId)
        ?.tables?.find((x) => x._id === locationObj.tableObj?.tableId),
    },
    sourceType: "repeatingMapMarker",
  };

  return (
    <>
      <div className="mapPrimaryUnit">
        <div className="calcItemUnit">
          <RepeatingDatabaseSelector
            {...{
              ...props,
              value: locationObj.tableObj,
              onChange: (x) => setTableObj(x),

              valueTypeData: { tableSelector: { maxNoOfInputs: 0 } },
              immediateProps: {
                externalApi: false,
                webrtcStreams: false,
                databaseSortingQuery: false,
                pagination: false,
              },
            }}
          />

          <div className="calcItemUnitFooter">
            <div className="calcItemUnitMain">
              <div className="calcItemUnitMainLabel">Address</div>

              <ColumnSelector
                {...{
                  ...props,
                  value: locationObj?.tableObj?.columns?.[0],
                  tableObj: locationObj?.tableObj,
                  placeholder: "Select Column",
                  onChange: (x) =>
                    setLocationObj({
                      tableObj: {
                        ...(locationObj?.tableObj || {}),
                        columns: update(locationObj?.tableObj?.columns || [], {
                          $merge: { [0]: x },
                        }),
                      },
                    }),
                }}
              />
            </div>

            {/* {props.routerVsMarkerCheckbox} */}
            {/* {props.themeSelectors.dropdown} */}

            <div className="calcItemUnitMain">
              <div className="optionDatabaseBoxLabelMultiUnit">
                <div className="calcItemUnitMainLabel">Map Icon</div>
                <ToggleButton
                  classNames={{
                    container: "optionHeadTabs headTabsDark",
                    item: "optionHeadTabItem",
                  }}
                  overideDefaultStyle
                  value={locationObj.icon?.valueType || "database"}
                  onChange={(x) =>
                    setLocationObj({
                      icon: {
                        ...(locationObj.icon || {}),
                        valueType: x,
                      },
                    })
                  }
                  options={[
                    { value: "database", label: "Database" },
                    { value: "textParts", label: "Custom" },
                  ]}
                  setDefaultOption="database"
                />
              </div>

              <IconData
                {...props}
                locationObj={locationObj}
                setLocationObj={setLocationObj}
                value={locationObj.icon}
                onChange={(x) => {
                  setLocationObj({
                    icon: {
                      ...locationObj.icon,
                      ...x,
                    },
                  });
                }}
              />
            </div>

            <LinkingBoxesPopup
              {...{
                ...props,
                passedParameters: [...props.passedParameters, passedParameter],
                value: locationObj.linkings,
                onChange: (linkings) => setLocationObj({ linkings }),
                autoAddOneEmptyLinking: false,
                linkBoxOptions: {},
              }}
            />
          </div>
        </div>
      </div>

      {/* <DatabaseDataSelector
        {...{
          ...props,
          value: locationObj.tableObj,
          onChange: (x) => setTableObj(x),
          valueType: "tableSelector-valueOfColumn",
          valueTypeData: {
            tableSelector: {
              // additionalInput: true,
              minNoOfInputs: 0,
              maxNoOfInputs: 0,
            },
            label: "Address",
          },
        }}
      />

      <div className="optionMapDatabaseWrapper">
        <div className="optionMapDatabase">
          <div className="optionItemBody">
            <div className="optionItemRow filterItemUnit">
              <div className="optionDatabaseBoxWrapper">
                <div className="optionDatabaseBox">
                  <div className="optionDatabaseFilters">
                    <div className="addressSelector mt-10">
                      <div className="optionDatabaseBoxLabel">Address</div>
                      <ColumnSelector
                        {...{
                          ...props,
                          value: locationObj?.tableObj?.columns?.[0],
                          tableObj: locationObj?.tableObj,
                          onChange: (x) =>
                            setLocationObj({
                              tableObj: {
                                ...(locationObj?.tableObj || {}),
                                columns: update(
                                  locationObj?.tableObj?.columns || [],
                                  { $merge: { [0]: x } }
                                ),
                              },
                            }),
                        }}
                      />
                    </div>
                    <div className="addressSelector mapIconSection">
                      <div className="optionDatabaseBoxLabelMultiUnit">
                        <div className="optionDatabaseBoxLabel">Map Icon</div>
                        <ToggleButton
                          classNames={{
                            container: "optionHeadTabs headTabsDark",
                            item: "optionHeadTabItem",
                          }}
                          overideDefaultStyle
                          value={locationObj.icon?.valueType || "database"}
                          onChange={(x) =>
                            setLocationObj({
                              icon: {
                                ...(locationObj.icon || {}),
                                valueType: x,
                              },
                            })
                          }
                          options={[
                            { value: "database", label: "Database" },
                            { value: "textParts", label: "Custom" },
                          ]}
                          setDefaultOption="database"
                        />
                      </div>
                      <div className="optionItemRow optionItemIconDimension">
                        <div className="optionItemBox">
                          <div className="optionInputIconBox">
                            <img
                              className="optionInputIconBoxArrowIco"
                              src={require("../../../../../Assets/img/options/container/widthIco.png")}
                            ></img>
                            <input
                              type="text"
                              className="optionInputIconBoxSpacingField"
                              placeholder="25px"
                              value={locationObj.icon?.width || ""}
                              onChange={(e) =>
                                setLocationObj({
                                  icon: {
                                    ...(locationObj.icon || {}),
                                    width: e.target.value,
                                  },
                                })
                              }
                            />
                          </div>
                        </div>
                        <div className="optionItemBox">
                          <div className="optionInputIconBox">
                            <img
                              className="optionInputIconBoxArrowIco rotate90"
                              src={require("../../../../../Assets/img/options/container/widthIco.png")}
                            ></img>
                            <input
                              type="text"
                              className="optionInputIconBoxSpacingField"
                              placeholder="25px"
                              value={locationObj.icon?.height || ""}
                              onChange={(e) =>
                                setLocationObj({
                                  icon: {
                                    ...(locationObj.icon || {}),
                                    height: e.target.value,
                                  },
                                })
                              }
                            />
                          </div>
                        </div>
                      </div>

                      <IconData
                        {...props}
                        locationObj={locationObj}
                        setLocationObj={setLocationObj}
                        value={locationObj.icon}
                        onChange={(x) => {
                          setLocationObj({
                            icon: {
                              ...locationObj.icon,
                              ...x,
                            },
                          });
                        }}
                      />
                    </div>

                    <FilterResults
                      {...props}
                      value={filterResults}
                      onChangeObj={setLocationObj}
                    />

                    <div className="addressSelector">
                      <div className="optionDatabaseTextBox mt10">
                        <div className="optionDatabaseTextBoxLabel">
                          Map Center
                        </div>
                        <div className="optionDatabaseTextBoxBody">
                          <MapCenter
                            {...props}
                            value={props.mapCenter}
                            onChange={(value) => props.changeMapCenter(value)}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="addressSelector">
                      <LinkingBoxesPopup
                        {...{
                          ...props,
                          passedParameters: [
                            ...props.passedParameters,
                            passedParameter,
                          ],
                          value: locationObj.linkings,
                          onChange: (linkings) => setLocationObj({ linkings }),
                          autoAddOneEmptyLinking: false,
                          linkBoxOptions: {},
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}
    </>
  );
};

const ColumnSelector = (props) => {
  const { value, onChange, tableObj = {} } = props;

  const database = props.databases?.find((x) => x._id === tableObj.dbId);
  const table = database?.tables?.find((x) => x._id === tableObj.tableId);
  const databaseColumns = table?.columns || [];

  return (
    <CustomSelect
      value={value || ""}
      onChange={(option) => onChange(option.value)}
      options={databaseColumns.map((x) => ({
        value: x.name,
        label: x.name,
      }))}
      placeholder={"Select Column"}
      classNames={{
        head: "calcItemUnitMainDropdown",
        label: "calcItemUnitMainDropdownLabel",
      }}
      jointActionRow={
        <div className="calcItemUnitMainDropdownFooter">
          <div className="calcItemUnitMainDropdownFooterArrow">
            <div className="one"></div>
            <div className="two"></div>
          </div>
        </div>
      }
    />
  );
};

class IconData extends ImageData {
  databaseColumnConfig() {
    return (
      <ColumnSelector
        {...{
          ...this.props,
          value: this.props.locationObj?.tableObj?.columns?.[1],
          tableObj: this.props.locationObj?.tableObj,
          onChange: (x) =>
            this.props.setLocationObj({
              icon: {
                ...(this.props.locationObj?.icon || {}),
                valueType: "database",
              },
              tableObj: {
                ...(this.props.locationObj?.tableObj || {}),
                columns: update(
                  this.props.locationObj?.tableObj?.columns || [],
                  {
                    $merge: { [1]: x },
                  }
                ),
              },
            }),
        }}
      />
    );
  }

  render() {
    return this.props.value?.valueType === "textParts"
      ? this.textPartsConfig()
      : this.databaseColumnConfig();
  }
}

const FilterResults = (props) => {
  const { value, onChangeObj } = props;

  const filterResults = value;
  const setFilterResultsObj = onChangeObj;

  return (
    <div className="addressSelector">
      <div className="optionDatabaseBoxLabel">Markers</div>
      <div className="optionDatabaseTextBox mt10">
        <div className="optionDatabaseTextBoxLabel">Plot Center</div>
        <div className="optionDatabaseTextBoxBody">
          <RichTextData
            {...{
              ...props,
              key: "plotCenter",
              placeholder: "",
              value: filterResults.plotCenter?.valueObj || {},
              onChange: (valueObj) =>
                setFilterResultsObj({
                  plotCenter: {
                    valueObj,
                    valueType: "textParts",
                  },
                }),
            }}
          />
        </div>
      </div>
      <div className="optionDatabaseTextBox mt10">
        <div className="optionDatabaseTextBoxLabel">Within radius of</div>
        <div className="optionDatabaseTextBoxBody">
          <RichTextData
            {...{
              ...props,
              key: "radius",
              placeholder: "",
              value: filterResults.radius?.valueObj || {},
              onChange: (valueObj) =>
                setFilterResultsObj({
                  radius: {
                    valueObj,
                    valueType: "textParts",
                  },
                }),
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default RepeatingMark;
