import React from "react";
import { Link } from "react-router-dom";

const AccountHeader = ({ subtitle, activeLink }) => {
  return (
    <div className="upHeader">
      <div className="upHeaderTop">
        <div className="upHeaderRow">
          <div className="upHeaderContext">
            <div className="upTitle">My Account</div>
            <div className="upSubtitle">{subtitle}</div>
          </div>
        </div>

        <div className="upTabs">
          {[
            { link: "/account/details", label: "Details" },
            { link: "/account/billing", label: "Billing" },
            { link: "/account/security", label: "Security" },
          ].map(({ link, label }) => (
            <Link
              key={link}
              className={"upTabsItem" + (activeLink === link ? " active" : "")}
              to={link}
            >
              {label}
            </Link>
          ))}
        </div>
      </div>
    </div>
  );
};

export default AccountHeader;
