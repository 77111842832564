import { v4 as uuidv4 } from "uuid";

const etcModule = {
  getDeviceToken: () => {
    let deviceToken = localStorage.getItem("DEVICE_TOKEN");
    if (!deviceToken) {
      deviceToken = uuidv4();
      localStorage.setItem("DEVICE_TOKEN", deviceToken);
    }

    return deviceToken;
  },

  mergeObjects: (x, y, keys) => {
    if (!keys.length) {
      return { ...x, ...y };
    }

    const [currentKey, ...remainingKeys] = keys;
    const newX = { ...(x || {}) };

    newX[currentKey] = etcModule.mergeObjects(
      newX[currentKey],
      y,
      remainingKeys
    );
    return newX;
  },

  toViewableDateFormat: (d) => {
    const date = new Date(d);

    let dd = date.getDate();
    let MM = date.getMonth();
    let yyyy = date.getFullYear();

    if (isNaN(dd)) dd = 0;
    if (isNaN(MM)) MM = 0;
    if (isNaN(yyyy)) yyyy = "0000";

    yyyy = yyyy.toString();

    return `${etcModule.shortMonths[MM]} ${dd}, ${yyyy}`;
  },

  shortMonths: [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ],
};

export default etcModule;
