import React, { useEffect } from "react";
import update from "immutability-helper";

import PlotMarks from "./PlotMarks";
import CreateRoute from "./CreateRoute";
import Navigate from "./Navigate";
import OptionItem from "../../../../../Components/etc/OptionItem";
import ToggleButton from "../../../../../Components/Input/ToggleButton";
import MapCenter from "./MapCenter";
import CustomSelect from "../../../../../Components/etc/CustomSelect";

const MapData = (props) => {
  const { value = {}, onChange } = props;
  const mergeChange = (obj) => onChange({ ...value, ...obj });

  const operations = value.operations || [
    { uid: Math.random(), typeOfData: "fixed", locationMarks: [{}] },
  ];
  const setOperation = (operation, i) => {
    mergeChange({
      operations: update(operations, { $merge: { [i]: operation } }),
    });
  };

  const themeSelectors = {
    tab: (
      <div style={{ display: "flex", justifyContent: "space-evenly" }}>
        {[
          { value: "standard", label: "Standard" },
          { value: "aubergine", label: "Dark" },
          { value: "dark", label: "Night" },
        ].map((option) => (
          <div
            key={option.value}
            onClick={() =>
              mergeChange({
                theme: option.value,
              })
            }
            className={
              " " +
              (value?.theme || "standard" === option.value ? " active" : "")
            }
          >
            {option?.label}
          </div>
        ))}
      </div>
    ),

    dropdown: (
      // <div className="optionItemRow">
      //   <div className="optionItemBox">
      //     <div>
      //       <div className="oibLabel">Theme</div>
      <div>
        <CustomSelect
          onChange={(option) =>
            mergeChange({
              theme: option.value,
            })
          }
          value={value?.theme || "standard"}
          options={[
            { value: "standard", label: "Standard" },
            { value: "aubergine", label: "Dark" },
            { value: "dark", label: "Night" },
          ]}
          placeholder={"Select"}
          classNames={{
            head: "optionInputIconBox",
            label: "optionInputIconBoxField",
            chevron: "optionDatabaseSelectChevron",
          }}
        />
      </div>
      //     </div>
      //   </div>
      // </div>
    ),
  };

  return (
    <div>
      {operations.map((operation, i) => (
        <MapOperation
          {...props}
          key={i}
          value={operation}
          onChange={(value) => setOperation(value, i)}
          delete={() =>
            mergeChange({
              operations: update(operations, { $splice: [[i, 1]] }),
            })
          }
          themeSelectors={themeSelectors}
        />
      ))}

      {/* <div
        onClick={() =>
          setOperation(
            { uid: Math.random(), typeOfData: "fixed" },
            operations.length
          )
        }
        className="mapLinkingWrapper"
      >
        <div className="optionAddLinkingButton">Add Map Item</div>
      </div> */}

      {/* <div className="mapLinkingWrapper">
        <div className="optionDatabaseTextBox mt10">
          <div className="optionDatabaseTextBoxLabel">Map Center</div>
          <MapCenter
            {...props}
            value={value.mapCenter}
            onChange={(value) => mergeChange({ mapCenter: value })}
          />
        </div>
      </div> */}
    </div>
  );
};

const MapOperation = (props) => {
  const { value, onChange } = props;

  const operation = value || {};
  const setOperationObj = (obj) => {
    onChange(update(operation, { $merge: obj }));
  };

  useEffect(() => {
    if (!operation.operation) setOperationObj({ operation: "mark" });
  }, []);

  const routerVsMarkerCheckbox = (
    <div
      onClick={() =>
        setOperationObj({
          operation: operation.operation === "route" ? "mark" : "route",
        })
      }
    >
      <div
        className={" " + (operation.operation === "route" ? " active" : "")}
      />
      Map Route
    </div>
  );

  const propsToPass = {
    ...props,
    value: operation,
    onChange: onChange,
    routerVsMarkerCheckbox,
  };

  const components = {
    mark: <PlotMarks {...propsToPass} />,
    route: <CreateRoute {...propsToPass} />,
    // navigate: <Navigate {...propsToPass} />,
  };

  const selectedOperation = operation.operation;

  const optionHeadTabs = (
    <ToggleButton
      classNames={{
        container: "optionHeadTabs",
        item: "optionHeadTabItem",
      }}
      overideDefaultStyle
      value={operation.typeOfData}
      onChange={(value) => setOperationObj({ typeOfData: value })}
      options={[
        { label: "Fixed", value: "fixed" },
        { label: "Repeating", value: "repeating" },
      ]}
    />
  );

  return (
    <>
      <OptionItem optionItemLabel="Map" optionHeadTabs={optionHeadTabs}>
        <div className="optionItemBody revealOffset">
          <div className="optionItemRow">
            <div className="linkingBoxWrapper">
              <div className="linkingBoxMain">
                <div className="linkingBoxHead displayNone">
                  <div className="optionItemBody">
                    <div className="linkLabel">Map Item</div>
                    <div className="linkDropdown">
                      <div className="linkDropdownHead">
                        <div className="calcItemHeadTab">
                          {operationOptions?.map((item) => {
                            const isActive = operation.operation === item.value;

                            return (
                              <div
                                key={item.value}
                                className={`calcItemHeadTabItem ${
                                  isActive ? "active" : ""
                                }`}
                                onClick={() =>
                                  setOperationObj({ operation: item.value })
                                }
                              >
                                {item.label}
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {components[selectedOperation] || null}

                {props.delete ? (
                  <div className="removeX" onClick={props.delete}>
                    <div className="removeXLine one"></div>
                    <div className="removeXLine two"></div>
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </OptionItem>
    </>
  );
};

const operationOptions = [
  {
    label: "Marker",
    value: "mark",
  },
  {
    label: "Route",
    value: "route",
  },
  // {
  //   label: "Navigate",
  //   value: "navigate",
  // },
];

export default MapData;
