import React, { useState, useEffect, useRef, createRef } from "react";
import { connect } from "react-redux";
import _ from "lodash";
import update from "immutability-helper";

import UnpActions from "../../Stores/redux/Unpersisted/Actions";
import PActions from "../../Stores/redux/Persisted/Actions";
import SideNav from "../Common/SideNav";
import UserPresence from "../Builder/UserPresence";
import SearchInput from "../../Components/Input/SearchInput";
import NavBar from "../Common/NavBar";
import api from "../../Services/Api/api";
import { toast } from "react-toastify";
import AccountHeader from "./AccountHeader";
import CustomSelect from "../../Components/etc/CustomSelect";
import OutsideClickDetector from "../../Components/etc/OutsideClickDetector";
import authModule from "../../Modules/auth/auth-module";
import PaymentComponent from "../../Components/etc/PaymentComponent";

const SCREEN_NAME = "ACCOUNT_DETAILS";

const BillingScreen = (props) => {
  const subscription = props.team.subscription || {};

  const [transactions, setTransactions] = useState(null);
  const [loading, setLoading] = useState(false);
  const [plan, setPlan] = useState(subscription.planName);

  useEffect(() => {
    load();
  }, []);

  const load = async () => {
    setLoading(true);
    try {
      const { transactions } = await api.get("v1/user/transaction", {
        filter: JSON.stringify({ where: { status: "success" } }),
      });
      setTransactions(transactions);
      console.log({ transactions });
    } catch (e) {
      toast.error(e.message);
    }
    setLoading(false);
  };

  return (
    <div className="userPanel">
      <SideNav
        activeSidebarItem=""
        activeSidebarSubItem={"billing"}
        key="sidenav"
      />
      <div className="upMain ticketsScreen">
        <NavBar activeLink="/account/billing" />
        <AccountHeader
          subtitle="View and manage your billing information"
          activeLink={"/account/billing"}
        />

        <div className="upAccountBody">
          <div class="upaNote">
            Here, you can view the details of your current plan, including
            features and billing information. Stay informed about your
            subscription and make adjustments as needed.
          </div>

          <div className="upaBox">
            <div className="upaBoxRow">
              <div className="upaBoxItem">
                <div className="upaLabel">Current Plan</div>
                <PlanDropdown {...{ ...props, plan, setPlan }} />
              </div>
            </div>

            <div className="upaBoxRow upaActionRow">
              <EditSubscription {...{ ...props, plan, callback: () => {} }} />
            </div>
          </div>

          <div className="upaBox">
            <div className="upaBoxRow">
              <div className="upaBoxItem">
                <div className="upaLabel">Payment Methods</div>
                <PaymentMethods {...props} />
              </div>
            </div>
          </div>
        </div>

        <div className="upBody billingList">
          <div className="upBodyInner">
            <div className="fullDataTable">
              <table>
                <thead>
                  <tr>
                    <th>Sl</th>
                    <th>Amount</th>
                    <th>Time</th>
                    <th>Type</th>
                    <th>Status</th>
                    {/* <th>Action</th> */}
                  </tr>
                </thead>
                <tbody>
                  {transactions?.map((transaction, index) => {
                    const { amount, createdAt, status, transactionType } =
                      transaction;
                    return (
                      <tr key={index} onClick={() => {}}>
                        <td>{index + 1}</td>
                        <td>
                          <div className="tableData">{amount}</div>
                        </td>
                        <td>
                          <div className="tableData">
                            {formatDate(createdAt)}
                          </div>
                        </td>
                        <td>{_.startCase(transactionType)}</td>
                        <td>{_.startCase(status)}</td>
                        {/* <td>
                          <div className="tableData">
                            <div className="minActionRow">
                              <div
                                onClick={() =>
                                  setVisibleModal("transaction", transaction)
                                }
                                className="minButton"
                              >
                                Details
                              </div>
                            </div>
                          </div>
                        </td> */}
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
            <div className="emptyBillingLabel">No invoices yet.</div>
          </div>
        </div>
      </div>
    </div>
  );
};

const PlanDropdown = (props) => {
  const subscription = props.team.subscription || {};
  const { plan, setPlan } = props;
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);

  const targetRef = useRef();

  const planDetails = subscription.planDetails?.plans?.find(
    (x) => x.value === plan
  );

  useEffect(() => {
    setPlan(subscription.planName);
  }, [subscription.planName]);

  const reload = async () => {
    try {
      await authModule.fetchAndSetMyProfile();
    } catch (e) {
      console.error(e.message);
    }
  };

  const handlePlanChange = async (planDetails) => {
    try {
      setPlan(planDetails.value);
      setVisible(false);
      setLoading(true);
    } catch (e) {
      toast.error(e.message);
    }

    await reload().catch((e) => console.error(e.message));
    setLoading(false);
  };

  return (
    <div className="upaDropdown" ref={targetRef}>
      <div
        className="upaDropdownLabel"
        onClick={() => setVisible((prev) => !prev)}
      >
        <div className="upaDropdownLabelText">
          {planDetails?.label} Plan{" "}
          <span className="planDetails">
            ${planDetails?.monthlyPrice}
            /month
          </span>
        </div>
        <img
          className="selectIco"
          src={require("../../Assets/img/user/select.png")}
        ></img>
      </div>
      {visible ? (
        <div>
          {subscription.planDetails?.plans
            ?.filter((x) => x.active)
            ?.map((planDetails) => (
              <div
                key={planDetails.value}
                className="upaDropdownLabel"
                onClick={() => handlePlanChange(planDetails)}
              >
                <div className="upaDropdownLabelText">
                  {planDetails?.label} Plan{" "}
                  <span className="planDetails">
                    ${planDetails?.monthlyPrice}
                    /month
                  </span>
                </div>
              </div>
            ))}
        </div>
      ) : null}

      <OutsideClickDetector
        {...{
          targetRef,
          onOutsideClick: () => setVisible(false),
          onEscPress: () => setVisible(false),
        }}
      />
    </div>
  );
};

const PaymentMethods = (props) => {
  const subscription = props.team.subscription;

  const paymentMethods = subscription?.paymentMethods;
  const [paymentMethodId, setPaymentMethodId] = useState(
    subscription?.stripe?.paymentMethodId
  );
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);

  const targetRef = useRef();

  const paymentMethodDetails = paymentMethods?.find(
    (x) => x.id === paymentMethodId
  );

  useEffect(() => {
    console.log({ subscription, p: subscription?.stripe?.paymentMethodId });
    setPaymentMethodId(subscription?.stripe?.paymentMethodId);
  }, [subscription?.stripe?.paymentMethodId]);

  const reload = async () => {
    try {
      await authModule.fetchAndSetMyProfile();
    } catch (e) {
      console.error(e.message);
    }
  };

  const setDefaultPaymentMethod = async (paymentMethod) => {
    try {
      setPaymentMethodId(paymentMethod.id);
      setVisible(false);
      setLoading(true);
      await api.post("v1/user/subscription/setDefaultPaymentMethod", {
        paymentMethodId: paymentMethod.id,
      });
    } catch (e) {
      toast.error(e.message);
    }

    await reload().catch((e) => console.error(e.message));
    setLoading(false);
  };

  return (
    <div className="upaDropdown" ref={targetRef}>
      <div
        className="upaDropdownLabel"
        onClick={() => setVisible((prev) => !prev)}
      >
        <div className="upaDropdownLabelText">
          {paymentMethodDetails?.card?.brand} ending in{" "}
          {paymentMethodDetails?.card?.last4}{" "}
          <span className="planDetails">Primary</span>
        </div>
        <img
          className="selectIco"
          src={require("../../Assets/img/user/select.png")}
        ></img>
      </div>
      {visible ? (
        <div>
          {paymentMethods?.map((paymentMethodDetails) => (
            <div
              key={paymentMethodDetails.id}
              className="upaDropdownLabel"
              onClick={() => setDefaultPaymentMethod(paymentMethodDetails)}
            >
              <div className="upaDropdownLabelText">
                {paymentMethodDetails?.card?.brand} ending in{" "}
                {paymentMethodDetails?.card?.last4}{" "}
                {paymentMethodDetails.id === paymentMethodId ? (
                  <span className="planDetails">Primary</span>
                ) : null}
              </div>
            </div>
          ))}
        </div>
      ) : null}
      <OutsideClickDetector
        {...{
          targetRef,
          onOutsideClick: () => setVisible(false),
          onEscPress: () => setVisible(false),
        }}
      />
    </div>
  );
};

const EditSubscription = (props) => {
  const subscription = props.team.subscription || {};
  const [visible, setVisible] = useState(false);
  const [formError, setFormError] = useState(false);
  const [costDetails, setCostDetails] = useState(false);
  const [loading, setLoading] = useState(false);

  const { plan, callback } = props;

  const planDetails = subscription.planDetails?.plans?.find(
    (x) => x.value === plan
  );

  const paymentInformationRef = useRef();

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      if (loading) return;

      setLoading(true);
      setFormError(null);

      const paymentMethodId =
        await paymentInformationRef.current.getPaymentMethodId();

      if (!paymentMethodId && costDetails.newProjectCost) return null;

      const res = await api.post("v1/user/subscription/modify", {
        planName: plan,
        costDetails,
        paymentMethodId: paymentMethodId || null,
        cost: costDetails.amount || 0,
      });

      authModule.fetchAndSetMyProfile().catch(console.warn);

      setLoading(false);
      setVisible(false);

      callback(null, res.project);
    } catch (e) {
      setLoading(false);
      setFormError(e.message);
      toast.error(e.message);

      /* eslint-disable-next-line no-unused-expressions */
      paymentInformationRef.current?.load?.().catch(console.log);
    }
  };

  return (
    <>
      <div
        className="upaActionMain"
        onClick={() =>
          planDetails?.value !== subscription?.planName
            ? setVisible((prev) => !prev)
            : null
        }
      >
        Edit Subscription
      </div>
      <div
        className={"upModal centerModal"}
        style={{
          display: visible ? "flex" : "none",
        }}
      >
        <div className="upModalItem">
          <div className="upmBody">
            <div>
              <h1 className="mb0">Plan Details</h1>

              <div className="bgNotes mb40 mt20">Review your plan details.</div>

              {formError ? <div className="errormsg">{formError}</div> : null}

              <div className="upmRowGroup mt50">
                <div className="upmRow aCenter">
                  <div className="upmRowLabel">Plan Name</div>
                  <div className="upmRowValue">{planDetails.label}</div>
                </div>

                <div className="upmRow">
                  <div className="upmRowLabel">Monthly Charge</div>
                  <div className="upmRowValue">${planDetails.monthlyPrice}</div>
                </div>
              </div>
            </div>

            <div className="bgNotes mb40 mt20">Payment Information</div>

            {visible ? (
              <PaymentInformation
                team={props.team}
                ref={paymentInformationRef}
                onLoad={(x) => setCostDetails(x)}
                planDetails={planDetails}
              />
            ) : null}

            <div className="mpmActionRow mt50 mb15">
              <div
                onClick={() => setVisible(false)}
                className="lightDefaultButton"
              >
                Cancel
              </div>
              <div className="darkDefaultButton" onClick={handleSubmit}>
                {loading || !costDetails ? "Loading" : "Submit"}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

class PaymentInformation extends React.Component {
  state = {
    selectedMethod: this.paymentMethods?.length
      ? this.paymentMethods?.[this.paymentMethods.length - 1]?.id || ""
      : "new",
    clientSecret: null,
    loading: false,
    costDetails: null,
    error: null,
  };

  paymentComponentRef = createRef();

  get paymentMethods() {
    return this.props.team.subscription?.paymentMethods;
  }

  componentDidMount() {
    this.load();
  }

  async load() {
    try {
      console.log("loading");
      const loadid = Math.random();
      this.loadId = loadid;
      this.setState({ loading: true, error: null });
      const costDetails = await api.post("v1/user/subscription/usage", {
        planName: this.props.planDetails?.value,
      });
      console.log({ costDetails });
      if (this.loadId === loadid) {
        this.setState({ costDetails, loading: false });
        this.props.onLoad(costDetails);
      }
    } catch (e) {
      this.setState({ error: e.message });
      this.setState({ loading: false });
      toast.error("Error loading payment information");
    }
  }

  async getPaymentMethodId() {
    if (this.state.selectedMethod && this.state.selectedMethod !== "new")
      return this.state.selectedMethod;
    else {
      const setupIntent =
        await this.paymentComponentRef.current?.handleSetupIntent?.();

      return setupIntent?.payment_method;
    }
  }

  getLabel(paymentMethod) {
    return (
      <span>{`${paymentMethod.type}: ${
        paymentMethod.type === "card"
          ? `${paymentMethod?.card?.brand || ""} ${paymentMethod.card.last4}`
          : ""
      }`}</span>
    );
  }

  render() {
    const totalAmount = this.state.costDetails?.amount;

    if (this.state.loading && !this.state.costDetails)
      return (
        <div style={{ width: "100%" }}>
          <div className="loader" />
        </div>
      );
    else if (this.state.error) {
      return <div style={{ color: "red" }}>{this.state.error}</div>;
    } else if (!this.state.costDetails) {
      return null;
    }

    return (
      <>
        <div className="upmRowGroup mt50">
          <div className="upmRow aCenter">
            <div className="upmRowLabel">Cost</div>
            <div className="upmRowValue">${totalAmount}</div>
          </div>
          {this.state.costDetails?.details?.charges?.chargesOnDataUsed ? (
            <>
              <div className="upmRow aCenter">
                <div className="upmRowLabel">Pending Usage</div>
                <div className="upmRowValue">
                  ${this.state.costDetails?.details?.charges?.chargesOnDataUsed}
                </div>
              </div>
              <div className="upmRow aCenter">
                <div className="upmRowLabel">total</div>
                <div className="upmRowValue">
                  ${this.state.costDetails?.details?.charges?.subscriptionFee}
                </div>
              </div>
            </>
          ) : null}
          {totalAmount ? (
            <div className="upmRow aCenter removeBorderBottom">
              <div className="upmRowLabel">Payment Method</div>
              <div className="upmRowValue">
                <CustomSelect
                  onChange={(option) =>
                    this.setState({ selectedMethod: option.value })
                  }
                  value={this.state.selectedMethod || ""}
                  options={[
                    { value: "new", label: "Add New" },
                    ...(this.paymentMethods || []).map((x) => ({
                      value: x.id,
                      label: this.getLabel(x),
                    })),
                  ]}
                  placeholder={"Select"}
                  classNames={{
                    head: "selectBox",
                    label: "optionInputIconBoxField",
                  }}
                  jointActionRow={
                    <img
                      className="selectIco"
                      src={require("../../Assets/img/user/select.png")}
                    ></img>
                  }
                />
              </div>
            </div>
          ) : null}
        </div>

        {this.state.selectedMethod === "new" && totalAmount ? (
          <PaymentComponent
            ref={this.paymentComponentRef}
            clientSecret={this.state.clientSecret}
            setClientSecret={(x) => this.setState({ clientSecret: x })}
          />
        ) : null}

        <div>
          Your next bill will be on{" "}
          {new Date(
            new Date().setMonth(new Date().getMonth() + 1)
          ).toLocaleDateString()}
        </div>
      </>
    );
  }
}

const formatDate = (date) => {
  const options = {
    month: "2-digit",
    day: "2-digit",
    year: "numeric",
    hour: "numeric",
    minute: "2-digit",
    hour12: true,
  };

  return new Date(date).toLocaleString("en-US", options).toLowerCase();
};

const mapStateToProps = (state) => ({
  team: state.pState.AUTH?.team,
});

const mapDispatchToProps = (dispatch) => ({
  setScreenState: (obj, persist = false, screenName = SCREEN_NAME) =>
    persist
      ? dispatch(PActions.setPScreenState(screenName, obj))
      : dispatch(UnpActions.setVScreenState(screenName, obj)),
});

export default connect(mapStateToProps, mapDispatchToProps)(BillingScreen);
