import React from "react";

import PropertyClass from "../../Classes/PropertyClass";
import etcModule from "../../../../../Modules/etc/etc-module";
import Padding from "../../Common/StyleProperties/Padding";
import Margin from "../../Common/StyleProperties/Margin";
import Border from "../../Common/StyleProperties/Border";
import BorderRadius from "../../Common/StyleProperties/BorderRadius";
import MapData from "./MapData";
import MapDimensions from "../../Common/StyleProperties/MapDimensions";
import Spacing from "../../Common/StyleProperties/Spacing";

class MapProperties extends PropertyClass {
  render() {
    const {
      props: { value = {}, onChange },
    } = this;
    const mergeChange = (obj) => onChange({ ...value, ...obj });

    const styleProps = {
      ...this.props,
      value: this.props.styles,
      onChange: this.props.setStyles,
      dataProps: {
        value: value.mapData,
        onChange: (x) => mergeChange({ mapData: x }),
      },
    };

    return (
      <>
        <MapDimensions {...styleProps} />
        <Spacing {...styleProps} />
        <Border {...styleProps} />
        <BorderRadius {...styleProps} />

        <MapData
          {...this.props}
          value={value.mapData}
          onChange={(x) => mergeChange({ mapData: x })}
        />
      </>
    );
  }
}

export default MapProperties;
