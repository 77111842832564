import React, { useState } from "react";

const MomentMacros = () => {
  const [tab, setTab] = useState(null);
  const [visible, setVisible] = useState(false);

  return (
    <div className="macroConsole">
      <div className="mcTop">
        <div className="macroConsoleLabelRow" onClick={() => setVisible((prev) => !prev)}>
          <div
            className="macroConsoleLabel"
          >
            {visible ? "Hide" : "View"} Options
          </div>
          <div
            className="macroConsoleLabelIco"
          >
            {visible ? "-" : "+"}
          </div>
        </div>
        <div className="mcTabs" style={{ display: visible ? "flex" : "none" }}>
          {formatCategories?.map((category) => (
            <div
              key={category}
              onClick={() => setTab(category)}
              className={"mcTabItem" + (tab === category ? " active" : "")}
            >
              {category}
            </div>
          ))}
        </div>
      </div>

      {tab ? (
        <div className="mcTable" style={{ display: visible ? "flex" : "none" }}>
          <table>
            <tbody>
              <tr>
                <th className="mcOne">Format</th>
                <th className="mcTwo">Example</th>
                <th className="mcThree">Details</th>
              </tr>
            </tbody>

            {supportedMacros
              .filter((x) => x.category === tab)
              .map((item) => (
                <tr>
                  <td className="mcOne">
                    <div>{item.format}</div>
                  </td>
                  <td className="mcTwo">
                    <div>{item.output}</div>
                  </td>
                  <td className="mcThree">
                    <div>{item.type}</div>
                  </td>
                </tr>
              ))}
          </table>
        </div>
      ) : null}
    </div>
  );
};

const supportedMacros = [
  { format: "ddd", type: "Day Short Name", output: "Mon", category: "Day" },
  { format: "dddd", type: "Day Full Name", output: "Monday", category: "Day" },
  {
    format: "DD",
    type: "Day of Month (2-digit)",
    output: "07",
    category: "Day",
  },
  { format: "D", type: "Day of Month (1-digit)", output: "7", category: "Day" },
  {
    format: "Do",
    type: "Day of Month with Suffix",
    output: "7th",
    category: "Day",
  },
  { format: "E", type: "Day of Week (Number)", output: "1", category: "Day" },
  { format: "w", type: "Week of Year", output: "2", category: "Day" },
  {
    format: "WW",
    type: "Week of Year (2-digit)",
    output: "02",
    category: "Day",
  },
  { format: "W", type: "ISO Week of Year", output: "2", category: "Day" },
  { format: "MMM", type: "Month Short Name", output: "Jan", category: "Month" },
  {
    format: "MMMM",
    type: "Month Full Name",
    output: "January",
    category: "Month",
  },
  { format: "MM", type: "Month (2-digit)", output: "01", category: "Month" },
  { format: "M", type: "Month (1-digit)", output: "1", category: "Month" },
  {
    format: "Qo",
    type: "Quarter of Year with Suffix",
    output: "1st",
    category: "Month",
  },
  { format: "YY", type: "Year (2-digit)", output: "25", category: "Year" },
  { format: "YYYY", type: "Year (4-digit)", output: "2025", category: "Year" },
  { format: "gg", type: "Week-Year (2-digit)", output: "25", category: "Year" },
  {
    format: "gggg",
    type: "Week-Year (4-digit)",
    output: "2025",
    category: "Year",
  },
  {
    format: "HH",
    type: "Hour (24-hour format)",
    output: "18",
    category: "Time",
  },
  {
    format: "hh",
    type: "Hour (12-hour format)",
    output: "06",
    category: "Time",
  },
  { format: "mm", type: "Minute", output: "23", category: "Time" },
  { format: "ss", type: "Second", output: "04", category: "Time" },
  { format: "A", type: "AM/PM", output: "PM", category: "Time" },
  {
    format: "Z",
    type: "UTC Offset with Colon",
    output: "+00:00",
    category: "Time",
  },
  {
    format: "ZZ",
    type: "UTC Offset without Colon",
    output: "+0000",
    category: "Time",
  },
  {
    format: "z",
    type: "Time Zone Name (Abbreviation)",
    output: "UTC",
    category: "Time",
  },
  {
    format: "X",
    type: "Unix Timestamp (Seconds)",
    output: "1673267445",
    category: "Time",
  },
  {
    format: "x",
    type: "Unix Timestamp (Milliseconds)",
    output: "1673267445000",
    category: "Time",
  },
];
const formatCategories = ["Day", "Month", "Year", "Time"];

export default MomentMacros;
