import api from "../../Services/Api/api";
import { initSocket } from "../../Services/Socket/socket";
import { store } from "../../store";
import PActions from "../../Stores/redux/Persisted/Actions";
import UnpActions from "../../Stores/redux/Unpersisted/Actions";
import _ from "lodash";

const authModule = {
  fetchMyProfile: async (opt = {}) => {
    let user = opt.user;
    if (!user) {
      let apiOpt = {};
      if (opt?.token) {
        apiOpt = {
          headers: {
            authorization: "Bearer " + opt.token,
          },
        };
      }

      const { user: latestUserData } = await api.get(
        "v1/user/self",
        {},
        apiOpt
      );
      user = latestUserData;
    }

    let team = store?.getState()?.pState?.AUTH?.team;

    let teamId = opt?.teamId || team?.user?._id;
    let matchedTeam = user.teams?.find((x) => x.user?._id === teamId);
    team = matchedTeam;

    return { user, team };
  },

  setAuthStates: async (data) => {
    let keys = ["token", "user", "team"];

    let updateObj = {},
      isSet = false;

    for (let i = 0; i < keys.length; i++) {
      const key = keys[i];
      if (data[key] !== undefined) {
        updateObj[key] = data[key];
        isSet = true;
      }
    }

    if (isSet) {
      await store.dispatch(PActions.setPScreenState("AUTH", updateObj));
    }
  },

  fetchAndSetMyProfile: async (opt) => {
    const profileRes = await authModule.fetchMyProfile(opt);
    await authModule.setAuthStates(profileRes);

    return profileRes;
  },

  initAuth: async (opt = {}) => {
    await authModule.setAuthStates(opt);

    initSocket({ onConnect: null });
    const profileRes = await authModule.fetchAndSetMyProfile(opt);

    return { ...profileRes };
  },

  signup: async (data) => {
    return api.post(
      "v1/user/signup",
      _.pick(data, [
        "firstName",
        "lastName",
        "email",
        "password",
        "paymentMethodId",
        "planName",
        "trial",
      ])
    );
  },

  logout: async () => {
    await store.dispatch(PActions.resetPState());
    await store.dispatch(UnpActions.resetVState());
  },
};

export default authModule;
