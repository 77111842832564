import React from "react";

import {
  LinkingOptionItemBody,
} from "../../../Components/etc/OptionItem";
import RichTextData from "../../Builder/Properties/Common/DataProperties/RichTextData";

const SendSMS = (props) => {
  const { value = {}, onChange, linkBoxOptions, triggerOption } = props;
  const mergeChange = (obj) => onChange({ ...value, ...obj });

  return (
    <>
      <LinkingOptionItemBody {...props}>
        <div className="databaseOperationModule">
          <div className="domHeader">
            <div className="domLabel">{"Recipient(s)"}</div>

            <RichTextData
              {...{
                ...props,
                immediateProps: {
                  classNames: {
                    wrapper: " ",
                    editor: "calcDatabaseFilterMultiBox spaceTopify",
                    paramListRow: "",
                  },
                },
                placeholder: "Type here",
                value: value?.targetPhoneNumbers?.valueObj,
                onChange: (valueObj) => {
                  mergeChange({
                    targetPhoneNumbers: {
                      valueObj,
                      valueType: "textParts",
                    },
                  });
                },
              }}
            />
          </div>

          <div className="domBodys">
            {[
              {
                label: "Message",
                key: "smsText",
              },
            ].map((item) => (
              <div className="calcItemDatabaseFilterBox" key={item.key}>
                <div className="calcItemDatabaseFilterBoxInner">
                  <div className="calcItemDatabaseFilterBoxInnerRow">
                    <div className="calcItemDBLabel">{item.label}</div>
                  </div>
                  <RichTextData
                    {...{
                      ...props,
                      immediateProps: {
                        classNames: {
                          wrapper: " ",
                          editor: "calcDatabaseFilterMultiBox ",
                          paramListRow: "",
                        },
                      },
                      placeholder: "Type here",
                      value: value?.[item.key]?.valueObj,
                      onChange: (valueObj) => {
                        mergeChange({
                          [item.key]: {
                            valueObj,
                            valueType: "textParts",
                          },
                        });
                      },
                    }}
                  />
                </div>
              </div>
            ))}
          </div>
        </div>
      </LinkingOptionItemBody>

      {linkBoxOptions.showTriggerOption ? (
        <div className="linkingBoxFooter"> {triggerOption}</div>
      ) : null}
    </>
  );
};

export default SendSMS;
