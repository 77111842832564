import React, {
  useEffect,
  useState,
  useCallback,
  useRef,
  Fragment,
} from "react";
import _ from "lodash";
import { connect } from "react-redux";
import update from "immutability-helper";

import api from "../../Services/Api/api";
import NavBar from "../Common/NavBar";
import SideNav from "../Common/SideNav";
import withRouter from "../../Components/Wrapper/with-router";
import { toast } from "react-toastify";
import UnpActions from "../../Stores/redux/Unpersisted/Actions";
import PActions from "../../Stores/redux/Persisted/Actions";
import CustomSelect from "../../Components/etc/CustomSelect";
import DraggableList from "../../Components/etc/DraggableList";
import DraggablePopup from "../../Components/etc/DraggablePopup";
import ToggleButton from "../../Components/Input/ToggleButton";
import { getDatabaseSelectorOptions } from "../Common/DatabaseOperations_v2/DatabaseSelector";
import DatabaseQueryFilter from "../Common/DatabaseOperations_v2/DatabaseQueryFilter";
import navigationModule from "../../Modules/navigation/navigation-module";
import ContentRenderer from "../../Components/etc/ContentRenderer";
import EditableCell from "./EditableCell";

const SCREEN_NAME = "DATABASES";

const DatabaseDetailsInner = (props) => {
  const dbName = props.dbName;

  const [database, setDatabase] = useState(null);
  const [table, setTable] = useState(null);
  const [loading, setLoading] = useState(false);
  const [visibleModal, setVisibleModal] = useState(null);
  const [tablesBuf, setTablesBuf] = useState(null);
  const [reorderingTables, setReorderingTables] = useState(null);
  const [ts, setTs] = useState(null);
  const [escKeyPress, setEscKeyPress] = useState(null);
  const [syncRecord, setSyncRecord] = useState(null);
  const [addingRow, setAddingRow] = useState(false);

  const isAdmin = ["owner", "admin"].includes(props.team.role);

  const databaseRecordsRef = useRef(null);

  useEffect(() => {
    load();
  }, [dbName]);

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === "Escape") {
        setEscKeyPress(Date.now());
      }
    };

    document.addEventListener("keydown", handleKeyDown);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  const load = async (opt = {}) => {
    if (dbName !== database?.name) {
      setDatabase(null);
      setTable(null);
    }

    try {
      setLoading(true);

      const database = await getDatabase(dbName);

      if (!database) throw new Error("Error loading database details");

      setDatabase(database);

      if (opt.tableId) {
        let table = database?.tables?.find((x) => x._id === opt.tableId);
        setTable(table);
      } else if (opt.tableName) {
        let table = database?.tables?.find((x) => x.name === opt.tableName);
        setTable(table);
      } else {
        setTable(database?.tables?.[0]);
      }
      setLoading(false);
    } catch (e) {
      console.warn(e);
      toast.error(e.message);
      setLoading(false);
    }
  };

  const deleteTable = async (table) => {
    try {
      setLoading(true);

      await api.delete(`v1/database/${database?._id}/table`, {
        tableName: table.name,
      });
      load();

      setLoading(false);
    } catch (e) {
      toast.error(e.message);
      setLoading(false);
    }
  };

  const reorderTables = async (tables) => {
    try {
      setTablesBuf(tables);
      setReorderingTables(true);

      await api.put(`v1/database/${database?._id}/reorder-tables`, {
        tableIds: tables?.map((x) => x._id),
      });
      await load({ tableId: table?._id });

      setReorderingTables(false);
      setTablesBuf(null);
    } catch (e) {
      toast.error(e.message);
      setReorderingTables(false);
      setTablesBuf(null);
    }
  };

  const getDatabase = useCallback(async (dbName, opt = {}) => {
    let allDatabases = props.databases;

    if (!allDatabases || opt.reload) {
      const { databases } = await api.get("v1/database");
      props.setScreenState({ databases }, false, "BUILDER");
      allDatabases = databases;
    }

    return allDatabases.find((x) => x.name === dbName);
  }, []);

  const addRow = async () => {
    try {
      if (!databaseRecordsRef.current?.handleAddRow || addingRow) return;

      setAddingRow(true);
      await databaseRecordsRef.current?.handleAddRow({});
      setAddingRow(false);
    } catch (e) {
      setAddingRow(false);
      toast.error(e.message);
      console.warn(e);
    }
  };

  const isTableDetailsVisible = visibleModal === "tableDetails" && table;
  const isDbSyncVisible = visibleModal === "dbSync" && table;

  const selectedRecordCount =
    databaseRecordsRef.current?.state?.selectedRecordIds?.length;

  return (
    <>
      <div className="upHeader">
        <div className="upHeaderTop">
          <div className="upHeaderRow">
            <div className="dbScreenTitle">Database Editor</div>
            <div className="dbSelector displayNone">
              <div className="upHeadOptionLabel">Database</div>
              <div className="dbSelectorType">
                {database?.type
                  ? database.type.charAt(0).toUpperCase() // Get the first letter and capitalize
                  : null}
              </div>
              <CustomSelect
                onChange={(option) => {
                  if (props.onDbChange) props.onDbChange(option);
                }}
                value={database?._id || ""}
                options={props.databases?.map((x) => ({
                  value: x._id,
                  label: `${x.name} (${x.type})`,
                  titleLabel: x.name,
                  name: x.name,
                }))}
                placeholder={"Select Database"}
                classNames={{
                  head: "optionInputIconBox",
                  label: "upTitle",
                  chevron: "optionDatabaseSelectChevron",
                }}
              />
            </div>

            <div
              className="actionButton primaryButtonize displayNone"
              onClick={() => addRow()}
            >
              <img
                className="actionButtonIco"
                src={require("../../Assets/img/user/addRow.png")}
              ></img>
              <div className="actionButtonLabel">Add Row</div>
            </div>

            <div className="displayNone">
              {table ? (
                <div style={{ display: "flex" }}>
                  {databaseRecordsRef.current.state?.selectedRecordIds
                    ?.length ? (
                    <span>
                      {
                        databaseRecordsRef.current.state?.selectedRecordIds
                          ?.length
                      }{" "}
                      selected
                    </span>
                  ) : null}
                  <div
                    style={{ display: "flex" }}
                    onClick={() =>
                      databaseRecordsRef.current?.handleSelectedItemDelete()
                    }
                  >
                    <div className="upAction">Delete</div>
                  </div>
                  <div style={{ display: "flex" }} onClick={() => addRow()}>
                    <div className="upAction">Add</div>
                  </div>

                  <div
                    style={{ display: "flex" }}
                    onClick={() => setVisibleModal("dbSync")}
                  >
                    <div className="upAction">Sync</div>
                  </div>
                </div>
              ) : null}
            </div>
          </div>

          <div className="databaseBar">
            <div className="databaseBarLeft">
              <div className="dbSelector">
                <div className="upHeadOptionLabel">Database</div>
                <div className="dbSelectorType">
                  {database?.type
                    ? database.type.charAt(0).toUpperCase() // Get the first letter and capitalize
                    : null}
                </div>
                <CustomSelect
                  onChange={(option) => {
                    if (props.onDbChange) props.onDbChange(option);
                  }}
                  value={database?._id || ""}
                  options={props.databases?.map((x) => ({
                    value: x._id,
                    label: `${x.name} (${x.type})`,
                    titleLabel: x.name,
                    name: x.name,
                  }))}
                  placeholder={"Select Database"}
                  classNames={{
                    head: "optionInputIconBox",
                    label: "upTitle",
                    chevron: "optionDatabaseSelectChevron",
                  }}
                />
              </div>
              <div className="dbTableSelector">
                <div className="upHeadOptionLabel">Table</div>
                <DraggablePopup
                  {...{
                    value: table?._id,
                    onChange: (option) => setTable(option),
                    options: (tablesBuf ? tablesBuf : database?.tables)?.map(
                      (x) => ({
                        ...x,
                        value: x._id,
                        label: x.name,
                      })
                    ),
                    disableDelete: !isAdmin,
                    onEditOption: (option, opt) => {
                      opt.close();
                      setTable(option);
                      setTimeout(() => {
                        setVisibleModal("tableDetails");
                      }, 200);
                    },
                    onDeleteOption: (option, opt) => {
                      deleteTable(option);
                    },
                    onChangeOptions: (options) => reorderTables(options),
                    renderMenuHeader: (opt) => (
                      <div
                        className="draggableAddSector"
                        onClick={() => {
                          setVisibleModal("addTable");
                          opt.close();
                        }}
                      >
                        + Add Table
                      </div>
                    ),
                  }}
                />
              </div>
              <div className="dbColumnSelector">
                <div className="upHeadOptionLabel">Column</div>
                <ColumnList
                  {...{
                    ...props,
                    database,
                    table,
                    callback: (err) => {
                      if (!err) load({ tableId: table?._id });
                    },

                    key: table?._id,
                  }}
                />
              </div>
            </div>

            <div className="databaseBarRight">
              {selectedRecordCount > 0 ? (
                <div className="actionButton">
                  <img
                    className="actionButtonIco"
                    src={require("../../Assets/img/user/deleteRow.png")}
                  ></img>
                  <div
                    className="actionButtonLabel"
                    onClick={() =>
                      databaseRecordsRef.current?.handleSelectedItemDelete()
                    }
                  >
                    Delete {selectedRecordCount} row
                    {selectedRecordCount > 1 ? "s" : ""}
                  </div>
                </div>
              ) : null}

              <div
                className="actionButton"
                onClick={() => setVisibleModal("dbSync")}
              >
                <img
                  className="actionButtonIco"
                  src={require("../../Assets/img/user/sync.png")}
                ></img>
                <div className="actionButtonLabel">Sync</div>
              </div>

              {database?.type === "remote" ? (
                <div
                  className="actionButton primaryButtonizes"
                  onClick={() => addRow()}
                >
                  <img
                    className="actionButtonIco"
                    src={require("../../Assets/img/user/addRow.png")}
                  ></img>
                  <div className="actionButtonLabel">Add Row</div>
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </div>

      <div className="upBody">
        <div className="upBodyInner">
          <DatabaseRecords
            {...{
              ...props,
              ref: databaseRecordsRef,
              table,
              database,
              key: database?._id + "/" + table?._id,
              callback: (err) => {
                if (!err) load({ tableId: table?._id });
              },
              onRecordSelect: () => setTs(Date.now()),
              escKeyPress,
              syncRecord,
            }}
          />
        </div>

        <TableDetailsPopup
          key={"table" + isTableDetailsVisible}
          visible={isTableDetailsVisible}
          close={() => setVisibleModal(null)}
          callback={(err, table) => {
            if (!err) {
              getDatabase(dbName, { reload: true })
                .then(() => load({ tableId: table._id }))
                .catch(console.warn);
            }
          }}
          database={database}
          table={table}
          team={props.team}
        />

        <TableSyncPopup
          {...props}
          key={"dbsync" + isDbSyncVisible}
          visible={isDbSyncVisible}
          close={() => setVisibleModal(null)}
          callback={(err) => {
            if (!err) {
              getDatabase(dbName, { reload: true })
                .then(() => load({ tableId: table._id }))
                .catch(console.warn);
            }
          }}
          database={database}
          table={table}
          team={props.team}
        />

        <AddTablePopup
          key={"addtable" + (visibleModal === "addTable")}
          visible={visibleModal === "addTable"}
          close={() => setVisibleModal(null)}
          callback={(err, table) => {
            if (!err) {
              getDatabase(dbName, { reload: true })
                .then(() => load({ tableName: table.name }))
                .catch(console.warn);
            }
          }}
          team={props.team}
          database={database}
        />
      </div>
    </>
  );
};

const ColumnList = (props) => {
  const { callback, database, table } = props;

  const [form, setForm] = useState(table || {});
  const [loading, setLoading] = useState(false);
  const [formError, setFormError] = useState(null);
  const [newColumn, setNewColumn] = useState("");
  const [visibleModal, setVisibleModal] = useState(null);

  useEffect(() => {
    setForm(props.table || {});
  }, [props.table?.columns]);

  if (!table || !database) return null;

  const updateForm = (obj) => {
    setForm(update(form, { $merge: obj }));
  };

  const handleSubmit = async (event, formProvided) => {
    if (event) event.preventDefault();

    formProvided = formProvided || form;
    try {
      if (loading) return;

      let invalidValues = [];
      ["name"].map((x) => {
        if (!formProvided[x]) invalidValues.push(x);
      });

      if (invalidValues.length) {
        return setFormError(
          `Missing valid required input${
            invalidValues.length > 1 ? "s" : ""
          } ${invalidValues.join(", ")}`
        );
      }

      setLoading(true);
      setFormError(null);

      await api.put(`v1/database/${database._id}/table`, {
        tableName: table.name,
        newTableName: table.name,
        columns: formProvided.columns,
      });

      setLoading(false);

      callback(null, formProvided);
    } catch (e) {
      setLoading(false);
      setFormError(e.message);
      toast.error(e.message);
    }
  };

  const handleAddColumnForm = (e) => {
    e.preventDefault();
    if (newColumn) {
      setVisibleModal(null);

      let updatedForm = {
        ...form,
        columns: [...(form.columns || []), { name: newColumn }].filter(
          (x, i, arr) => i === arr.indexOf(x)
        ),
      };
      updateForm(updatedForm);

      setNewColumn("");
      handleSubmit(null, updatedForm);
    }
  };

  const deleteColumn = (column) => {
    let updatedForm = {
      ...form,
      columns: form.columns.filter((x) => x.name !== column?.name),
    };
    updateForm(updatedForm);

    handleSubmit(null, updatedForm);
  };

  const reorderColumns = (columns) => {
    let updatedForm = {
      ...form,
      columns: columns.map((x) => ({ name: x.name, _id: x._id })),
    };
    updateForm(updatedForm);

    handleSubmit(null, updatedForm);
  };

  return (
    <DraggablePopup
      {...{
        value: "",
        onChange: () => {},
        options: form?.columns?.map((x) => ({
          ...x,
          value: x.name,
          label: x.name,
        })),
        placeholder: "Columns",
        disableEdit: true,
        onDeleteOption: (option, opt) => {
          deleteColumn(option);
        },
        onChangeOptions: (options) => reorderColumns(options),
        renderMenuHeader: (opt) => (
          <>
            {visibleModal === "addColumn" ? (
              <form onSubmit={(e) => handleAddColumnForm(e)}>
                <input
                  value={newColumn || ""}
                  onChange={(e) => setNewColumn(e.target.value)}
                  autoFocus
                />
              </form>
            ) : (
              <div
                className="draggableAddSector"
                onClick={() => {
                  setVisibleModal("addColumn");
                }}
              >
                Add Column
              </div>
            )}
          </>
        ),
      }}
    />
  );
};

class DatabaseRecords extends React.Component {
  state = {
    loading: false,
    records: null,
    query: {},
    page: 1,
    limit: this.props.databaseSelector?.limit || 25,
    sortby: "_id",
    order: -1,
    selectedRecordIds: null,
    visibleModal: null,
  };

  componentDidMount() {
    this.load();
  }

  componentDidUpdate(prevProps) {
    if (this.props.escKeyPress !== prevProps.escKeyPress) {
      this.setState({ selectedCellUid: null });
    }

    if (this.props.syncRecord !== prevProps.syncRecord) {
      this.loadWithThrottle();
    }
  }

  searchDb(propQuery) {
    const {
      props: { database, table },
    } = this;

    if (table) {
      let payload = {
        dbId: database._id,
        tableId: table._id,
        filters: [],
        limit: 50,
        skip: 0,
        sortby: "_id",
        order: -1,
        ...propQuery,
      };

      return api.post("v1/database/read", payload).then((x) => x.data);
    }
  }

  async load() {
    try {
      const {
        props: { database, table },
        state: { page, limit, query, sortby, order },
      } = this;

      if (
        !database?._id ||
        !table ||
        !table.columns ||
        ["local"].includes(database.type)
      )
        return;

      this.setState({ loading: true, error: null });

      const filters = Object.keys(query)
        .map((x) => ({
          name: x,
          value: query[x],
        }))
        .filter((x) => !["", null, undefined].includes(x.value));

      const count = await this.searchDb({
        countOnly: true,
        filters,
      });
      this.setState({ count });

      let skip = limit * (parseInt(page) - 1);
      const items = await this.searchDb({
        limit,
        skip,
        filters,
        sortby: sortby || "_id",
        order: order || -1,
      });
      this.setState({
        selectedItems: null,
        records: items,
        loading: false,
        loadedPage: page,
      });
    } catch (e) {
      toast.error(e.message || e.toString?.());
      this.setState({ loading: false, error: e.message || e.toString?.() });
    }
  }

  loadThrottleTimer = null;
  async loadWithThrottle(opt) {
    clearTimeout(this.loadThrottleTimer);
    this.loadThrottleTimer = setTimeout(() => {
      this.load(opt);
    }, 300);
  }

  updateRecord(_id, record, opt = {}) {
    const recordIndex =
      _id && _id !== 0
        ? this.state.records?.findIndex((x) => x?._id === _id)
        : null;

    const newRecords = update(
      this.state.records || [],
      opt.action === "add"
        ? { $unshift: [record] }
        : opt.action === "delete"
        ? { $splice: [[recordIndex, 1]] }
        : {
            $merge: { [recordIndex]: record },
          }
    );

    this.setState({ records: newRecords });
  }

  async handleEditRow(_id, item, { deselectCell, oldItem }) {
    try {
      this.updateRecord(_id, { ...oldItem, ...item });
      if (deselectCell) this.setState({ selectedCellUid: null });

      const document = {};
      for (const key in item) {
        if (Object.hasOwnProperty.call(item, key)) {
          const value = item[key];
          if (key !== "_id") document[key] = { value };
        }
      }

      const payload = {
        dbId: this.props.database._id,
        tableId: this.props.table._id,
        document,
        valueType: "editRecord",
        filters: [{ name: "_id", value: _id }],
        limit: 1,
      };

      const data = await api.post("v1/database/write", payload);

      if (data?.count === 1 && data.records[0]) {
        this.updateRecord(_id, data.records[0]);
      } else {
        throw Error("Record update failed");
      }
    } catch (e) {
      toast.error(e.message);
      console.warn(e);

      if (oldItem) this.updateRecord(_id, oldItem);
    }
  }

  async handleAddRow(item) {
    let recordId = "temp-" + Math.random();
    try {
      this.updateRecord(null, { ...item, _id: recordId }, { action: "add" });

      const document = {};
      for (const key in item) {
        if (Object.hasOwnProperty.call(item, key)) {
          const value = item[key];
          if (key !== "_id") document[key] = { value };
        }
      }

      const payload = {
        dbId: this.props.database._id,
        tableId: this.props.table._id,
        document,
        valueType: "addRecord",
      };

      const { records } = await api.post("v1/database/write", payload);

      const record = records[0];

      this.updateRecord(recordId, record);
    } catch (e) {
      this.updateRecord(null, item, { action: "delete" });
      throw e;
    }
  }

  async handleDeleteRows(_ids) {
    try {
      const payload = {
        dbId: this.props.database._id,
        tableId: this.props.table._id,
        valueType: "deleteRecord",
        filters: [
          {
            name: "[OR]",
            type: "filterGroup",
            filters: _ids.map((_id) => ({
              name: "_id",
              value: _id,
            })),
          },
        ],
      };

      await api.post("v1/database/write", payload);

      this.load().then(() => {
        this.setState({ selectedRecordIds: [] });
      });
    } catch (e) {
      toast.error(e.message);
      console.warn(e);
    }
  }

  handleSortIconClick({ name }) {
    this.setState(
      this.state.sortby === name
        ? this.state.order === 1
          ? { sortby: "_id", order: -1 }
          : { sortby: name, order: 1 }
        : { sortby: name, order: -1 },
      () => this.loadWithThrottle()
    );
  }

  async handleDeleteColumn(column) {
    try {
      const columns = this.props.table.columns.filter(
        (x) => x.name !== column?.name
      );
      await api.put(`v1/database/${this.props.database._id}/table`, {
        tableName: this.props.table.name,
        newTableName: this.props.table.name,
        columns: columns,
      });

      this.props.callback(null, { ...this.props.table, columns });
    } catch (e) {
      toast.error(e.message);
    }
  }

  handleSelectedItemDelete() {
    const { selectedRecordIds } = this.state;
    if (!selectedRecordIds?.length) return;
    else if (
      window.confirm(
        `Are you sure you want to delete ${
          selectedRecordIds?.length > 1
            ? `all these ${selectedRecordIds?.length} records`
            : "this record"
        }?`
      )
    ) {
      this.handleDeleteRows(selectedRecordIds);
    }
  }

  renderPagination() {
    const {
      state: { count, limit, page, loadedPage, loading },
    } = this;

    if (!count || count <= limit) return null;

    return (
      <div className="paginationBar">
        <div className="paginationLeft">
          <button
            className={`paginateItem previous ${
              loadedPage <= 1 ? "disabled" : ""
            }`}
            disabled={loadedPage <= 1}
            onClick={() =>
              this.setState({ page: loadedPage - 1 }, () => this.load())
            }
          >
            <img
              className="paginateIco"
              src={require("../../Assets/img/options/options/reveal.png")}
            ></img>
          </button>

          <div className="currentPage">
            {loadedPage}
            {" / "}
            {Math.ceil(count / limit)}
          </div>
          <button
            className={`paginateItem next ${
              loadedPage >= Math.ceil(count / limit) ? "disabled" : ""
            }`}
            disabled={loadedPage >= Math.ceil(count / limit)}
            onClick={() =>
              this.setState({ page: loadedPage + 1 }, () => this.load())
            }
          >
            <img
              className="paginateIco"
              src={require("../../Assets/img/options/options/reveal.png")}
            ></img>
          </button>
        </div>
        <div className="paginationRight">
          {"    "}
          Page:{" "}
          <input
            value={page}
            min={1}
            max={Math.ceil(count / limit)}
            onChange={(e) => this.setState({ page: e.target.value })}
            type="number"
            style={{ width: "50px" }}
          />
          <button
            onClick={() => {
              this.setState(
                {
                  page: Math.max(1, Math.min(page, Math.ceil(count / limit))),
                },
                () => this.load()
              );
            }}
          >
            {loading ? "Loading" : "Load"}
          </button>
        </div>
      </div>
    );
  }

  render() {
    const {
      props: { table, database, onRecordSelect },
      state: {
        records,
        loading,
        query,
        sortby,
        order,
        selectedRecordIds,
        visibleModal,
      },
    } = this;

    const columns = table?.columns || [];

    return (
      <>
        {loading && !records ? (
          <div
            style={{
              position: "absolute",
              inset: 0,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <div className="loader large"></div>
          </div>
        ) : (
          <div className="fullDataTable">
            <table>
              <thead>
                <tr>
                  {columns?.map((x) => (
                    <th key={x.name}>
                      <div className="dbHead">
                        <div className="dbHeadLabel">{x.name}</div>
                        <div className="dbHeadMulti">
                          {query?.[x.name] || query?.[x.name] === "" ? (
                            <form
                              onSubmit={(e) => {
                                e.preventDefault();
                                this.loadWithThrottle();
                              }}
                            >
                              <input
                                {...{
                                  value: query?.[x.name],
                                  onChange: (e) =>
                                    this.setState({
                                      query: {
                                        ...query,
                                        [x?.name]: e.target.value,
                                      },
                                    }),
                                }}
                              />
                              <div
                                onClick={() =>
                                  this.setState({
                                    query: {
                                      ...query,
                                      [x?.name]: null,
                                    },
                                  })
                                }
                              >
                                X
                              </div>
                            </form>
                          ) : (
                            <img
                              onClick={() =>
                                this.setState({
                                  query: {
                                    ...query,
                                    [x?.name]: "",
                                  },
                                })
                              }
                              className="dbHeadSearchIco"
                              src={require("../../Assets/img/user/search.png")}
                            />
                          )}
                          <div
                            className="dbHeadSort"
                            onClick={() => this.handleSortIconClick(x)}
                          >
                            {sortby === x.name ? (
                              order === -1 ? (
                                <div>[sort desc]</div>
                              ) : (
                                <div>[sort asc]</div>
                              )
                            ) : (
                              <div>[sort]</div>
                            )}
                          </div>
                          <CustomSelect
                            onChange={(option) => {
                              if (option.value === "sortAsc") {
                                this.setState({ order: 1, sortby: x.name });
                              } else if (option.value === "sortDsc") {
                                this.setState({ order: 1, sortby: x.name });
                              } else if (option.value === "delete") {
                                if (
                                  window.confirm(
                                    `Are you sure you want to delete the column named "${x.name}"?`
                                  )
                                ) {
                                  this.handleDeleteColumn(x);
                                }
                              }
                            }}
                            value={""}
                            placeholder=" "
                            options={[
                              { value: "sortAsc", label: "Sort Asc" },
                              { value: "sortDsc", label: "Sort Dsc" },
                              { value: "delete", label: "Delete" },
                            ]}
                            classNames={{
                              head: "dbHeadAction",
                              label: "optionInputIconBoxField",
                              chevron: "optionDatabaseSelectChevron",
                            }}
                          />
                        </div>
                      </div>
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                <tr>
                  {table?.columns?.map((column) => {
                    return (
                      <td key={column.name}>
                        <form
                          className="tableSearchForm"
                          onSubmit={(e) => {
                            e.preventDefault();
                            this.loadWithThrottle();
                          }}
                        >
                          <input
                            placeholder="Search Here"
                            value={query[column?.name] || ""}
                            className="tableSearch"
                            onChange={(e) =>
                              this.setState({
                                query: {
                                  ...query,
                                  [column?.name]: e.target.value,
                                },
                              })
                            }
                          />
                        </form>
                      </td>
                    );
                  })}
                </tr>
                {records?.map((item, index) => {
                  const selectedIndex = selectedRecordIds?.findIndex(
                    (x) => x === item._id
                  );
                  const isSelected = selectedIndex > -1;

                  return (
                    <TableRow
                      {...{
                        ...this.props,
                        columns,
                        key: item._id,
                        item,
                        index,
                        selectedCellUid: this.state.selectedCellUid,
                        selectCell: (x) =>
                          this.setState({ selectedCellUid: x }),
                        editRow: (newItem, opt = {}) =>
                          this.handleEditRow(item._id, newItem, {
                            ...opt,
                            oldItem: item,
                          }),
                        isSelected,
                        selectRow: () => {
                          this.setState(
                            {
                              selectedRecordIds: update(
                                selectedRecordIds || [],
                                isSelected
                                  ? { $splice: [[selectedIndex, 1]] }
                                  : { $push: [item._id] }
                              ),
                            },
                            () => {
                              onRecordSelect();
                            }
                          );
                        },
                      }}
                    />
                  );
                })}
              </tbody>
            </table>

            {this.renderPagination()}

            {selectedRecordIds?.length ? (
              <div className="dbActionRow">
                <span className="dbActionRowLabel">
                  {selectedRecordIds?.length} selected
                </span>{" "}
                <div className="dbActionRowContext">
                  <img
                    className="actionButtonIco"
                    src={require("../../Assets/img/user/deleteRow.png")}
                  ></img>
                  <span onClick={this.handleSelectedItemDelete.bind(this)}>
                    Delete
                  </span>
                </div>
              </div>
            ) : null}
            {/* <div
                  onClick={() => this.setState({ visibleModal: "addRecord" })}
                >
                  Add
                </div> */}
          </div>
        )}

        <AddRecordPopup
          {...this.props}
          key={"addRecord" + (visibleModal === "addRecord")}
          visible={visibleModal === "addRecord"}
          close={() => this.setState({ visibleModal: null })}
          handleAddRow={this.handleAddRow.bind(this)}
          callback={(err, record) => {}}
        />
      </>
    );
  }
}

class TableRow extends React.PureComponent {
  render() {
    const { table, item, isSelected } = this.props;

    return (
      <tr className={` ${isSelected ? "active" : ""}`}>
        {table?.columns?.map((column, index) => {
          return (
            <TableCell
              {...this.props}
              item={item}
              column={column}
              index={index}
              key={column.name}
              editCell={(value) =>
                this.props.editRow(
                  {
                    [column.name]: (value?.toString?.() || "")
                      .split(",")
                      .map((x) => x.trim())
                      .filter((x) => x.length)
                      .join(","),
                  },
                  { deselectCell: true }
                )
              }
            ></TableCell>
          );
        })}
      </tr>
    );
  }
}

class TableCell extends React.PureComponent {
  state = {
    valueBuf: this.props.item?.[this.props.column?.name] || "",
    uid: Math.random(),
    loading: false,
    urlPopup: null,
  };

  async handleSubmitValue(value) {
    this.setState({ loading: true });
    await this.props.editCell(value || "");
    this.setState({ loading: false });
  }

  async handleSubmit(e) {
    if (e) e.preventDefault();
    this.setState({ loading: true });
    await this.props.editCell(this.state.valueBuf || "");
    this.setState({ loading: false });
  }

  handleKeyDown(e) {
    if (e.key === "Enter") {
      if (e.shiftKey) {
      } else {
        e.preventDefault();
        this.handleSubmit(e);
      }
    }
  }

  async handleFiles(e) {
    e.preventDefault();
    const files = e.target.files;

    if (!files.length) return;

    this.setState({ loading: true });

    try {
      const resultP = [];
      for (let i = 0; i < files.length; i++) {
        const file = files[i];

        const result = api
          .media(
            "v1/file",
            { file },
            {
              onUploadProgress: (x) => console.info("on upload progress: ", x),
            }
          )
          .then((result) =>
            api.getFileLink(result.file, {
              params: { mime_type: result.file?.file_mime_type },
            })
          )
          .then((fullUrl) => ({
            value: fullUrl,
            valueObj: { ...result, uri: fullUrl },
          }));
        resultP.push(result);
      }

      const result = await Promise.all(resultP);

      const valueBuf = this.state.valueBuf || "";

      const arrayBuf = valueBuf
        .split?.(",")
        ?.map((x) => x.trim())
        .filter((x) => x);

      const combinedArray = [...arrayBuf, ...result.map((x) => x.value)];

      await this.props.editCell(combinedArray.join(", "));

      this.setState({ loading: false });
    } catch (e) {
      toast.error(e.message);
      this.setState({ loading: false });
    }
  }

  onChange(data) {
    console.log({ data });
  }

  renderValue(value) {
    const stringValue =
      !value && value !== 0
        ? ""
        : typeof value === "object"
        ? JSON.stringify(value)
        : value.toString();

    const capsules = stringValue.split(",").map((x) => x.trim());

    return capsules.length > 1 ? (
      <span className="dbDataBlocks">
        {capsules.map((stringValue, i) => (
          <Fragment key={i}>
            <span className="ddbItem" key={i + stringValue}>
              {this.formatText(stringValue)}
            </span>
            {i < capsules.length - 1 && <span>,</span>}
          </Fragment>
        ))}
      </span>
    ) : (
      this.formatText(stringValue)
    );
  }

  renderUrl(url) {
    const mime_type = url.searchParams.get("mime_type");
    const mime = mime_type?.split("/")[0] || "";

    return (
      <span style={{}} className={" url " + mime}>
        <a
          href="#"
          onClick={(e) => {
            e.preventDefault();
            this.setState({ urlPopup: url.href });
          }}
          style={{
            color: "blue",
            textDecoration: "underline",
            cursor: "pointer",
          }}
        >
          {_.startCase(mime || "URL")}
        </a>
      </span>
    );
  }

  formatText(str) {
    const urlValue = this.isValidUrl(str);

    return urlValue ? this.renderUrl(urlValue) : <span>{str}</span>;
  }

  isValidUrl = (url) => {
    try {
      let x = new URL(url);
      return x;
    } catch (_) {
      return false;
    }
  };

  getUrlMime = (validUrl) => {
    const mime_type = validUrl.searchParams.get("mime_type");
    const mime = mime_type?.split("/")[0];

    return mime || "";
  };

  parseValue = (value = "") => {
    return (value || "")
      .toString()
      .split(",")
      .map((item) => item.trim())
      .filter((item) => item.length > 0);
  };

  hasValidUrl(value) {
    return this.parseValue(value).find(this.isValidUrl);
  }

  render() {
    const { valueBuf, uid, loading, urlPopup } = this.state;
    const { selectedCellUid, item, column, isSelected, selectRow, index } =
      this.props;

    const tempRow = item._id?.match(/^temp/);

    const editable = !tempRow && selectedCellUid === uid;
    const value = item?.[column?.name];

    const editCell = () => {
      if (column?.name === "_id") return;
      this.setState({ valueBuf: value, urlPopup: null }, () => {
        this.props.selectCell(this.state.uid);
      });
    };

    return (
      <td
        onDoubleClick={editCell}
        // onClick={() => console.log({ value, valueBuf })}
      >
        <div className="tableData">
          {index === 0 ? (
            tempRow ? (
              <div className="loader"></div>
            ) : (
              <input
                type="checkbox"
                checked={isSelected}
                onChange={() => selectRow()}
                className="dbRowCheck"
              />
            )
          ) : null}

          <span className="dbDataCell" style={{ maxWidth: "unset" }}>
            {this.renderValue(value)}
            <span className="editCell" onClick={editCell}></span>

            {/* {editable ? (
              <div
                className="editableCell"
                style={{
                  zIndex: 1,
                  background: "#999",
                  position: "unset",
                }}

              >
                <RichTextEditable
                  value={valueBuf || ""}
                  onChange={(updatedValue) => {
                    console.log("Updated Value:", updatedValue);
                    this.setState({ valueBuf: updatedValue });
                  }}
                />
              </div>
            ) : null} */}
          </span>

          {editable ? (
            <>
              <EditableCell
                value={valueBuf || ""}
                onChange={(updatedValue) => {
                  this.setState({ valueBuf: updatedValue });
                }}
                submit={(value) => this.handleSubmitValue(value)}
              />
              <div className="cellFileUpload">
                <label>
                  + Upload
                  <input
                    type="file"
                    hidden
                    value={""}
                    onChange={(e) => this.handleFiles(e)}
                    multiple
                  />
                </label>
              </div>
              <div
                className="editCellConfirm"
                onClick={(e) => this.handleSubmit(e)}
              >
                <img
                  className="editCellIco"
                  src={require("../../Assets/img/user/check.png")}
                ></img>
              </div>
            </>
          ) : urlPopup ? (
            <>
              <div
                style={{
                  position: "fixed",
                  top: 0,
                  left: 0,
                  right: 0,
                  bottom: 0,
                  backgroundColor: "rgba(0, 0, 0, 0.5)",
                  zIndex: 999,
                }}
                onClick={() => this.setState({ urlPopup: null })}
              ></div>

              <div
                style={{
                  position: "fixed",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                  backgroundColor: "#fff",
                  padding: "20px",
                  borderRadius: "8px",
                  boxShadow: "0 2px 10px rgba(0, 0, 0, 0.2)",
                  zIndex: 1000,
                  width: "80%",
                  maxWidth: "600px",
                }}
              >
                <button
                  onClick={() => this.setState({ urlPopup: null })}
                  style={{
                    position: "absolute",
                    top: "10px",
                    right: "10px",
                    background: "transparent",
                    border: "none",
                    fontSize: "16px",
                    cursor: "pointer",
                  }}
                >
                  ✖
                </button>
                <div>
                  <ContentRenderer url={urlPopup} />
                </div>
              </div>
            </>
          ) : null}
          {loading ? (
            <div style={{ alignSelf: "center" }}>
              <div className="loader" />
            </div>
          ) : null}
        </div>
      </td>
    );
  }
}

const TableDetailsPopup = (props) => {
  const { visible, close, callback, database, table, team } = props;

  const [form, setForm] = useState(table || {});
  const [loading, setLoading] = useState(false);
  const [formError, setFormError] = useState(null);
  const [newColumn, setNewColumn] = useState("");

  if (!table || !database) return null;

  const isAdmin = ["owner", "admin"].includes(team.role);

  const updateForm = (obj) => {
    setForm(update(form, { $merge: obj }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      if (loading) return;

      let invalidValues = [];
      ["name"].map((x) => {
        if (!form[x]) invalidValues.push(x);
      });

      if (invalidValues.length) {
        return setFormError(
          `Missing valid required input${
            invalidValues.length > 1 ? "s" : ""
          } ${invalidValues.join(", ")}`
        );
      }

      setLoading(true);
      setFormError(null);

      await api.put(`v1/database/${database._id}/table`, {
        tableName: table.name,
        newTableName: form.name,
        columns: form.columns,
      });

      setLoading(false);
      close();

      callback(null, form);
    } catch (e) {
      setLoading(false);
      setFormError(e.message);
      toast.error(e.message);
    }
  };

  const handleAddColumnForm = (e) => {
    e.preventDefault();
    if (newColumn) {
      updateForm({
        columns: [...(form.columns || []), { name: newColumn }].filter(
          (x, i, arr) => i === arr.indexOf(x)
        ),
      });
      setNewColumn("");
    }
  };

  return (
    <div
      className={"upModal fixed"}
      style={{
        display: visible ? "flex" : "none",
      }}
    >
      <div className="upModalItem">
        <div className="upmPreHead">
          <img
            className="upmCloseIco"
            src={require("../../Assets/img/user/close.png")}
            onClick={close}
          ></img>

          <div className="upmPreHeadTitle">{table?.name}</div>
        </div>

        <div className="upmBody">
          <div className="upmRowGroup mt50">
            <div className="upmRow">
              <div className="upmRowLabel">Table Name</div>
              <div className="upmRowValue">
                <input
                  type="text"
                  placeholder="Enter name"
                  className="upmRowInput"
                  required
                  value={form?.name || ""}
                  onChange={(e) => {
                    updateForm({ name: e.target.value });
                  }}
                />
              </div>
            </div>

            <form onSubmit={handleAddColumnForm}>
              <div className="upmRow">
                <div className="upmRowLabel">Add Column</div>
                <div className="upmRowValue">
                  <input
                    type="text"
                    placeholder="Enter Column Name"
                    className="upmRowInput"
                    required
                    value={newColumn || ""}
                    onChange={(e) => {
                      const columnNameRegex = /^[a-zA-Z_][a-zA-Z0-9_]*$/;
                      const value = e.target.value;
                      if (!value || columnNameRegex.test(value))
                        setNewColumn(value);
                    }}
                  />
                </div>
                <div onClick={handleAddColumnForm}>Submit</div>
              </div>
            </form>

            <div>
              <DraggableList
                droppableId="table-columns-list"
                value={form.columns?.map((x) => ({
                  id: x.name,
                  item: x,
                  content: (
                    <div
                      style={{
                        padding: "10px",
                        marginBottom: "10px",
                        background: "#fff",
                        border: "1px solid #ddd",
                        borderRadius: "4px",
                        boxShadow: "0 1px 3px rgba(0,0,0,0.1)",
                        cursor: "grab",
                      }}
                    >
                      <div>_icon_</div>
                      <div>{x.name}</div>
                      <div
                        onClick={() =>
                          updateForm({
                            columns: form.columns?.filter(
                              (col) => col._id !== x._id
                            ),
                          })
                        }
                      >
                        Delete
                      </div>
                    </div>
                  ),
                }))}
                onChange={(x) => updateForm({ columns: x.map((x) => x.item) })}
                classNames={{
                  droppableList: "columnlist",
                }}
              />
            </div>
          </div>

          {formError ? <div className="errormsg">{formError}</div> : null}
          <div className="mpmActionRow mt50 mb15">
            <div className="defaultButton" onClick={handleSubmit}>
              {loading ? "Loading" : "Update Table"}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const TableSyncPopup = (props) => {
  const { visible, close, callback, database, table } = props;

  const [form, setForm] = useState(table?.dbSyncs?.[0]);
  const [loading, setLoading] = useState(false);
  const [formError, setFormError] = useState(null);

  if (!table || !database) return null;

  const updateForm = (obj) => {
    setForm(update(form || {}, { $merge: obj }));
  };

  const applyDbSync = async (dbSync) => {
    try {
      if (loading) return;

      if (!dbSync?.synchFilter?.tableId)
        throw new Error("Select a database table");

      const payload = {
        ..._.pick(dbSync, ["_id", "status", "synchFilter"]),
        synchFromDatabase: dbSync?.synchFilter?.dbId,
        synchFromTable: dbSync?.synchFilter?.tableId,
        synchToDatabase: props.database._id,
        synchToTable: props.table._id,
      };

      await api.post("v1/database/dbsync", payload);

      setLoading(false);

      callback(null);
      close();
    } catch (e) {
      setLoading(false);
      setFormError(e.message);
      toast.error(e.message);
    }
  };

  const toggleDbSync = async (dbSync) => {
    try {
      updateForm({
        status: dbSync?.status === "active" ? "deactive" : "active",
      });
    } catch (error) {
      console.error(error);
      setFormError(error.message);
    }
  };

  return (
    <div
      className={"upModal fixed"}
      style={{
        display: visible ? "flex" : "none",
      }}
    >
      <div className="upModalItem">
        <div className="upmPreHead">
          <img
            className="upmCloseIco"
            src={require("../../Assets/img/user/close.png")}
            onClick={close}
          ></img>

          <div className="upmPreHeadTitle">{table?.name}</div>
        </div>

        <div className="upmBody">
          <div className="upmRowGroup mt50">
            <div className="upmRow">
              <div className="upmRowLabel">Database Syncing</div>
              <div className="upmRowValue">
                <ToggleButton
                  classNames={{
                    container: "optionHeadTabs",
                    item: "optionHeadTabItem",
                  }}
                  overideDefaultStyle
                  value={form?.status === "active"}
                  onChange={(x) =>
                    x !== (form?.status === "active") && toggleDbSync(form)
                  }
                  options={[
                    { value: false, label: "Off" },
                    { value: true, label: "On" },
                  ]}
                />
              </div>
            </div>
          </div>

          {form?.status === "active" ? (
            <DatabaseDataSelector
              {...{
                ...props,
                // availableFor: "front",

                disbaleFilterGroup: false,
                databases: (props.databases || []).filter(
                  (x) => x._id !== props.database?._id
                ),
                value: form?.synchFilter || {},
                customFilterConditions: [{ label: "Equals", value: "eq" }],
                onChange: (x) => {
                  let _dbSync = {
                    ...(form || {}),
                    synchFilter: { ...(form?.synchFilter || {}), ...x },
                  };
                  updateForm(_dbSync);
                },
              }}
            />
          ) : null}

          {formError ? <div className="errormsg">{formError}</div> : null}
          <div className="mpmActionRow mt50 mb15">
            <div className="defaultButton" onClick={() => applyDbSync(form)}>
              {loading ? "Loading" : "Submit"}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

class DatabaseDataSelector extends React.Component {
  selectorId = Math.random();

  render() {
    const { value, onChange } = this.props;
    const mergeChange = (obj) => onChange({ ...(value || {}), ...obj });

    return (
      <div className="calcItemUnit">
        <div className="calcItemUnitBox">
          <div className="calcItemUnitDecor"></div>
          <div className="calcItemUnitMain">
            <div className="calcItemUnitMainLabel calcSourceLabel">
              Database Table
            </div>

            <CustomSelect
              classNames={{
                head: "calcItemUnitMainDropdown",
                label: "calcItemUnitMainDropdownLabel",
              }}
              labelIcon={
                <img
                  className="calcItemUnitMainDropdownIco"
                  src={require("../../Assets/img/options/options/database.png")}
                ></img>
              }
              placeholder={"Select"}
              {...getDatabaseSelectorOptions({
                ...this.props,
                selectorId: this.selectorId,
                onChange: (obj) => mergeChange(obj),
              })}
              jointActionRow={
                <div className="calcItemUnitMainDropdownFooter">
                  <div className="calcItemUnitMainDropdownFooterArrow">
                    <div className="one"></div>
                    <div className="two"></div>
                  </div>
                </div>
              }
            />
          </div>
        </div>

        <div className="calcItemUnitBox filterWrapper">
          <div className="calcItemDatabaseFilter">
            <DatabaseQueryFilter
              {...this.props}
              dbId={value?.dbId}
              tableId={value?.tableId}
              value={value}
              onChange={(obj) => mergeChange(obj)}
              valueTypeData={null}
            />
          </div>
        </div>
      </div>
    );
  }
}

const AddTablePopup = (props) => {
  const { visible, close, callback, database } = props;

  const [form, setForm] = useState({});
  const [loading, setLoading] = useState(false);
  const [formError, setFormError] = useState(null);
  const [newColumn, setNewColumn] = useState("");

  const updateForm = (obj) => {
    setForm(update(form, { $merge: obj }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      if (loading) return;

      let invalidValues = [];
      ["name"].map((x) => {
        if (!form[x]) invalidValues.push(x);
      });

      if (invalidValues.length) {
        return setFormError(
          `Missing valid required input${
            invalidValues.length > 1 ? "s" : ""
          } ${invalidValues.join(", ")}`
        );
      }

      setLoading(true);
      setFormError(null);

      await api.post(`v1/database/${database._id}/table`, {
        tableName: form.name,
        columns: form.columns || [],
      });

      setLoading(false);
      close();

      callback(null, form);
    } catch (e) {
      setLoading(false);
      setFormError(e.message);
      toast.error(e.message);
    }
  };

  const handleAddColumnForm = (e) => {
    e.preventDefault();
    if (newColumn) {
      updateForm({
        columns: [...(form.columns || []), newColumn].filter(
          (x, i, arr) => i === arr.indexOf(x)
        ),
      });
      setNewColumn("");
    }
  };

  return (
    <div
      className={"upModal fixed"}
      style={{
        display: visible ? "flex" : "none",
      }}
    >
      <div className="upModalItem">
        <div className="upmPreHead">
          <img
            className="upmCloseIco"
            src={require("../../Assets/img/user/close.png")}
            onClick={close}
          ></img>

          <div className="upmPreHeadTitle">Create New Table</div>
        </div>

        <div className="upmBody">
          <div className="upmRowGroup mt50">
            <div className="upmRow">
              <div className="upmRowLabel">Table Name</div>
              <div className="upmRowValue">
                <input
                  type="text"
                  placeholder="Enter name"
                  className="upmRowInput"
                  required
                  value={form?.name || ""}
                  onChange={(e) => {
                    updateForm({ name: e.target.value });
                  }}
                />
              </div>
            </div>

            <form onSubmit={handleAddColumnForm}>
              <div className="upmRow">
                <div className="upmRowLabel">Add Column</div>
                <div className="upmRowValue">
                  <input
                    type="text"
                    placeholder="Enter Column Name"
                    className="upmRowInput"
                    required
                    value={newColumn || ""}
                    onChange={(e) => {
                      const columnNameRegex = /^[a-zA-Z_][a-zA-Z0-9_]*$/;
                      const value = e.target.value;
                      if (!value || columnNameRegex.test(value))
                        setNewColumn(value);
                    }}
                  />
                </div>
                <div onClick={handleAddColumnForm}>Submit</div>
              </div>
            </form>

            <div>
              <DraggableList
                droppableId="new-table-columns-list"
                value={form.columns?.map((x) => ({
                  id: x,
                  content: (
                    <div
                      style={{
                        padding: "10px",
                        marginBottom: "10px",
                        background: "#fff",
                        border: "1px solid #ddd",
                        borderRadius: "4px",
                        boxShadow: "0 1px 3px rgba(0,0,0,0.1)",
                        cursor: "grab",
                      }}
                    >
                      <div>_icon_</div>
                      <div>{x}</div>
                      <div
                        onClick={() =>
                          updateForm({
                            columns: form.columns?.filter((col) => col !== x),
                          })
                        }
                      >
                        Delete
                      </div>
                    </div>
                  ),
                }))}
                onChange={(x) => updateForm({ columns: x.map((x) => x.id) })}
                classNames={{
                  droppableList: "columnlist",
                }}
              />
            </div>
          </div>

          {formError ? <div className="errormsg">{formError}</div> : null}
          <div className="mpmActionRow mt50 mb15">
            <div className="defaultButton" onClick={handleSubmit}>
              {loading ? "Loading" : "Create Table"}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const AddRecordPopup = (props) => {
  const { visible, close, callback, database, table } = props;

  const [form, setForm] = useState({});
  const [loading, setLoading] = useState(false);
  const [formError, setFormError] = useState(null);

  const updateForm = (obj) => {
    setForm(update(form, { $merge: obj }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      if (loading) return;

      setLoading(true);
      setFormError(null);

      await props.handleAddRow(form);

      setLoading(false);
      close();

      callback(null, form);
    } catch (e) {
      setLoading(false);
      setFormError(e.message);
      toast.error(e.message);
      callback(e, null);
    }
  };

  return (
    <div
      className={"upModal fixed"}
      style={{
        display: visible ? "flex" : "none",
      }}
    >
      <div className="upModalItem">
        <div className="upmPreHead">
          <img
            className="upmCloseIco"
            src={require("../../Assets/img/user/close.png")}
            onClick={close}
          ></img>

          <div className="upmPreHeadTitle">Create New Record</div>
        </div>

        <div className="upmBody">
          <div className="upmRowGroup mt50">
            {table?.columns?.map((column) => {
              if (!column?.name || column?.name === "_id") return null;
              return (
                <div className="upmRow" key={column?._id}>
                  <div className="upmRowLabel">{column?.name}</div>
                  <div className="upmRowValue">
                    <input
                      type="text"
                      placeholder="Enter name"
                      className="upmRowInput"
                      value={form?.[column?.name] || ""}
                      onChange={(e) => {
                        updateForm({ [column?.name]: e.target.value });
                      }}
                    />
                  </div>
                </div>
              );
            })}

            {formError ? <div className="errormsg">{formError}</div> : null}
            <div className="mpmActionRow mt50 mb15">
              <div className="defaultButton" onClick={handleSubmit}>
                {loading ? "Loading" : "Submit"}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  team: state.pState.AUTH?.team,
  databases: state.vState.BUILDER.databases,
});

const mapDispatchToProps = (dispatch) => ({
  setScreenState: (obj, persist = false, screenName = SCREEN_NAME) =>
    persist
      ? dispatch(PActions.setPScreenState(screenName, obj))
      : dispatch(UnpActions.setVScreenState(screenName, obj)),
});

export const DatabaseDetails = connect(
  mapStateToProps,
  mapDispatchToProps
)(DatabaseDetailsInner);

const DatabaseDetailsScreen = (props) => {
  const dbName = props.router.params?.dbName;

  return (
    <div className="userPanel">
      <SideNav activeSidebarItem="databases" key="sidenav" />
      <div className="upMain databasesScreen">
        <NavBar activeLink="/databases" />
        <DatabaseDetails
          {...{
            ...props,
            dbName,
            onDbChange: (x) =>
              navigationModule.navigate(
                `/database/${x.name}`,
                props.router.navigate
              ),
            databaseSelector: { limit: 1000 },
          }}
        />
      </div>
    </div>
  );
};

export default withRouter(DatabaseDetailsScreen);
