import React from "react";
import _ from "lodash";

import OptionItem from "../../../../../../Components/etc/OptionItem";
import { CheckBoxHorizontal } from "../../../../../../Components/etc/RadioBox";
import RichTextData from "../../../Common/DataProperties/RichTextData";

const SliderConfig = (props) => {
  const { value, onChange } = props;

  const sliderConfig = value || {};
  const changeSliderConfig = (obj) => {
    onChange({ ...sliderConfig, ...obj });
  };

  const checkboxOptions = [
    { value: "autoPlay", label: "Auto Play" },
    { value: "loop", label: "Loop" },
  ];

  return (
    <>
      <OptionItem optionItemLabel="Options">
        <div className="optionItemBody sliderConfigBox">
          <div className="optionItemRow">
            <CheckBoxHorizontal
              value={checkboxOptions
                .map((x) => x.value)
                .filter((key) => sliderConfig[key])}
              onChange={(list) => {
                let updatedSliderConfig = { ...sliderConfig };
                checkboxOptions.map((x) => {
                  return (updatedSliderConfig[x.value] = !!list.includes(
                    x.value
                  ));
                });
                changeSliderConfig(updatedSliderConfig);
              }}
              options={checkboxOptions}
              isVertical
            />
          </div>

          {[
            {
              value: "autoPlayInterval",
              placeholder: "In milliseconds",
              active: sliderConfig.autoPlay,
            },
            {
              value: "scrollCount",
              label: "Slides to Scroll",
              placeholder: "Number of slides to scroll",
              active: true,
            },
          ]
            .filter((x) => x.active)
            .map((item) => {
              const itemValue = sliderConfig?.[item.value] || {};
              const onChange = (valueObj) =>
                changeSliderConfig({
                  [item.value]: {
                    ...itemValue,
                    valueObj,
                    valueType: "textParts",
                  },
                });

              return (
                <div className="optionItemRow pad7" key={item.value}>
                  <div className="optionConditionTableSelectorFilterItemContext lightenMode">
                    <div className="optionConditionTableSelectorFilterItemHead">
                      <div className="optionConditionTableFilterMetaRequirementLabel">
                        {item.label || _.startCase(item.value)}
                      </div>
                    </div>

                    <RichTextData
                      {...{
                        ...props,
                        key: item.value,
                        value: itemValue.valueObj,
                        placeholder: item.placeholder,
                        onChange,
                      }}
                    />
                  </div>
                </div>
              );
            })}

          <div className="optionItemRow pad7">
            <div className="optionConditionTableSelectorFilterItemContext lightenMode">
              <div className="optionConditionTableSelectorFilterItemHead">
                <div className="optionConditionTableFilterMetaRequirementLabel">
                  Active Tab
                </div>
              </div>

              <RichTextData
                {...{
                  ...props,
                  key: "defaultActiveTab",
                  placeholder: "Enter tab value or order number",
                  value: sliderConfig.defaultActiveTab?.valueObj || {},
                  onChange: (valueObj) =>
                    changeSliderConfig({
                      defaultActiveTab: { valueObj, valueType: "textParts" },
                    }),
                }}
              />
            </div>
          </div>
        </div>
      </OptionItem>
    </>
  );
};

export default SliderConfig;
