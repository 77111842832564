import React from "react";
import _ from "lodash";

import ToggleButton from "../../../../../Components/Input/ToggleButton";
import DatabaseDataSelector from "../../../../Common/DatabaseOperations/DatabaseDataSelector";
import {
  RepeatingDatabaseSelector,
  DatabaseSelector,
} from "../../../../Common/DatabaseOperations_v2/DatabaseDataSelector";

import DatabaseSortingQuery from "../../../../Common/DatabaseOperations/DatabaseSortingQuery";
import {
  LinkingBoxes,
  LinkingBoxesPopup,
} from "../../../../Common/Linking/LinkingBox";
import OptionItem from "../../../../../Components/etc/OptionItem";
import ExternalApiDataSelector from "../../../../Common/ExternalApiOperations/ExternalApiDataSelector";
import RichTextData from "../../Common/DataProperties/RichTextData";
import { ContainerTypeScreen } from "./ContainerType/ContainerType";

const ContainerData = (props) => {
  const { value: _value, onChange: _onChange } = props;

  const value = _value?.containerData || {};
  const onChange = (x) => _onChange({ containerData: x });
  const mergeChange = (obj) => onChange({ ...value, ...obj });

  const optionHeadTabs = (
    <ToggleButton
      classNames={{ container: "optionHeadTabs", item: "optionHeadTabItem" }}
      overideDefaultStyle
      value={
        _value?.containerType?.type === "screen" ? "screen" : !!value.repeating
      }
      onChange={(val) => {
        if (val === "screen") {
          _onChange({
            containerType: {
              ...(_value?.containerType || {}),
              type: "screen",
              prevType: _value?.containerType?.type,
            },
          });
        } else {
          _onChange({
            containerType: {
              ...(_value?.containerType || {}),
              type:
                _value?.containerType?.prevType !== "screen"
                  ? _value?.containerType?.prevType
                  : "container",
            },
            containerData: {
              ...(_value.containerData || {}),
              repeating: !!val,
            },
          });
        }
      }}
      options={[
        { label: "Fixed", value: false },
        { label: "Repeating", value: true },
        { label: "Page", value: "screen" },
      ]}
    />
  );

  return (
    <>
      <OptionItem optionItemLabel="Content" optionHeadTabs={optionHeadTabs}>
        {_value?.containerType?.type === "screen" ? (
          <ContainerTypeScreen
            {...props}
            value={_value.containerType}
            onChange={(x) => _onChange({ containerType: x })}
          />
        ) : value.repeating ? (
          value.dbId === "externalApi" ? (
            <div className="revealOffset decorRemoval" style={{ padding: "0px 16px 16px 16px" }}>
              <ExternalApiDataSelector
                {...{
                  ...props,
                  value: value,
                  onChange: (value) => mergeChange(value),
                }}
              />
              <ExternalApiPagination
                {...props}
                value={value.pagination}
                onChange={(val) => mergeChange({ pagination: val })}
              />
            </div>
          ) : value.dbId === "staticValues" ? (
            <div className="revealOffset decorRemoval" style={{ padding: "0px 16px 16px 16px" }}>
              <div className="calcItemUnit">
                <div className="calcItemUnitBox">
                  <div className="calcItemUnitDecor"></div>
                  <div className="calcItemUnitMain">
                    <div className="calcItemUnitMainLabel calcSourceLabel">
                      Database Table
                    </div>

                    <DatabaseSelector
                      {...props}
                      value={value}
                      onChange={(obj) => mergeChange(obj)}
                      externalApi={true}
                      webrtcStreams={true}
                    />
                  </div>
                </div>

                <div className="calcItemUnitBox">
                  <div className="calcItemDatabaseFilter">
                    <RichTextData
                      {...{
                        ...props,
                        value: value?.staticValues?.valueText?.valueObj,
                        onChange: (valueObj) =>
                          mergeChange({
                            staticValues: {
                              ...(value?.staticValues || {}),
                              valueText: { valueType: "textParts", valueObj },
                            },
                          }),
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          ) : value.dbId === "webrtcStreams" ? (
            <div className="revealOffset decorRemoval" style={{ padding: "0px 16px 16px 16px" }}>
              <div className="calcItemUnit">
                <RepeatingDatabaseSelector
                  {...{
                    ...props,
                    value: value,
                    onChange: (value) => {
                      mergeChange(value);
                    },

                    valueTypeData: { tableSelector: { maxNoOfInputs: 0 } },
                    immediateProps: {
                      externalApi: true,
                      webrtcStreams: true,
                      databaseSortingQuery: true,
                      pagination: true,
                    },
                  }}
                />
              </div>
            </div>
          ) : (
            <>
              <div className="revealOffset decorRemoval" style={{ padding: "0px 16px 16px 16px" }}>
                <div className="calcItemUnit">
                  <RepeatingDatabaseSelector
                    {...{
                      ...props,
                      value: value,
                      onChange: (value) => {
                        mergeChange(value);
                      },

                      valueTypeData: { tableSelector: { maxNoOfInputs: 0 } },
                      immediateProps: {
                        externalApi: true,
                        webrtcStreams: true,
                        databaseSortingQuery: true,
                        pagination: true,
                      },
                    }}
                  />
                </div>
              </div>
            </>
          )
        ) : null}
      </OptionItem>

      <LinkingBoxes
        {...{
          ...props,
          value: value.linkings,
          onChange: (linkings) => mergeChange({ linkings }),
          availableFor: ["emailTemplate"].includes(props.availableFor)
            ? props.availableFor
            : "front",
          autoAddOneEmptyLinking: true,
          immediateProps: {
            disableAdditionalLinking: true,
            autoAddLinkingDefault: { dataType: "off" },
          },

          triggerOnOptions: ["onPress", "onLongPress", "onLoad"].map((x) => ({
            value: x,
            label: _.startCase(x),
          })),
        }}
      />
    </>
  );
};

const ExternalApiPagination = (props) => {
  const { value = {}, onChange } = props;
  const mergeChange = (obj) => onChange({ ...(value || {}), ...obj });

  return (
    <div>
      <div>
        <input
          type="checkbox"
          checked={value.valueType === "externalApiInfinite"}
          onChange={() =>
            mergeChange({
              valueType:
                value.valueType === "externalApiInfinite"
                  ? null
                  : "externalApiInfinite",
            })
          }
        />
        Infinite Pagination
      </div>

      {value.valueType === "externalApiInfinite" ? (
        <div>
          <div>Additional Configuration for Infinite Pagination</div>
          <ExternalApiDataSelector
            {...{
              ...props,
              value: value.externalApi,
              onChange: (value) => mergeChange({ externalApi: value }),
              mode: "externalApiInfinitePagination",
            }}
          />
        </div>
      ) : null}
    </div>
  );
};

export default ContainerData;
