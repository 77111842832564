// const getStyleDataKey = (allStyleData) => {
//   return allStyleData.isActiveContainerTab ? "activeContainerTab" : "default";
// };

import defaultElementStylesEngine from "../../appxolo-engine/modules/styling/defaultElementStyles";

const getStyleData = (allStyleData) => {
  // const styleDataKey = getStyleDataKey(allStyleData);
  // return allStyleData[styleDataKey] || {};

  return allStyleData;
};

const mergeStyleData = (obj, allStyleData, mergeAllStyleData) => {
  // const styleDataKey = getStyleDataKey(allStyleData);

  // mergeAllStyleData({
  //   [styleDataKey]: { ...(allStyleData[styleDataKey] || {}), ...obj },
  // });

  return mergeAllStyleData(obj);
};

const getSelectedStyleCondition = (styleData) => {
  if (styleData.isActiveContainerTab) {
    return "activeContainerTab";
  }

  return styleData.conditionSelector?.heightOrWidth
    ? styleData?.conditions?.find(({ condition, styles }) => {
        const keys = Object.keys(styleData.conditionSelector);
        for (let i = 0; i < keys.length; i++) {
          const key = keys[i];

          if (condition?.[key] !== styleData.conditionSelector?.[key])
            return false;
        }
        return true;
      })?.condition
    : styleData.conditionSelector?.hover
    ? "hover"
    : "default";
};

const getSelectedConditionalStyles = (styleData) => {
  if (styleData.isActiveContainerTab) {
    return styleData.activeContainerTab;
  }

  const selectedConditionalStyles = styleData.conditionSelector?.heightOrWidth
    ? styleData?.conditions?.find(({ condition, styles }) => {
        const keys = Object.keys(styleData.conditionSelector);
        for (let i = 0; i < keys.length; i++) {
          const key = keys[i];

          if (condition?.[key] !== styleData.conditionSelector?.[key])
            return false;
        }
        return true;
      })?.styles
    : styleData.conditionSelector?.hover
    ? styleData.hover
    : styleData.default;

  return selectedConditionalStyles;
};

function mergeIgnoringUndefined(...objects) {
  return objects.reduce((acc, current) => {
    for (const key in current) {
      if (current[key] !== undefined) {
        acc[key] = current[key];
      }
    }
    return acc;
  }, {});
}

const getElementStyleData = (element, key = "builder") => {
  const activeTab =
    element.value?.data?.tabs?.[element.value?.data?.activeTabIndex || 0];
  const allStyleData = activeTab?.styleData || {};

  const styleData = getStyleData(allStyleData) || {};
  const conditionalStyle = getSelectedConditionalStyles(styleData);

  const defaultConditionalStyles = getSelectedConditionalStyles({
    ...styleData,
    isActiveContainerTab: false,
  });
  const defaultStyles = styleData.default;
  const hoverStyles = styleData.conditionSelector?.hover
    ? styleData.hover
    : null;

  const defaultElementStyles = defaultElementStylesEngine.get(
    element?.value?.elementType,
    defaultElementStylesEngine.getVarient(element)
  );

  let overrideStyles = {};

  if (
    key === "builderWrapper" &&
    element.value.elementType === "input" &&
    ["checkBox", "radio", "switch"].includes(activeTab.inputType?.group)
  ) {
    overrideStyles = { height: "min-content", width: "min-content" };
  }

  const styles = mergeIgnoringUndefined(
    defaultElementStyles?.[key] || {},
    defaultStyles?.[key] || {},
    hoverStyles?.[key] || {},
    defaultConditionalStyles?.[key] || {},
    conditionalStyle?.[key] || {},
    overrideStyles
  );

  return styles;
};

const setSelectedConditionalStyles = (styles, styleData, mergeStyleData) => {
  let updatedConditions = [...(styleData.conditions || [])];
  let activeContainerTab = styleData.activeContainerTab;

  if (styleData.isActiveContainerTab) {
    activeContainerTab = styles;
  } else if (styleData.conditionSelector?.heightOrWidth) {
    updatedConditions = updatedConditions.map((condition) => {
      const keys = Object.keys(styleData.conditionSelector);
      const matches = keys.every(
        (key) =>
          condition.condition?.[key] === styleData.conditionSelector?.[key]
      );
      return matches ? { ...condition, styles } : condition;
    });
  } else if (styleData.conditionSelector?.hover) {
    styleData.hover = styles;
  } else {
    styleData.default = styles;
  }

  const updatedStyleData = {
    ...styleData,
    conditions: updatedConditions,
    activeContainerTab,
  };

  mergeStyleData(updatedStyleData);
};

const styleModule = {
  // getStyleDataKey,
  getStyleData,
  mergeStyleData,
  getSelectedStyleCondition,
  getSelectedConditionalStyles,
  getElementStyleData,
  setSelectedConditionalStyles,
};

export default styleModule;
