import React from "react";
import { SketchPicker } from "react-color";

class ColorButton extends React.Component {
  state = {
    displayColorPicker: false,
    color: this.props.value || "#ffffffff",
    focusedElement: this.props.focusedElement || [],
  };

  componentDidUpdate(prevProps) {
    if (
      this.props.value !== prevProps.value &&
      this.props.value !== this.state.color
    ) {
      this.setState({ color: this.props.value });
    }
  }

  handleClick = () => {
    this.setState({ displayColorPicker: !this.state.displayColorPicker });
  };

  handleClose = () => {
    this.setState({ displayColorPicker: false });
  };

  rgba2hex(orig) {
    var a,
      rgb = orig,
      alpha = (rgb && rgb.a) || "",
      hex = rgb
        ? (rgb.r | (1 << 8)).toString(16).slice(1) +
          (rgb.g | (1 << 8)).toString(16).slice(1) +
          (rgb.b | (1 << 8)).toString(16).slice(1)
        : orig;

    if (alpha !== "") {
      a = alpha;
    } else {
      a = "01";
    }
    a = ((a * 255) | (1 << 8)).toString(16).slice(1);
    hex = "#" + hex + a;

    return hex;
  }

  handleChange = (color) => {
    const hex = this.rgba2hex(color.rgb);

    this.setState({
      color: hex,
    });
    this.props.onChange(hex);
  };

  render() {
    if (
      this.props.focusedElement &&
      this.state.focusedElement.join() !== this.props.focusedElement.join()
    ) {
      this.setState({
        color: this.props.value || "",
        focusedElement: this.props.focusedElement,
      });
    }
    const styles = {
      container: {
        position: "relative",
      },
      switch: {
        background: this.state.color,
      },
      popover: {
        position: "absolute",
        zIndex: "2",
        transform: this.props.transform || "",
      },
      cover: {
        position: "fixed",
        top: "0px",
        right: "0px",
        bottom: "0px",
        left: "0px",
      },
    };

    return (
      <div style={styles.container}>
        <div
          style={styles.switch}
          className={this.props.className}
          onClick={this.handleClick}
        ></div>
        {this.state.displayColorPicker ? (
          <div>
            <div style={styles.cover} onClick={this.handleClose} />

            <div style={styles.popover}>
              <SketchPicker
                color={this.state.color}
                onChange={this.handleChange}
              />
            </div>
          </div>
        ) : null}
      </div>
    );
  }
}

export default ColorButton;
