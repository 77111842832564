import React, { useEffect, useState, useRef } from "react";

const AudioVisualizer = ({ style }) => {
  const [barColor, setBarColor] = useState("yellow");

  const canvasRef = useRef(null);
  const containerRef = useRef(null);

  const numBars = 5;

  function drawRoundedBar(ctx, x, y, width, height, radius) {
    radius = Math.min(radius, width / 2, height / 2);
    ctx.beginPath();
    ctx.moveTo(x + radius, y);
    ctx.arcTo(x + width, y, x + width, y + height, radius);
    ctx.arcTo(x + width, y + height, x, y + height, radius);
    ctx.arcTo(x, y + height, x, y, radius);
    ctx.arcTo(x, y, x + width, y, radius);
    ctx.closePath();
    ctx.fill();
  }

  useEffect(() => {
    const generateVibrantColorHex = () => {
      const dominant = Math.floor(Math.random() * 56 + 200);
      const secondary1 = Math.floor(Math.random() * 100 + 0);
      const secondary2 = Math.floor(Math.random() * 100 + 0);

      const colors = [dominant, secondary1, secondary2].sort(
        () => Math.random() - 0.5
      );

      const color = `#${colors
        .map((c) => c.toString(16).padStart(2, "0"))
        .join("")}`;

      return color;
    };

    setBarColor(generateVibrantColorHex());
  }, []);

  useEffect(() => {
    const canvas = canvasRef.current;
    const canvasCtx = canvas?.getContext("2d");

    const draw = () => {
      // requestAnimationFrame(draw);

      canvasCtx.clearRect(0, 0, canvas.width, canvas.height);

      const barWidth = canvas.width / numBars;
      const centerY = canvas.height / 2;

      for (let i = 0; i < numBars; i++) {
        const barHeight = Math.max(10, Math.random() * (canvas.height / 3));

        canvasCtx.fillStyle = barColor;

        const position = i;
        drawRoundedBar(
          canvasCtx,
          position * barWidth + barWidth * 0.1, // Add spacing
          centerY - barHeight,
          barWidth * 0.8, // Slightly narrower bars
          barHeight * 2,
          999
        );
      }
    };

    const resizeCanvas = () => {
      if (containerRef.current) {
        canvas.width = containerRef.current.clientWidth * 3;
        canvas.height = containerRef.current.clientHeight * 3;
      }
      draw();
    };

    window.addEventListener("resize", resizeCanvas);
    resizeCanvas();

    return () => {
      window.removeEventListener("resize", resizeCanvas);
    };
  }, [barColor]);

  return (
    <div ref={containerRef} style={{ ...style, overflow: "hidden" }}>
      <canvas ref={canvasRef} style={{ display: "block", zoom: 0.33 }} />
    </div>
  );
};

export default AudioVisualizer;
