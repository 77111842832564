import React from "react";
import update from "immutability-helper";

import ControlledInput from "../../../../../Components/Input/ControlledInput";
import OptionItem from "../../../../../Components/etc/OptionItem";
import CustomSelect from "../../../../../Components/etc/CustomSelect";
import ColorButton from "../../../../../Components/Input/ColorButton";

const MapDimensions = (props) => {
  const styles = props.value || {};
  const mergeStyle = (obj) => props.onChange({ ...styles, ...obj });

  const getStyleValue = props.getStyleValue;

  return (
    <OptionItem optionItemLabel={"Appearance"}>
      <div className="optionItemBody revealOffset">
        <div className="optionItemRow">
          <div className="optionItemBox">
            <div>
              <div className="optionInputIconBox">
                <img
                  className="optionInputIconBoxIco"
                  src={require("../../../../../Assets/img/options/container/widthIco.png")}
                  alt="width"
                ></img>
                <ControlledInput
                  type="text"
                  value={getStyleValue(styles, "width")}
                  onChange={(event) =>
                    mergeStyle({ width: event.target.value })
                  }
                  className="optionInputIconBoxField"
                  placeholder="Auto"
                />
              </div>
            </div>
          </div>
          <div className="optionItemBox">
            <div>
              <div>
                <div className="optionInputIconBox">
                  <img
                    className="optionInputIconBoxIco rotate90"
                    src={require("../../../../../Assets/img/options/container/widthIco.png")}
                    alt="height"
                  ></img>
                  <ControlledInput
                    type="text"
                    onChange={(event) =>
                      mergeStyle({ height: event.target.value })
                    }
                    className="optionInputIconBoxField"
                    placeholder="Auto"
                    value={getStyleValue(styles, "height")}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="optionItemRow">
          <div className="optionItemBox max90w">
            <CustomSelect
              value={props.dataProps?.value?.theme || "standard"}
              onChange={(option) =>
                props.dataProps.onChange({
                  ...(props.dataProps?.value || {}),
                  theme: option.value,
                })
              }
              options={[
                { value: "standard", label: "Standard" },
                { value: "aubergine", label: "Dark" },
                { value: "dark", label: "Night" },
              ]}
              placeholder={"Select"}
              classNames={{
                head: "optionInputIconBox",
                label: "optionInputIconBoxField",
                chevron: "optionDatabaseSelectChevron",
              }}
              // labelIcon={
              //   <img
              //     className="optionInputIconBoxIco"
              //     src={require("../../../../../Assets/img/options/text/font.png")}
              //   ></img>
              // }
            />
          </div>

          <div className="optionItemBox">
            <CustomSelect
              value={
                props.dataProps?.value?.operations?.[0]?.operation || "mark"
              }
              onChange={(option) =>
                props.dataProps.onChange({
                  ...(props.dataProps?.value || {}),
                  operations: update(props.dataProps?.value?.operatios || [], {
                    $merge: {
                      [0]: {
                        ...(props.dataProps?.value?.operations?.[0] || {}),
                        operation: option.value,
                      },
                    },
                  }),
                })
              }
              options={[
                { value: "mark", label: "Point" },
                { value: "route", label: "Route" },
              ]}
              placeholder={"Select"}
              classNames={{
                head: "optionInputIconBox",
                label: "optionInputIconBoxField",
                chevron: "optionDatabaseSelectChevron",
              }}
              // labelIcon={
              //   <img
              //     className="optionInputIconBoxIco"
              //     src={require("../../../../../Assets/img/options/text/font.png")}
              //   ></img>
              // }
            />
          </div>
        </div>
      </div>

      {props.dataProps?.value?.operations?.[0]?.operation === "route" ? (
        <>
          <div className="optionItemHead pad5t">
            <div className="optionItemLabel">Colors</div>
          </div>

          <div className="optionItemBody revealOffset colorUnitRow">
            <div className="optionItemRow">
              <div className="optionItemBox">
                <div className="optionColorItem">
                  <ColorButton
                    className="optionColorCircle"
                    value={
                      props.dataProps?.value?.operations?.[0]
                        ?.polylineStrokeColor || "#000000"
                    }
                    onChange={(val) =>
                      props.dataProps.onChange({
                        ...(props.dataProps?.value || {}),
                        operations: update(
                          props.dataProps?.value?.operatios || [],
                          {
                            $merge: {
                              [0]: {
                                ...(props.dataProps?.value?.operations?.[0] ||
                                  {}),
                                polylineStrokeColor: val,
                              },
                            },
                          }
                        ),
                      })
                    }
                  />
                  <div className="optionInputIconBoxText">Map Line Color</div>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : null}
    </OptionItem>
  );
};

export default MapDimensions;
