import React, { useState, useEffect, useRef, useCallback } from "react";
import Chevron from "./Chevron";

const CustomSelect = ({
  options = [],
  value,
  onChange,
  className,
  placeholder,
  classNames = {},
  jointActionRow,
  labelIcon,
  renderOptionListHeader,
  renderOptionListFooter,
  listEmptyPlaceholder,
  onHover,
  onClose,
  onOpen,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);
  const [optionListPositionStyles, setOptionListPositionStyles] = useState({});

  const dropdownRef = useRef(null);
  const optionListRef = useRef(null);

  const hoveredOption = useRef(null);
  const wasOpen = useRef(null);

  useEffect(() => {
    if (isOpen) {
      if (onOpen) onOpen();
      if (!wasOpen.current) wasOpen.current = true;
    } else if (!isOpen && wasOpen.current) {
      if (onClose) onClose();
    }
  }, [isOpen]);

  const findOption = useCallback((valueToFind, optionsToSearch) => {
    for (const option of optionsToSearch) {
      if (option.value === valueToFind) {
        return option;
      }

      if (option.options) {
        const foundNestedOption = findOption(valueToFind, option.options);
        if (foundNestedOption) {
          return foundNestedOption;
        }
      }
    }

    return null;
  }, []);

  useEffect(() => {
    const foundOption = findOption(value, options);
    setSelectedOption(foundOption || null);
  }, [value, options, findOption]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    const handleEscapeKey = (event) => {
      if (event.key === "Escape") {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    document.addEventListener("keydown", handleEscapeKey);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
      document.removeEventListener("keydown", handleEscapeKey);
    };
  }, []);

  useEffect(() => {
    if (isOpen) {
      const {
        top: ddTop,
        bottom: ddBottom,
        width: ddWidth,
      } = dropdownRef.current?.getBoundingClientRect();
      const optionlistHeight =
        optionListRef.current?.getBoundingClientRect()?.height;

      const windowHeight = window.innerHeight;
      const lowestPoint = Math.max(0, windowHeight - (optionlistHeight + 100));

      const renderPos = ddBottom > lowestPoint ? "top" : "bottom";

      const style =
        renderPos === "bottom"
          ? { top: ddBottom }
          : { bottom: windowHeight - ddTop };

      setOptionListPositionStyles({
        position: "fixed",
        minWidth: ddWidth,
        ...style,
      });
    }
  }, [isOpen]);

  const handleOptionClick = (option) => {
    onChange(option);
    setIsOpen(false);
  };

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const renderOptions = (optionsToRender) => {
    return optionsToRender.map((option, i) => {
      if (option.options) {
        return (
          <div className="optgroup" key={option.label || option.uid || i}>
            <div className="optgroup-label">{option.label}</div>
            <div className="optgroup-options" style={{ marginLeft: "20px" }}>
              {renderOptions(option.options)}
            </div>
          </div>
        );
      } else {
        const active = selectedOption && selectedOption.value === option.value;
        return (
          <div
            key={option.value}
            className={`option ${active ? "active" : ""}`}
            onClick={() => handleOptionClick(option)}
            onMouseOver={() => {
              if (hoveredOption.current !== option.value) {
                hoveredOption.current = option.value;
                if (onHover) onHover(option);
              }
            }}
            onMouseLeave={() => {
              if (hoveredOption.current) {
                hoveredOption.current = null;
                if (onHover) onHover(null);
              }
            }}
          >
            {option.renderIcon ? option.renderIcon() : null}
            {option.menuLabel || option.label}
          </div>
        );
      }
    });
  };

  return (
    <div
      className={`custom-select ${className}`}
      ref={dropdownRef}
      style={{ position: "relative" }}
    >
      <div
        className={`custom-select-head ${classNames.head}`}
        onClick={toggleDropdown}
      >
        {labelIcon ? labelIcon : null}
        <div className={`selected-option ${classNames.label}`}>
          {selectedOption
            ? selectedOption.titleLabel || selectedOption.label
            : placeholder || "Select an option"}
        </div>
        {jointActionRow ? (
          jointActionRow
        ) : (
          <Chevron className={classNames.chevron} />
        )}
      </div>
      {isOpen && (
        <div
          className="options-list"
          style={{
            background: "white",
            position: "",
            ...optionListPositionStyles,
          }}
          ref={optionListRef}
        >
          {renderOptionListHeader ? renderOptionListHeader() : null}
          {renderOptions(options)}
          {!options.length ? (
            listEmptyPlaceholder === null ||
            listEmptyPlaceholder === false ? null : (
              <div>{listEmptyPlaceholder || <span>No Items</span>}</div>
            )
          ) : null}
          {renderOptionListFooter ? renderOptionListFooter() : null}
        </div>
      )}
    </div>
  );
};

export const HoverableCustomSelect = (props) => {
  const [selected, setSelected] = useState({ value: props.value });
  return (
    <>
      <CustomSelect
        {...{
          ...props,
          onChange: (option) => {
            props.onChange(option);
            setSelected(option);
          },
          onClose: (x) => {
            if (props.onTempChange) props.onTempChange(null);
            else props.onChange(selected);
          },
          onOpen: (x) => {
            setSelected({ value: props.value });
          },
          onHover: (x) => {
            if (props.onTempChange) props.onTempChange(x);
            else props.onChange(x || selected);
          },
        }}
      />
    </>
  );
};

export default CustomSelect;
