import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import _ from "lodash";
import update from "immutability-helper";

import UnpActions from "../../Stores/redux/Unpersisted/Actions";
import PActions from "../../Stores/redux/Persisted/Actions";
import SideNav from "../Common/SideNav";
import UserPresence from "../Builder/UserPresence";
import SearchInput from "../../Components/Input/SearchInput";
import NavBar from "../Common/NavBar";
import api from "../../Services/Api/api";
import { toast } from "react-toastify";
import AccountHeader from "./AccountHeader";
import Avatar from "../../Components/etc/Avatar";
import authModule from "../../Modules/auth/auth-module";

const SCREEN_NAME = "ACCOUNT_DETAILS";

const AccountDetailsScreen = (props) => {
  const user = props.team.user;

  const [form, setForm] = useState({});
  const [loading, setLoading] = useState(false);
  const [uploadPercent, setUploadPercent] = useState(null);
  const [edit, setEdit] = useState(false);

  useEffect(() => {
    reloadForm();
  }, [user]);

  const updateUserDetails = async (obj) => {
    setLoading(true);
    try {
      await api.patch("v1/user", obj);
      const accountRes = await authModule.fetchAndSetMyProfile();
      reloadForm(accountRes.team?.user);
    } catch (e) {
      toast.error(e.message);
    }
    setLoading(false);
  };

  const uploadImage = async (file) => {
    try {
      const fileRes = file ? await api.media("v1/file", { file: file }) : null;

      await updateUserDetails({ image: fileRes.file?._id });
    } catch (e) {
      toast.error(e.message);
    }
  };

  const reloadForm = (data) => {
    setForm(_.pick(data || user, ["firstName", "lastName", "email", "phone"]));
  };

  return (
    <div className="userPanel">
      <SideNav
        activeSidebarItem=""
        activeSidebarSubItem={"details"}
        key="sidenav"
      />
      <div className="upMain ticketsScreen">
        <NavBar activeLink="/account/details" />
        <AccountHeader
          subtitle="View and manage your account details"
          activeLink={"/account/details"}
        />

        <div className="upAccountBody">
          <div className="upaBox">
            <div className="upaBoxRow">
              <div className="upaAvatar">
                <Avatar user={user} />
              </div>
              <div className="upaBoxItem">
                <div className="upaMain">
                  {user.firstName || ""} {user.lastName || ""}
                </div>
                <label className="upaUploadLine">
                  Upload photo
                  <input
                    hidden
                    type="file"
                    accept="image/*"
                    value=""
                    onChange={(e) => uploadImage(e.target.files?.[0])}
                  />
                </label>
              </div>
            </div>
          </div>

          <div class="upaNote">
            You can update your personal information on this screen. Ensure your
            details are accurate to keep your account up to date.
          </div>

          <div className="upaBox">
            <div className="upaBoxRow">
              <div className="upaBoxItem">
                <div className="upaLabel">First Name</div>
                <input
                  placeholder="First Name"
                  value={form.firstName || ""}
                  onChange={(e) =>
                    setForm({ ...form, firstName: e.target.value })
                  }
                  disabled={!edit}
                />
              </div>

              <div className="upaBoxItem">
                <div className="upaLabel">Last Name</div>
                <input
                  placeholder="Last Name"
                  value={form.lastName || ""}
                  onChange={(e) =>
                    setForm({ ...form, lastName: e.target.value })
                  }
                  disabled={!edit}
                />
              </div>
            </div>
            <div className="upaBoxRow">
              <div className="upaBoxItem">
                <div className="upaLabel">Email Address</div>
                <input
                  placeholder="example@email.com"
                  value={form.email || ""}
                  onChange={(e) => setForm({ ...form, email: e.target.value })}
                  disabled
                />
                <img
                  className="fieldLockIco"
                  src={require("../../Assets/img/user/lock1.png")}
                ></img>
              </div>
              <div className="upaBoxItem">
                <div className="upaLabel">Phone Number</div>
                <input
                  placeholder="123-456-7890"
                  value={form.phone || ""}
                  onChange={(e) => setForm({ ...form, phone: e.target.value })}
                  disabled={!edit}
                />
              </div>
            </div>

            <div className="upaBoxRow upaActionRow">
              {edit ? (
                <>
                  <div
                    className="upaActionMain"
                    onClick={() => {
                      reloadForm();
                      setEdit(false);
                    }}
                  >
                    Cancel
                  </div>
                  <div
                    className="upaActionMain"
                    onClick={() =>
                      updateUserDetails(
                        _.pick(form, [
                          "firstName",
                          "lastName",
                          "email",
                          "phone",
                        ])
                      ).then(() => setEdit(false))
                    }
                  >
                    {loading ? "Loading" : "Submit"}
                  </div>
                </>
              ) : (
                <div
                  className="upaActionMain"
                  onClick={() => {
                    reloadForm();
                    setEdit(true);
                  }}
                >
                  Edit
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  team: state.pState.AUTH?.team,
});

const mapDispatchToProps = (dispatch) => ({
  setScreenState: (obj, persist = false, screenName = SCREEN_NAME) =>
    persist
      ? dispatch(PActions.setPScreenState(screenName, obj))
      : dispatch(UnpActions.setVScreenState(screenName, obj)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AccountDetailsScreen);
