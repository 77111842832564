import React, { useRef, useEffect, useState } from "react";

const RichTextEditor = ({
  defaultValue,
  onChange,
  onClick,
  onInsert,
  activeCustomComponentId,
  placeholder,
  immediateProps,
  onFocus,
}) => {
  const editorRef = useRef(null);
  const [isPlaceholderVisible, setPlaceholderVisible] = useState(false);

  useEffect(() => {
    if (defaultValue?.length) {
      const fragment = document.createDocumentFragment();
      defaultValue.forEach((item) => {
        if (item.type === "text") {
          const textNode = document.createTextNode(item.content);
          fragment.appendChild(textNode);
        } else if (item.type === "custom") {
          const customComponentElement = getCustomComponent(item, {
            isActive: activeCustomComponentId === item.data?.id,
          });

          fragment.appendChild(customComponentElement);
        }
      });
      editorRef.current.innerHTML = "";
      editorRef.current.appendChild(fragment);
    } else {
      setPlaceholderVisible(editorRef.current.innerText.trim() === "");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getValidUrl = (url) => {
    try {
      return new URL(url);
    } catch (_) {
      return false;
    }
  };

  const getUrlMime = (validUrl) => {
    if (!validUrl) return null;

    const mime_type = validUrl.searchParams.get("mime_type");
    const mime = mime_type?.split("/")[0];

    return mime || "";
  };

  const getCustomComponent = (item, opt) => {
    const customComponentElement = document.createElement("span");
    customComponentElement.className = opt?.isActive
      ? "custom-span active"
      : "custom-span";

    customComponentElement.contentEditable = false;

    if (item?.data) {
      Object.keys(item.data).map(
        (key) => (customComponentElement.dataset[key] = item.data[key])
      );
    }

    if (!customComponentElement.dataset.id) {
      const customComponentId = generateRandomId();
      customComponentElement.dataset.id = customComponentId;
    }

    customComponentElement.addEventListener("click", (e) => {
      handleCustomComponentClick(e, customComponentElement);
    });

    if (item?.data?.itemType === "calculation") {
      customComponentElement.innerHTML = `
      <span class="mainCopy">Calculation<span class="doubleCopy">Calculation</span></span>
    `;
    } else if (item?.data?.itemType === "files") {
      const customText =
        item?.data?.valueObj?.calculation?.[0]?.valueObj?.customText || "";
      const url = getValidUrl(customText.split(",")?.[0]);
      const mime = getUrlMime(url) || "file";

      customComponentElement.innerHTML = `
        <span class="fileTypeWrapper ${mime}">
          
          ${mime === "image" ? `<img src="${url.href}"/>` : ""}
          <span class="fileTypeLabel">Image</span>
        </span>
      `;
    } else {
      customComponentElement.textContent =
        item?.data?.itemTextContent || "Custom Component";
    }

    return customComponentElement;
  };

  const insertCustomComponent = (item) => {
    const selection = window.getSelection();
    const editorElement = editorRef.current;

    if (!editorElement.contains(selection.anchorNode)) {
      return; // Do not insert if the selection is outside the editor
    }

    const immediateParent = selection.anchorNode.parentNode;
    if (
      immediateParent !== editorElement &&
      selection.anchorNode !== editorElement
    ) {
      return; // Do not insert if the selection is inside custom-component
    }

    const range = selection.getRangeAt(0);

    const customComponentElement = getCustomComponent(item);

    const spaceElement = document.createTextNode("\u00A0"); // Insert a non-breaking space

    range.insertNode(spaceElement);
    range.insertNode(customComponentElement);

    // Move the cursor after the inserted custom component and space
    range.setStartAfter(spaceElement);
    range.setEndAfter(spaceElement);
    selection.removeAllRanges();
    selection.addRange(range);

    editorRef.current.focus();

    updateContentProp({
      onInsert: Object.assign({}, customComponentElement.dataset),
    });

    // if (onInsert) {
    //   setTimeout(() => {
    //     onInsert(Object.assign({}, customComponentElement.dataset));
    //   }, 200);
    // }
  };

  const generateRandomId = () => {
    return Math.random().toString(36).substring(2, 10); // Generate a random alphanumeric ID
  };

  const handleCustomComponentClick = (e, x) => {
    e.stopPropagation();
    if (onClick) onClick(Object.assign({}, x.dataset));
  };

  const handleEditorInput = () => {
    updateContentProp();

    setPlaceholderVisible(editorRef.current.innerText.trim() === "");
    onFocus(true);
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      event.preventDefault(); // Prevent new line entry on Enter key press
    }
  };

  const updateContentProp = (opt) => {
    if (onChange && editorRef.current) {
      const contentNodes = Array.from(editorRef.current.childNodes);
      const jsonContent = contentNodes
        .map((node) => {
          if (node.nodeType === Node.TEXT_NODE) {
            return {
              type: "text",
              content: node.textContent,
            };
          } else if (node.classList.contains("custom-span")) {
            return {
              type: "custom",
              data: {
                ...node.dataset,
              },
            };
          } else if (node.nodeType === Node.ELEMENT_NODE && node.textContent) {
            return {
              type: "text",
              content: node.textContent,
            };
          }
          return null;
        })
        .filter(Boolean);

      onChange(jsonContent, opt);
    }
  };

  return (
    <div
      className={ `weInner ${immediateProps?.classNames?.wrapper || "optionConditionTableFilterMetaQuery"}` }
    >
      <div className="weMainWrapper" style={{ position: "relative" }}>
        <div
          contentEditable
          ref={editorRef}
          className={`rich-text-editor ${
            immediateProps?.classNames?.editor ||
            "optionConditionTableFilterMetaQueryInput"
          }`}
          onInput={handleEditorInput}
          suppressContentEditableWarning
          onClick={() => {
            onClick(null);
          }}
          onFocus={() => onFocus(true)}
          onKeyDown={handleKeyDown}
          onBlur={() => {
            onFocus(false);
            setPlaceholderVisible(editorRef.current.innerText.trim() === "");
          }}
        />
        {isPlaceholderVisible ? (
          <div
            className="rte-placeholder"
            onClick={() => {
              setPlaceholderVisible(false);
              editorRef.current.focus();
            }}
          >
            {placeholder}
          </div>
        ) : null}
      </div>

      <div
        className={immediateProps?.classNames?.paramListRow || "paramListRow"}
      >
        <button
          className={
            "paramListItem " + (immediateProps?.classNames?.pButton || "")
          }
          style={{ cursor: "pointer", border: "none" }}
          onClick={() =>
            insertCustomComponent({
              data: { itemType: "calculation", itemTextContent: "Calculation" },
            })
          }
        >
          <img
            className="calculateIco"
            src={require("../../Assets/img/options/options/calculateLight.png")}
          ></img>
        </button>
        {immediateProps?.fileButton ? (
          <button
            className={
              "paramListItem fButton" +
              (immediateProps?.classNames?.fButton || "")
            }
            style={{ cursor: "pointer", border: "none" }}
            onClick={() =>
              insertCustomComponent({
                data: { itemType: "files", itemTextContent: "Files" },
              })
            }
          >
            F
          </button>
        ) : null}
      </div>
    </div>
  );
};

export default RichTextEditor;
