const evalStaticTextParts = (parts, opt = {}) => {
  let result = { value: "", hasCalculation: false };
  try {
    let value = "";
    for (let i = 0; i < parts?.length; i++) {
      const { valueType, valueObj, data } = parts[i] || {};
      if (valueType === "customText") {
        value += valueObj?.customText || "";
      } else if (
        valueType === "calculation" &&
        data?.itemType === "files" &&
        valueObj?.calculation?.[0]?.valueType === "customText"
      ) {
        value += valueObj?.calculation?.[0]?.valueObj?.customText || "";
      } else {
        result.hasCalculation = true;
      }
    }

    if (opt.toArray) {
      value = value
        .split(",")
        .map((x) => x.trim())
        .filter((x) => x);
    }

    result.value = value;
  } catch (e) {
    console.error(e.message);
  }
  return result;
};

export default evalStaticTextParts;
