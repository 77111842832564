import React from "react";
import _ from "lodash";

import OptionItem from "../../../../../Components/etc/OptionItem";
import ControlledInput from "../../../../../Components/Input/ControlledInput";
import CustomSelect from "../../../../../Components/etc/CustomSelect";
import { AppearanceForInput } from "./Appearance";
import {
  CheckBoxHorizontal,
  RadioBoxHorizontal,
} from "../../../../../Components/etc/RadioBox";
import { LinkingBoxes } from "../../../../Common/Linking/LinkingBox";
const InputAppearance = (props) => {
  const {
    value = {},
    onChange,
    inputDataProps,
    inputStyleProps,
    getStyleValue,
  } = props;

  const styles = inputStyleProps.value || {};
  const mergeStyles = (obj) => inputStyleProps.onChange({ ...styles, ...obj });

  const data = inputDataProps.value || {};
  const mergeData = (obj) => inputDataProps.onChange({ ...data, ...obj });

  const selectedGroup = value.inputType?.group || "textInput";

  return (
    <>
      <OptionItem optionItemLabel={"Appearance"}>
        <div className="optionItemBody revealOffset">
          <div className="optionItemRow">
            <div className="optionItemBox">
              <div>
                <div className="optionInputIconBox">
                  <img
                    className="optionInputIconBoxIco"
                    src={require("../../../../../Assets/img/options/container/widthIco.png")}
                    alt="width"
                  ></img>
                  <ControlledInput
                    type="text"
                    value={getStyleValue(styles, "width")}
                    onChange={(event) =>
                      mergeStyles({ width: event.target.value })
                    }
                    className="optionInputIconBoxField"
                    placeholder="Auto"
                  />
                </div>
              </div>
            </div>
            <div className="optionItemBox">
              <div>
                <div>
                  <div className="optionInputIconBox">
                    <img
                      className="optionInputIconBoxIco rotate90"
                      src={require("../../../../../Assets/img/options/container/widthIco.png")}
                      alt="height"
                    ></img>
                    <ControlledInput
                      type="text"
                      onChange={(event) =>
                        mergeStyles({ height: event.target.value })
                      }
                      className="optionInputIconBoxField"
                      placeholder="Auto"
                      value={getStyleValue(styles, "height")}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <AppearanceForInput {...inputStyleProps} />
        </div>
      </OptionItem>
    </>
  );
};

export const InputTypeSelector = (props) => {
  const { value = {}, onChange, inputDataProps, inputStyleProps } = props;

  const styles = inputStyleProps.value || {};
  const mergeStyles = (obj) => inputStyleProps.onChange({ ...styles, ...obj });

  const data = inputDataProps.value || {};
  const mergeData = (obj) => inputDataProps.onChange({ ...data, ...obj });

  const selectedGroup = value.inputType?.group || "textInput";

  const builderGroupType = [
    "textInput",
    "textarea",
    "number",
    "password",
  ].includes(selectedGroup)
    ? "textInput"
    : ["radio", "checkBox", "switch"].includes(selectedGroup)
    ? "radio"
    : ["filePicker", "imagePicker"].includes(selectedGroup)
    ? "filePicker"
    : selectedGroup;

  return (
    <div className="inputOptionWrapper">
      <div className="optionItemRow">
        <div className="optionItemBox">
          <div>
            <div className="oibLabel">Type</div>
            <div>
              <CustomSelect
                onChange={(option) =>
                  onChange({
                    inputType: {
                      ...(value.inputType || {}),
                      group: option.value,
                      inputType: "default",
                    },
                  })
                }
                labelIcon={iconList[builderGroupType]()}
                value={builderGroupType}
                className={"twoOptionsInRow"}
                options={[
                  {
                    options: [
                      {
                        value: "textInput",
                        label: "Text",
                      },
                      {
                        value: "selectBox",
                        label: "Dropdown",
                      },
                    ],
                  },
                  {
                    options: [
                      {
                        value: "button",
                        label: "Button",
                      },
                      {
                        value: "radio",
                        label: "Toggle",
                      },
                    ],
                  },
                  {
                    options: [
                      {
                        value: "dateTimePicker",
                        label: "Date Picker",
                      },
                      {
                        value: "locationInput",
                        label: "Location",
                      },
                    ],
                  },
                  {
                    options: [
                      {
                        value: "filePicker",
                        label: "File Picker",
                      },
                      {
                        value: "audioRecorder",
                        label: "Audio",
                      },
                    ],
                  },

                  // { value: "checkBox", label: "Check Box" },
                  // { value: "switch", label: "Switch" },
                  // { value: "imagePicker", label: "Media Picker" },
                  // { value: "textarea", label: "Long Text" },
                  // { value: "number", label: "Number" },
                  // { value: "password", label: "Password" },
                ].map((x) => ({
                  ...x,
                  options: x.options.map((x) => ({
                    ...x,
                    renderIcon: iconList[x.value],
                  })),
                }))}
                placeholder={"Select"}
                classNames={{
                  head: "optionInputIconBox ",
                  label: "optionInputIconBoxField",
                  chevron: "optionDatabaseSelectChevron",
                }}
              />
            </div>
          </div>
        </div>
      </div>

      {builderGroupType === "textInput" ? (
        <div className="optionItemRow dateTypeSelector">
          <RadioBoxHorizontal
            value={selectedGroup}
            onChange={(x) =>
              onChange({
                inputType: {
                  ...(value.inputType || {}),
                  group: x,
                  inputType: "default",
                },
              })
            }
            options={[
              { value: "number", label: "Number" },
              { value: "password", label: "Password" },
              { value: "textarea", label: "Long" },
            ]}
          />
        </div>
      ) : builderGroupType === "radio" ? (
        <div className="optionItemRow dateTypeSelector">
          <RadioBoxHorizontal
            value={selectedGroup}
            onChange={(x) =>
              onChange({
                inputType: {
                  ...(value.inputType || {}),
                  group: x,
                  inputType: "default",
                },
              })
            }
            options={[
              { value: "radio", label: "Radio" },
              { value: "checkBox", label: "Check" },
              { value: "switch", label: "Switch" },
            ]}
          />
        </div>
      ) : builderGroupType === "filePicker" ? (
        <>
          <div className="optionItemRow dateTypeSelector">
            <CheckBoxHorizontal
              value={data.acceptFileTypes}
              onChange={(x) =>
                mergeData({
                  acceptFileTypes: x,
                })
              }
              options={[
                { value: "photo", label: "Photo" },
                { value: "video", label: "Video" },
                { value: "all", label: "All" },
              ]}
            />
          </div>

          <div className="inputOptionsLabel">Options</div>
          <div className="optionItemRow">
            <div className="optionItemBox">
              <div className="oibLabel">Multi Select</div>
              <div>
                <CustomSelect
                  value={data.multiple || false}
                  onChange={(option) =>
                    mergeData({
                      multiple: option.value,
                    })
                  }
                  options={[
                    { value: false, label: "Disable" },
                    { value: true, label: "Enable" },
                  ]}
                  placeholder={"Select"}
                  classNames={{
                    head: "optionInputIconBox",
                    label: "optionInputIconBoxField",
                    chevron: "optionDatabaseSelectChevron",
                  }}
                />
              </div>
            </div>
          </div>
        </>
      ) : // ) : selectedGroup === "imagePicker" ? (
      //   <div className="optionItemRow">
      //     <div className="optionItemBox">
      //       <div className="oibLabel">Multi Select</div>
      //       <div>
      //         <CustomSelect
      // value={data.multiple || false}
      // onChange={(option) =>
      //   mergeData({
      //     multiple: option.value,
      //   })
      // }
      //           options={[
      //             { value: false, label: "Disable" },
      //             { value: true, label: "Enable" },
      //           ]}
      //           placeholder={"Select"}
      //           classNames={{
      //             head: "optionInputIconBox",
      //             label: "optionInputIconBoxField",
      //             chevron: "optionDatabaseSelectChevron",
      //           }}
      //         />
      //       </div>
      //     </div>

      //     <div className="optionItemBox">
      //       <div className="oibLabel">Media Type</div>
      //       <div>
      //         <CustomSelect
      //           value={data.acceptFileType || "photo"}
      //           onChange={(option) =>
      //             mergeData({
      //               acceptFileType: option.value,
      //             })
      //           }
      //           options={[
      //             { value: "photo", label: "Photo" },
      //             { value: "video", label: "Video" },
      //           ]}
      //           placeholder={"Select"}
      //           classNames={{
      //             head: "optionInputIconBox",
      //             label: "optionInputIconBoxField",
      //             chevron: "optionDatabaseSelectChevron",
      //           }}
      //         />
      //       </div>
      //     </div>
      //   </div>
      selectedGroup === "dateTimePicker" ? (
        <>
          <div className="optionItemRow">
            {/* <div className="optionItemBox">
            <div className="oibLabel">Mode</div>
            <div>
              <CustomSelect
                value={data.datetimePicker?.datepickerMode || "datepicker"}
                onChange={(option) =>
                  mergeData({
                    datetimePicker: {
                      ...(data.datetimePicker || {}),
                      datepickerMode: option.value,
                    },
                  })
                }
                options={[
                  { label: "Date", value: "datepicker" },
                  { label: "Time", value: "timepicker" },
                  { label: "DateTime", value: "datetimepicker" },
                ]}
                placeholder={"Select"}
                classNames={{
                  head: "optionInputIconBox",
                  label: "optionInputIconBoxField",
                  chevron: "optionDatabaseSelectChevron",
                }}
              />
            </div>
          </div> */}

            {(data.datetimePicker?.datepickerMode || "datepicker") ===
            "datepicker" ? (
              <>
                <div className="optionItemBox">
                  <div className="oibLabel">Style</div>
                  <div>
                    <CustomSelect
                      value={data.datetimePicker?.datepickerType || "select"}
                      onChange={(option) =>
                        mergeData({
                          datetimePicker: {
                            ...(data.datetimePicker || {}),
                            datepickerType: option.value,
                          },
                        })
                      }
                      options={[
                        { label: "Default", value: "select" },
                        { label: "Bar", value: "bar" },
                        // { label: "Open", value: "open" },
                        // { label: "Infinite", value: "infinite" },
                      ]}
                      placeholder={"Select"}
                      classNames={{
                        head: "optionInputIconBox",
                        label: "optionInputIconBoxField",
                        chevron: "optionDatabaseSelectChevron",
                      }}
                    />
                  </div>
                </div>

                <div className="optionItemBox">
                  <div className="oibLabel">Select</div>
                  <div>
                    <CustomSelect
                      value={data.datetimePicker?.selectType || "singleSelect"}
                      onChange={(option) =>
                        mergeData({
                          datetimePicker: {
                            ...(data.datetimePicker || {}),
                            selectType: option.value,
                          },
                        })
                      }
                      options={[
                        { value: "singleSelect", label: "Single Date" },
                        { value: "rangeSelect", label: "Date Range" },
                      ]}
                      placeholder={"Select"}
                      classNames={{
                        head: "optionInputIconBox",
                        label: "optionInputIconBoxField",
                        chevron: "optionDatabaseSelectChevron",
                      }}
                    />
                  </div>
                </div>
              </>
            ) : null}
          </div>
        </>
      ) : null}
    </div>
  );
};

const renderIcon = (src) => (
  <img className="" style={{ width: "30px" }} src={src} alt="icon" />
);

const iconList = {
  textInput: () =>
    renderIcon(require("../../../../../Assets/img/inputIcons/text.png")),
  selectBox: () =>
    renderIcon(require("../../../../../Assets/img/inputIcons/dropdown.png")),
  button: () =>
    renderIcon(require("../../../../../Assets/img/inputIcons/button.png")),
  radio: () =>
    renderIcon(require("../../../../../Assets/img/inputIcons/toggles.png")),
  dateTimePicker: () =>
    renderIcon(require("../../../../../Assets/img/inputIcons/calendar.png")),
  locationInput: () =>
    renderIcon(require("../../../../../Assets/img/inputIcons/location.png")),
  filePicker: () =>
    renderIcon(require("../../../../../Assets/img/inputIcons/file.png")),
  audioRecorder: () =>
    renderIcon(require("../../../../../Assets/img/inputIcons/microphone.png")),
};

export default InputAppearance;
