import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import _ from "lodash";
import update from "immutability-helper";

import UnpActions from "../../Stores/redux/Unpersisted/Actions";
import PActions from "../../Stores/redux/Persisted/Actions";
import SideNav from "../Common/SideNav";
import UserPresence from "../Builder/UserPresence";
import SearchInput from "../../Components/Input/SearchInput";
import NavBar from "../Common/NavBar";
import api from "../../Services/Api/api";
import { toast } from "react-toastify";
import AccountHeader from "./AccountHeader";

const SCREEN_NAME = "ACCOUNT_DETAILS";

const SecurityScreen = (props) => {
  const [password, setPassword] = useState(null);
  const [confirmPassword, setConfirmPassword] = useState(null);
  const [oldPassword, setOldPassword] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  useEffect(() => {}, []);

  const changePassword = async () => {
    try {
      if (!oldPassword || !password) return;

      if (password !== confirmPassword) {
        throw new Error("Confirm Password does not match the Password field.");
      }
      setError(null);
      setLoading(true);
      await api.put("v1/user/password", { oldPassword, password });
      toast.success("Password changed");
      setOldPassword("");
      setPassword("");
      setConfirmPassword("");
    } catch (e) {
      toast.error(e.message);
      setError(e.message);
    }
    setLoading(false);
  };

  return (
    <div className="userPanel">
      <SideNav
        activeSidebarItem=""
        activeSidebarSubItem={"security"}
        key="sidenav"
      />
      <div className="upMain ticketsScreen">
        <NavBar activeLink="/account/security" />
        <AccountHeader
          subtitle="View and manage your account details"
          activeLink="/account/security"
        />

        <div className="upAccountBody">
          <div class="upaNote">
            Use this screen to change your password and enhance the security of
            your account. Make sure to choose a strong, unique password to
            protect your information.
          </div>

          <div className="upaBox">
            <div className="upaBoxRow">
              <div className="upaBoxItem">
                <div className="upaLabel">Current password</div>
                <input
                  placeholder=""
                  value={oldPassword || ""}
                  onChange={(e) => setOldPassword(e.target.value)}
                  type="password"
                />
              </div>
            </div>
            <div className="upaBoxRow">
              <div className="upaBoxItem">
                <div className="upaLabel">New password</div>
                <input
                  placeholder=""
                  value={password || ""}
                  onChange={(e) => setPassword(e.target.value)}
                  type="password"
                />
              </div>
            </div>
            <div className="upaBoxRow">
              <div className="upaBoxItem">
                <div className="upaLabel">Confirm new password</div>
                <input
                  placeholder=""
                  value={confirmPassword || ""}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  type="password"
                />
              </div>
            </div>

            {error ? <div className="errormsg">{error}</div> : null}
            <div className="upaBoxRow upaActionRow">
              <div className="upaActionMain" onClick={changePassword}>
                {loading ? "Loading" : "Change Password"}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  team: state.pState.AUTH?.team,
});

const mapDispatchToProps = (dispatch) => ({
  setScreenState: (obj, persist = false, screenName = SCREEN_NAME) =>
    persist
      ? dispatch(PActions.setPScreenState(screenName, obj))
      : dispatch(UnpActions.setVScreenState(screenName, obj)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SecurityScreen);
