import React, { useState, useRef, useEffect } from "react";
import _, { set } from "lodash";
import OptionItem, {
  OptionToggle1,
} from "../../../../../Components/etc/OptionItem";
import ControlledInput from "../../../../../Components/Input/ControlledInput";
import ToggleButton from "../../../../../Components/Input/ToggleButton";
import JustifyContent from "./JustifyContent";
import AlignItems from "./AlignItems";
import Background from "./Background";
import CustomSelect, {
  HoverableCustomSelect,
} from "../../../../../Components/etc/CustomSelect";
import SliderConfig from "../../ElementTypes/container/ContainerType/SliderConfig";
import ContainerTabsConfig from "../../ElementTypes/container/ContainerType/ContainerTabsConfig";
import ContainerBackground from "./ContainerBackground";
const ContainerAppearance = (props) => {
  const [hover, setHover] = useState(false);

  const isEmailTemplate = ["emailTemplate"].includes(props.availableFor);

  const { styleProps, getStyleValue } = props;

  const _styles = hover ? styleProps.hover.value || {} : styleProps.value || {};
  const styles = hover ? { ...(styleProps.value || {}), ..._styles } : _styles;
  const mergeStyle = (obj) =>
    (hover ? styleProps.hover.onChange : styleProps.onChange)({
      ..._styles,
      ...obj,
    });

  const hoverIndependentStyle = styleProps.value || {};
  const mergeHoverIndependentStyle = (obj) =>
    styleProps.onChange({ ...hoverIndependentStyle, ...obj });

  const flexDirection = styles.flexDirection;

  const containerType = props.value?.containerType || {};
  const mergeContainerType = (obj) =>
    props.onChange({
      ...(props.value || {}),
      containerType: { ...containerType, ...obj },
    });

  return (
    <>
      <OptionItem optionItemLabel={"Appearance"} key={hover}>
        <div className="optionItemBody revealOffset">
          <div className="optionItemRow">
            <div className="optionItemBox">
              <div>
                <div className="optionInputIconBox">
                  <img
                    className="optionInputIconBoxIco"
                    src={require("../../../../../Assets/img/options/container/widthIco.png")}
                    alt="width"
                  ></img>
                  <ControlledInput
                    type="text"
                    onChange={(event) =>
                      mergeStyle({ width: event.target.value })
                    }
                    className="optionInputIconBoxField"
                    placeholder="Auto"
                    value={
                      styles.flex === 1 ? "" : getStyleValue(styles, "width")
                    }
                    disabled={styles.flex === 1}
                  />
                </div>
              </div>
            </div>
            <div className="optionItemBox">
              <div>
                <div>
                  <div className="optionInputIconBox">
                    <img
                      className="optionInputIconBoxIco rotate90"
                      src={require("../../../../../Assets/img/options/container/widthIco.png")}
                      alt="height"
                    ></img>
                    <ControlledInput
                      type="text"
                      onChange={(event) =>
                        mergeStyle({ height: event.target.value })
                      }
                      className="optionInputIconBoxField"
                      placeholder="Auto"
                      value={
                        styles.flex === 1 ? "" : getStyleValue(styles, "height")
                      }
                      disabled={styles.flex === 1}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="optionItemRow">
            <FlexDirection
              {...props}
              value={styles}
              onChange={(styles) =>
                mergeStyle({ ...styles, display: styles.display || "flex" })
              }
            />

            <div className="optionItemBox">
              <div>
                <div>
                  <CustomSelect
                    onChange={(option) =>
                      mergeContainerType({
                        type: option.value,
                      })
                    }
                    value={containerType.type || "container"}
                    options={
                      isEmailTemplate
                        ? [{ value: "container", label: "Basic" }]
                        : [
                            { value: "container", label: "Basic" },
                            { value: "slider", label: "Slider" },
                            { value: "containerTabs", label: "Tabs" },
                          ]
                    }
                    placeholder={"Select"}
                    classNames={{
                      head: "optionInputIconBox",
                      label: "optionInputIconBoxField",
                      chevron: "optionDatabaseSelectChevron",
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="optionItemHead pad5t">
          <div className="optionItemLabel">Background</div>
        </div>
        <ContainerBackground {...{ ...styleProps, backgroundImage: true }} />{" "}
      </OptionItem>

      {containerType.type === "slider" ? (
        <SliderConfig
          {...props}
          value={containerType.sliderConfig}
          onChange={(x) => mergeContainerType({ sliderConfig: x })}
        />
      ) : containerType.type === "containerTabs" ? (
        <ContainerTabsConfig
          {...props}
          value={containerType.containerTabsConfig}
          onChange={(x) => mergeContainerType({ containerTabsConfig: x })}
        />
      ) : null}
    </>
  );
};

const alignmentOptions = {
  row: {
    label: (
      <img
        className="naTabItemIco direction rotate90 inputDirectionItem"
        src={require("../../../../../Assets/img/options/alignment/vertical.png")}
      ></img>
    ),

    alignments: [
      {
        id: "horizontalAlignment",
        value: "justifyContent",
        label: "Horizontal",
        options: [
          {
            value: "flex-start",
            label: "Left",
          },
          {
            value: "flex-end",
            label: "Right",
          },
          {
            value: "space-between",
            label: "Stretch",
          },
          {
            value: "space-evenly",
            label: "Even",
          },
          {
            value: "center",
            label: "Center",
          },
        ],
      },
      {
        id: "verticalAlignment",
        value: "alignItems",
        label: "Vertical",
        options: [
          {
            value: "flex-start",
            label: "Top",
          },
          {
            value: "flex-end",
            label: "Bottom",
          },
          {
            value: "stretch",
            label: "Stretch",
          },
          {
            value: "center",
            label: "Center",
          },
        ],
      },
    ],
  },
  column: {
    label: (
      <img
        className="naTabItemIco direction inputDirectionItem"
        src={require("../../../../../Assets/img/options/alignment/vertical.png")}
      ></img>
    ),
    alignments: [
      {
        id: "verticalAlignment",
        value: "justifyContent",
        label: "Horizontal",
        options: [
          {
            value: "flex-start",
            label: "Top",
          },
          {
            value: "flex-end",
            label: "Bottom",
          },
          {
            value: "space-between",
            label: "Stretch",
          },
          {
            value: "center",
            label: "Center",
          },
        ],
      },
      {
        id: "horizontalAlignment",
        value: "alignItems",
        label: "Vertical",
        options: [
          {
            value: "flex-start",
            label: "Left",
          },
          {
            value: "flex-end",
            label: "Right",
          },
          {
            value: "stretch",
            label: "Stretch",
          },
          {
            value: "center",
            label: "Center",
          },
        ],
      },
    ],
  },
};

const findAlignmentOption = ({
  flexDirection,
  alignmentValue,
  alignmentId,
  value,
}) => {
  const options = alignmentOptions[flexDirection]?.alignments?.find((x) =>
    alignmentId ? x.id === alignmentId : x.value === alignmentValue
  )?.options;
  return options?.find((x) => x.value === value);
};

const FlexDirection = (props) => {
  const { value, defaultElementStyles } = props;
  const styles = { ...defaultElementStyles, ...(value || {}) };

  const [visible, setVisible] = useState(false);

  const dropdownRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setVisible(false);
      }
    };

    const handleEscapeKey = (event) => {
      if (event.key === "Escape") {
        setVisible(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    document.addEventListener("keydown", handleEscapeKey);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
      document.removeEventListener("keydown", handleEscapeKey);
    };
  }, []);

  return (
    <div className="optionItemBox customDirectionInput">
      <div>
        <div>
          <div className="custom-select" ref={dropdownRef}>
            <div
              onClick={() => setVisible((prev) => !prev)}
              className="custom-select-head optionInputIconBox"
            >
              <div className="selected-option optionInputIconBoxField">
                <span className="adItem one">
                  {alignmentOptions[styles?.flexDirection]?.label}
                </span>

                <span className="adItem two">
                  {findAlignmentOption({
                    flexDirection: styles?.flexDirection,
                    alignmentValue: "alignItems",
                    value: styles.alignItems,
                  })?.label || "--"}
                </span>

                <span className="adItem three">
                  {findAlignmentOption({
                    flexDirection: styles?.flexDirection,
                    alignmentValue: "justifyContent",
                    value: styles.justifyContent,
                  })?.label || "--"}
                </span>
              </div>
              <img
                className="optionDatabaseSelectChevron"
                src={require("../../../../../Assets/img/options/container/chevronDown.png")}
              ></img>
            </div>
            {visible && (
              <FlexDirectionPopup
                {...{ ...props, value: styles, close: () => setVisible(false) }}
              />
            )}
          </div>
        </div>
        <div></div>
      </div>
    </div>
  );
};

const FlexDirectionPopup = (props) => {
  const { value: styles, onChange: mergeStyle, close } = props;

  const [form, _setForm] = useState({
    ..._.pick(styles || {}, ["flexDirection", "justifyContent", "alignItems"]),
    flexDirection: ["row", "column"].includes(styles.flexDirection)
      ? styles.flexDirection
      : "column",
  });

  const setForm = (form) => {
    _setForm(form);
    mergeStyle(form);
  };

  const { flexDirection } = form;

  return (
    <div className="options-list customizeDropdown alignmentDropdownList">
      <div className="dpoLabel">Alignment</div>
      <div className="naRow">
        <div className="naLabel">Direction</div>
        <div className="naTabWrapper">
          {[
            {
              value: "row",
              label: (
                <img
                  className="naTabItemIco direction rotate90"
                  src={require("../../../../../Assets/img/options/alignment/vertical.png")}
                ></img>
              ),
            },
            {
              value: "column",
              label: (
                <img
                  className="naTabItemIco direction"
                  src={require("../../../../../Assets/img/options/alignment/vertical.png")}
                ></img>
              ),
            },
          ].map((direction) => (
            <div
              key={direction.value}
              className={
                "naTabItem " +
                (flexDirection === direction.value ? " active" : "")
              }
              onClick={() =>
                setForm({ ...form, flexDirection: direction.value })
              }
            >
              <div className="naTabCanvas">{direction.label}</div>
            </div>
          ))}
        </div>
      </div>

      {alignmentOptions[flexDirection]?.alignments?.map((alignment) => (
        <div className="naRow" key={alignment.value}>
          <div className="naLabel">{alignment.label}</div>
          <div className="naDropdown">
            <HoverableCustomSelect
              value={form[alignment.value]}
              onChange={(option) =>
                setForm({ ...form, [alignment.value]: option.value })
              }
              onTempChange={(option) =>
                setForm({ ...form, ["temp_" + alignment.value]: option?.value })
              }
              options={alignment.options}
              placeholder={"Select"}
              classNames={{
                head: "naDropdownLabel",
                label: "",
                chevron: "optionDatabaseSelectChevron",
              }}
            />
          </div>
        </div>
      ))}
    </div>
  );
};

export default ContainerAppearance;
