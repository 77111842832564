import React from "react";
import { connect } from "react-redux";

import ColumnsEditor from "./ColumnsEditor";
// import RecordIdOperations from "./RecordIdOperations";
import { DatabaseTableSelector } from "../DatabaseOperations_v2/DatabaseDataSelector";
import DatabaseQueryFilter from "../DatabaseOperations_v2/DatabaseQueryFilter";
import { getOptionGroups } from "../DataQueryOperations/PassedParamSelector";

const EditRecord = (props) => {
  const { value = {}, onChange, allDatabases, recordIdOperation } = props;

  const [dbColumns, setDbColumns] = React.useState([]);
  const [currentRecordParam, setCurrentRecordParam] = React.useState(null);

  const mergeChange = (obj) => onChange({ ...value, ...obj });

  const dbData =
    value?.query?.valueType === "currentValue"
      ? value?.query?.valueObj?.valueObj?.dbData
      : value.query?.valueObj;
  const { dbId, tableId } = dbData || {};

  React.useEffect(() => {
    setDbColumns(
      allDatabases
        ?.find((x) => x._id === dbId)
        ?.tables?.find((x) => x._id === tableId)
        ?.columns?.map((x) => x.name)
        ?.filter((x) => x !== "_id")
    );
  }, [allDatabases, dbId, tableId]);

  React.useEffect(() => {
    const selectedParams = getOptionGroups(props).filter(
      (x) =>
        x.sourceType === "repeatingContainer" &&
        !["externalApi", "staticValues", "webrtcStreams"].includes(
          x.dbData?.dbId
        ) &&
        x.options?.length
    );

    const currentParam = selectedParams[selectedParams.length - 1]?.options[0];
    setCurrentRecordParam(currentParam);
  }, []);

  const query = value?.query;
  const mergeQuery = (obj) =>
    onChange({
      ...(value || {}),
      query: { ...(value.query || {}), ...(obj || {}) },
    });

  const propsToPass = {
    ...props,
    mode: null,
    className: null,
    classNames: null,
  };

  return (
    <div className="optionItemBody">
      <div className="databaseItemActionBar">
        <div className="databaseItemActionLabel">Database</div>
        <div
          className="databaseItemActionRemove"
          onClick={() => mergeChange({ query: {} })}
        >
          Clear
        </div>
      </div>

      <div className="databaseOperationModule">
        {value?.query?.valueType !== "currentValue" ? (
          <>
            <div className="domHeader">
              <div className="domLabel">Table</div>

              <DatabaseTableSelector
                {...{
                  ...propsToPass,
                  value: value?.query?.valueObj,
                  onChange: (valueObj) => mergeQuery({ valueObj }),
                  valueTypeData: { tableSelector: { maxNoOfInputs: 0 } },
                }}
              />
            </div>
            <div className="calcItemUnitBox filterWrapper domBody domAdjusted">
              <div className="calcItemDatabaseFilter">
                <DatabaseQueryFilter
                  {...propsToPass}
                  dbId={query?.valueObj?.dbId}
                  tableId={query?.valueObj?.tableId}
                  value={query?.valueObj}
                  onChange={(obj) =>
                    mergeQuery({
                      valueObj: { ...(query?.valueObj || {}), ...obj },
                    })
                  }
                  valueTypeData={null}
                />
              </div>
            </div>
          </>
        ) : null}

        <div className="pad12 bgWhite">
          <div className="domLabel pad">Edit Record</div>

          <div className="linkingOptionsBox spaceTopify">
            <div className="pad6 flexRow flex1">
              <div
                onClick={() =>
                  mergeQuery(
                    query?.valueType === "currentValue"
                      ? {
                          valueType: "",
                          valueObj: {},
                        }
                      : {
                          valueType: "currentValue",
                          valueObj: { valueObj: currentRecordParam },
                        }
                  )
                }
                className={
                  "optionDatabaseRadio checkBoxify " +
                  (query?.valueType === "currentValue" ? "active" : "")
                }
              >
                <div className="optionDatabaseRadioDecor"></div>
              </div>
              <div className="cGrey">Edit current</div>
            </div>
            <div className="pad6 flexRow flex1">
              <div
                className={
                  "optionDatabaseRadio checkBoxify " +
                  (value?.upsert ? "active" : "")
                }
                onClick={() =>
                  mergeChange({
                    upsert: !value.upsert,
                  })
                }
              >
                <div className="optionDatabaseRadioDecor"></div>
              </div>
              <div className="cGrey">Create new if doesn't exist</div>
            </div>
          </div>
        </div>

        <div className="domBodys">
          <ColumnsEditor
            {...propsToPass}
            value={value.document}
            onChange={(val) => mergeChange({ document: val })}
            columns={dbColumns}
          />
        </div>

        {/* {recordIdOperation ? (
          <RecordIdOperations
            {...propsToPass}
            value={value.recordIdOperations}
            onChange={(val) => mergeChange({ recordIdOperations: val })}
          />
        ) : null} */}
      </div>
    </div>
  );
};

export default connect((state) => ({
  allDatabases: state.vState.BUILDER.databases,
}))(EditRecord);
