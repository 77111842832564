import React, { useState } from "react";
import api from "../../../Services/Api/api";
import _ from "lodash";
import { toast } from "react-toastify";

const SaveComponent = ({ component }) => {
  const [loading, setLoading] = useState(false);
  const submit = async () => {
    if (loading) return;
    setLoading(true);
    await api
      .put("v1/component", _.pick(component, ["_id", "data"]))
      .then(() =>
        api.socket("v1/component/reload", { component: component._id })
      )
      .catch((e) =>
        toast.error(e.message, {
          hideProgressBar: true,
          pauseOnHover: true,
        })
      );

    setLoading(false);
  };

  return (
    <div
      className="xoloDeploy"
      onClick={submit}
      style={{ opacity: loading ? 0.5 : 1 }}
    >
      <img
        className="xoloDeployDeviceIco"
        src={require("../../../Assets/img/header/play.png")}
      />
      <div className="xoloDeployIco"></div>
      {/* <div className="xoloDeployLabel">{loading ? "Deploying" : "Deploy"}</div> */}
    </div>
  );
};

export default SaveComponent;
