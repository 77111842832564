import React from "react";
import OptionItem from "../../../../../Components/etc/OptionItem";
import CustomSelect from "../../../../../Components/etc/CustomSelect";
import OptionToggle from "../../../../../Components/Input/OptionToggle";
import ToggleButton from "../../../../../Components/Input/ToggleButton";
import Switch from "../../../../../Components/Input/Switch";

const ContainerOptions = (props) => {
  const styles = props.value || {};
  const mergeStyle = (obj) => props.onChange({ ...styles, ...obj });

  const itemMap = {
    scrolling: {
      value: styles.scrollable || "",
      onChange: (x) =>
        mergeStyle({ scrollable: x === styles.scrollable ? "" : x }),
      label: "Scroll",
      options: [
        { value: "", label: "Off" },
        {
          value: "horizontal",
          label: "X",
        },
        {
          value: "vertical",
          label: "Y",
        },
      ],
      style: "tabs",
    },
    wrap: {
      value: styles.flexWrap || "",
      onChange: (x) => mergeStyle({ flexWrap: x }),
      label: "Wrap",
      options: [
        { value: "", label: "Off" },
        { value: "wrap", label: "On" },
      ],
    },
    safeArea: {
      value: props.safeAreaViewConfig?.enable || "",
      onChange: (x) =>
        props.setSafeAreaViewConfig({
          enable: x,
        }),
      label: "Safe Area",
      options: [
        { value: "", label: "Off" },
        { value: "enable", label: "On" },
      ],
    },
    visibility: {
      value: styles.display || "flex",
      onChange: (x) => mergeStyle({ display: x }),
      label: "Visibility",
      options: [
        { value: "none", label: "Hide" },
        { value: "flex", label: "Show" },
      ],
    },
  };

  return (
    <OptionItem optionItemLabel={"Options"}>
      <div className="optionItemBody revealOffset containerOptions bionicContainerOptions">
        <div className="optionItemRow bionicOptionsWrapper">
          {[itemMap.scrolling, itemMap.wrap].map((item) => (
            <div key={item.label} className="bionicOptions">
              <div className="optionItemListLabel">{item.label}</div>

              <RenderItem {...{ item }} />
            </div>
          ))}
        </div>
        <div className="optionItemRow bionicOptionsWrapper">
          {[itemMap.safeArea, itemMap.visibility].map((item) => (
            <div key={item.label} className="bionicOptions">
              <div className="optionItemListLabel">{item.label}</div>

              <RenderItem {...{ item }} />
            </div>
          ))}
        </div>
      </div>
    </OptionItem>
  );
};

const RenderItem = ({ item }) => {
  if (item.style === "tabs") {
    return (
      // <ToggleButton
      //   classNames={{
      //     container: "bionicHeadTabs",
      //     item: "bionicHeadTabItem",
      //   }}
      //   overideDefaultStyle
      //   {...item}
      // />
      <CustomSelect
        {...{
          ...item,
          onChange: (option) => item.onChange(option.value),
          placeholder: 'Off',
          classNames: {
            head: "optionInputIconBox",
            label: "optionInputIconBoxField",
            chevron: "optionDatabaseSelectChevron",
          },
        }}
      />
    );
  } else
    return (
      <CustomSelect
        {...{
          ...item,
          value: item.options[1]?.value === item.value ? "on" : "off",
          onChange: (option) =>
            item.onChange(item.options[option.value === "on" ? 1 : 0].value),
          placeholder: "Select",
          options: [
            { value: "off", label: "Off" },
            { value: "on", label: "On" },
          ],
          classNames: {
            head: "optionInputIconBox",
            label: "optionInputIconBoxField",
            chevron: "optionDatabaseSelectChevron",
          },
        }}
      />
    );
};

export default ContainerOptions;
