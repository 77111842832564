import React from "react";

const CanvasWidthSelector = (props) => {
  const canvasConfig = props.canvasConfig || {};

  return (
    <div
      className="canvasWidthSelector"
    >
      <input
        placeholder="Screen Width"
        value={canvasConfig.width || ""}
        onChange={(e) =>
          props.setScreenState({
            canvasConfig: { ...canvasConfig, width: e.target.value },
          })
        }
      />

      <div
        className={" " + (canvasConfig.fit ? " active" : "")}
        onClick={() =>
          props.setScreenState({
            canvasConfig: { ...canvasConfig, fit: !canvasConfig.fit },
          })
        }
      >
        Scale
      </div>
    </div>
  );
};

export default CanvasWidthSelector;
