import React, { useRef, useImperativeHandle, useState } from "react";
import { DragSource, DropTarget } from "react-dnd";
import _ from "lodash";
import { connect } from "react-redux";

import FloatingProperties from "./Properties/FloatingProperties";
import UnpActions from "../../Stores/redux/Unpersisted/Actions";
import PActions from "../../Stores/redux/Persisted/Actions";
import styleModule from "../../Modules/style/style-module";

import Text from "./Elements/Text/Text";
import Image from "./Elements/Image/Image";
import Input from "./Elements/Input/Input";
import Map from "./Elements/Map/Map";
import Media from "./Elements/Media/Media";

import hoverPositionModule from "../../appxolo-engine/builder/hover-position";
import config from "../../Config";
import Component from "./Elements/Component/Component";
const { getElementPosition } = hoverPositionModule;

let _hoveredIndices = [];

const DraggableCanvasElementInner = React.forwardRef((props, ref) => {
  const [parentData, setParentData] = useState({});
  const {
    hover,
    isDragging,
    connectDragSource,
    connectDropTarget,
    parentData: { hoverPosition },
    element,
    indices,
    dom,
    builderData: { focusedElement },
    setScreenState,
    style,
  } = props;

  const elementRef = useRef(null);
  connectDragSource(elementRef);
  connectDropTarget(elementRef);

  useImperativeHandle(ref, () => ({
    getNode: () => elementRef.current,
  }));

  const isMouseOver = _hoveredIndices?.join() === indices?.join();
  const isFocused = focusedElement?.element?.id === element.id;
  const showFloatingProperties = isMouseOver && !hover && !isDragging;

  return (
    <div
      ref={elementRef}
      className={Object.entries({
        isDragging,
        isFocused,
        mouseOver: hover,
        floatingProperties: showFloatingProperties,
      })
        .filter(([_, value]) => value) // Keep only truthy values
        .map(([key]) => key) // Extract class names
        .join(" ")}
      style={Object.assign(
        {},
        styleObj.defaultWrapperStyle,
        style,
        hover && styleObj.borderStyle[hoverPosition],
        isDragging && { opacity: 0.2 },
        isFocused && styleObj.borderStyle.focused,
        showFloatingProperties && styleObj.borderStyle.mouseOver
      )}
      onClick={(e) => {
        e.stopPropagation();
        console.log({
          element,
          indices,
          activeTab:
            element.value?.data?.tabs?.[
              element.value?.data?.activeTabIndex || 0
            ],
        });
        props.setBuilderData({ focusedElement: { element, indices } });
      }}
      onMouseOver={() => {
        const isChild = dom.isChild(indices, _hoveredIndices || []);
        if (!isChild) {
          _hoveredIndices = indices;
          setScreenState({ hoveredIndices: indices });
        }
      }}
      onMouseLeave={() => {
        _hoveredIndices = [];
        setScreenState({ hoveredIndices: [] });
      }}
    >
      {showFloatingProperties ? <FloatingProperties {...props} /> : null}
      <RenderCanvasElement {...{ ...props, parentData, setParentData }} />
    </div>
  );
});

const SCREEN_NAME = "DRAGGABLE_CANVAS_ELEMENT";

const mapStateToProps = (state) => ({
  hoveredIndices: state.vState[SCREEN_NAME]?.hoveredIndices || [],
});

const mapDispatchToProps = (dispatch) => ({
  setScreenState: (obj, persist = false, screenName = SCREEN_NAME) =>
    persist
      ? dispatch(PActions.setPScreenState(screenName, obj))
      : dispatch(UnpActions.setVScreenState(screenName, obj)),
});

const DraggableCanvasElementWithRedux = connect(
  mapStateToProps,
  mapDispatchToProps,
  null,
  { forwardRef: true }
)(DraggableCanvasElementInner);

const DraggableCanvasElement = DropTarget(
  "item",
  {
    hover(props, monitor, component) {
      if (!component) {
        return null;
      }
      // node = HTML Div element from imperative API
      const node = component.getNode();
      if (!node) {
        return null;
      }
      const dragItem = monitor.getItem();
      const hoveredElement = props.element;

      // Don't replace items with themselves
      if (
        dragItem.set === "canvasElement" &&
        dragItem.id === hoveredElement.id
      ) {
        return;
      }

      let hoverPosition = getElementPosition(
        node,
        monitor,
        hoveredElement.value.elementType
      );

      if (props.parentData.hoverPosition !== hoverPosition)
        props.setParentData({ hoverPosition });
    },
    drop(props, monitor, component) {
      if (monitor.didDrop()) return;

      if (!component) {
        return undefined;
      }

      const dragItem = monitor.getItem();
      const dropItem = props.element;
      const dom = props.dom;

      // Don't replace items with themselves
      if (dragItem.set === "canvasElement" && dragItem.id === dropItem.id) {
        return;
      }

      let movingNode = dragItem;

      if (dragItem.set !== "canvasElement") {
        const { newNode } = dom.addNodeValue("ROOT", dragItem);
        movingNode = newNode;
      }

      dom.moveNode(
        movingNode.id,
        dropItem.id,
        {
          center: "inside",
          top: "before",
          bottom: "after",
          left: "before",
          right: "after",
        }[props.parentData.hoverPosition]
      );
    },
  },
  (connect, monitor) => ({
    connectDropTarget: connect.dropTarget(),
    hover: monitor.isOver({ shallow: true }),
  })
)(
  DragSource(
    "item",
    {
      beginDrag: (props) => ({
        set: "canvasElement",
        id: props.element.id,
      }),
    },
    (connect, monitor) => ({
      connectDragSource: connect.dragSource(),
      isDragging: monitor.isDragging(),
    })
  )(DraggableCanvasElementWithRedux)
);

const RenderCanvasElement = React.forwardRef((props, ref) => {
  const elementMap = {
    container: <Container ref={ref} {...props} />,
    text: <Text {...props} />,
    image: <Image {...props} />,
    input: <Input {...props} />,
    map: <Map {...props} />,
    media: <Media {...props} />,
  };

  return elementMap[props.element.value.elementType];
});

const Container = React.forwardRef((props, ref) => {
  const element = props.element;
  const children = element.children;
  const activeTab =
    element.value?.data?.tabs?.[element.value?.data?.activeTabIndex || 0];

  let style = styleModule.getElementStyleData(element);

  style = {
    ...style,
    justifyContent: style["temp_justifyContent"] || style.justifyContent,
    alignItems: style["temp_alignItems"] || style.alignItems,
  };

  return (
    <div ref={ref} style={style}>
      {activeTab?.containerType?.type === "screen" ? (
        <Component {...props} />
      ) : (
        children.map((element, index) => (
          <DraggableCanvasElement
            key={element.id}
            {...{
              ...props,
              style: styleModule.getElementStyleData(element, "builderWrapper"),
              element,
              indices: [...props.indices, index],
            }}
          />
        ))
      )}
    </div>
  );
});

const styleObj = {
  defaultWrapperStyle: {
    borderTop: "",
    borderBottom: "",
    borderLeft: "",
    borderRight: "",
  },

  borderStyle: {
    center: {
      // borderTop: "2px solid blue",
      // borderBottom: "2px solid blue",
      // borderLeft: "2px solid blue",
      // borderRight: "2px solid blue",
      outline: "2px solid #397dff",
    },
    focused: {
      // borderTop: "2px solid #397dff",
      // borderBottom: "2px solid #397dff",
      // borderLeft: "2px solid #397dff",
      // borderRight: "2px solid #397dff",
      // outline: "2px solid red",
      borderRadius: 0,
    },
    mouseOver: {
      // borderTop: "1.5px solid #9797ff",
      // borderBottom: "1.5px solid #9797ff",
      // borderLeft: "1.5px solid #9797ff",
      // borderRight: "1.5px solid #9797ff",
      // outline: "2px solid #397dff",
    },
    top: {
      borderTop: "1.5px solid blue",
    },
    bottom: {
      borderBottom: "1.5px solid blue",
    },
    left: {
      borderLeft: "1.5px solid blue",
    },
    right: {
      borderRight: "1.5px solid blue",
    },
  },
};

export default DraggableCanvasElement;
