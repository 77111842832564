import React, { useRef, useState } from "react";

import ColorButton from "../../../../../Components/Input/ColorButton";
import StaticImageSources from "../ImageSources/StaticImageSources";
import OptionItem from "../../../../../Components/etc/OptionItem";
import CustomSelect from "../../../../../Components/etc/CustomSelect";
import api from "../../../../../Services/Api/api";
import ImageUpload from "../ImageSources/ImageUpload";
import OutsideClickDetector from "../../../../../Components/etc/OutsideClickDetector";
import FileManagerModal from "../../../../Common/FileManager/FileManager";

const ContainerBackground = (props) => {
  const [hover, setHover] = useState(false);
  const [visible, setVisible] = useState(false);
  const [showFileManager, setShowFileManager] = useState(false);

  const targetRef = useRef();

  const _styles = hover ? props.hover.value || {} : props.value || {};
  const styles = hover ? { ...(props.value || {}), ..._styles } : _styles;
  const mergeStyle = (obj) =>
    (hover ? props.hover.onChange : props.onChange)({ ..._styles, ...obj });

  let backgroundColor = styles.backgroundColor;

  const changeColor = (hex) => {
    backgroundColor = hex;
    let updateObj = {};
    updateObj.backgroundColor = hex ? hex : "";
    // console.log({ updateObj, classId });
    mergeStyle(updateObj);
  };

  let image = styles.backgroundImage?.replace("url(", "").replace(")", "");
  const appendSrc = (value) => {
    mergeStyle({
      backgroundImage: value && `url(${value})`,
      backgroundSize: styles.backgroundSize || "contain",
    });
  };

  const sliceSrc = () => {
    mergeStyle({
      backgroundImage: "",
    });
  };

  return (
    <>
      <div className="optionItemBody revealOffset colorUnitRow" ref={targetRef}>
        <div className="optionItemRow">
          <div className="optionItemBox">
            <div className="optionColorItem">
              <ColorButton
                className="optionColorCircle"
                style={{ width: "100%", height: "100%" }}
                value={backgroundColor || "#FFFFFF"}
                onChange={(val) => changeColor(val)}
              />
              <div className="optionInputIconBoxText">Color</div>
            </div>
          </div>

          <div className="optionItemBox">
            <div className="optionColorItem">
              <div
                className="optionColorCircle bgImageIco"
                onClick={() => setVisible((prev) => !prev)}
              >
                {image ? (
                  <img src={image} alt="uploadedImage" />
                ) : (
                  <>
                    <div className="bgImageLine one"></div>
                    <div className="bgImageLine two"></div>
                  </>
                )}
              </div>
              <div
                className="optionInputIconBoxText"
                onClick={() => setVisible((prev) => !prev)}
              >
                Image
              </div>
              {visible ? (
                <div className="container-bg-dropdown">
                  <div className="container-bg-upload">
                    <div
                      className="container-bg-upload-box"
                      onClick={() => setShowFileManager(true)}
                    >
                      Open File Library
                    </div>
                  </div>

                  <div className="optionItemRow">
                    <div>
                      {image ? (
                        <div style={{ position: "relative" }}>
                          <div
                            onClick={() => sliceSrc()}
                            className="roundMinus"
                          >
                            &#8211;
                          </div>
                          <img
                            alt=""
                            src={image}
                            style={Object.assign(
                              { width: "40px" },
                              styles.imagePreview
                            )}
                          />
                        </div>
                      ) : null}
                    </div>
                  </div>
                  <div className="optionItemRow">
                    <div
                      className={
                        "optionItemRow" + (image || 1 ? "" : " displayNone")
                      }
                    >
                      <div className="optionItemBox">
                        <CustomSelect
                          onChange={(option) =>
                            mergeStyle({ backgroundRepeat: option.value })
                          }
                          value={styles.backgroundRepeat || ""}
                          options={[
                            { value: "no-repeat", label: "No Repeat" },
                            { value: "repeat", label: "Repeat" },
                          ]}
                          placeholder={"Select"}
                          classNames={{
                            head: "optionInputIconBox",
                            label: "optionInputIconBoxField",
                            chevron: "optionDatabaseSelectChevron",
                          }}
                        />
                      </div>

                      <div className="optionItemBox">
                        <CustomSelect
                          onChange={(option) =>
                            mergeStyle({ backgroundSize: option.value })
                          }
                          value={styles.backgroundSize || ""}
                          options={[
                            { value: "cover", label: "Cover" },
                            { value: "contain", label: "Contain" },
                            { value: "100% 100%", label: "Stretch" },
                            { value: "center", label: "Center" },
                          ]}
                          placeholder={"Select"}
                          classNames={{
                            head: "optionInputIconBox",
                            label: "optionInputIconBoxField",
                            chevron: "optionDatabaseSelectChevron",
                          }}
                        />
                      </div>
                    </div>
                  </div>

                  {showFileManager ? (
                    <FileManagerModal
                      {...{
                        ...props,
                        value: [image || ""]
                          .map((x) => x.trim())
                          .filter((x) => x),
                        onChange: (urls) => {
                          appendSrc(urls[0] || "");
                        },
                        visible: true,
                        close: () => setShowFileManager(false),
                        maxLength: 1,
                      }}
                    />
                  ) : null}
                </div>
              ) : null}
            </div>
            {/* <ImageUpload
              style={{ backgroundColor: "unset" }}
              label={
                <div className="optionColorItem">
                  <div className="optionColorCircle bgImageIco">
                    <div className="bgImageLine one"></div>
                    <div className="bgImageLine two"></div>
                  </div>
                  <div className="optionInputIconBoxText">Background Image</div>
                </div>
              }
              onChange={(file) => appendSrc(api.getFileLink(file))}
            /> */}
          </div>
        </div>
      </div>

      <OutsideClickDetector
        {...{
          targetRef,
          onOutsideClick: () => setVisible(false),
          onEscPress: () => setVisible(false),
        }}
      />
    </>
  );
};

export default ContainerBackground;
