import React from "react";
import { connect } from "react-redux";
import _ from "lodash";

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import UnpActions from "../../Stores/redux/Unpersisted/Actions";
import PActions from "../../Stores/redux/Persisted/Actions";
import SideNav from "../Common/SideNav";
import NavBar from "../Common/NavBar";
import CustomSelect from "../../Components/etc/CustomSelect";
import { AnalyticsDashboardScreenInner } from "./AnalyticsDashboardScreen";
import withRouter from "../../Components/Wrapper/with-router";
import api from "../../Services/Api/api";
import Avatar from "../../Components/etc/Avatar";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const SCREEN_NAME = "ANALYTICS";

const AnalyticsChartScreen = (props) => {
  const chartId = props.router.params.chartId;
  return <AnalyticsChartInner {...{ ...props, key: chartId }} />;
};

class AnalyticsChartInner extends AnalyticsDashboardScreenInner {
  constructor(props) {
    super(props);

    const chartId = props.router.params.chartId;
    this.chartName = _.camelCase(chartId);
    this.chartConfig = this.chartConfigs[this.chartName];

    this.state = {
      filter: this.props.filter || {},
    };
  }

  renderChart(lineData) {
    const options = {
      responsive: true,
      // maintainAspectRatio: false,
      plugins: {
        legend: {
          display: false,
        },
        title: {
          display: false,
          text: "",
        },
      },
      scales: {
        x: {
          display: true,
          offset: true, // Adds space on each side of the x-axis items
          grid: {
            display: false, // Optional: remove grid lines on the x-axis
            borderWidth: 0,
          },
          border: {
            display: false, // Removes the x-axis border line
          },
          ticks: {
            color: "#bdbdbd", // Set the color for x-axis labels (e.g., red)
            font: {
              family: "Avenir",
              size: 12, // Optional: Set font size for x-axis labels
            },
          },
        },
        y: {
          display: true,
          grid: {
            display: false, // Removes the horizontal grid lines along the y-axis
            borderWidth: 0,
          },
          border: {
            display: false, // Removes the x-axis border line
          },
          ticks: {
            color: "#bdbdbd", // Set the color for x-axis labels (e.g., red)
            font: {
              family: "Avenir",
              size: 12, // Optional: Set font size for x-axis labels
            },
          },
          min: 0,
        },
      },
      hover: {
        mode: "nearest", // Ensures the hover mode is set to detect nearest points
        intersect: false, // Ensures it only triggers when directly over a point
      },
    };

    return (
      <div className="mainChartHolder">
        {lineData ? <Line options={options} data={lineData} /> : null}
      </div>
    );
  }

  render() {
    const {
      props: { filter = {}, projects, data },
      state: { loading },
      chartConfig,
      chartName,
    } = this;

    const mergeFilter = (obj) => {
      this.props.setScreenState({ filter: { ...filter, ...obj } });
      this.triggerReload();
    };

    const [startDate, endDate] = filter?.dateRange || [];
    const setDateRange = (dateRange) => mergeFilter({ ...filter, dateRange });

    const { totals, percentageChanges, lineDataSet } = data || {};
    const total = totals?.[chartName];
    const percentageChange = percentageChanges?.[chartName];
    const lineData = lineDataSet?.[chartName];

    const selectedProject = projects?.find(
      (x) => x._id === filter?.projects?.[0]
    );

    return (
      <div className="userPanel">
        <SideNav
          activeSidebarItem=""
          activeSidebarSubItem={this.chartName}
          key="sidenav"
        />
        <div className="upMain analyticsScreen">
          <NavBar activeLink="/analytics" />

          <div className="analyticsHeader">
            <div className="analyticsTitle">{_.startCase(chartName)}</div>

            <div className="analyticsFilters">
              <CustomSelect
                onChange={(option) => mergeFilter({ projects: [option.value] })}
                value={filter.projects?.[0] || ""}
                placeholder="All Project"
                options={projects?.map((x) => ({
                  value: x._id,
                  label: x.name,
                }))}
                classNames={{
                  head: "optionInputIconBox",
                  label: "optionInputIconBoxField",
                  chevron: "optionDatabaseSelectChevron",
                }}
                labelIcon={
                  <div className="labelIco">
                    <Avatar
                      user={{
                        firstName: "",
                        image: selectedProject?.image,
                      }}
                    />
                  </div>
                }
              />

              <DatePicker
                selectsRange={true}
                startDate={startDate}
                endDate={endDate}
                onChange={(update) => {
                  setDateRange(update);
                }}
              />
            </div>
          </div>

          <div className="analyticsMainBody">
            <div className="analyticsDetailValue">
              <div className="chartAggregate">
                {(chartConfig?.formatTotal
                  ? chartConfig.formatTotal(total)
                  : total) || "..."}
              </div>
            </div>
            {this.renderChart(lineData)}

            <div className="fullDataTable">
              <table>
                <thead>
                  <tr>
                    <th>Date</th>
                    <th>{_.startCase(chartName)}</th>
                  </tr>
                </thead>
                <tbody>
                  {data?.analytics?.map((item) => (
                    <tr key={item._id}>
                      <td>{new Date(item._id).toLocaleDateString()}</td>
                      <td>
                        {this.chartConfig?.formatTotal
                          ? this.chartConfig.formatTotal(item[chartName])
                          : item[chartName]}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  team: state.pState.AUTH?.team,
  filter: state.vState[SCREEN_NAME].filter || {},
  data: state.vState[SCREEN_NAME].data || {},
  projects: state.vState.PROJECTS.projects,
});

const mapDispatchToProps = (dispatch) => ({
  setScreenState: (obj, persist = false, screenName = SCREEN_NAME) =>
    persist
      ? dispatch(PActions.setPScreenState(screenName, obj))
      : dispatch(UnpActions.setVScreenState(screenName, obj)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(AnalyticsChartScreen));
