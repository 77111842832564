import React from "react";
import _ from "lodash";
export const RadioBoxHorizontal = (props) => {
  const { value, onChange, options, isCheckbox = false } = props;

  return (
    <div className="flex column pad7 flex1">
      <div className="linkingOpen flexRow bgWhite shadow flex1 br10 pad6">
        {options?.map((option) => {
          const active = option.value === value;
          return (
            <div
              className="pad6 flexRow flex1"
              onClick={() =>
                onChange(
                  isCheckbox ? (active ? null : option.value) : option.value
                )
              }
              key={option.value}
            >
              <div
                className={
                  "optionDatabaseRadio checkBoxify" + (active ? " active" : "")
                }
              >
                <div className="optionDatabaseRadioDecor"></div>
              </div>
              <div className="cGrey">
                {option.label || _.startCase(option.value)}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export const CheckBoxHorizontal = (props) => {
  const {
    value: values = [],
    onChange,
    options,
    isVertical,
    className,
    wrapperClass,
  } = props;

  return (
    <div className={wrapperClass ? wrapperClass : "flex column pad7 flex1"}>
      <div
        className={
          className
            ? className
            : `linkingOpen ${
                isVertical ? "flexColumn" : "flexRow"
              } bgWhite shadow flex1 br8 pad6`
        }
      >
        {options?.map((option) => {
          const active = values.includes(option.value);
          return (
            <div
              className="pad6 flexRow flex1"
              onClick={() =>
                onChange(
                  active
                    ? values.filter((x) => x !== option.value)
                    : [...values, option.value]
                )
              }
              key={option.value}
            >
              <div
                className={
                  "optionDatabaseRadio checkBoxify" + (active ? " active" : "")
                }
              >
                <div className="optionDatabaseRadioDecor"></div>
              </div>
              <div className="cGrey">
                {option.label || _.startCase(option.value)}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export const RadioBoxVertical = (props) => {
  const { value, onChange, options, isCheckbox = false } = props;

  return (
    <div className="linkingOpen flexColumn bgWhite shadow flex1 br10 pad6">
      {options?.map((option) => {
        const active = option.value === value;
        return (
          <div
            className="pad6 flexRow flex1"
            onClick={() =>
              onChange(
                isCheckbox ? (active ? null : option.value) : option.value
              )
            }
            key={option.value}
          >
            <div
              className={
                "optionDatabaseRadio" + (active ? " stillRadio active" : "")
              }
            >
              <div className="optionDatabaseRadioDecor"></div>
            </div>
            <div className="cGrey">
              {option.label || _.startCase(option.value)}
            </div>
          </div>
        );
      })}
    </div>
  );
};
