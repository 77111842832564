import React from "react";
import _ from "lodash";

import QuickValueSelector from "../../../../Common/QuickValueSelector/QuickValueSelector";
import OptionItem from "../../../../../Components/etc/OptionItem";
import { RadioBoxHorizontal } from "../../../../../Components/etc/RadioBox";
import RichTextData from "../../Common/DataProperties/RichTextData";
import {
  LinkingBoxes,
  LinkingBoxesPopup,
} from "../../../../Common/Linking/LinkingBox";
import { InputTypeSelector } from "../../Common/StyleProperties/InputAppearance";

const InputData = (props) => {
  const {
    value = {},
    onChange,
    inputType = { group: "textInput" },
    inputAppearanceProps,
  } = props;
  const mergeChange = (obj) => onChange({ ...value, ...obj });

  const setTextParts = (valueObj) => {
    mergeChange({
      valueType: "textParts",
      valueObj: { ...(value.valueObj || {}), ...valueObj },
    });
  };

  const placeholderInput = (opt = {}) => (
    <div className="optionItemRow pad7">
      <div className="optionConditionTableSelectorFilterItemContext lightenMode">
        <div className="optionConditionTableSelectorFilterItemHead">
          <div className="optionConditionTableFilterMetaRequirementLabel">
            Label
          </div>
        </div>

        <RichTextData
          {...{
            ...props,
            key: inputType?.group + "_placeholder",
            value: value.placeholder?.valueObj || {},
            placeholder: opt.placeholder,
            onChange: (valueObj) =>
              mergeChange({
                placeholder: {
                  valueType: "textParts",
                  valueObj,
                },
              }),
          }}
        />
      </div>
    </div>
  );

  const inputDataSource = (inputType) => {
    switch (inputType) {
      case "textInput":
      case "textarea":
      case "number":
      case "password":
      case "locationInput":
      case "switch":
      case "checkBox":
        return (
          <><div className="inputOptionsLabel">Options</div>
            {placeholderInput()}
            <div className="optionItemRow pad7">
              <div className="optionConditionTableSelectorFilterItemContext lightenMode">
                <div className="optionConditionTableSelectorFilterItemHead">
                  <div className="optionConditionTableFilterMetaRequirementLabel">
                    Default Value
                  </div>
                </div>

                <RichTextData
                  {...{
                    ...props,
                    key: inputType + "_defaultValue",
                    value: value.valueObj || {},
                    onChange: setTextParts,
                  }}
                />
              </div>
            </div>
          </>
        );

      case "radio":
      case "selectBox":
        return (
          <><div className="inputOptionsLabel">Options</div>
            <div className="optionItemRow pad7">
              <div className="optionConditionTableSelectorFilterItemContext lightenMode">
                <div className="optionConditionTableSelectorFilterItemHead">
                  <div className="optionConditionTableFilterMetaRequirementLabel">
                    Selected Option
                  </div>
                </div>

                <RichTextData
                  {...{
                    ...props,
                    key: inputType + "-selected",
                    placeholder: "Selected Value",
                    value: value.selected?.valueObj || {},
                    onChange: (valueObj) =>
                      mergeChange({
                        selected: { valueObj, valueType: "textParts" },
                      }),
                  }}
                />
              </div>
            </div>
            <div className="optionItemRow pad7">
              <div className="optionConditionTableSelectorFilterItemContext lightenMode">
                <div className="optionConditionTableSelectorFilterItemHead">
                  <div className="optionConditionTableFilterMetaRequirementLabel">
                    Available Options
                  </div>
                </div>

                <RichTextData
                  {...{
                    ...props,
                    key: inputType + "-options",
                    placeholder: "Comma separated value",
                    value: value.options?.valueObj || {},
                    onChange: (valueObj) =>
                      mergeChange({
                        options: { valueObj, valueType: "textParts" },
                      }),
                  }}
                />
              </div>
            </div>
          </>
        );

      case "imagePicker":
      case "filePicker":
        const isVideoPicker =
          inputType === "imagePicker" && value?.acceptFileType === "video";
        return (
          <>
            {value.multiple ? (
              <div className="optionItemRow pad7">
                <div className="optionConditionTableSelectorFilterItemContext lightenMode">
                  <div className="optionConditionTableSelectorFilterItemHead">
                    <div className="optionConditionTableFilterMetaRequirementLabel">
                      Max Files Count
                    </div>
                  </div>

                  <RichTextData
                    {...{
                      ...props,
                      key: inputType + "-labeltext",
                      placeholder: "50",
                      value: value.maximumImageCount?.valueObj || {},
                      onChange: (valueObj) =>
                        mergeChange({
                          maximumImageCount: {
                            valueObj,
                            valueType: "textParts",
                          },
                        }),
                    }}
                  />
                </div>
              </div>
            ) : null}
            <div className="optionItemRow pad7">
              <div className="optionConditionTableSelectorFilterItemContext lightenMode">
                <div className="optionConditionTableSelectorFilterItemHead">
                  <div className="optionConditionTableFilterMetaRequirementLabel">
                    {isVideoPicker
                      ? "Max Length (in Minute)"
                      : "Max Size (in MB)"}
                  </div>
                </div>

                <RichTextData
                  {...{
                    ...props,
                    key: inputType + "-labeltext-" + isVideoPicker,
                    placeholder: isVideoPicker
                      ? "Length in Minute"
                      : "Size in MB",
                    value:
                      value[isVideoPicker ? "maxLength" : "maxSize"]
                        ?.valueObj || {},
                    onChange: (valueObj) =>
                      mergeChange({
                        [isVideoPicker ? "maxLength" : "maxSize"]: {
                          valueObj,
                          valueType: "textParts",
                        },
                      }),
                  }}
                />
              </div>
            </div>
            <div className="optionItemRow pad7">
              <div className="optionConditionTableSelectorFilterItemContext lightenMode">
                <div className="optionConditionTableSelectorFilterItemHead">
                  <div className="optionConditionTableFilterMetaRequirementLabel">
                    Label Text
                  </div>
                </div>

                <RichTextData
                  {...{
                    ...props,
                    key: inputType + "-labeltext",
                    placeholder: "Upload",
                    value: value.label?.valueObj || {},
                    onChange: (valueObj) =>
                      mergeChange({
                        label: { valueObj, valueType: "textParts" },
                      }),
                  }}
                />
              </div>
            </div>
          </>
        );

      case "dateTimePicker":
        return (
          <><div className="inputOptionsLabel">Options</div>
            {placeholderInput({ placeholder: "Select Date" })}
            {[
              { value: "minDate", label: "Minimum Date" },
              { value: "maxDate", label: "Maximum Date" },
              // { value: "minTime", label: "Minimum Time" },
              // { value: "maxTime", label: "Maximum Time" },
              { value: "disabledDates", label: "Disable Dates" },
              { value: "allowedDates", label: "Allowed Dates" },
            ].map((item) => {
              const itemValue = value.datetimePicker?.[item.value] || {};
              const onChange = (valueObj) =>
                mergeChange({
                  datetimePicker: {
                    ...(value.datetimePicker || {}),
                    [item.value]: {
                      ...itemValue,
                      valueObj,
                      valueType: "textParts",
                    },
                  },
                });

              return (
                <div className="optionItemRow pad7" key={item.value}>
                  <div className="optionConditionTableSelectorFilterItemContext lightenMode">
                    <div className="optionConditionTableSelectorFilterItemHead">
                      <div className="optionConditionTableFilterMetaRequirementLabel">
                        {item.label || _.startCase(item.value)}
                      </div>
                    </div>

                    <RichTextData
                      {...{
                        ...props,
                        key: inputType + "-" + item.value,
                        value: itemValue.valueObj,
                        onChange,
                      }}
                    />
                  </div>
                </div>
              );
            })}
          </>
        );

      case "button":
        return (
          <>
            <div className="optionItemRow pad7">
              <div className="optionConditionTableSelectorFilterItemContext lightenMode">
                <div className="optionConditionTableSelectorFilterItemHead">
                  <div className="optionConditionTableFilterMetaRequirementLabel">
                    Label Text
                  </div>
                </div>

                <RichTextData
                  {...{
                    ...props,
                    key: inputType + "-labeltext",
                    placeholder: "Button Text",
                    value: value.label?.valueObj || {},
                    onChange: (valueObj) =>
                      mergeChange({
                        label: { valueObj, valueType: "textParts" },
                      }),
                  }}
                />
              </div>
            </div>
          </>
        );

      default:
        return null;
    }
  };

  return (
    <>
      <OptionItem optionItemLabel="Input">
        <div className="optionItemBody revealOffset">
          <InputTypeSelector {...inputAppearanceProps} />
          <div className="">{inputDataSource(inputType.group)}</div>
        </div>
      </OptionItem>
      {["textInput"].includes(inputType.group) ? (
        <LinkingBoxes
          {...{
            ...props,
            value: value.linkings,
            onChange: (linkings) => mergeChange({ linkings }),
            availableFor: ["emailTemplate"].includes(props.availableFor)
              ? props.availableFor
              : "front",
            autoAddOneEmptyLinking: false,
            triggerOnOptions: ["onPress", "onKeyPress", "onLoad"].map((x) => ({
              value: x,
              label: _.startCase(x),
            })),
            linkBoxOptions: {},
          }}
        />
      ) : null}

      {["button"].includes(inputType.group) ? (
        <LinkingBoxes
          {...{
            ...props,

            value: value.linkings || [],
            onChange: (linkings) => mergeChange({ linkings }),

            availableFor: "front",
            autoAddOneEmptyLinking: true,
            immediateProps: {
              disableAdditionalLinking: true,
              autoAddLinkingDefault: { dataType: "off" },
            },

            triggerOnOptions: ["onPress", "onLongPress"].map((x) => ({
              value: x,
              label: _.startCase(x),
            })),

            linkBoxOptions: {
              conditionalDataType: true,
              showTriggerOption: true,
              styles: {
                container: {
                  margin: "3px 0",
                  padding: "5px",
                  border: "solid 0.5px grey",
                  borderRadius: "2px",
                  backgroundColor: "#fff",
                  color: "#333",
                },
              },
            },
          }}
        />
      ) : null}
    </>
  );
};

export default InputData;
