import React, { createRef } from "react";
import update from "immutability-helper";
import _ from "lodash";

import RichTextEditor from "../../../../../Components/Input/RichTextEditor";
import { CalculationTrigger } from "../../../../Common/DataQueryOperations/CalculationBox";
import FileManagerModal from "../../../../Common/FileManager/FileManager";

const CALCULATION_WINDOW_HEIGHT = 600;

class RichTextData extends React.Component {
  state = { focusStatus: "neverFocused", updatedAt: 0 };

  divRef = createRef();

  richTextChangeTimer = 0;

  generateRandomId() {
    return Math.random().toString(36).substring(2, 10);
  }

  richtextToParts(richTextParts) {
    const existingParts = this.props.value?.textParts || [];
    return richTextParts.map((x) => {
      if (x.type === "text") {
        return {
          id: x.data?.id || this.generateRandomId(),
          valueType: "customText",
          valueObj: {
            customText: x.content || "",
          },
        };
      } else {
        const existingPart =
          existingParts.find((part) => part.id === x.data.id) || {};
        return {
          ...existingPart,
          ...x,
          id: x.data.id,
          valueObj: existingPart.valueObj,
          valueType: existingPart.valueType || "calculation",
        };
      }
    });
  }

  onRichTextChange(richTextParts, opt = {}) {
    clearTimeout(this.richTextChangeTimer);
    this.richTextChangeTimer = setTimeout(() => {
      const textParts = this.richtextToParts(richTextParts);

      let updateObj = { textParts };

      if (opt.onInsert?.id)
        updateObj = {
          ...updateObj,
          activePartId: opt.onInsert?.id,
          updatedAt: Date.now(),
        };
      this.mergeChange(updateObj);
    }, 50);
  }

  mergeChange(obj) {
    this.props.onChange({ ...this.props.value, ...obj });
  }

  getCustomComponentLabel(part) {
    return part.valueType === "passedParameter"
      ? part.valueObj?.[part.valueType]?.valueObj?.optionLabel ||
          _.startCase(part.valueType)
      : part.valueType === "calculation"
      ? _.startCase(part.valueType)
      : part.valueObj?.customText;
  }

  getYPos() {
    return this.divRef.current?.getBoundingClientRect().top;
  }

  render() {
    const {
      textParts = [],
      activePartId,
      updatedAt = 0,
    } = this.props.value || {};

    const { placeholder = "Type Here" } = this.props;

    const activePartIndex = textParts.findIndex(
      (x) => activePartId && x?.id === activePartId
    );
    const part = textParts[activePartIndex];

    const setPart = (part, index) =>
      this.mergeChange({
        textParts: update(textParts || [], { $merge: { [index]: part } }),
        activePartIndex: index,
        updatedAt: Date.now(),
      });

    const richTextParts = textParts.map((x) => ({
      type: x.valueType === "customText" ? "text" : "custom",
      content: this.getCustomComponentLabel(x) || "",
      data: {
        ...(x.data || {}),
        itemType: x.data?.itemType || "calculation",
        itemTextContent: x.data?.itemTextContent || "Calculation",
        id: x.id,
        valueObj: x.valueObj,
        valueType: x.valueType,
      },
    }));

    return (
      <div
        style={{ position: "relative" }}
        className={"woloEditor " + this.state.focusStatus}
        ref={this.divRef}
      >
        <RichTextEditor
          immediateProps={this.props.immediateProps}
          key={updatedAt.toString()}
          defaultValue={richTextParts}
          placeholder={placeholder}
          onChange={(richTextParts, opt) =>
            this.onRichTextChange(richTextParts, opt)
          }
          onClick={(x) =>
            this.mergeChange(
              x?.id
                ? { activePartId: x?.id, updatedAt: Date.now() }
                : { activePartId: null }
            )
          }
          onInsert={(x) =>
            this.mergeChange(
              x?.id
                ? { activePartId: x?.id, updatedAt: Date.now() }
                : { activePartId: null }
            )
          }
          onFocus={(x) => {
            this.setState((prevState) => ({
              focusStatus: x ? "pastFocused active" : "pastFocused",
            }));
          }}
          activeCustomComponentId={activePartId}
        />

        {part?.data?.itemType === "files" ? (
          <FileManager
            {...{
              ...this.props,
              immediateProps: null,
              value: part,
              onChange: (part) => setPart(part, activePartIndex),
              close: () =>
                this.mergeChange({ activePartId: null, updatedAt: Date.now() }),
            }}
          />
        ) : part && this.divRef.current ? (
          <AutoCalculationTrigger
            {...{
              ...this.props,
              immediateProps: null,
              value: part,
              getYPos: this.getYPos.bind(this),
              onChange: (part) => setPart(part, activePartIndex),
              onHide: () =>
                this.mergeChange({ activePartId: null, updatedAt: Date.now() }),
            }}
          />
        ) : null}
      </div>
    );
  }
}

export class FileManager extends React.Component {
  render() {
    const { value = {}, onChange } = this.props;

    const valueObjProps = {
      ...this.props,
      value: (
        (value?.valueObj?.calculation?.[0]?.valueObj?.customText || "").split?.(
          ","
        ) || []
      )
        .map((x) => x.trim())
        .filter((x) => x),
      onChange: (urls) => {
        const customText = urls?.join(",") || "";
        const updatedValue = update(value || {}, {
          valueObj: {
            $set: {
              calculation: [
                {
                  valueType: "customText",
                  valueObj: { customText },
                },
              ],
            },
          },
        });

        onChange(updatedValue);
      },
    };

    return <FileManagerModal {...{ ...valueObjProps, visible: true }} />;
  }
}

class AutoCalculationTrigger extends React.Component {
  constructor(props) {
    super(props);
    const y = props.getYPos?.();

    const windowHeight = window.innerHeight;
    const lowestPoint = Math.max(0, windowHeight - CALCULATION_WINDOW_HEIGHT);

    const yPosition = Math.min(y, lowestPoint);
    this.yPosition = yPosition;
  }

  render() {
    const { value = {}, onChange } = this.props;
    const valueType = "calculation";
    const mergeChange = (obj) => onChange({ ...value, ...obj });

    const valueObjProps = {
      ...this.props,
      yPosition: this.yPosition,
      value: value.valueObj?.[valueType],
      onChange: (x) =>
        mergeChange({
          valueObj: { ...(value.valueObj || []), [valueType]: x },
        }),
    };

    return (
      <CalculationTrigger
        {...{ ...valueObjProps, visible: true }}
        style={{ display: "none" }}
      />
    );
  }
}

export default RichTextData;
