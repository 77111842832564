import React from "react";
import _ from "lodash";

import styleModule from "../../../../Modules/style/style-module";
import { Form } from "react-bootstrap";
import CustomSelect from "../../../../Components/etc/CustomSelect";
import evalStaticTextParts from "../../../../Modules/calculation/evalStaticTextParts";
import etcModule from "../../../../Modules/etc/etc-module";
import {
  Checkbox,
  RadioBox,
  Switch,
} from "../../../../appxolo-engine/components/react/Toggle";

class Input extends React.PureComponent {
  updateStaticText(x) {
    console.log(x);
  }

  render() {
    const element = this.props.element;
    const activeTab =
      element.value?.data?.tabs?.[element.value?.data?.activeTabIndex || 0] ||
      {};

    const inputStyle = styleModule.getElementStyleData(element) || {};
    const labelStyle = {};

    const withoutBorderStyle = {
      borderTopWidth: "0px",
      borderBottomWidth: "0px",
      borderLeftWidth: "0px",
      borderRightWidth: "0px",
    };
    const withBorderStyle = {
      borderTopWidth: "1px",
      borderBottomWidth: "1px",
      borderLeftWidth: "1px",
      borderRightWidth: "1px",
      borderTopStyle: "solid",
      borderBottomStyle: "solid",
      borderLeftStyle: "solid",
      borderRightStyle: "solid",
    };

    const inputType = activeTab.inputType || {
      group: "textInput",
      inputType: "default",
    };

    const textInput = {
      default: (
        <input style={inputStyle} placeholder={`Input #${element.id}`} />
      ),
      withLabel: (
        <>
          <div style={labelStyle}>
            {activeTab?.inputData?.labelText || "Label"}
          </div>
          <input
            style={{ ...withoutBorderStyle, ...inputStyle }}
            placeholder={`Input #${element.id}`}
          />
        </>
      ),
      withBorderAndLabel: (
        <>
          <div style={labelStyle}>
            {activeTab?.inputData?.labelText || "Label"}
          </div>
          <input
            style={{ ...withBorderStyle, ...inputStyle }}
            placeholder={`Input #${element.id}`}
          />
        </>
      ),
    };

    const textarea = {
      default: (
        <textarea style={inputStyle} placeholder={`Input #${element.id}`} />
      ),
    };

    const number = {
      default: (
        <input
          type="number"
          style={inputStyle}
          placeholder={`Input #${element.id}`}
        />
      ),
    };

    const password = {
      default: (
        <input
          type="password"
          style={inputStyle}
          placeholder={`Input #${element.id}`}
        />
      ),
    };

    const selectBoxValue =
      activeTab.inputData?.selected?.valueObj?.customText || "";
    const selectBoxOptions = [
      { value: "", label: "Select" },
      ...(activeTab.inputData?.options?.valueObj?.multipleStatic || []),
    ]
      .filter((x, i, arr) => i === arr.findIndex((z) => x.value === z.value))
      .map((x, i) => (
        <option key={x.value + i} value={x.value}>
          {x.label || x.value}
        </option>
      ));

    const components = {
      textInput: textInput,
      textarea,
      number,
      password,
      locationInput: textInput,
      selectBox: {
        default: (
          <div
            className={`custom-select `}
            style={{
              ...withBorderStyle,
              ...inputStyle,
              position: "relative",
            }}
          >
            <div
              className={`custom-select-head `}
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                width: "100%",
                height: "100%",
              }}
            >
              <div className={`selected-option `} style={{ flex: 1 }}>
                Select
              </div>

              <Chevron style={{ borderColor: inputStyle?.["--icon-color"] }} />
            </div>
          </div>
        ),
        withLabel: (
          <>
            <div style={labelStyle}>
              {activeTab?.inputData?.labelText || "Label"}
            </div>
            <select
              style={{ ...withoutBorderStyle, ...inputStyle }}
              defaultValue={selectBoxValue}
            >
              {selectBoxOptions}
            </select>
          </>
        ),
        withBorderAndLabel: (
          <>
            <div style={labelStyle}>
              {activeTab?.inputData?.labelText || "Label"}
            </div>
            <select
              style={{ ...withBorderStyle, ...inputStyle }}
              defaultValue={selectBoxValue}
            >
              {selectBoxOptions}
            </select>
          </>
        ),
      },
      checkBox: {
        default: (
          <RenderCheckbox
            data={activeTab?.inputData}
            style={inputStyle}
            labelStyle={labelStyle}
          />
        ),
      },
      radio: {
        default: (
          <RenderRadioBox
            data={activeTab?.inputData}
            style={inputStyle}
            labelStyle={labelStyle}
          />
        ),
      },
      switch: {
        default: (
          <RenderSwitch
            data={activeTab?.inputData}
            style={inputStyle}
            labelStyle={labelStyle}
          />
        ),
      },
      imagePicker: {
        default: (
          <FilePicker
            type={activeTab?.inputData?.acceptFileType || "image"}
            style={inputStyle}
            labelStyle={labelStyle}
            data={activeTab.inputData || {}}
          />
        ),
      },
      filePicker: {
        default: (
          <FilePicker
            style={inputStyle}
            labelStyle={labelStyle}
            data={activeTab.inputData || {}}
          />
        ),
      },
      dateTimePicker: {
        default: (
          <DatePicker
            data={activeTab?.inputData}
            style={inputStyle}
            pickerConfig={activeTab.inputData?.datetimePicker || {}}
          />
        ),
      },
      audioRecorder: {
        default: <AudioRecorder style={inputStyle} />,
      },

      button: {
        default: (
          <div
            style={{
              ...inputStyle,
            }}
          >
            <span style={{ color: inputStyle?.["--placeholder-color"] }}>
              {evalStaticTextParts(
                activeTab?.inputData?.label?.valueObj?.textParts
              )?.value || "Button"}
            </span>
          </div>
        ),
      },
    };

    return components[inputType.group]?.[inputType.inputType] || null;
  }
}

const Chevron = ({ style = {} }) => {
  return (
    <div
      style={{
        width: "10px",
        height: "10px",
        borderRight: "2px solid black",
        borderTop: "2px solid black",
        transform: "rotate(135deg)",
        borderRadius: "2px",
        ...style,
      }}
    ></div>
  );
};

class RenderCheckbox extends React.Component {
  state = { checked: false };

  get labelText() {
    const placeholderEval = evalStaticTextParts(
      this.props.data?.placeholder?.valueObj?.textParts
    );
    return placeholderEval?.value;
  }

  get style() {
    return {
      height: "100%",
      width: "100%",
      ...(this.props.style || {}),
      display: "flex",
      alignItems: "center",
    };
  }

  render() {
    return (
      <Checkbox
        styles={{
          box: { ...this.style, backgroundColor: this.style["--box-color"] },
          activeBox: { backgroundColor: this.style["--activebox-color"] },
          check: { fill: this.style["--check-color"] },
          label: { color: this.style["--label-color"] },
        }}
        value={this.state.checked}
        onChange={(x) => this.setState({ checked: x })}
        label={this.labelText || "Label"}
      />
    );
  }
}

class RenderRadioBox extends RenderCheckbox {
  render() {
    return (
      <RadioBox
        styles={{
          box: { ...this.style, backgroundColor: this.style["--box-color"] },
          activeBox: {
            backgroundColor: this.style["--activebox-color"] || "#000000",
          },
          inactiveBox: {
            backgroundColor: this.style["--inactivebox-color"] || "#ffffff",
          },
          label: { color: this.style["--label-color"] },
        }}
        value={this.state.checked}
        onChange={(x) => this.setState({ checked: x })}
        label={this.labelText || "Label"}
      />
    );
  }
}

class RenderSwitch extends RenderCheckbox {
  render() {
    return (
      <Switch
        styles={{
          box: { ...this.style, backgroundColor: this.style["--box-color"] },
          activeBox: {
            backgroundColor: this.style["--activebox-color"] || "#e8e8e8",
          },
        }}
        value={this.state.checked}
        onChange={(x) => this.setState({ checked: x })}
        label={this.labelText || "Label"}
      />
    );
  }
}

const FilePicker = ({ style, type, data, labelStyle }) => {
  const [files, setFiles] = React.useState(null);
  const [src, setSrc] = React.useState(null);

  const inputEl = React.useRef(null);

  const handleChange = (event) => {
    let files = event.target.files;
    setFiles(files);

    if (type === "image" && files && files[0])
      setSrc(URL.createObjectURL(files[0]));
    else setSrc(false);
  };

  const elStyle = style || {};

  const evalTextParts = (data) => {
    const textParts = data?.textParts
      ?.map((x) =>
        !x?.valueType || x?.valueType === "customText"
          ? x?.valueObj?.customText
          : "(" + _.startCase(x?.valueType) + ")"
      )
      ?.filter((x) => x !== undefined);
    const text = textParts?.length ? textParts.join("") : undefined;
    return text;
  };

  const labelDataText = evalTextParts(data?.label?.valueObj);

  const styles = {
    container: {
      width: "290px",
      margin: "auto",
      display: "flex",
      flexDirection: "row",
      gap: "10px",
      height: "90px",
      backgroundColor: "#ffffff",
      padding: "10px",
      border: "1px solid #dbdce3",
      borderRadius: "8px",
    },
    uploadButton: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      backgroundColor: "#eeeeee",
      borderRadius: "2px",
      padding: "0px 20px",
      whiteSpace: "nowrap",
      border: "1px solid transparent",
    },
    uploadList: {
      display: "flex",
      overflowX: "hidden",
      gap: "10px",
    },
    uploadItem: {
      display: "flex",
      borderRadius: "2px",
      backgroundColor: "#eeeeee",
      position: "relative",
      border: "1px solid #eeeeee",
      alignItems: "center",
    },
    closeButton: {
      width: "16px",
      height: "16px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      backgroundColor: "#FFFFFF",
      borderRadius: "2px",
      fontSize: "10px",
      position: "absolute",
      top: "2px",
      right: "2px",
      lineHeight: "1",
      zIndex: 1,
      color: "#000000",
      border: "1px solid #eeeeee",
    },
    uploadText: {
      padding: "0px 20px",
      whiteSpace: "nowrap",
      opacity: 0,
    },
    uploadFileName: {
      left: "10px",
      right: "10px",
      position: "absolute",
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
      overflow: "hidden",
      color: "#000000",
    },
    image: {
      width: "100%",
      height: "100%",
      objectFit: "contain",
      position: "absolute",
      inset: 0,
    },
  };

  return (
    <div style={{ ...styles.container, ...elStyle }}>
      <div
        style={{
          ...styles.uploadButton,
          backgroundColor:
            elStyle["--button-color"] || styles.uploadButton?.backgroundColor,
        }}
      >
        + Upload
      </div>
      <div style={styles.uploadList}>
        {[1].map((item) => (
          <div key={item} style={styles.uploadItem}>
            <div style={styles.closeButton}>×</div>
            <span style={styles.uploadText}>+ Upload</span>
          </div>
        ))}
      </div>
    </div>
  );

  return (
    <span
      // onClick={() => inputEl.current?.click()}
      style={elStyle}
    >
      <input
        style={{ display: "none" }}
        ref={inputEl}
        type="file"
        onChange={handleChange}
        accept={type === "image" ? "image/*" : "*/*"}
      />

      {files ? (
        type === "image" ? (
          <img alt="" src={src} style={elStyle} />
        ) : (
          <div style={elStyle}>{files[0]?.name}</div>
        )
      ) : (
        <div style={labelStyle}>{labelDataText || "Upload"}</div>
      )}
    </span>
  );
};

class DatePicker extends React.PureComponent {
  constructor(props) {
    super(props);

    this.today = new Date(new Date().setHours(0, 0, 0, 0));
    this.weekDays = this.getWeekDays();
    this.weeks = this.loadWeeks(new Date(), 1);
  }
  getFirstDayOfWeek(date) {
    const currentDate = new Date(date);
    const dayOfWeek = currentDate.getDay();
    const diff = dayOfWeek;
    currentDate.setDate(currentDate.getDate() - diff);

    return currentDate;
  }

  datepickerTypeSelect() {
    const {
      props: { style, pickerConfig, data },
    } = this;

    const placeholder = evalStaticTextParts(
      data?.placeholder?.valueObj?.textParts || []
    )?.value;

    return (
      <div
        style={Object.assign({}, style, {
          flexDirection: "row",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        })}
      >
        {placeholder ? (
          <span style={{ color: style?.["--placeholder-color"] }}>
            {placeholder}
          </span>
        ) : pickerConfig.selectType === "rangeSelect" ? (
          <span>{`${etcModule.toViewableDateFormat(
            new Date()
          )}  -  ${etcModule.toViewableDateFormat(
            new Date().setMonth(new Date().getMonth() + 1)
          )}`}</span>
        ) : (
          <span>{`${etcModule.toViewableDateFormat(new Date())}`}</span>
        )}

        <Chevron style={{ borderColor: style?.["--icon-color"] }} />
      </div>
    );
  }

  loadWeeks(date, n = 1) {
    const weeks = [];
    const firstDay = this.getFirstDayOfWeek(date);

    let baseDate = new Date(firstDay);

    for (let i = 0; i < n; i++) {
      const week = [];

      for (let j = 0; j < 7; j++) {
        week.push(this.preProcessDate(new Date(baseDate)));
        baseDate.setDate(baseDate.getDate() + 1);
      }
      weeks.push(week);
    }

    return weeks;
  }

  preProcessDate(date) {
    return {
      date,
      isToday: this.today.getTime() === new Date(date).setHours(0, 0, 0, 0),
      isDisabledDate: false,
    };
  }

  renderMonthTitle() {
    const date = new Date();
    const monthTitle = new Date(date).toLocaleString("default", {
      month: "long",
    });

    return (
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-evenly",
          alignItems: "center",
        }}
      >
        <div style={{ cursor: "pointer", padding: "5px" }}>◀◀</div>
        <div style={{ cursor: "pointer", padding: "5px" }}>◀</div>
        <div style={{ minWidth: "50%", textAlign: "center" }}>
          {monthTitle}, {date.getFullYear()}
        </div>
        <div style={{ cursor: "pointer", padding: "5px" }}>▶</div>
        <div style={{ cursor: "pointer", padding: "5px" }}>▶▶</div>
      </div>
    );
  }

  getWeekDays(locale = "en-US", option = {}) {
    var baseDate = new Date(Date.UTC(2017, 0, 1)); // just a Monday
    var weekDays = [];
    for (let i = 0; i < 7; i++) {
      weekDays.push(
        baseDate.toLocaleDateString(locale, {
          weekday: option.format || "short",
        })
      );
      baseDate.setDate(baseDate.getDate() + 1);
    }
    return weekDays;
  }

  renderWeekDays() {
    return (
      <Flex count={7}>
        {this.weekDays.map((x) => (
          <div key={x}>
            <div
              style={{
                fontSize: 12,
                fontWeight: "800",
                textAlign: "center",
                cursor: "pointer",
              }}
            >
              {x}
            </div>
          </div>
        ))}
      </Flex>
    );
  }

  renderWeek(week) {
    return (
      <Flex key={week[0].date.getTime()} count={7}>
        {week.map((item) => {
          const { date, isToday } = item;

          return (
            <div key={date.getTime()}>
              <div
                style={{
                  padding: "10%",
                  margin: "0px",
                  fontSize: 12,
                  textAlign: "center",
                  cursor: "pointer",
                  backgroundColor: "#fff",
                  ...(isToday ? { fontWeight: "800" } : {}),
                }}
              >
                {date.getDate()}
              </div>
            </div>
          );
        })}
      </Flex>
    );
  }

  datepickerTypeBar(opt = {}) {
    const { weeks } = this;

    return (
      <div
        style={Object.assign({}, opt.style, {
          display: "flex",
          flexDirection: "column",
          maxWidth: "500px",
          backgroundColor: "#fff",
        })}
      >
        {this.renderMonthTitle()}
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            paddingLeft: "1%",
            paddingRight: "1%",
            alignItems: "center",
          }}
        >
          <div style={{ cursor: "pointer", padding: "5px" }}>◀</div>
          <div style={{ flex: 1 }}>
            {this.renderWeekDays()}
            <div style={{ backgroundColor: "#f8f8f8" }}>
              {weeks.map((week) => this.renderWeek(week))}
            </div>
          </div>
          <div style={{ cursor: "pointer", padding: "5px" }}>▶</div>
        </div>
      </div>
    );
  }

  render() {
    const type = this.props.pickerConfig?.datepickerType || "select";

    switch (type) {
      case "bar":
        return this.datepickerTypeBar({ style: this.props.style });
      case "select":
      default:
        return this.datepickerTypeSelect();
    }
  }
}

const AudioRecorder = ({ style }) => {
  const styles = {
    awAudioRecorder: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
    },
    awrLeft: {
      gap: "10px",
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
    },
    awrMicIco: {
      width: "14px",
      height: "14px",
    },
    awrDuration: {
      fontSize: "12px",
      fontFamily: "system-ui",
    },
    awrAction: {
      fontSize: "12px",
      color: "#cd0a1f",
      fontFamily: "system-ui",
      cursor: "pointer",
    },
  };
  return (
    <div style={{ ...style, ...styles.awAudioRecorder }}>
      <div style={styles.awrLeft}>
        <img
          src={require("../../../../Assets/img/recordMicrophone.png")}
          style={styles.awrMicIco}
        />
        <div style={styles.awrDuration}>{"00:00"}</div>
      </div>
      <div style={styles.awrAction}>{"Record"}</div>
    </div>
  );
};

function toPercent(num) {
  return `${num}%`;
}

function Flex({
  children,
  count,
  direction,
  offset,
  style,
  wrap,
  ...otherProps
}) {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: direction,
        flexWrap: wrap ? "wrap" : "nowrap",
        ...style,
      }}
      {...otherProps}
    >
      {React.Children.map(children, (child, index) => {
        const marginInlineStart =
          offset && index === 0 ? toPercent((100 * offset) / count) : null;

        return React.cloneElement(child, {
          ...child.props,
          style: {
            flexBasis: toPercent(100 / count),
            flexShrink: 0,
            flexGrow: 0,
            overflow: "hidden",
            marginLeft: marginInlineStart,
            marginInlineStart: marginInlineStart,
            marginInlineEnd: 0,
          },
        });
      })}
    </div>
  );
}

export default Input;
