import React, { useEffect } from "react";
import _ from "lodash";
import update from "immutability-helper";

import RichTextData from "../../Common/DataProperties/RichTextData";
import OptionItem from "../../../../../Components/etc/OptionItem";
import api from "../../../../../Services/Api/api";
import ColorButton from "../../../../../Components/Input/ColorButton";
import CustomSelect from "../../../../../Components/etc/CustomSelect";
import { AudioDataConfig, VideoDataConfig } from "./MediaDataConfig";

const MediaData = (props) => {
  const { value = {}, onChange } = props;
  const mergeChange = (obj) => onChange({ ...value, ...obj });

  const mediaType = value.mediaType || "";

  const mediaTypeSelector = (
    <div className="inputOptionWrapper streamTypeWrapper">
      <div className="optionItemBox">
        <div>
          <div className="oibLabel">Type</div>
          <div>
            <CustomSelect
              onChange={(option) =>
                mergeChange({
                  mediaType: option.value,
                })
              }
              value={value.mediaType || ""}
              options={[
                { value: "video", label: "Video" },
                { value: "audio", label: "Audio" },
                { value: "chart", label: "Chart" },
                { value: "webrtc", label: "Stream" },
              ]}
              placeholder={"Select"}
              classNames={{
                head: "optionInputIconBox",
                label: "optionInputIconBoxField",
                chevron: "optionDatabaseSelectChevron",
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );

  return (
    <>
      {["video", "audio"].includes(mediaType) ? (
        <VideoAudioData
          {...{
            ...props,
            key: mediaType,
            mediaType,
            value: value[mediaType],
            onChange: (x) => mergeChange({ [mediaType]: x }),
            mediaTypeSelector,
          }}
        />
      ) : ["chart"].includes(mediaType) ? (
        <ChartData
          {...{
            ...props,
            key: mediaType,
            mediaType,
            value: value[mediaType],
            onChange: (x) => mergeChange({ [mediaType]: x }),
            mediaTypeSelector,
          }}
        />
      ) : ["webrtc"].includes(mediaType) ? (
        <OptionItem optionItemLabel="DATA">
          {mediaTypeSelector}
          <div className="optionItemBody">
            {[
              { value: "participantId", label: "Participant ID" },
              {
                value: "mode",
                label: "Mode",
                placeholder: "Video / Audio / None",
              },
            ].map((item) => (
              <TextPartsOptionItemRow
                {...{
                  ...props,
                  key: item.value,
                  title: item.label,
                  placeholder: item.placeholder || item.label,
                  value: value[mediaType]?.[item.value]?.valueObj,
                  onChange: (valueObj) =>
                    mergeChange({
                      [mediaType]: {
                        ...(value[mediaType] || {}),
                        [item.value]: { valueType: "textParts", valueObj },
                      },
                    }),
                }}
              />
            ))}
          </div>
        </OptionItem>
      ) : (
        <OptionItem optionItemLabel="DATA">{mediaTypeSelector}</OptionItem>
      )}
    </>
  );
};

const VideoAudioData = (props) => {
  const { value = {}, onChange, mediaType, mediaTypeSelector } = props;
  const mergeChange = (obj) => onChange({ ...value, ...obj });

  const [src, setSrc] = React.useState(value.valueObj?.staticUrl);
  const [uploadProgress, setProgress] = React.useState(null);

  const valueType = value.valueType || "textParts";

  const uploadHandler = async (event) => {
    try {
      let files = event.target.files;
      const localUrl = URL.createObjectURL(files[0]);
      setSrc(localUrl);

      const fileRes = await api.media(
        "v1/file",
        { file: files[0] },
        {
          cb: (x) => {
            setProgress(x);
          },
        }
      );

      const staticUrl = api.getFileLink(fileRes?.file, { video: true });
      mergeChange({
        valueObj: { ...(value?.valueObj || {}), staticUrl },
      });

      setSrc(staticUrl);
      setProgress(null);
    } catch (e) {
      console.error("Error uploading file: " + e.message);
      setSrc(null);
    }
  };

  const staticUrlConfig = (
    <div className="optionItemRow">
      <div className="optionDatabaseBoxWrapper">
        <div className="optionDatabaseTextBox">
          <div className="optionDatabaseTextBoxLabel">
            {_.startCase(mediaType)} File
          </div>
          <div className="optionDatabaseTextBoxBody uploadPhotoContainer">
            {src ? (
              <div className="uploadPhotoItem">
                {mediaType === "video" ? (
                  <video className="uploadPhoto" src={src} controls={false} />
                ) : (
                  <audio className="uploadPhoto" src={src} controls={true} />
                )}
                <div
                  style={{
                    background: "#fff6",
                    position: "absolute",
                    left: 0,
                    right: 0,
                    top: 0,
                    height:
                      uploadProgress === null ? 0 : 100 - uploadProgress + "%",
                  }}
                ></div>
                <div
                  className="deletePhoto"
                  onClick={() => {
                    mergeChange({
                      staticUrl: null,
                    });
                    setSrc(null);
                  }}
                >
                  <div className="deletePhotoLine one"></div>
                  <div className="deletePhotoLine two"></div>
                </div>
              </div>
            ) : null}
          </div>
          <div className="optionDatabaseTextBoxFooter">
            <label className="optionDatabaseTextBoxFooterUpload">
              <div className="optionDatabaseTextBoxFooterUploadLine one"></div>
              <div className="optionDatabaseTextBoxFooterUploadLine two"></div>
              <input
                key={src}
                type="file"
                hidden
                onChange={uploadHandler}
                accept={`${mediaType}/*`}
              />
            </label>
          </div>
        </div>
      </div>
    </div>
  );

  const textPartsConfig = (
    <TextPartsOptionItemRow
      {...{
        ...props,
        title: <>{_.startCase(mediaType)} Reference</>,
        placeholder:
          mediaType === "video"
            ? "Video / Stream reference "
            : "Reference to " + mediaType,
        value: value?.valueObj,
        onChange: (valueObj) =>
          mergeChange({
            valueType: "textParts",
            valueObj: { ...(value?.valueObj || {}), ...valueObj },
          }),
        immediateProps: { fileButton: true },
      }}
    />
  );

  return (
    <OptionItem optionItemLabel="DATA">
      {mediaTypeSelector}
      <div className="optionItemBody">
        {valueType === "textParts" ? textPartsConfig : staticUrlConfig}
      </div>

      {/* {mediaType === "video" ? (
        <VideoDataConfig
          {...{
            value: value.playerConfig,
            onChange: (obj) => mergeChange({ playerConfig: obj }),
          }}
        />
      ) : mediaType === "audio" ? (
        <AudioDataConfig
          {...{
            value: value.playerConfig,
            onChange: (obj) => mergeChange({ playerConfig: obj }),
          }}
        />
      ) : null} */}
    </OptionItem>
  );
};

const ChartData = (props) => {
  const { value = {}, onChange, mediaTypeSelector } = props;
  const mergeChange = (obj) => onChange({ ...value, ...obj });

  useEffect(() => {
    if (!value.chartType) {
      mergeChange({ chartType: "line" });
    }

    if (!value.datasets?.length) {
      addDataset();
    }
  }, [value.chartType, value.datasets]);

  const addDataset = () => {
    mergeChange({
      datasets: update(value.datasets || [], {
        $push: [{ uid: Math.random() }],
      }),
    });
  };

  const chartTypes = [
    {
      value: "line",
      label: "Line",
      icon: <div className="containerDecor"></div>,
    },
    {
      value: "bar",
      label: "Bar",
      icon: <div className="containerDecor"></div>,
    },
    {
      value: "pie",
      label: "Pie",
      icon: <div className="containerDecor"></div>,
    },
  ];

  return (
    <>
      <OptionItem optionItemLabel="DATA">
        {mediaTypeSelector}

        <div className="inputOptionWrapper streamTypeWrapper">
        <div className="optionItemBox">
          <div>
            <div className="oibLabel">Chart Type</div>
            <div>
              <CustomSelect
                onChange={(option) =>
                  mergeChange({
                    chartType: option.value,
                  })
                }
                value={value.chartType || ""}
                options={chartTypes}
                placeholder={"Select"}
                classNames={{
                  head: "optionInputIconBox",
                  label: "optionInputIconBoxField",
                  chevron: "optionDatabaseSelectChevron",
                }}
              />
            </div>
          </div>
        </div>
        </div>

        {/* <div className="optionItemBody pad5t">
          <div className="optionItemRow">
            {chartTypes.map((item) => (
              <div
                key={item.value}
                className="optionItemBox"
                onClick={() => mergeChange({ chartType: item.value })}
              >
                <div
                  className={
                    "advancedRadioItem" +
                    (value.chartType == item.value ? " lightActive" : "")
                  }
                >
                  <div className="verticalOptions jStart">{item.icon}</div>
                  <div className="advancedMultiLabel tCenter">
                    {item.label || _.startCase(item.value)}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div> */}
        <div>
          <div className="optionItemBody">
            {[
              {
                value: "labels",
                label: "X Values",
                placeholder: "Comma seperated data",
              },
            ].map((item) => (
              <TextPartsOptionItemRow
                {...{
                  ...props,
                  key: item.value,
                  title: item.label,
                  placeholder: item.placeholder,
                  value: value?.[item.value]?.valueObj,
                  onChange: (valueObj) =>
                    mergeChange({
                      [item.value]: {
                        valueObj,
                        valueType: "textParts",
                      },
                    }),
                }}
              />
            ))}
          </div>

          {value.datasets?.map((dataset, i) => {
            const setDataset = (obj) =>
              mergeChange({
                datasets: update(value.datasets || [], {
                  $merge: { [i]: { ...dataset, ...obj } },
                }),
              });

            return (
              <div className={"optionItem datasetItemBox"} key={dataset.uid}>
                <div className="optionItemInner">
                  <div className="optionItemHead">
                    <div className="optionItemLabelCombo">
                      <div className="optionItemLabel">{`Dataset ${
                        i + 1
                      }`}</div>
                    </div>
                    <div style={{ display: "flex" }}>
                      <div
                        className="optionItemDecor"
                        onClick={() =>
                          mergeChange({
                            datasets: update(value.datasets || [], {
                              $splice: [[i, 1]],
                            }),
                          })
                        }
                      >
                        <div className="optionItemDecorLine one"></div>
                        <div className="optionItemDecorLine two"></div>
                      </div>
                    </div>
                  </div>

                  <div className="optionItemBody">
                    {[
                      {
                        value: "chartData",
                        label: "Values",
                        placeholder: "Comma seperated data",
                      },
                      ...(["line"].includes(value?.chartType)
                        ? [
                            {
                              value: "borderColor",
                              label: "Line Color",
                              placeholder: "Color code or name",
                            },
                          ]
                        : [
                            {
                              value: "backgroundColor",
                              label: "Colors",
                              placeholder: "Comma seperated data",
                            },
                          ]),
                    ].map((item) => (
                      <TextPartsOptionItemRow
                        {...{
                          ...props,
                          key: item.value,
                          title: item.label,
                          placeholder: item.placeholder,
                          value: dataset?.[item.value]?.valueObj,
                          onChange: (valueObj) =>
                            setDataset({
                              [item.value]: {
                                valueObj,
                                valueType: "textParts",
                              },
                            }),
                        }}
                      />
                    ))}
                  </div>
                </div>
              </div>
            );
          })}
        </div>

        <div className="addDatasetButton" onClick={() => addDataset()}>Add More</div>
      </OptionItem>
    </>
  );
};

const TextPartsOptionItemRow = (props) => {
  const { title, placeholder, value, onChange } = props;
  return (
    <div className="optionItemRow">
      <div className="optionDatabaseBoxWrapper">
        <div className="optionDatabaseTextBox">
          <div className="optionDatabaseTextBoxLabel">{title}</div>
          <RichTextData
            {...{
              ...props,
              placeholder,
              value,
              onChange,
            }}
          />
        </div>
      </div>
    </div>
  );
};

const ColorBox = ({ value, onChange, remove }) => {
  return (
    <div className="optionItemBox">
      <div className="optionColorItem">
        <ColorButton
          className="optionColorCircle"
          style={{ width: "100%", height: "100%" }}
          value={value || "#FFF"}
          onChange={(hex) => onChange(hex)}
        />
      </div>
      <div onClick={() => remove()}>x</div>
    </div>
  );
};

function getRandomHexColor() {
  const randomNumber = Math.floor(Math.random() * 16777215);
  const hexColor = `#${randomNumber.toString(16).padStart(6, "0")}`;
  return hexColor;
}

export default MediaData;
