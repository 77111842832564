import React from "react";

import { LinkingOptionItemBody } from "../../../Components/etc/OptionItem";
import CustomSelect from "../../../Components/etc/CustomSelect";

const ReqPermission = (props) => {
  const { value = {}, onChange, linkBoxOptions, triggerOption } = props;
  const mergeChange = (obj) => onChange({ ...value, ...obj });

  return (
    <>
      <LinkingOptionItemBody {...props}>
        <div className="databaseOperationModule">
          <div className="domHeader">
            <div className="domLabel">Type</div>

            <CustomSelect
              value={value.type || ""}
              onChange={(option) => mergeChange({ type: option.value })}
              options={[
                { value: "location", label: "Location" },
                { value: "notification", label: "Notification" },
              ]}
              placeholder={"Select"}
              classNames={{
                head: "calcItemUnitMainDropdown spaceTopify",
                label: "calcItemUnitMainDropdownLabel",
              }}
              jointActionRow={
                <div className="calcItemUnitMainDropdownFooter">
                  <div className="calcItemUnitMainDropdownFooterArrow">
                    <div className="one"></div>
                    <div className="two"></div>
                  </div>
                </div>
              }
            />
          </div>
        </div>
      </LinkingOptionItemBody>

      {linkBoxOptions.showTriggerOption ? (
        <div className="linkingBoxFooter"> {triggerOption}</div>
      ) : null}
    </>
  );
};

export default ReqPermission;
