import React, { useRef, useState } from "react";
import _ from "lodash";

import { LinkingOptionItemBody } from "../../../Components/etc/OptionItem";
import CustomSelect from "../../../Components/etc/CustomSelect";
import ValueOfElementSelector, {
  SetValueOfElementSelector,
} from "../DataQueryOperations/ValueOfElementSelector";
import RichTextData from "../../Builder/Properties/Common/DataProperties/RichTextData";

export const ToggleVisibility = (props) => {
  const { value = {}, onChange, linkBoxOptions, triggerOption } = props;
  const mergeChange = (obj) => onChange({ ...value, ...obj });

  return (
    <>
      <LinkingOptionItemBody {...props}>
        <div className="databaseOperationModule">
          <div className="domHeader">
            <div className="domLabel">Element</div>

            <ValueOfElementSelector
              {...{
                ...props,
                elementType: null,
                elementFilter: () => true,
                styles: { dd: { width: "100%" } },
                value: props.value?.element,
                onChange: (x) => mergeChange({ element: x }),
              }}
            />
          </div>
        </div>
      </LinkingOptionItemBody>

      <div className="linkingBoxFooter">
        <div className="optionItemBody">
          <div className="linkLabel">Action</div>
          <div className="optionItemRow">
            <CustomSelect
              value={value.action || ""}
              onChange={(option) => mergeChange({ action: option.value })}
              placeholder="Toggle"
              options={[
                {
                  value: "",
                  label: "Toggle",
                },
                {
                  value: "none",
                  label: "Hide",
                },
                {
                  value: "flex",
                  label: "Show",
                },
              ]}
              className="linkingToBox flexColumn flex1 br10 pad6"
              classNames={{
                head: "pad6 flexRow aCenter",
                label: "cDark flex1 cPurple",
                chevron: "optionDatabaseSortKeyIco",
              }}
            />
          </div>
        </div>

        {linkBoxOptions.showTriggerOption ? triggerOption : null}
      </div>
    </>
  );
};

export const SetElementValue = (props) => {
  const { value = {}, onChange, linkBoxOptions, triggerOption } = props;
  const mergeChange = (obj) => onChange({ ...value, ...obj });

  const [ts, setTs] = useState(0);
  const valueOfElementSelectorRef = useRef();

  return (
    <div>
      <div className="linkingComponent">
        <div className="linkingBoxBody">
          <div className="linkingBoxDecor"></div>
          <div className="linkingBoxItem" style={{ flexDirection: "column" }}>
            <div className="removeX" onClick={props.delete}>
              <div className="removeXLine one"></div>
              <div className="removeXLine two"></div>
            </div>

            <div className="optionItemBody">
              <div className="databaseOperationModule">
                <div className="domHeader">
                  <div className="domLabel">Element</div>

                  <SetValueOfElementSelector
                    {...{
                      ...props,
                      ref: valueOfElementSelectorRef,
                      elementType: null,
                      elementFilter: () => true,
                      styles: { dd: { width: "100%" } },
                      value: props.value?.element,
                      onChange: (x) => {
                        mergeChange({ element: x });
                        setTimeout(() => {
                          setTs(Date.now());
                        }, 100);
                      },
                    }}
                  />
                </div>

                <div className="domBody">
                  {valueOfElementSelectorRef.current?.renderConfigs?.({
                    wrapper: (children) => (
                      <div className="optionItemBody">
                        <div className="databaseOperationModule">
                          <div className="domHeader">{children}</div>
                        </div>
                      </div>
                    ),
                  })}

                  <div className="calcItemDatabaseFilterBox">
                    <div className="calcItemDatabaseFilterBoxInner">
                      <div className="calcItemDatabaseFilterBoxInnerRow">
                        <div className="calcItemDBLabel">Element Value</div>
                      </div>
                      <RichTextData
                        {...{
                          ...props,
                          immediateProps: {
                            classNames: {
                              wrapper: " ",
                              editor: "calcDatabaseFilterMultiBox ",
                              paramListRow: "",
                            },
                          },
                          placeholder: "Type here",
                          value: value?.elementValue?.valueObj,
                          onChange: (valueObj) => {
                            mergeChange({
                              elementValue: {
                                valueObj,
                                valueType: "textParts",
                              },
                            });
                          },
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* {valueOfElementSelectorRef.current?.renderConfigs?.({
        wrapper: (children) => <div>{children}</div>,
      })} */}

      {linkBoxOptions.showTriggerOption ? (
        <div className="linkingBoxFooter">{triggerOption}</div>
      ) : null}
    </div>
  );
};
