import React, { createRef, Fragment, useEffect, useRef, useState } from "react";
import update from "immutability-helper";
import _ from "lodash";

import conditionObj from "../../../appxolo-engine/modules/calculation/condition-obj";
import CustomSelect from "../../../Components/etc/CustomSelect";
import {
  PassedParameterFromParentSelector,
  UrlParamValueSelector,
} from "./PassedParamSelector";
import {
  backendValueTypes,
  emailTepmplateValueTypes,
  frontValueTypes,
} from "../../../Modules/calculation/builder-value-types";
import CalculationValueSelector from "./CalculationValueSelector";
import ValueOfElementSelector from "./ValueOfElementSelector";
import RichTextData from "../../Builder/Properties/Common/DataProperties/RichTextData";
import { dateToolInfo } from "../../../Modules/calculation/dateTimeTool";
import MomentMacros from "../../../Components/etc/MomentMacros";

class CalculationBox extends React.Component {
  scrollRef = createRef(null);

  componentDidMount() {
    if (!this.props.value?.length) this.addCalculation();
  }

  setCalculations(calculations) {
    this.props.onChange(calculations);
  }

  addCalculation(calculation) {
    calculation = calculation ? calculation : {};
    calculation.id =
      calculation.id || "#" + Math.random().toString(10).substring(2, 7);

    this.setCalculations(
      update(this.props.value || [], { $push: [calculation] })
    );

    setTimeout(() => {
      if (this.scrollRef.current)
        this.scrollRef.current.scroll(
          0,
          this.scrollRef.current.scrollHeight || 0
        );
    }, 100);
  }

  removeNthCalculation = (n) => {
    this.setCalculations(update(this.props.value || [], { $splice: [[n, 1]] }));
  };

  setNthCalculation = (calculation, n) =>
    this.setCalculations(
      update(this.props.value || [], { $merge: { [n]: calculation } })
    );

  updateNthCalculation(obj, n) {
    return this.setNthCalculation(
      { ...(this.props.value?.[n] || {}), ...obj },
      n
    );
  }

  getCalculationInputs(calculations, index) {
    return calculations?.filter((x, i) => i < index).map((x) => x.id);
  }

  getValidValueTypes = (i, calculationBoxes) => {
    const calculationBox = calculationBoxes[i];
    if (false && i && !calculationBox.operator) {
      return [];
    } else {
      return conditionObj.valueTypes.filter(
        (x) =>
          (!this.props.availableFor ||
            x.availableFor.includes(this.props.availableFor)) &&
          this.haveCommonDataType(
            conditionObj.operators[calculationBox.operator]?.inputTypes,
            x.outputType
          )
      );
    }
  };

  haveCommonDataType(arr, dataType) {
    if (!arr || !arr.length) return true;
    else if (dataType === "any") return true;
    else if (arr.includes("any")) return true;
    return arr.includes(dataType);
  }

  getBuilderValueTypes({ availableFor }) {
    return availableFor === "backend"
      ? backendValueTypes
      : availableFor === "emailTemplate"
      ? emailTepmplateValueTypes
      : frontValueTypes;
  }

  onOperatorChange(operator, prevOperator, i) {
    console.log({ operator, prevOperator, i, calculations: this.props.value });
  }

  render() {
    const {
      props: { value: calculations, disableAddAnotherItem },
    } = this;

    const disableAddCalculation = calculations?.find((x) => !x.valueType);

    return (
      <div
        className="calculationOutside"
        onClick={() => this.props.setVisible(false)}
      >
        <div
          className="calculationWindow"
          ref={this.scrollRef}
          style={{ top: this.props.yPosition || 0 }}
          onClick={(e) => e.stopPropagation()}
        >
          <div className="calcHead">
            <div
              className="calcHeadAction"
              onClick={() => this.props.setVisible(false)}
            >
              <div className="calculationHeadBackLine one"></div>
              <div className="calculationHeadBackLine two"></div>
              <div className="calculationHeadBackLineHorizontal"></div>
            </div>
            <div className="calcHeadMeta">
              <div className="calcHeadLabel">Calculation</div>

              <span className="calcHeadSublineLabel">
                <span>{calculations?.length || 0}</span> Step
                {calculations?.length > 1 ? "s" : ""} in Calculation
              </span>
            </div>

            {!disableAddAnotherItem || !calculations?.length ? (
              <div
                className={
                  "addOperation" + (disableAddCalculation ? " disabled" : "")
                }
                onClick={() => !disableAddCalculation && this.addCalculation()}
              >
                <span className="ao one">+</span>
                <span className="ao two">Add</span>
                <span className="ao three">Step</span>
              </div>
            ) : null}
          </div>

          <div className="calcBody">
            {calculations?.map((calculation, i) => {
              const builderValueTypes = this.getBuilderValueTypes(this.props);
              const categories = builderValueTypes
                .map((x) => x.category)
                .filter((x, i, arr) => arr.indexOf(x) === i);

              return (
                <CalculationItem
                  {...{
                    ...this.props,
                    key: calculation.id,
                    calculation,
                    i,
                    onOperatorChange: (...x) => this.onOperatorChange(...x, i),
                    removeCalculation: () => this.removeNthCalculation(i),
                    updateCalculation: (calculation) =>
                      this.updateNthCalculation(calculation, i),
                    builderValueTypes,
                    calculationInputs: this.getCalculationInputs(
                      calculations,
                      i
                    ),
                    categories,
                  }}
                />
              );
            })}

            {!disableAddAnotherItem || !calculations?.length ? (
              <div className="calcItem displayNone">
                <div className="calcItemHead">
                  <div
                    className={
                      "addOperation" +
                      (disableAddCalculation ? " disabled" : "")
                    }
                    onClick={() =>
                      !disableAddCalculation && this.addCalculation()
                    }
                  >
                    <div className="addOperationLabel">Add Operation</div>
                  </div>
                </div>
              </div>
            ) : null}
          </div>
        </div>
      </div>
    );
  }
}

export const CalculationItem = (props) => {
  const {
    calculation,
    i,
    removeCalculation,
    updateCalculation,
    builderValueTypes,
    calculationInputs,
    onOperatorChange,
  } = props;

  const [showCategories, setShowCategories] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState("");

  const categoryMap = {
    value: {
      // placeholder: "Get value from input",
      className: "tabValue",
      label: "Value",
      subLabel: "Value",
    },
    database: {
      // placeholder: "Select database operation",
      className: "tabDatabase",
      label: "Database",
      subLabel: "Database operations",
    },
    information: {
      // placeholder: "Get information item",
      className: "tabInformation",
      label: "Information",
      subLabel: "Information panel",
    },
  };
  const categories = Object.keys(categoryMap);

  const builderValueTypeInfo = builderValueTypes.find(
    (x) => x.value === calculation.valueType
  );

  const category = selectedCategory
    ? selectedCategory
    : calculation.valueType
    ? builderValueTypeInfo?.category
    : "value";

  const categoryValueTypes = builderValueTypes.filter(
    (x) => x.category === category
  );

  const categoryList = categories.map((categoryName) => (
    <div
      key={categoryName}
      className={`calcItemHeadTabItem ${
        categoryMap[categoryName]?.className || ""
      } ${categoryName === category ? "active" : ""}`}
      onClick={() => {
        updateCalculation({
          operator: "",
          valueType: "",
          valueObj: {},
        });
        setTimeout(() => {
          setSelectedCategory(categoryName);
        }, 50);
      }}
    >
      {categoryMap[categoryName]?.label || categoryName}
    </div>
  ));

  const renderDateReformat = (
    <>
      <div className="calcItemUnit">
        <div className="calcItemUnitBox">
          <div className="calcItemUnitDecor"></div>
          <div className="calcItemUnitMain">
            <div className="calcItemUnitMainLabel">Reformat</div>
            <CustomSelect
              className="mainMaximal"
              onChange={(option) => {
                updateCalculation({
                  valueType: "customText",
                  valueObj: {
                    ...(calculation.valueObj || {}),
                    customText:
                      option.value === "CUSTOM:"
                        ? "CUSTOM:MM/dd/yyyy HH:mm:ss"
                        : option.value,
                  },
                });
              }}
              value={dateToolInfo.getReformatOptionValue(
                calculation?.valueObj?.customText || ""
              )}
              options={dateToolInfo.reformatOptions}
              placeholder={"Select Format"}
              classNames={{
                head: "calcDropdown",
                label: "calcDropdownLabel",
              }}
              jointActionRow={
                <div className="calcItemUnitMainDropdownFooterArrow">
                  <div className="one"></div>
                  <div className="two"></div>
                </div>
              }
            />
          </div>
        </div>
      </div>
      {dateToolInfo.getReformatOptionValue(
        calculation?.valueObj?.customText || ""
      ) === "CUSTOM:" ? (
        <div className="dtCustomFormat">
          <div className="dtCustomFormatLabel">Custom Format</div>
          <input
            type="text"
            value={(calculation?.valueObj?.customText || "").replace(
              /^CUSTOM:/,
              ""
            )}
            onChange={(e) =>
              updateCalculation({
                valueType: "customText",
                valueObj: {
                  ...(calculation.valueObj || {}),
                  customText: "CUSTOM:" + e.target.value,
                },
              })
            }
          />
          <MomentMacros />
        </div>
      ) : null}
    </>
  );

  return (
    <div className="calcItem" key={calculation.valueType}>
      {i ? (
        <>
          <div className="calcItemHeadAction calcHeadAdditiveUnit">
            <div className="calcItemHeadOrder">{i + 1}</div>
            <CustomSelect
              className=""
              onChange={(option) => {
                if (onOperatorChange)
                  onOperatorChange(option.value, calculation?.operator);

                if (option.value === "DATE_TOOL") {
                  updateCalculation({
                    operator: "FORMAT_DATE",
                    valueType: "customText",
                    valueObj: {
                      ...(calculation.valueObj || {}),
                      customText: "MM/dd/yyyy",
                    },
                  });
                } else if (
                  dateToolInfo.dateTimeToolOperators.includes(option.value)
                ) {
                  updateCalculation({
                    operator: option.value,
                    valueType: "textParts",
                  });
                } else {
                  updateCalculation({ operator: option.value });
                }
              }}
              value={
                dateToolInfo.dateTimeToolOperators.includes(
                  calculation?.operator
                )
                  ? "DATE_TOOL"
                  : calculation?.operator || ""
              }
              options={[
                ...conditionObj.operatorList.filter(
                  (x) =>
                    ["number", "string", "null"].includes(x.outputType) &&
                    !dateToolInfo.dateTimeToolOperators.includes(x.value)
                ),
                { value: "DATE_TOOL", label: "Date Time Tool" },
              ]}
              placeholder={"Select Operation"}
              classNames={{
                head: "calcDropdown",
                label: "calcDropdownLabel",
              }}
              jointActionRow={
                <div className="calcItemUnitMainDropdownFooterArrow">
                  <div className="one"></div>
                  <div className="two"></div>
                </div>
              }
            />
          </div>

          <div className="calcItemHead calcHeadAdditiveUnit">
            <div className="calcItemRemove" onClick={removeCalculation}>
              <div className="calculationCloseLine one"></div>
              <div className="calculationCloseLine two"></div>
            </div>
            <div className="maximizeDropdownItem">
              {dateToolInfo.dateTimeToolOperators.includes(
                calculation.operator
              ) ? (
                <CustomSelect
                  className="twoOptionsInRow"
                  onChange={(option) => {
                    if (onOperatorChange)
                      onOperatorChange(option.value, calculation?.operator);

                    updateCalculation({
                      operator: option.value,
                      valueType:
                        option.value === "DATE_TOOL"
                          ? "customText"
                          : "textParts",
                    });
                  }}
                  value={calculation?.operator || ""}
                  options={dateToolInfo.dateTimeToolOptions}
                  placeholder={"Select Operation"}
                  classNames={{
                    head: "calcDropdown",
                    label: "calcDropdownLabel",
                  }}
                  jointActionRow={
                    <div className="calcItemUnitMainDropdownFooterArrow">
                      <div className="one"></div>
                      <div className="two"></div>
                    </div>
                  }
                />
              ) : (
                <div className="calcItemHeadTab">{categoryList}</div>
              )}
            </div>
          </div>
        </>
      ) : (
        <>
          <div className="calcItemHead">
            <div className="calcItemHeadOrder">{i + 1}</div>
            <div className="calcItemHeadTab">{categoryList}</div>
          </div>
        </>
      )}

      {dateToolInfo.dateTimeToolOperators.includes(calculation.operator) ? (
        <div className="calcItemBody">
          <div className="calcItemDecor"></div>
          <div className="calcItemBodyMain">
            <div className="calcItemBodyMainHeader">
              <div className="calcItemBodyMainHeaderLabel">
                Date & Time Tool
              </div>
              <div
                onClick={() => {
                  updateCalculation({
                    operator: "",
                    valueType: "",
                    valueObj: {},
                  });
                }}
                className="calcItemBodyMainHeaderAction"
              >
                Clear
              </div>
            </div>
            {calculation.operator === "FORMAT_DATE" ? (
              renderDateReformat
            ) : (
              <div className="calcItemUnit customValueBox">
                <div className="calcItemUnitBox">
                  {/* <div className="calcItemUnitDecor"></div> */}
                  <div className="calcItemUnitMain">
                    <div className="calcItemUnitMainLabel">
                      {
                        conditionObj.operatorList.find(
                          (x) => x.value === calculation.operator
                        )?.label
                      }
                    </div>

                    <RichTextData
                      {...{
                        ...props,
                        placeholder: "Custom Value",
                        value: calculation.valueObj?.textParts?.valueObj || {},
                        onChange: (valueObj) =>
                          updateCalculation({
                            valueObj: {
                              ...(calculation.valueObj || {}),
                              textParts: {
                                valueType: "textParts",
                                valueObj,
                              },
                            },
                            valueType: "textParts",
                          }),
                      }}
                    />
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      ) : selectedCategory ||
        !calculation.valueType ||
        ["currentTableName"].includes(calculation.valueType) ? (
        <div className="calcItemBody">
          <div className="calcItemDecor"></div>
          <div className="calcItemBodyMain mainCalculationItems">
            {category === "value" &&
            categoryValueTypes.find((x) => x.value === "passedParameter") ? (
              <>
                <PassedParameterFromParentSelector
                  {...{
                    ...props,
                    valueType: calculation.valueType,
                    value: calculation?.valueObj?.passedParameter,
                    onChange: (obj) => {
                      updateCalculation({
                        valueType: "passedParameter",
                        valueObj: {
                          ...(calculation.valueObj || {}),
                          passedParameter: obj,
                        },
                      });
                      setTimeout(() => {
                        setSelectedCategory(null);
                      }, 50);
                    },
                    immediateProps: {
                      header: (
                        <div
                          className={
                            i
                              ? "calcItemBodyMainHeaderSecondary"
                              : "calcItemBodyMainHeader"
                          }
                        >
                          <div className="calcItemBodyMainHeaderLabel">
                            Parent
                          </div>
                        </div>
                      ),
                    },
                  }}
                />
              </>
            ) : null}

            <div
              className={
                i ? "calcItemBodyMainHeaderSecondary" : "calcItemBodyMainHeader"
              }
            >
              <div className="calcItemBodyMainHeaderLabel">
                {categoryMap[category]?.subLabel}
              </div>
            </div>

            {categoryValueTypes.map((valueType) => {
              return valueType.value === "passedParameter" ? (
                <UrlParamValueSelector
                  {...{
                    ...props,
                    key: valueType,
                    valueType: calculation.valueType,
                    value: calculation?.valueObj?.passedParameter,
                    onChange: (obj) => {
                      updateCalculation({
                        valueType: "passedParameter",
                        valueObj: {
                          ...(calculation.valueObj || {}),
                          passedParameter: obj,
                        },
                      });
                      setTimeout(() => {
                        setSelectedCategory(null);
                      }, 50);
                    },
                  }}
                />
              ) : ["valueOfElement"].includes(valueType.value) ? (
                <GroupListElementSelector
                  {...{
                    ...props,
                    key: valueType.value,
                    valueType,
                    calculation,
                    updateCalculation,
                    setSelectedCategory,
                  }}
                />
              ) : ["valueOfRepeatingContainerRow"].includes(valueType.value) ? (
                <GroupListValueOfRepeatingContainerRow
                  {...{
                    ...props,
                    key: valueType.value,
                    valueType,
                    calculation,
                    updateCalculation,
                    setSelectedCategory,
                  }}
                />
              ) : (
                <div
                  className="calcItemUnit unitOfValue"
                  key={valueType.value}
                  onClick={() => {
                    if (calculation.valueType !== valueType.value) {
                      updateCalculation({ valueType: valueType.value });
                      setTimeout(() => {
                        setSelectedCategory(null);
                      }, 50);
                    } else {
                      setSelectedCategory(null);
                    }
                  }}
                >
                  <div className="calcItemUnitBox">
                    <div
                      className={
                        valueType.value === calculation.valueType
                          ? "calcItemUnitDecor"
                          : "calcItemUnitDecorUnselected"
                      }
                    ></div>
                    <div className="calcItemUnitMain">
                      <div className="calcItemUnitMainUnselectedLabel">
                        {valueType.label}
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      ) : (
        <div className="calcItemBody">
          <div className="calcItemDecor"></div>
          <div className="calcItemBodyMain">
            <div className="calcItemBodyMainHeader">
              <div className="calcItemBodyMainHeaderLabel">
                {builderValueTypeInfo?.label}
              </div>
              <div
                onClick={() => {
                  updateCalculation({
                    valueObj: {},
                    valueType: null,
                  });
                  setTimeout(() => {
                    setSelectedCategory(category);
                  }, 50);
                }}
                className="calcItemBodyMainHeaderAction"
              >
                Clear
              </div>
            </div>

            <CalculationValueSelector
              {...props}
              valueType={calculation.valueType}
              value={calculation.valueObj}
              onChange={(obj) => updateCalculation({ valueObj: obj })}
              calculationInputs={() => calculationInputs}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export const ConditionItemValueSelector = (props) => {
  const {
    calculation,
    i,
    removeCalculation,
    updateCalculation,
    builderValueTypes,
    calculationInputs,
  } = props;

  const [showCategories, setShowCategories] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [popupVisible, setPopupVisible] = useState(null);

  const categoryMap = {
    value: {
      // placeholder: "Get value from input",
      className: "tabValue",
      label: "Value",
      subLabel: "Value",
    },
    database: {
      // placeholder: "Select database operation",
      className: "tabDatabase",
      label: "Database",
      subLabel: "Database operations",
    },
    information: {
      // placeholder: "Get information item",
      className: "tabInformation",
      label: "Information",
      subLabel: "Information panel",
    },
  };
  const categories = Object.keys(categoryMap);

  const builderValueTypeInfo = builderValueTypes.find(
    (x) => x.value === calculation.valueType
  );

  const category = selectedCategory
    ? selectedCategory
    : calculation.valueType
    ? builderValueTypeInfo?.category
    : "value";

  const categoryValueTypes = builderValueTypes.filter(
    (x) => x.category === category
  );

  const categoryList = categories.map((categoryName) => (
    <div
      key={categoryName}
      className={`calcItemHeadTabItem ${
        categoryMap[categoryName]?.className || ""
      } ${categoryName === category ? "active" : ""}`}
      onClick={() => setSelectedCategory(categoryName)}
    >
      {categoryMap[categoryName]?.label || categoryName}
    </div>
  ));

  const getValidConditionOperators = (i) => {
    if (!i) {
      return [
        {
          value: "if",
          label: "If",
        },
      ];
    } else {
      return conditionObj.operatorList.filter((x) => x.outputType !== "null");
    }
  };

  const valueSelector = (
    <div>
      <div className="calcItemHead">
        <div className="calcItemHeadTab">{categoryList}</div>
      </div>
      <div>
        {selectedCategory ||
        !calculation.valueType ||
        ["currentTableName"].includes(calculation.valueType) ? (
          <div className="calcItemBody">
            <div className="calcItemDecor"></div>
            <div className="calcItemBodyMain">
              {category === "value" &&
              categoryValueTypes.find((x) => x.value === "passedParameter") ? (
                <>
                  <PassedParameterFromParentSelector
                    {...{
                      ...props,
                      valueType: calculation.valueType,
                      value: calculation?.valueObj?.passedParameter,
                      onChange: (obj) => {
                        updateCalculation({
                          valueType: "passedParameter",
                          valueObj: {
                            ...(calculation.valueObj || {}),
                            passedParameter: obj,
                          },
                        });
                        setTimeout(() => {
                          setSelectedCategory(null);
                        }, 50);
                      },
                      immediateProps: {
                        header: (
                          <div
                            className={
                              i
                                ? "calcItemBodyMainHeaderSecondary"
                                : "calcItemBodyMainHeader"
                            }
                          >
                            <div className="calcItemBodyMainHeaderLabel">
                              Parent
                            </div>
                          </div>
                        ),
                      },
                    }}
                  />
                </>
              ) : null}

              <div
                className={
                  i
                    ? "calcItemBodyMainHeaderSecondary"
                    : "calcItemBodyMainHeader"
                }
              >
                <div className="calcItemBodyMainHeaderLabel">
                  {categoryMap[category]?.subLabel}
                </div>
              </div>

              {categoryValueTypes.map((valueType) => {
                return valueType.value === "passedParameter" ? (
                  <UrlParamValueSelector
                    {...{
                      ...props,
                      valueType: calculation.valueType,
                      value: calculation?.valueObj?.passedParameter,
                      onChange: (obj) => {
                        updateCalculation({
                          valueType: "passedParameter",
                          valueObj: {
                            ...(calculation.valueObj || {}),
                            passedParameter: obj,
                          },
                        });
                        setTimeout(() => {
                          setSelectedCategory(null);
                        }, 50);
                      },
                    }}
                  />
                ) : ["valueOfElement"].includes(valueType.value) ? (
                  <GroupListElementSelector
                    {...{
                      ...props,
                      key: valueType.value,
                      valueType,
                      calculation,
                      updateCalculation,
                      setSelectedCategory,
                    }}
                  />
                ) : ["valueOfRepeatingContainerRow"].includes(
                    valueType.value
                  ) ? (
                  <GroupListValueOfRepeatingContainerRow
                    {...{
                      ...props,
                      key: valueType.value,
                      valueType,
                      calculation,
                      updateCalculation,
                      setSelectedCategory,
                    }}
                  />
                ) : (
                  <div
                    className="calcItemUnit"
                    key={valueType.value}
                    onClick={() => {
                      if (calculation.valueType !== valueType.value) {
                        updateCalculation({ valueType: valueType.value });
                        setTimeout(() => {
                          setSelectedCategory(null);
                        }, 50);
                      } else {
                        setSelectedCategory(null);
                      }
                    }}
                  >
                    <div className="calcItemUnitBox">
                      <div
                        className={
                          valueType.value === calculation.valueType
                            ? "calcItemUnitDecor"
                            : "calcItemUnitDecorUnselected"
                        }
                      ></div>
                      <div className="calcItemUnitMain">
                        <div className="calcItemUnitMainUnselectedLabel">
                          {valueType.label}
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        ) : (
          <div className="calcItemBody">
            <div className="calcItemDecor"></div>
            <div className="calcItemBodyMain">
              <div className="calcItemBodyMainHeader">
                <div className="calcItemBodyMainHeaderLabel">
                  {builderValueTypeInfo?.label}
                </div>
                <div
                  onClick={() => {
                    updateCalculation({
                      valueObj: {},
                      valueType: null,
                    });
                    setTimeout(() => {
                      setSelectedCategory(category);
                    }, 50);
                  }}
                  className="calcItemBodyMainHeaderAction"
                >
                  Clear
                </div>
              </div>

              <CalculationValueSelector
                {...props}
                valueType={calculation.valueType}
                value={calculation.valueObj}
                onChange={(obj) => updateCalculation({ valueObj: obj })}
                calculationInputs={() => calculationInputs}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );

  return valueSelector;
};

export class CalculationTrigger extends React.Component {
  state = {
    visible: !!this.props.visible,
  };

  componentDidUpdate(prevProps, prevState) {
    if (this.props.onHide && prevState.visible && !this.state.visible)
      this.props.onHide();
  }

  render() {
    return (
      <>
        <div
          onClick={() => {
            this.setState({ visible: true });
          }}
          style={{
            padding: "10px",
            background: "blue",
            color: "white",
            cursor: "pointer",
            ...(this.props.style || {}),
          }}
        >
          Calculation
        </div>
        {this.state.visible ? (
          <CalculationBox
            {...{
              ...this.props,
              setVisible: (x) => this.setState({ visible: x }),
            }}
          />
        ) : null}
      </>
    );
  }
}

const GroupListCustomValueSelector = (props) => {
  const { valueType, calculation, updateCalculation, setSelectedCategory } =
    props;

  return (
    <div className="calcItemUnit onGroupList">
      <div className="calcItemUnitBox">
        <div
          className={
            valueType.value === calculation.valueType
              ? "calcItemUnitDecor"
              : "calcItemUnitDecorUnselected"
          }
        ></div>
        <div className="calcItemUnitMain">
          <RichTextData
            {...{
              ...props,
              value: calculation?.valueObj?.textParts?.valueObj,
              placeholder: "Custom Value",
              onChange: (valueObj) => {
                updateCalculation({
                  valueType: valueType.value,
                  valueObj: {
                    ...calculation.valueObj,
                    textParts: {
                      valueType: "textParts",
                      valueObj,
                    },
                  },
                });
                setTimeout(() => {
                  setSelectedCategory(null);
                }, 50);
              },
            }}
          />
        </div>
      </div>
    </div>
  );
};

const GroupListElementSelector = (props) => {
  const { valueType, calculation, updateCalculation, setSelectedCategory } =
    props;

  return (
    <div className="calcItemUnit onGroupList">
      <div className="calcItemUnitBox">
        <div
          className={
            valueType.value === calculation.valueType
              ? "calcItemUnitDecor"
              : "calcItemUnitDecorUnselected"
          }
        ></div>
        <div className="calcItemUnitMain">
          <ValueOfElementSelector
            {...{
              ...props,
              value: calculation?.valueObj?.element,
              immediateProps: { placeholder: valueType.label },
              elementFilter: null,

              onChange: (obj) => {
                updateCalculation({
                  valueType: valueType.value,
                  valueObj: {
                    ...(calculation.valueObj || {}),
                    element: obj,
                  },
                });
                setTimeout(() => {
                  setSelectedCategory(null);
                }, 50);
              },

              elementType: null,
              styles: { dd: { width: "100%" } },
            }}
          />
        </div>
      </div>
    </div>
  );
};

const GroupListValueOfRepeatingContainerRow = (props) => {
  const [visible, setVisible] = useState(false);
  const { valueType, calculation, updateCalculation, setSelectedCategory } =
    props;

  const elementSelectorRef = useRef();

  useEffect(() => {
    if (elementSelectorRef?.current?.state?.elementIds?.length)
      setVisible(true);
  }, [elementSelectorRef.current]);

  const elementFilter = (x) => {
    const tabs =
      x.value?.data?.dataType === "conditional"
        ? x.value?.data?.tabs || []
        : [x.value?.data?.tabs?.[0]].filter((x) => x);

    for (let i = 0; i < tabs.length; i++) {
      const tab = tabs[i];

      if (tab?.containerData?.repeating) {
        return true;
      }
    }
    return false;
  };

  return (
    <div
      className="calcItemUnit onGroupList"
      style={visible ? {} : { display: "none" }}
    >
      <div className="calcItemUnitBox">
        <div
          className={
            valueType.value === calculation.valueType
              ? "calcItemUnitDecor"
              : "calcItemUnitDecorUnselected"
          }
        ></div>
        <div className="calcItemUnitMain">
          <ValueOfElementSelector
            {...{
              ...props,
              ref: elementSelectorRef,
              value:
                calculation?.valueObj?.valueOfRepeatingContainerRow?.element,
              immediateProps: { placeholder: valueType.label },
              elementType: "container",
              elementFilter,

              onChange: (x) => {
                updateCalculation({
                  valueType: valueType.value,
                  valueObj: {
                    ...(calculation.valueObj || {}),
                    valueOfRepeatingContainerRow: {
                      ...(calculation.valueObj?.valueOfRepeatingContainerRow ||
                        {}),
                      element: x,
                    },
                  },
                });
                setTimeout(() => {
                  setSelectedCategory(null);
                }, 50);
              },

              elementType: null,
              styles: { dd: { width: "100%" } },
            }}
          />
        </div>
      </div>
    </div>
  );
};

export class CalculationModal extends React.Component {
  render() {
    const { value: calculations } = this.props;

    return <></>;
  }
}

export default CalculationBox;
