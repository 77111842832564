import React from "react";
import { GoogleApiWrapper } from "google-maps-react";
import styleModule from "../../../../Modules/style/style-module";
import config from "../../../../Config";
import MapContainer from "./MapContainer";
import evalStaticTextParts from "../../../../Modules/calculation/evalStaticTextParts";

class DemoMap extends React.Component {
  cache = {}; // Cache resolved lat/lng results
  batchQueue = new Set(); // Queue for unprocessed addresses
  apiCallTimeout = null; // Debounce timer for API calls

  constructor(props) {
    super(props);
    this.state = {
      processedMapData: {}, // Store processed map data
    };
  }

  // Fetch lat/lng for a batch of addresses
  async fetchBatchLocation(locations) {
    const results = {};
    const promises = locations.map(async (location) => {
      if (this.cache[location]) {
        results[location] = this.cache[location]; // Return cached result
      } else {
        try {
          const response = await fetch(
            `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(
              location
            )}&key=${config.googleApiKey}`
          );
          const data = await response.json();

          if (data.status === "OK") {
            this.cache[location] = { success: true, ...data.results[0] };
            results[location] = this.cache[location];
          } else {
            this.cache[location] = {};
            console.error("Geocoding failed:", data.status);
            results[location] = {};
          }
        } catch (error) {
          console.error("Geocoding API Error:", error);
          results[location] = {};
        }
      }
    });

    await Promise.all(promises);
    return results;
  }

  // Process the queued addresses after changes stop
  async processBatchQueue() {
    if (this.batchQueue.size === 0) return;

    const batchArray = Array.from(this.batchQueue);
    this.batchQueue.clear(); // Clear the queue

    const locationResult = await this.fetchBatchLocation(batchArray);

    let operations = this.mapData?.operations || [];
    for (const operation of operations) {
      for (const locationMark of operation.locationMarks || []) {
        if (
          locationMark.origin?.value?.trim() &&
          locationResult[locationMark.origin.value]
        ) {
          locationMark.value = locationResult[locationMark.origin.value];
        } else {
          locationMark.value = null;
        }
      }

      operation.locationMarks = operation.locationMarks?.filter((x) => x.value);
    }

    operations = operations.filter((operation) => {
      if (!operation?.locationMarks?.length) return false;
      if (
        operation?.operation === "route" &&
        operation.locationMarks?.length < 2
      )
        return false;
      return true;
    });

    console.log("Processed Operations: ", { operations, locationResult });

    // Update state with processed data
    this.setState({
      processedMapData: { ...this.mapData, operations },
    });
  }

  preProcessData(mapData = {}) {
    return {
      ...mapData,
      operations: mapData.operations?.map((operation = {}) => ({
        ...operation,
        locationMarks: operation.locationMarks?.map((locationMark = {}) => ({
          origin: evalStaticTextParts(
            locationMark?.origin?.valueObj?.textParts
          ),
          icon: {
            src: evalStaticTextParts(locationMark?.icon?.valueObj?.textParts)
              ?.value,
          },
        })),
      })),
    };
  }

  // Queue new locations for processing
  processData(mapData = {}) {
    mapData = this.preProcessData(mapData); // Pre-process raw mapData
    this.mapData = mapData;

    const operations = mapData.operations || [];
    for (const operation of operations) {
      for (const locationMark of operation.locationMarks || []) {
        if (locationMark.origin?.value) {
          this.batchQueue.add(locationMark.origin.value);
        }
      }
    }

    // Debounce the processing
    if (this.apiCallTimeout) {
      clearTimeout(this.apiCallTimeout); // Clear previous timer
    }

    this.apiCallTimeout = setTimeout(() => {
      this.processBatchQueue();
      this.apiCallTimeout = null; // Reset timer after processing
    }, 2000); // Process batch after 2 seconds of inactivity
  }

  componentDidUpdate(prevProps) {
    const prevMapData =
      prevProps.element?.value?.data?.tabs?.[
        prevProps.element?.value?.data?.activeTabIndex || 0
      ]?.mapData;

    const currentMapData =
      this.props.element?.value?.data?.tabs?.[
        this.props.element?.value?.data?.activeTabIndex || 0
      ]?.mapData;

    if (1 || JSON.stringify(prevMapData) !== JSON.stringify(currentMapData)) {
      this.processData(currentMapData);
    }
  }

  componentWillUnmount() {
    if (this.apiCallTimeout) {
      clearTimeout(this.apiCallTimeout); // Clear timer on unmount
    }
  }

  render() {
    const element = this.props.element;
    let style = styleModule.getElementStyleData(element);

    const theme =
      element.value?.data?.tabs?.[element.value?.data?.activeTabIndex || 0]
        ?.mapData?.theme;

    return (
      <MapContainer
        {...{
          key: "emptymap",
          data: this.state.processedMapData, // Feed only processed data
          onPress: () => {},
          style: { ...style },
          theme: theme || "standard",
        }}
      />
    );
  }
}

// Wrap with Google API
export default GoogleApiWrapper({
  apiKey: config.googleApiKey,
})(DemoMap);
