import React from "react";
import ControlledInput from "../Input/ControlledInput";

const OptionItem = ({
  className = "",
  optionItemLabel,
  optionHeadTabs,
  optionHeadRight,
  renderOptionHeadTabs,
  children,
  onRightButtonClick,
}) => {
  return (
    <div className={"optionItem " + className + " " + optionItemLabel}>
      <div className="optionItemInner">
        <div className="optionItemHead">
          <div className="optionItemLabelCombo">
            <div className="optionItemLabel">{optionItemLabel}</div>
            {optionHeadTabs
              ? optionHeadTabs
              : renderOptionHeadTabs
              ? renderOptionHeadTabs()
              : null}
          </div>
          <div className="optionHeadRight">
            {optionHeadRight}
            <div className="optionItemDecor" onClick={onRightButtonClick}>
              <img
                className="optionItemDecorIco"
                src={require("../../Assets/img/options/options/reveal.png")}
              ></img>
            </div>
          </div>
        </div>
        {children}
      </div>
    </div>
  );
};

export const OptionToggle1 = ({ value = {}, onChange, options }) => {
  return (
    <div className="optionItemRow">
      {options.map((item) => {
        return (
          <div className="optionItemBox" key={item.value}>
            <div
              className={
                "advancedRadioItem" +
                (value === item.value ? " lightActive" : "")
              }
              onClick={() => onChange(item)}
            >
              <div className="advancedRadioLabel">{item.label}</div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export const OptionFourInputs = ({
  value = {},
  onChange,
  options,
  getStyleValue,
}) => {
  const mergeStyle = (obj) => onChange({ ...value, ...obj });

  return (
    <div className="optionItemRow">
      {options.map((item) => {
        const key = item.key;
        return (
          <div className="optionItemBox" key={key}>
            <div className="optionInputIconBox">
              <img
                className={"optionInputIconBoxArrowIco " + item.iconClass}
                src={require("../../Assets/img/options/options/greyArrow.png")}
              ></img>

              <ControlledInput
                type="text"
                onChange={(event) => mergeStyle({ [key]: event.target.value })}
                className="optionInputIconBoxSpacingField"
                value={getStyleValue ? getStyleValue(value, key) : value[key]}
                key={key}
              />
            </div>
          </div>
        );
      })}
    </div>
  );
};

export const OptionItemType1 = (props) => {
  return (
    <OptionItem {...props}>
      <div className="optionItemBody revealOffset">
        <OptionToggle1 {...props} />
      </div>
    </OptionItem>
  );
};

export const OptionItemBody = ({ classNames = {}, label, children }) => {
  return (
    <div className={"optionItemBody "}>
      <div className={classNames.label || "Dark fwSemiBold f14 mb10"}>
        {label}
      </div>
      <div className="optionItemRow">{children}</div>
    </div>
  );
};

export const OptionItemBodyMultiRow = ({ classNames = {}, label, rows }) => {
  return (
    <div className={"optionItemBody "}>
      <div className={classNames.label || "cDark fwSemiBold f14 mb10"}>
        {label}
      </div>
      {rows?.map(({ render, key, className = "" }, index) => (
        <div className={"optionItemRow " + className} key={key || index}>
          {render()}
        </div>
      ))}
    </div>
  );
};

export const LinkingOptionItemBody = (props) => (
  <div className="linkingComponent">
    <div className="linkingBoxBody">
      <div className="linkingBoxDecor"></div>
      <div className="linkingBoxItem">
        <div className="removeX" onClick={props.delete}>
          <div className="removeXLine one"></div>
          <div className="removeXLine two"></div>
        </div>

        <div className="optionItemBody">{props.children}</div>
      </div>
    </div>
  </div>
);

export default OptionItem;
