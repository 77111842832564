import React from "react";
import RichTextData from "../../../Common/DataProperties/RichTextData";
import OptionItem from "../../../../../../Components/etc/OptionItem";

const ContainerTabsConfig = (props) => {
  const { value, onChange } = props;
  const containerTabsConfig = value || {};

  const changeContainerTabsConfig = (obj) =>
    onChange({ ...containerTabsConfig, ...obj });

  return (
    <>
      <OptionItem optionItemLabel="Options">
        <div className="optionItemBody revealOffset">
          <div className="optionItemRow pad7">
            <div className="optionConditionTableSelectorFilterItemContext lightenMode">
              <div className="optionConditionTableSelectorFilterItemHead">
                <div className="optionConditionTableFilterMetaRequirementLabel">
                  Active Tab
                </div>
              </div>

              <RichTextData
                {...{
                  ...props,
                  key: "defaultActiveTab",
                  placeholder: "Enter tab value or order number",
                  value: containerTabsConfig.defaultActiveTab?.valueObj || {},
                  onChange: (valueObj) =>
                    changeContainerTabsConfig({
                      defaultActiveTab: { valueObj, valueType: "textParts" },
                    }),
                }}
              />
            </div>
          </div>
        </div>
      </OptionItem>
    </>
  );
};

export default ContainerTabsConfig;
