import React, { useState } from "react";
import _ from "lodash";

import ControlledInput from "../../../../../Components/Input/ControlledInput";
import OptionItem from "../../../../../Components/etc/OptionItem";
import CustomSelect from "../../../../../Components/etc/CustomSelect";
import ColorButton from "../../../../../Components/Input/ColorButton";

const fontFamily = [
  "auto",
  "Montserrat",
  "cursive",
  "fantasy",
  "ingerit",
  "initial",
  "monospace",
  "none",
  "sans-serif",
  "serif",
  "unset",
];
const fontWeight = [
  "normal",
  "bold",
  "100",
  "200",
  "300",
  "400",
  "500",
  "600",
  "700",
  "800",
  "900",
];

const Appearance = (props) => {
  const [hover, setHover] = useState(false);

  const _styles = hover ? props.hover.value || {} : props.value || {};
  const styles = hover ? { ...(props.value || {}), ..._styles } : _styles;
  const mergeStyle = (obj) =>
    (hover ? props.hover.onChange : props.onChange)({ ..._styles, ...obj });

  const getStyleValue = props.getStyleValue;
  let currentFont = getStyleValue(styles, "fontFamily");
  let currentFontWeight = getStyleValue(styles, "fontWeight");
  let currentFontSize = getStyleValue(styles, "fontSize");
  let currentTextAlign = getStyleValue(styles, "textAlign") || "left";

  const changeFont = (obj) => {
    currentFont = obj.value;
    mergeStyle({ fontFamily: currentFont ? currentFont : "" });
  };
  const changeFontWeight = (obj) => {
    currentFontWeight = obj.value;
    mergeStyle({
      fontWeight: currentFontWeight ? currentFontWeight : "",
    });
  };
  const changeFontSize = (val) => {
    currentFontSize = val;
    mergeStyle({ fontSize: currentFontSize ? currentFontSize : "" });
  };
  const changeTextAlighn = (val) => {
    mergeStyle({ textAlign: val || "" });
  };

  return (
    <OptionItem optionItemLabel="Font" key={hover}>
      <div className="optionItemBody revealOffset">
        <div className="optionItemRow">
          <div className="optionItemBox max90w">
            <div className="optionInputIconBox">
              <ControlledInput
                onChange={(event) => changeFontSize(event.target.value)}
                value={currentFontSize || ""}
                className="optionInputIconBoxField"
                placeholder="Size"
              />
            </div>
          </div>

          <div className="optionItemBox">
            <CustomSelect
              onChange={(option) => changeFont({ value: option.value })}
              value={currentFont || ""}
              options={fontFamily.map((x) => ({
                value: x,
                label: _.startCase(x),
              }))}
              placeholder={"Font"}
              classNames={{
                head: "optionInputIconBox",
                label: "optionInputIconBoxField",
                chevron: "optionDatabaseSelectChevron",
              }}
              labelIcon={
                <img
                  className="optionInputIconBoxIco"
                  src={require("../../../../../Assets/img/options/text/font.png")}
                ></img>
              }
            />
          </div>
        </div>

        <div className="optionItemRow">
          <div className="optionItemBox">
            <CustomSelect
              onChange={(option) => changeFontWeight({ value: option.value })}
              value={currentFontWeight || ""}
              options={fontWeight.map((x) => ({
                value: x,
                label: _.startCase(x),
              }))}
              placeholder={"Style"}
              classNames={{
                head: "optionInputIconBox",
                label: "optionInputIconBoxField",
                chevron: "optionDatabaseSelectChevron",
              }}
            />
          </div>

          <div className="optionItemBox">
            <div className="textAlignUnit">
              {[
                {
                  value: "left",
                  label: "Left",
                  ico: require("../../../../../Assets/img/options/text/tLeft.png"),
                },
                {
                  value: "center",
                  label: "Center",
                  ico: require("../../../../../Assets/img/options/text/tCenter.png"),
                },
                {
                  value: "right",
                  label: "Right",
                  ico: require("../../../../../Assets/img/options/text/tRight.png"),
                },
              ].map((item) => (
                <div
                  key={item.value}
                  onClick={() => changeTextAlighn(item.value)}
                  className={
                    "taItem " +
                    (currentTextAlign === item.value ? "active" : "")
                  }
                >
                  <img className="taItemIco" src={item.ico}></img>
                </div>
              ))}
            </div>
          </div>

          <div></div>
        </div>
      </div>

      <div className="optionItemHead pad5t">
        <div className="optionItemLabel">Colors</div>
      </div>

      <div className="optionItemBody revealOffset colorUnitRow">
        <div className="optionItemRow">
          <div className="optionItemBox">
            <div className="optionColorItem">
              <ColorButton
                className="optionColorCircle"
                value={styles.color || "#000000"}
                onChange={(val) => mergeStyle({ color: val })}
              />
              <div className="optionInputIconBoxText">Text Color</div>
            </div>
          </div>
        </div>

        <div className="optionItemRow">
          <div className="optionItemBox">
            <div className="optionColorItem">
              <ColorButton
                className="optionColorCircle"
                value={styles.backgroundColor || "#FFFFFF"}
                onChange={(val) => mergeStyle({ backgroundColor: val })}
              />
              <div className="optionInputIconBoxText">Background</div>
            </div>
          </div>
        </div>
      </div>
    </OptionItem>
  );
};

export const AppearanceForInput = (props) => {
  const [hover, setHover] = useState(false);

  const inputGroup = props.inputType?.group;

  const _styles = hover ? props.hover.value || {} : props.value || {};
  const styles = hover ? { ...(props.value || {}), ..._styles } : _styles;
  const mergeStyle = (obj) =>
    (hover ? props.hover.onChange : props.onChange)({ ..._styles, ...obj });

  const getStyleValue = props.getStyleValue;
  let currentFont = getStyleValue(styles, "fontFamily");
  let currentFontWeight = getStyleValue(styles, "fontWeight");
  let currentFontSize = getStyleValue(styles, "fontSize");
  let currentTextAlign = getStyleValue(styles, "textAlign") || "left";

  const changeFont = (obj) => {
    currentFont = obj.value;
    mergeStyle({ fontFamily: currentFont ? currentFont : "" });
  };
  const changeFontWeight = (obj) => {
    currentFontWeight = obj.value;
    mergeStyle({
      fontWeight: currentFontWeight ? currentFontWeight : "",
    });
  };
  const changeFontSize = (val) => {
    currentFontSize = val;
    mergeStyle({ fontSize: currentFontSize ? currentFontSize : "" });
  };
  const changeTextAlighn = (val) => {
    mergeStyle({ textAlign: val || "" });
  };

  return (
    <>
      {inputGroup === "switch" ? null : (
        <>
          <div className="optionItemRow">
            <div className="optionItemBox max90w">
              <div className="optionInputIconBox">
                <ControlledInput
                  onChange={(event) => changeFontSize(event.target.value)}
                  value={currentFontSize || ""}
                  className="optionInputIconBoxField"
                  placeholder="Size"
                />
              </div>
            </div>

            <div className="optionItemBox">
              <CustomSelect
                onChange={(option) => changeFont({ value: option.value })}
                value={currentFont || ""}
                options={fontFamily.map((x) => ({
                  value: x,
                  label: _.startCase(x),
                }))}
                placeholder={"Font"}
                classNames={{
                  head: "optionInputIconBox",
                  label: "optionInputIconBoxField",
                  chevron: "optionDatabaseSelectChevron",
                }}
                labelIcon={
                  <img
                    className="optionInputIconBoxIco"
                    src={require("../../../../../Assets/img/options/text/font.png")}
                  ></img>
                }
              />
            </div>
          </div>

          <div className="optionItemRow">
            <div className="optionItemBox">
              <CustomSelect
                onChange={(option) => changeFontWeight({ value: option.value })}
                value={currentFontWeight || ""}
                options={fontWeight.map((x) => ({
                  value: x,
                  label: _.startCase(x),
                }))}
                placeholder={"Style"}
                classNames={{
                  head: "optionInputIconBox",
                  label: "optionInputIconBoxField",
                  chevron: "optionDatabaseSelectChevron",
                }}
              />
            </div>

            {["checkBox", "radio"].includes(inputGroup) ? (
              <div className="optionItemBox specialColorInput">
                <div className="optionColorItem">
                  <ColorButton
                    className="optionColorCircle"
                    value={getStyleValue(styles, "--label-color")}
                    onChange={(val) => mergeStyle({ ["--label-color"]: val })}
                  />
                </div>
              </div>
            ) : (
              <div className="optionItemBox">
                <div className="textAlignUnit">
                  {[
                    {
                      value: "left",
                      label: "Left",
                      ico: require("../../../../../Assets/img/options/text/tLeft.png"),
                    },
                    {
                      value: "center",
                      label: "Center",
                      ico: require("../../../../../Assets/img/options/text/tCenter.png"),
                    },
                    {
                      value: "right",
                      label: "Right",
                      ico: require("../../../../../Assets/img/options/text/tRight.png"),
                    },
                  ].map((item) => (
                    <div
                      key={item.value}
                      onClick={() => changeTextAlighn(item.value)}
                      className={
                        "taItem " +
                        (currentTextAlign === item.value ? "active" : "")
                      }
                    >
                      <img className="taItemIco" src={item.ico}></img>
                    </div>
                  ))}
                </div>
              </div>
            )}

            <div></div>
          </div>
        </>
      )}

      <div className="optionItemHead pad5t inputColorLabel">
        <div className="optionItemLabel">Colors</div>
      </div>

      <ColorSelector {...{ props, styles, mergeStyle }} />
    </>
  );
};

const ColorOption = ({ label, value, onChange }) => (
  <div className="optionItemRow">
    <div className="optionItemBox">
      <div className="optionColorItem">
        <ColorButton
          className="optionColorCircle"
          value={value}
          onChange={onChange}
        />
        <div className="optionInputIconBoxText">{label}</div>
      </div>
    </div>
  </div>
);

const ColorSelector = ({ props, styles, mergeStyle }) => {
  const inputGroup = props.inputType?.group;
  const { getStyleValue } = props;

  return (
    <div className="optionItemBody revealOffset colorUnitRow inputColorItem">
      {inputGroup === "checkBox" ? (
        <>
          <ColorOption
            label="Box"
            value={getStyleValue(styles, "--box-color") || "#FFFFFF"}
            onChange={(val) => mergeStyle({ ["--box-color"]: val })}
          />
          <ColorOption
            label="Selected"
            value={getStyleValue(styles, "--activebox-color") || "#FFFFFF"}
            onChange={(val) => mergeStyle({ ["--activebox-color"]: val })}
          />
          <ColorOption
            label="Check"
            value={getStyleValue(styles, "--check-color") || "#000000"}
            onChange={(val) => mergeStyle({ ["--check-color"]: val })}
          />
          {/* <ColorOption
            label="Label"
            value={getStyleValue(styles, "--label-color") || "#000000"}
            onChange={(val) => mergeStyle({ ["--label-color"]: val })}
          /> */}
        </>
      ) : inputGroup === "switch" ? (
        <>
          <ColorOption
            label="Switch"
            value={getStyleValue(styles, "--box-color") || "#bdbdbd"}
            onChange={(val) => mergeStyle({ ["--box-color"]: val })}
          />
          <ColorOption
            label="Thumb"
            value={getStyleValue(styles, "--activebox-color") || "#e8e8e8"}
            onChange={(val) => mergeStyle({ ["--activebox-color"]: val })}
          />
        </>
      ) : inputGroup === "radio" ? (
        <>
          <ColorOption
            label="Radio"
            value={getStyleValue(styles, "--box-color") || "#bdbdbd"}
            onChange={(val) => mergeStyle({ ["--box-color"]: val })}
          />
          {/* <ColorOption
              label="Label"
              value={getStyleValue(styles, "--label-color") || "#000000"}
              onChange={(val) => mergeStyle({ ["--label-color"]: val })}
            /> */}
          <ColorOption
            label="Unselected"
            value={getStyleValue(styles, "--inactivebox-color") || "#bdbdbd"}
            onChange={(val) => mergeStyle({ ["--inactivebox-color"]: val })}
          />
          <ColorOption
            label="Selected"
            value={getStyleValue(styles, "--activebox-color") || "#ff0000"}
            onChange={(val) => mergeStyle({ ["--activebox-color"]: val })}
          />
        </>
      ) : (
        <>
          {/* Conditional Block for filePicker and imagePicker */}
          {["filePicker", "imagePicker"].includes(inputGroup) ? (
            <>
              <ColorOption
                label="Button"
                value={getStyleValue(styles, "--button-color") || "#EEEEEE"}
                onChange={(val) => mergeStyle({ ["--button-color"]: val })}
              />
              <ColorOption
                label="Label"
                value={getStyleValue(styles, "color") || "#000000"}
                onChange={(val) => mergeStyle({ color: val })}
              />
            </>
          ) : (
            <>
              <ColorOption
                label="Text"
                value={getStyleValue(styles, "color") || "#000000"}
                onChange={(val) => mergeStyle({ color: val })}
              />
              <ColorOption
                label="Label"
                value={getStyleValue(styles, "--placeholder-color") || "gray"}
                onChange={(val) => mergeStyle({ ["--placeholder-color"]: val })}
              />
            </>
          )}

          {/* Conditional Block for dateTimePicker and selectBox */}
          {["dateTimePicker", "selectBox"].includes(inputGroup) && (
            <ColorOption
              label="Icon"
              value={getStyleValue(styles, "--icon-color") || "#000"}
              onChange={(val) => mergeStyle({ ["--icon-color"]: val })}
            />
          )}

          {/* Always Displayed */}
          <ColorOption
            label="Box"
            value={getStyleValue(styles, "backgroundColor") || "#FFFFFF"}
            onChange={(val) => mergeStyle({ backgroundColor: val })}
          />
        </>
      )}
    </div>
  );
};

export default Appearance;
