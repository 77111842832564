import React from "react";
import { connect } from "react-redux";
import _ from "lodash";

import ToggleButton from "../../../Components/Input/ToggleButton";
import CustomUrlParameters from "./CustomUrlParameters";
import { LinkingOptionItemBody } from "../../../Components/etc/OptionItem";
import CustomSelect from "../../../Components/etc/CustomSelect";

export const LinkToScreenNav = ({ valueType, setValueType }) => (
  <ToggleButton
    value={valueType}
    onChange={setValueType}
    options={[
      { value: "linkToScreen", label: "Screen" },
      { value: "linkToURL", label: "URL" },
    ]}
  />
);

const LinkToScreenInner = (props) => {
  const {
    value = {},
    onChange,
    components,
    linkBoxOptions,
    triggerOption,
  } = props;
  const mergeChange = (obj) => onChange({ ...value, ...obj });

  return (
    <>
      <LinkingOptionItemBody {...props}>
        <div className="databaseOperationModule">
          <div className="domHeader">
            <div className="domLabel">Destination</div>

            <CustomSelect
              value={value.to || ""}
              onChange={(option) => mergeChange({ to: option.value })}
              options={components?.map((x) => ({
                value: x._id,
                label: x.name,
              }))}
              placeholder={"Screen Name"}
              classNames={{
                head: "calcItemUnitMainDropdown spaceTopify",
                label: "calcItemUnitMainDropdownLabel",
              }}
              jointActionRow={
                <div className="calcItemUnitMainDropdownFooter">
                  <div className="calcItemUnitMainDropdownFooterArrow">
                    <div className="one"></div>
                    <div className="two"></div>
                  </div>
                </div>
              }
            />
          </div>
          <CustomUrlParameters
            {...{
              ...props,
              value: value.urlParameters,
              onChange: (urlParameters) => mergeChange({ urlParameters }),
            }}
          />
        </div>
      </LinkingOptionItemBody>

      <div className="linkingBoxFooter">
        <div className="optionItemBody">
          <div className="linkLabel">Format</div>
          <div className="optionItemRow">
            <CustomSelect
              value={value.openAs || "screen"}
              onChange={(option) => mergeChange({ openAs: option.value })}
              options={["screen", "modal"]?.map((x) => ({
                value: x,
                label: _.startCase(x),
              }))}
              placeholder={"Select"}
              className="linkingToBox flexColumn flex1 br10 pad6"
              classNames={{
                head: "pad6 flexRow aCenter",
                label: "cDark flex1 cPurple",
                chevron: "optionDatabaseSortKeyIco",
              }}
            />
          </div>
        </div>

        {linkBoxOptions.showTriggerOption ? triggerOption : null}
      </div>
    </>
  );
};

const LinkToScreen = connect((state) => ({
  components: state.vState.BUILDER.components,
}))(LinkToScreenInner);

export default LinkToScreen;
