import React, { useEffect, useRef, useState } from "react";
import { DropTarget } from "react-dnd";

import DraggableCanvasElement from "./DraggableCanvasElement";
import styleModule from "../../Modules/style/style-module";

const Canvas = (props) => {
  const [parentData, setParentData] = useState({});
  const [canvasStyle, setCanvasStyle] = useState({ zoom: 1 });

  const divRef = useRef(null);

  const { connectDropTarget, dom, builderData, setBuilderData, canvasConfig } =
    props;
  const elements = dom.tree.children || [];

  useEffect(() => {
    const { fit, width: inputWidth } = canvasConfig;
    const width = Math.max(inputWidth, 300);

    if (fit && divRef.current) {
      const parentWidth = divRef.current.parentElement.offsetWidth;
      const newZoomLevel = parentWidth / width;
      setCanvasStyle((prev) => ({ ...prev, zoom: newZoomLevel }));
    } else {
      setCanvasStyle((prev) => ({ ...prev, zoom: 1 }));
    }
  }, [canvasConfig]);

  return connectDropTarget(
    <div
      className="canvas-container"
      ref={divRef}
      style={{
        background: props.hover ? "#dedef2" : "",
        height: "100%",
        padding: "10px",
        position: "relative",
        overflow: "auto",
        width: canvasConfig.width + "px",
        ...canvasStyle,
      }}
    >
      {elements.map((element, index) => (
        <DraggableCanvasElement
          key={element.id}
          {...{
            ...props,
            element,
            indices: [index],
            builderData,
            setBuilderData,
            parentData,
            setParentData,
            dom,
            project: props.project,
            style: styleModule.getElementStyleData(element, "builderWrapper"),
          }}
        />
      ))}
    </div>
  );
};

export default DropTarget(
  "item",
  {
    drop(props, monitor) {
      const item = monitor.getItem();
      if (monitor.didDrop()) return;

      const dom = props.dom;
      if (item.set !== "canvasElement")
        dom.addNodeValue("ROOT", item, dom.tree.children.length);
      else dom.moveNode(item.id, "ROOT", "inside");
    },
  },
  function collect(connect, monitor) {
    return {
      connectDropTarget: connect.dropTarget(),
      hover: monitor.isOver({ shallow: true }),
      item: monitor.getItem(),
    };
  }
)(Canvas);
