import React from "react";
import DatabaseQueryFilter from "../DatabaseOperations_v2/DatabaseQueryFilter";
import { getOptionGroups } from "../DataQueryOperations/PassedParamSelector";
import { DatabaseTableSelector } from "../DatabaseOperations_v2/DatabaseDataSelector";

const DeleteRecord = (props) => {
  const { value = {}, onChange } = props;
  const [currentRecordParam, setCurrentRecordParam] = React.useState(null);

  React.useEffect(() => {
    const selectedParams = getOptionGroups(props).filter(
      (x) =>
        x.sourceType === "repeatingContainer" &&
        !["externalApi", "staticValues", "webrtcStreams"].includes(
          x.dbData?.dbId
        ) &&
        x.options?.length
    );

    const currentParam = selectedParams[selectedParams.length - 1].options[0];
    setCurrentRecordParam(currentParam);
  }, []);

  const mergeChange = (obj) => onChange({ ...value, ...obj });

  const query = value?.query;
  const mergeQuery = (obj) =>
    onChange({
      ...(value || {}),
      query: { ...(value.query || {}), ...(obj || {}) },
    });

  const propsToPass = {
    ...props,
    mode: null,
    className: null,
    classNames: null,
  };

  return (
    <div className="optionItemBody">
      <div className="databaseItemActionBar">
        <div className="databaseItemActionLabel">Database</div>
        <div
          className="databaseItemActionRemove"
          onClick={() => mergeChange({ query: {} })}
        >
          Clear
        </div>
      </div>

      <div className="databaseOperationModule">
        {value?.query?.valueType !== "currentValue" ? (
          <>
            <div className="domHeader">
              <div className="domLabel">Table</div>

              <DatabaseTableSelector
                {...{
                  ...propsToPass,
                  value: value?.query?.valueObj,
                  onChange: (valueObj) => mergeQuery({ valueObj }),
                  valueTypeData: { tableSelector: { maxNoOfInputs: 0 } },
                }}
              />
            </div>
            <div className="calcItemUnitBox filterWrapper">
              <div className="calcItemDatabaseFilter">
                <DatabaseQueryFilter
                  {...propsToPass}
                  dbId={query?.valueObj?.dbId}
                  tableId={query?.valueObj?.tableId}
                  value={query?.valueObj}
                  onChange={(obj) =>
                    mergeQuery({
                      valueObj: { ...(query?.valueObj || {}), ...obj },
                    })
                  }
                  valueTypeData={null}
                />
              </div>
            </div>
          </>
        ) : null}

        <div className="pad12 bgWhite">
          <div className="domLabel pad">Delete Record</div>

          <div className="linkingOptionsBox spaceTopify">
            <div className="pad6 flexRow flex1">
              <div
                onClick={() =>
                  mergeQuery(
                    query?.valueType === "currentValue"
                      ? {
                          valueType: "",
                          valueObj: {},
                        }
                      : {
                          valueType: "currentValue",
                          valueObj: { valueObj: currentRecordParam },
                        }
                  )
                }
                className={
                  "optionDatabaseRadio checkBoxify " +
                  (query?.valueType === "currentValue" ? "active" : "")
                }
              >
                <div className="optionDatabaseRadioDecor"></div>
              </div>
              <div className="cGrey">Delete current</div>
            </div>
          </div>
        </div>

        {/* {recordIdOperation ? (
          <RecordIdOperations
            {...propsToPass}
            value={value.recordIdOperations}
            onChange={(val) => mergeChange({ recordIdOperations: val })}
          />
        ) : null} */}
      </div>
    </div>
  );
};

export default DeleteRecord;
