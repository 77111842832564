import React, { useEffect, useRef, useState } from "react";
import update from "immutability-helper";
import _ from "lodash";

import linkingObj from "../../../appxolo-engine/modules/linking/linking-obj";
import RowOperations from "../DatabaseOperations/RowOperations";
import ToggleButton from "../../../Components/Input/ToggleButton";
import DataCondition from "../DataQueryOperations/DataCondition";
import LinkToScreen, { LinkToScreenNav } from "./LinkToSceen";
import LinkToURL, { LinkToURLNav } from "./LinkToURL";
import LinkToAlert from "./LinkToAlert";
import LinkToPayment from "./LinkToPayment";
import LinkToWebrtc from "./LinkToWebrtc";
import TriggerPushNotification from "./TriggerPushNotification";
import SendSMS from "./SendSMS";
import SendEmail from "./SendEmail";
import LinkToApi from "./LinkToApi";
import UpdateExternalApiReq from "./UpdateExternalApiReq";
import UpdateElement from "./UpdateElement";
import OptionItem from "../../../Components/etc/OptionItem";
import CustomSelect from "../../../Components/etc/CustomSelect";
import ToggleRadioButton from "../../../Components/Input/ToggleRadioButton";
import WebrtcOperations from "./WebrtcOperations";
import MicrophoneOperations from "./MicrophoneOperations";
import CameraOperations from "./CameraOperations";
import RepeatingAction from "./RepeatingAction";
import SuccessFailHandler, { PostOpHandler } from "./SuccessFailHandler";
import { ToggleVisibility, SetElementValue } from "./SetElementProperty";
import LinkToBroadcast from "./LinkToBroadcast";
import LinkToPhone from "./LinkToPhone";
import ShareToExternal from "./ShareToExternal";
import Chevron from "../../../Components/etc/Chevron";
import ReqPermission from "./ReqPermission";

const components = {
  linkToScreen: {
    renderNav: (props) => <LinkToScreenNav {...props} />,
    render: (props) => <LinkToScreen {...props} />,
  },
  linkToURL: {
    renderNav: (props) => <LinkToURLNav {...props} />,
    render: (props) => <LinkToURL {...props} />,
  },
  linkToAlert: {
    render: (props) => <LinkToAlert {...props} />,
  },

  linkToPayment: {
    render: (props) => <LinkToPayment {...props} />,
  },
  linkToWebrtc: {
    render: (props) => <LinkToWebrtc {...props} />,
  },
  rowOperations: {
    render: (props) => <RowOperations {...props} />,
  },
  repeatingAction: {
    render: (props) => <RepeatingAction {...props} />,
  },
  webrtcRoom: {
    render: (props) => <WebrtcOperations {...props} />,
  },
  microphone: {
    render: (props) => <MicrophoneOperations {...props} />,
  },
  camera: {
    render: (props) => <CameraOperations {...props} />,
  },
  linkToApi: {
    render: (props) => <LinkToApi {...props} />,
  },
  updateExternalApiReq: {
    render: (props) => <UpdateExternalApiReq {...props} />,
  },
  refreshElement: {
    render: (props) => <UpdateElement {...props} />,
  },
  triggerPushNotification: {
    render: (props) => <TriggerPushNotification {...props} />,
  },
  sendSMS: {
    render: (props) => <SendSMS {...props} />,
  },
  sendEmail: {
    render: (props) => <SendEmail {...props} />,
  },
  toggleVisibility: {
    render: (props) => <ToggleVisibility {...props} />,
  },
  setElementValue: {
    render: (props) => <SetElementValue {...props} />,
  },
  broadcast: {
    render: (props) => <LinkToBroadcast {...props} />,
  },
  linkToPhone: {
    render: (props) => <LinkToPhone {...props} />,
  },
  shareToExternal: {
    render: (props) => <ShareToExternal {...props} />,
  },
  reqPermission: {
    render: (props) => <ReqPermission {...props} />,
  },
};

const linkCategories = [
  {
    name: "general",
    valueTypes: [
      "linkToScreen",
      "linkToURL",
      "rowOperations",
      "linkToPayment",
      "linkToAlert",
      "toggleVisibility",
      "setElementValue",
      "repeatingAction",
    ],
  },
  {
    name: "stream",
    valueTypes: ["broadcast"],
  },
  {
    name: "connect",
    valueTypes: [
      "triggerPushNotification",
      "sendSMS",
      "sendEmail",
      "linkToPhone",
      "shareToExternal",
      "reqPermission",
    ],
  },
];

const LinkingBox = (props) => {
  const [valueTypesData, setValueTypesData] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [linkSelectorVisible, setLinkSelectoVisible] = useState(false);

  const { value, onChange, linkBoxOptions = {} } = props;
  const mergeChange = (obj) => onChange({ ...(value || {}), ...obj });

  const dropdownRef = useRef();

  const activeTab = value?.tabs?.[value?.activeTabIndex || 0] || {
    uid: Math.random(),
  };
  const setActiveTab = (tab) =>
    mergeChange({
      tabs: update(value?.tabs || [], {
        $merge: { [props.value?.activeTabIndex || 0]: tab },
      }),
    });

  useEffect(() => {
    const triggerOnOption = props.triggerOnOptions?.[0]?.value || "onPress";
    if (!value.triggerOn) mergeChange({ triggerOn: triggerOnOption });
  }, []);

  useEffect(() => {
    const allValidValueTypes = linkingObj.valueTypes.filter(
      (x) => !props.availableFor || x.availableFor.includes(props.availableFor)
    );

    let categoryWiseValueTypes = linkCategories.map((x) => ({
      ...x,
      valueTypes: x.valueTypes
        .map((x) =>
          allValidValueTypes.find(
            (validValueType) => validValueType.value === x
          )
        )
        .filter((x) => x),
    }));

    const linkOptions = categoryWiseValueTypes.map((x) => ({
      label: x.name,
      options: x.valueTypes,
    }));

    setValueTypesData({
      allValidValueTypes,
      categories: categoryWiseValueTypes,
      linkOptions,
    });
  }, [props.availableFor]);

  const linkingData = activeTab.linkingData || {};
  const mergeLinkingData = (obj) =>
    setActiveTab({
      ...activeTab,
      linkingData: { ...linkingData, ...obj },
    });

  const activeCategoryName = selectedCategory
    ? selectedCategory
    : linkingData.valueType
    ? valueTypesData?.categories.find((x) =>
        x.valueTypes.find((y) => y.value === linkingData.valueType)
      )?.name
    : "general";

  const activeCategory = valueTypesData?.categories.find(
    (x) => x.name === activeCategoryName
  );

  const triggerOption = (
    <>
      <div className="optionItemBody">
        <div className="linkLabel">Trigger</div>
        <div className="optionItemRow">
          <CustomSelect
            renderOptionListFooter={() =>
              [
                "rowOperations",
                "toggleVisibility",
                "triggerPushNotification",
              ].includes(linkingData.valueType) ? (
                <div style={{ display: "flex" }}>
                  <input
                    type="checkbox"
                    checked={!!value?.redo}
                    onChange={() =>
                      mergeChange({
                        redo: value?.redo ? null : {},
                      })
                    }
                  />
                  Redo{" "}
                  <input
                    value={value.redo?.value || ""}
                    onChange={(e) =>
                      mergeChange({
                        redo: { unit: "sec", value: e.target.value },
                      })
                    }
                    placeholder="Type here"
                    type="number"
                  />
                  Seconds
                </div>
              ) : null
            }
            value={value.triggerOn || ""}
            onChange={(option) => mergeChange({ triggerOn: option.value })}
            options={
              props.triggerOnOptions ||
              ["onPress", "onLoad"].map((x) => ({
                value: x,
                label: _.startCase(x),
              }))
            }
            placeholder={"Select"}
            className="linkingToBox flexColumn flex1 br10 pad6"
            classNames={{
              head: "pad6 flexRow aCenter",
              label: "cDark flex1 cPurple",
              chevron: "optionDatabaseSortKeyIco",
            }}
          />
        </div>
      </div>
    </>
  );

  const propsToBePassedToLinkingComponent = {
    ...props,
    value: linkingData.valueObj?.[linkingData.valueType],
    onChange: (valueTypeObj) =>
      mergeLinkingData({
        valueObj: {
          ...(linkingData.valueObj || {}),
          [linkingData.valueType]: valueTypeObj,
        },
      }),
    valueType: linkingData.valueType,
    setValueType: (valueType) => mergeLinkingData({ valueType }),
    triggerOption,
  };

  const optionHeadTabs = (
    <ToggleButton
      overideDefaultStyle
      classNames={{ container: "optionHeadTabs", item: "optionHeadTabItem" }}
      value={value.dataType || "off"}
      onChange={(x) =>
        mergeChange({
          dataType: x,
          activeTabIndex: x === "fixed" ? 0 : value.activeTabIndex,
        })
      }
      options={["off", "fixed", "conditional"]?.map((x) => ({
        value: x,
        label: _.startCase(x),
      }))}
    />
  );

  const conditionSelector = (
    <div className="optionItemBodys linkingConditionBlock">
      {/* <div className="cDark fwSemiBold f14 mb10">Conditions</div> */}
      <div className="optionItemRow">
        <div className="linkingToBox flexColumn bgWhite shadow flex1 br10">
          <DataCondition {...props} value={value} onChange={mergeChange} />
        </div>
      </div>
    </div>
  );

  const linktypeSpecificComponent = (
    <>
      {components[linkingData?.valueType]?.render(
        propsToBePassedToLinkingComponent
      )}
    </>
  );

  const linkDropdown = (
    <div className="linkDropdown">
      <div className="linkDropdownHead">
        <div className="calcItemHeadTab">
          {valueTypesData?.categories?.map((category) => {
            const isActive = category.name === activeCategory?.name;

            return (
              <div
                key={category.name}
                className={`calcItemHeadTabItem ${isActive ? "active" : ""}`}
                onClick={() => setSelectedCategory(category.name)}
              >
                {_.startCase(category.name)}
              </div>
            );
          })}
        </div>
      </div>
      <div className="linkDropdownBody">
        <div className="linkDropdownLabel">
          {_.startCase(activeCategory?.name || "")} linking
        </div>

        {activeCategory?.valueTypes.map((valueType, i) => {
          if (i % 2) return null;
          const items = [valueType, activeCategory?.valueTypes[i + 1]].filter(
            (x) => x
          );

          return (
            <div key={valueType.value} className="linkDropdownRow">
              {items.map((item) => (
                <div
                  key={item.value}
                  className={`calcItemUnit ${
                    item.value === linkingData.valueType ? "active" : null
                  }`}
                  onClick={() => {
                    mergeLinkingData({ valueType: item.value });
                    setLinkSelectoVisible(false);
                  }}
                >
                  <div className="calcItemUnitBox">
                    <div className="calcItemUnitDecorUnselected"></div>
                    <div className="calcItemUnitMain">
                      <div className="calcItemUnitMainUnselectedLabel">
                        {item.label}
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          );
        })}
      </div>
    </div>
  );

  const selectedDropdownOption = valueTypesData?.allValidValueTypes?.find(
    (x) => x.value === linkingData?.valueType
  );

  // console.log({ selectedDropdownOption, valueTypesData, linkingData });
  const linkSelector = (
    <>
      {/* <div className="linkingToBox flexColumn flex1 br10 pad6">
        <CustomSelect
          value={linkingData?.valueType || ""}
          onChange={(option) => mergeLinkingData({ valueType: option.value })}
          options={valueTypesData?.linkOptions || []}
          placeholder={"Select Linking"}
          classNames={{
            head: "pad6 flexRow aCenter",
            label: "cDark flex1 cPurple",
            chevron: "optionDatabaseSortKeyIco",
          }}
        />
      </div> */}

      <div
        className="linkingSelectDropdownWrapper"
        ref={dropdownRef}
        style={{ position: "relative" }}
      >
        <div
          className={"linkingSelectDropdown"}
          onClick={() => setLinkSelectoVisible((prev) => !prev)}
        >
          <div>{selectedDropdownOption?.label || "Select Linking"}</div>
          <Chevron className={"optionDatabaseSortKeyIco"} />
        </div>
        {linkSelectorVisible && (
          <div
            className="options-list"
            style={Object.assign(
              {},
              { background: "white" },
              dropdownRef.current.getBoundingClientRect()?.bottom >
                window.innerHeight - 375
                ? { top: "unset", bottom: "55px" }
                : {}
            )}
          >
            {linkDropdown}
          </div>
        )}
      </div>
    </>
  );

  if (linkBoxOptions.mode === "selectorOnly")
    return (
      <>
        {linkSelector}
        {linktypeSpecificComponent}
      </>
    );
  else if (linkBoxOptions.mode === "loopLinking") {
    return (
      <>
        <div className="loopLinking">
          <div className="optionItemBody">
            <div className="linkLabel">Link to</div>
            {linkSelector}
          </div>
        </div>
        {linktypeSpecificComponent}
      </>
    );
  } else if (linkBoxOptions.mode === "mapMarker") {
    return (
      <>
        <div className="loopLinking">
          <div className="optionItemBody">
            <div className="linkLabel">Link to</div>
            {linkSelector}
          </div>
        </div>
        {linktypeSpecificComponent}
      </>
    );
  } else if (linkBoxOptions.mode === "trigger") {
    return (
      <>
        <div className="linkingBoxMain">
          <div className="linkingBoxHead">
            <div className="optionItemBody">
              <div className="linkLabel">Link to</div>
              {linkSelector}
            </div>
          </div>
          {linktypeSpecificComponent}

          {props.delete ? (
            <div className="removeX" onClick={props.delete}>
              <div className="removeXLine one"></div>
              <div className="removeXLine two"></div>
            </div>
          ) : null}
        </div>
        {["rowOperations", "linkToPayment", "repeatingAction"].includes(
          linkingData?.valueType
        ) ? (
          <>
            <div className="triggerSeperator"></div>
            <PostOpHandler
              {...{
                ...propsToBePassedToLinkingComponent,
                immediateProps: null,
                key: "onSuccess",
                valueType: "onSuccess",
                classNames: {
                  onSuccess: "success",
                  onFail: "fail",
                  onCompletion: "completion",
                },
                value: propsToBePassedToLinkingComponent.value?.onSuccess,
                onChange: (x) =>
                  propsToBePassedToLinkingComponent.onChange({
                    ...(propsToBePassedToLinkingComponent.value || {}),
                    onSuccess: x,
                  }),
              }}
            />
            <div className="triggerSeperator"></div>
            <PostOpHandler
              {...{
                ...propsToBePassedToLinkingComponent,
                immediateProps: null,
                key: "onFail",
                valueType: "onFail",
                classNames: {
                  onSuccess: "success",
                  onFail: "fail",
                  onCompletion: "completion",
                },
                value: propsToBePassedToLinkingComponent.value?.onFail,
                onChange: (x) =>
                  propsToBePassedToLinkingComponent.onChange({
                    ...(propsToBePassedToLinkingComponent.value || {}),
                    onFail: x,
                  }),
              }}
            />
          </>
        ) : null}
      </>
    );
  }

  return (
    <OptionItem
      className="linkingModules"
      optionItemLabel="Linking"
      optionHeadTabs={
        linkBoxOptions.conditionalDataType || value.dataType === "conditional"
          ? optionHeadTabs
          : null
      }
    >
      {(value.dataType || "off") === "off" ? null : (
        <div className="optionItemBody revealOffset">
          <div className="optionItemRow">
            <div className="linkingBoxWrapper">
              <div className="linkingBoxMain">
                {value.dataType === "conditional" ? (
                  <>{conditionSelector}</>
                ) : null}
                <div className="linkingBoxHead">
                  <div className="optionItemBody">
                    <div className="linkLabel">Link to</div>
                    {linkSelector}
                  </div>
                </div>
                {linktypeSpecificComponent}

                {props.delete ? (
                  <div className="removeX" onClick={props.delete}>
                    <div className="removeXLine one"></div>
                    <div className="removeXLine two"></div>
                  </div>
                ) : null}
              </div>

              {/* <div className="linkingBoxItem">
              {props.delete ? (
                <div className="removeX" onClick={props.delete}>
                  <div className="removeXLine one"></div>
                  <div className="removeXLine two"></div>
                </div>
              ) : null}

              {value.dataType === "conditional" ? (
                <>{conditionSelector}</>
              ) : null}
              {linkSelector}
              {linktypeSpecificComponent}
              {linkBoxOptions.showTriggerOption ? triggerOption : null}
            </div> */}
            </div>
          </div>

          {["rowOperations", "linkToPayment", "repeatingAction"].includes(
            linkingData?.valueType
          ) ? (
            <SuccessFailHandler
              {...{
                ...propsToBePassedToLinkingComponent,
                immediateProps: {
                  postOpOptions: ["repeatingAction"].includes(
                    linkingData?.valueType
                  )
                    ? ["onSuccess", "onFail", "onCompletion"]
                    : ["onSuccess", "onFail"],
                },
              }}
            />
          ) : null}
        </div>
      )}
    </OptionItem>
  );
};

const RedoPopup = (props) => {
  const { value, onChange } = props;
  const [visible, setVisible] = useState(false);

  const dropdownRef = useRef();

  const mergeChange = (obj) => onChange({ ...(value || {}), ...obj });

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setVisible(false);
      }
    };

    const handleEscapeKey = (event) => {
      if (event.key === "Escape") {
        setVisible(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    document.addEventListener("keydown", handleEscapeKey);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
      document.removeEventListener("keydown", handleEscapeKey);
    };
  }, []);

  return (
    <div ref={dropdownRef}>
      <div onClick={() => setVisible((prev) => !prev)}>
        Every {value.redo?.value || "-"} {value.redo?.unit || "-"}
      </div>

      <div
        style={{
          display: visible ? "block" : "none",
        }}
        className="optionItemRow"
      >
        <div style={{ display: "flex" }}>
          Every{" "}
          <input
            value={value.redo?.value || ""}
            onChange={(e) =>
              mergeChange({
                redo: { ...(value.redo || {}), value: e.target.value },
              })
            }
            placeholder="Type here"
            type="number"
          />{" "}
          <CustomSelect
            value={value.redo?.unit || ""}
            onChange={(option) =>
              mergeChange({
                redo: { ...(value.redo || {}), unit: option.value },
              })
            }
            options={[
              { value: "sec", label: "Second" },
              { value: "min", label: "Minute" },
              { value: "hour", label: "Hour" },
              { value: "day", label: "Day" },
            ]}
            placeholder={"Select"}
            className="linkingToBox flexColumn flex1 br10 pad6"
            classNames={{
              head: "pad6 flexRow aCenter",
              label: "cDark flex1 cPurple",
              chevron: "optionDatabaseSortKeyIco",
            }}
          />
        </div>
      </div>
    </div>
  );
};

export const LinkingBoxes = (props) => {
  const {
    value = [],
    onChange,
    autoAddOneEmptyLinking,
    immediateProps,
  } = props;

  React.useEffect(() => {
    if (!value.length && autoAddOneEmptyLinking) {
      onChange([
        {
          uid: Math.random(),
          ...(immediateProps?.autoAddLinkingDefault || {}),
        },
      ]);
    }
  }, [value.length, onChange, autoAddOneEmptyLinking]);

  return (
    <div style={props.style}>
      {value.map((link, i) => {
        return (
          <LinkingBox
            {...{
              ...props,
              immediateProps: null,
              key: link.uid,
              value: link,
              delete: () =>
                onChange(update(value || [], { $splice: [[i, 1]] })),
              onChange: (link) =>
                onChange(update(value || [], { $merge: { [i]: link } })),
            }}
          />
        );
      })}

      {immediateProps?.disableAdditionalLinking ? null : (
        <div
          className="mapLinkingWrapper"
          onClick={() =>
            onChange(update(value || [], { $push: [{ uid: Date.now() }] }))
          }
        >
          <div className="optionAddLinkingButton">Add Linking</div>
        </div>
      )}
    </div>
  );
};

export const LinkingBoxesPopup = (props) => {
  const [visible, setVisible] = useState(false);

  return (
    <>
      <div className="mapLinkingWrapper" onClick={() => setVisible(!visible)}>
        <div className="optionAddLinkingButton">
          {props.value?.length
            ? `Added ${props.value.length} linking${
                props.value.length > 1 ? "s" : ""
              }`
            : "Linkings"}
        </div>
      </div>

      <div
        className="calculationOutside"
        style={{
          display: visible ? "block" : "none",
          position: "fixed",
          inset: 0,
          backgroundColor: "#0003",
        }}
      >
        <div className="calculationWindow">
          <div className="calcHead">
            <div className="calcHeadAction" onClick={() => setVisible(false)}>
              <div className="calculationHeadBackLine one"></div>
              <div className="calculationHeadBackLine two"></div>
              <div className="calculationHeadBackLineHorizontal"></div>
            </div>
            <div className="calcHeadMeta">
              <div className="calcHeadLabel">Linkings</div>
            </div>
          </div>

          <div className="calcBody">
            <LinkingBoxes {...props} />
          </div>
        </div>
      </div>
    </>
  );
};

export default LinkingBox;
