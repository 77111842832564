import React from "react";

import { LinkingOptionItemBody, OptionItemBodyMultiRow } from "../../../Components/etc/OptionItem";
import CustomValueSelector from "../QuickValueSelector/CustomValueSelector";
import RichTextData from "../../Builder/Properties/Common/DataProperties/RichTextData";

const TriggerPushNotification = (props) => {
  const { value = {}, onChange, linkBoxOptions, triggerOption } = props;
  const mergeChange = (obj) => onChange({ ...value, ...obj });
  return (
    <>
      <LinkingOptionItemBody {...props}>
        <div className="databaseOperationModule">
          <div className="domHeader">
            <div className="domLabel">Device ID(s)</div>

            <RichTextData
              {...{
                ...props,
                immediateProps: {
                  classNames: {
                    wrapper: " ",
                    editor: "calcDatabaseFilterMultiBox spaceTopify",
                    paramListRow: "",
                  },
                },
                placeholder: "Type here",
                value: value?.targetDeviceIds?.valueObj,
                onChange: (valueObj) => {
                  mergeChange({
                    targetDeviceIds: {
                      valueObj,
                      valueType: "textParts",
                    },
                  });
                },
              }}
            />
          </div>

          <div className="domBodys">
            {[
              {
                label: "Notification Title",
                className: "mb14",
                key: "notificationTitle",
              },
              {
                label: "Notification Description",
                key: "notificationBody",
              },
            ].map((item) => (
              <div className="calcItemDatabaseFilterBox" key={item.key}>
                <div className="calcItemDatabaseFilterBoxInner">
                  <div className="calcItemDatabaseFilterBoxInnerRow">
                    <div className="calcItemDBLabel">{item.label}</div>
                  </div>
                  <RichTextData
                    {...{
                      ...props,
                      immediateProps: {
                        classNames: {
                          wrapper: " ",
                          editor: "calcDatabaseFilterMultiBox ",
                          paramListRow: "",
                        },
                      },
                      placeholder: "Type here",
                      value: value?.[item.key]?.valueObj,
                      onChange: (valueObj) => {
                        mergeChange({
                          [item.key]: {
                            valueObj,
                            valueType: "textParts",
                          },
                        });
                      },
                    }}
                  />
                </div>
              </div>
            ))}
          </div>
        </div>
      </LinkingOptionItemBody>

      {linkBoxOptions.showTriggerOption ? (
        <div className="linkingBoxFooter"> {triggerOption}</div>
      ) : null}
    </>
  );

  return (
    <>
      {[
        {
          label: "Recipient",
          rows: [
            {
              label: "Device ID's",
              key: "targetDeviceIds",
            },
          ],
        },
        {
          label: "Notification",
          rows: [
            {
              label: "Notification Title",
              className: "mb14",
              key: "notificationTitle",
            },
            {
              label: "Notification Description",
              key: "notificationBody",
            },
          ],
        },
      ].map((group, index) => (
        <OptionItemBodyMultiRow
          label={group.label}
          key={index}
          rows={group.rows.map((row) => ({
            ...row,
            render: () => (
              <CustomValueSelector
                {...{
                  row,
                  value: value[row.key],
                  onChange: (x) => mergeChange({ [row.key]: x }),
                }}
              />
            ),
          }))}
        />
      ))}
    </>
  );
};

export default TriggerPushNotification;
