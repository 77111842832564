import React from "react";
import DraggableList from "./DraggableList";

class DraggablePopup extends React.PureComponent {
  constructor(props) {
    super(props);
    this.droppableId = props.droppableId || "id-" + Math.random();
    this.state = { visible: false };
    this.dropdownRef = React.createRef();

    const handleClickOutside = (event) => {
      if (
        this.dropdownRef.current &&
        !this.dropdownRef.current.contains(event.target)
      ) {
        this.setState({ visible: false });
      }
    };

    const handleEscapeKey = (event) => {
      if (event.key === "Escape") {
        this.setState({ visible: false });
      }
    };

    this.handleClickOutside = handleClickOutside.bind(this);
    this.handleEscapeKey = handleEscapeKey.bind(this);
  }

  componentDidMount() {
    document.addEventListener("mousedown", this.handleClickOutside);
    document.addEventListener("keydown", this.handleEscapeKey);
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
    document.removeEventListener("keydown", this.handleEscapeKey);
  }

  handleOptionClick(option) {
    this.props.onChange(option);
    this.setState({ visible: false });
  }

  render() {
    const {
      props: {
        value = "",
        options,
        placeholder = "Select",
        renderMenuHeader,
        onChange,
        onChangeOptions,
        onEditOption,
        onDeleteOption,
        classNames,
      },
      state: { visible },
      droppableId,
    } = this;

    const selectedOption = options?.find((x) => x.value === value);

    return (
      <div style={{ position: "relative" }} ref={this.dropdownRef}>
        <div
          className="draggableDropdown"
          onClick={() => this.setState({ visible: !visible })}
        >
          <img
            className="draggableDropdownIco"
            src={require("../../Assets/img/user/table.png")}
          ></img>
          <div>
            {selectedOption
              ? selectedOption?.titleLabel || selectedOption?.label
              : placeholder}
          </div>
          <img
            className="optionDatabaseSelectChevron"
            src={require("../../Assets/img/options/container/chevronDown.png")}
          ></img>
        </div>
        {visible ? (
          <div className="dropdownElement">
            {renderMenuHeader
              ? renderMenuHeader({
                  close: () => this.setState({ visible: false }),
                })
              : null}
            <DraggableList
              droppableId={droppableId}
              value={options?.map((option) => ({
                id: option.value,
                item: option,
                content: (
                  <Option
                    key={option.value}
                    {...this.props}
                    option={option}
                    onChange={this.handleOptionClick.bind(this)}
                    opt={{ close: () => this.setState({ visible: false }) }}
                  />
                ),
              }))}
              onChange={(x) => onChangeOptions(x.map((x) => x.item))}
              classNames={
                classNames || {
                  droppableList: "draggable-popup",
                }
              }
            />
          </div>
        ) : null}
      </div>
    );
  }
}

class Option extends React.PureComponent {
  render() {
    const { option, disableDelete } = this.props;

    return (
      <div className="draggableItem">
        <img
          className="draggableDecorIco"
          src={require("../../Assets/img/user/drag.png")}
        ></img>
        <div
          className="draggableItemLabel"
          onClick={() => this.props.onChange(option, this.props.opt)}
        >
          {option.label}
        </div>
        <div
          className="draggableItemAction"
          onClick={() => this.props.onEditOption(option, this.props.opt)}
        >
          <img
            className=""
            src={require("../../Assets/img/user/edit.png")}
          ></img>
        </div>

        {disableDelete ? null : (
          <div
            className="draggableItemAction"
            onClick={() => this.props.onDeleteOption(option, this.props.opt)}
          >
            <img
              className=""
              src={require("../../Assets/img/user/deleteRow.png")}
            ></img>
          </div>
        )}
      </div>
    );
  }
}

export default DraggablePopup;
