import React, { useEffect, useState } from "react";
import update from "immutability-helper";
import Slider from "rc-slider";

import SearchIcons from "../../Builder/Properties/Common/ImageSources/SearchIcons";
import api from "../../../Services/Api/api";
import { toast } from "react-toastify";
import SearchInput from "../../../Components/Input/SearchInput";
import { ChromePicker } from "react-color";
import ColorButton from "../../../Components/Input/ColorButton";
import ToggleButton from "../../../Components/Input/ToggleButton";
import CustomSelect from "../../../Components/etc/CustomSelect";

const ICON_SIZE = 300;

var svgData = {};
class FileManagerModal extends React.Component {
  state = {
    uploadings: [],
    ts: 0,
    resetFileInput: 0,
    fileData: {},
    staticUrls: (this.props.value || []).map((x) => ({ id: x, url: x })),

    iconSearchText: "",
    selectedFileType: "all",

    allIcons: [],
    filteredIcons: [],
    iconFilter: {
      iconFillColor: null,
      style: "materialsymbolsrounded",
      weight: 400,
      grad: 0,
      fill: false,
      size: 40,
      updatedAt: 0,
    },
  };

  maxLength = this.props.maxLength || Infinity;
  uploadProgress = {};

  componentDidMount() {
    this.loadOldFiles();
  }

  filterFileMime(files, mime) {
    return files.filter((file) => file.file_mime_type.split("/")[0] === mime);
  }

  filterExcludeFileMimes(files, mimes) {
    return files.filter(
      (file) => !mimes.includes(file.file_mime_type.split("/")[0])
    );
  }

  categorieseFile(files) {
    const imageFiles = this.filterFileMime(files, "image");
    const videoFiles = this.filterFileMime(files, "video");
    const audioFiles = this.filterFileMime(files, "audio");
    const otherFiles = this.filterExcludeFileMimes(files, ["video"]);

    return {
      all: files,
      image: imageFiles,
      video: videoFiles,
      audio: audioFiles,
      other: otherFiles,
    };
  }

  async loadOldFiles() {
    try {
      const { files } = await api.get("v1/file", {
        projectId: this.props.project?._id,
        uploadedFrom: "builder",
        file_type: { $ne: "androidBuild" },
      });

      this.setState({
        fileData: this.categorieseFile(files),
      });
    } catch (error) {
      console.error(error);
    }
  }

  appendStaticUrls = (urls) => {
    const staticUrls = this.state.staticUrls || [];
    let updated = update(staticUrls, { $push: urls });

    if (updated.length > this.maxLength) {
      const elementsToRemove = updated.length - this.maxLength;
      updated.splice(0, elementsToRemove);
    }

    this.setState({ staticUrls: updated });
  };

  uploadHandler = async (event) => {
    try {
      let files = event.target.files;
      let localUrls = [];

      for (let i = 0; i < files.length && i < this.maxLength; i++) {
        const file = files[i];
        const localUrl = URL.createObjectURL(file);
        localUrls.push({ url: localUrl, uid: Math.random(), file });
      }

      let updatedUploadings = [...this.state.uploadings, ...localUrls];
      this.setState({
        uploadings: updatedUploadings,
        resetFileInput: Date.now(),
      });

      const promises = updatedUploadings.map(async (uploading) => {
        const fileRes = await api.media(
          "v1/file",
          {
            file: uploading.file,
          },
          {
            cb: (x) => {
              this.uploadProgress[uploading.uid] = x;
              this.setState({ ts: Date.now() });
            },
          }
        );

        return fileRes.file;
      });

      const uploadedFiles = await Promise.all(promises);
      const staticUrls = uploadedFiles.map((file) => ({
        id: file._id,
        url: api.getFileLink(file, {
          params: { mime_type: file?.file_mime_type },
        }),
      }));

      const allFiles = [...uploadedFiles, ...(this.state.fileData?.all || [])];

      this.setState({
        uploadings: this.state.uploadings.filter(
          (x) => !updatedUploadings.find((u) => u.uid === x.uid)
        ),
        fileData: this.categorieseFile(allFiles),
      });

      this.appendStaticUrls(staticUrls);
    } catch (e) {
      console.error("Error uploading file: " + e.message);
    }
  };

  async applyIcon(svgString) {
    try {
      return new Promise((resolve, reject) => {
        const canvas = document.createElement("canvas");
        const ctx = canvas.getContext("2d");

        const img = new Image();
        img.onload = async () => {
          this.setState({ selecting: true });

          try {
            canvas.width = img.width;
            canvas.height = img.height;
            ctx.drawImage(img, 0, 0);

            const pngData = canvas.toDataURL("image/png");

            const blob = dataURLToBlob(pngData);
            const fileRes = await api.media("v1/file", {
              file: blob,
            });
            const staticUrl = api.getFileLink(fileRes?.file, {
              params: { mime_type: fileRes?.file?.file_mime_type },
            });

            resolve(staticUrl);
          } catch (e) {
            reject(e);
          }
          this.setState({ selecting: false });
        };

        img.src = svgString;
      });
    } catch (e) {
      window.alert(e.message);
    }
  }

  async handleSelect() {
    const staticUrls = this.state.staticUrls;
    const iconFillColor = this.state.iconFilter.iconFillColor;

    let processedUrlsP = staticUrls.map(async (x) =>
      x.type === "icon" && iconFillColor && svgData[x.url]
        ? await this.applyIcon(
            getIconSvgDataString(svgData[x.url], iconFillColor)
          )
        : x.url
    );

    let processedUrls = await Promise.all(processedUrlsP);

    console.log({
      staticUrls,
      iconFillColor,
      processedUrls,
    });

    this.props.onChange(processedUrls);

    setTimeout(() => {
      this.props.close();
    }, 50);
  }

  imageGallery = () => {
    const {
      props: { onChange, styleData, acceptFileMime = "image/*" },
      state: { uploadings, iconSearchText, staticUrls, selectedFileType },
    } = this;
    return (
      <div
        className="mediaGalleryGrid"
        style={iconSearchText ? { display: "none" } : null}
      >
        <label className="uploadPhotoItem">
          <div className="uploadPhoto uploadPhotoUnit">+ Upload</div>
          <input
            key={this.state.resetFileInput}
            type="file"
            hidden
            onChange={this.uploadHandler.bind(this)}
            multiple
            accept={acceptFileMime}
          />
        </label>

        {uploadings.map((uploading) => (
          <div className={"uploadPhotoItem"} key={uploading.uid}>
            <img
              className="uploadPhoto"
              src={uploading.url}
              alt="uploadedPhoto"
            />
            <div
              style={{
                background: "#fff6",
                position: "absolute",
                left: 0,
                right: 0,
                top: 0,
                height: 100 - (this.uploadProgress[uploading.uid] || 0) + "%",
              }}
            ></div>
          </div>
        ))}

        {this.state.fileData?.[this.state.selectedFileType || "all"]?.map(
          (file) => {
            const link = api.getFileLink(file, {
              params: { mime_type: file?.file_mime_type },
            });
            const selected = staticUrls?.find((x) => x.url === link);
            return (
              <FileItem
                key={file._id}
                file={file}
                selected={selected}
                onClick={() =>
                  selected
                    ? this.setState({
                        staticUrls: this.state.staticUrls.filter(
                          (x) => x.url !== link
                        ),
                      })
                    : this.appendStaticUrls([{ id: file._id, url: link }])
                }
              />
            );
          }
        )}
      </div>
    );
  };

  content() {
    const {
      props: { onChange, styleData, acceptFileMime = "image/*" },
      state: { uploadings, iconSearchText, staticUrls, selectedFileType },
    } = this;

    return (
      <>
        <div className="mediaBar">
          <div className="mediaFilterTabs">
            {[
              { value: "all", label: "All" },
              { value: "image", label: "Image" },
              { value: "video", label: "Video" },
              { value: "audio", label: "Audio" },
              { value: "icon", label: "Icons" },
            ].map((item) => (
              <div
                key={item.value}
                className={
                  "" +
                  (this.state.selectedFileType === item.value ? " active" : "")
                }
                onClick={() => this.setState({ selectedFileType: item.value })}
              >
                {item.label}
              </div>
            ))}
          </div>

          {selectedFileType === "icon" ? (
            <IconSearch
              {...{
                parent: this,
                state: this.state,
                setState: this.setState.bind(this),
              }}
            />
          ) : null}
        </div>
        <div className="mediaGalleryBodyWrapper">
          <div className="mediaGalleryBody">
            {selectedFileType === "icon" ? (
              <IconGallery
                {...{
                  parent: this,
                  state: this.state,
                  setState: this.setState.bind(this),
                }}
              />
            ) : (
              this.imageGallery()
            )}
          </div>
        </div>

        <div className="mediaAction">
          <div
            className="mediaSelectButton"
            onClick={() => this.handleSelect()}
          >
            {this.state.selecting ? "Selecting" : "Select"}
          </div>
          <div className="mediaSelectedLabel">{staticUrls.length} selected</div>
        </div>
      </>
    );
  }

  render() {
    return (
      <div className="calculationOutside mediaLibrary">
        <div className="calculationWindow" ref={this.scrollRef}>
          <div className="calcHead">
            <div className="calcHeadAction" onClick={() => this.props.close()}>
              <div className="calculationHeadBackLine one"></div>
              <div className="calculationHeadBackLine two"></div>
              <div className="calculationHeadBackLineHorizontal"></div>
            </div>
            File Library
          </div>
          {this.content()}
        </div>
      </div>
    );
  }
}

class FileItem extends React.PureComponent {
  render() {
    return (
      <div
        className={"uploadPhotoItem" + (this.props.selected ? " active" : "")}
        onClick={this.props.onClick}
      >
        <img
          className="uploadPhoto"
          src={api.getFileLink(this.props.file, { width: 200 })}
          alt="uploadedPhoto"
        />
      </div>
    );
  }
}

const IconSearch = (props) => {
  const { state, setState } = props;
  const allIcons = state.allIcons;

  const [q, setQ] = useState("");

  const filter = props.state.iconFilter;
  const setFilter = (obj) =>
    props.setState({
      iconFilter: { ...filter, ...obj, updatedAt: Date.now() },
    });

  useEffect(() => {
    handleSearchInput(q, allIcons);
  }, [allIcons, q, state.iconFilter.fill]);

  const handleSearchInput = async (q, allIcons) => {
    let icons = allIcons;
    if (q) {
      const regexes = [
        new RegExp(`^${q}$`, "i"),
        new RegExp(`^${q}.*$`, "i"),
        new RegExp(`^.*${q}$`, "i"),
        new RegExp(`${q}`, "i"),
      ];

      icons = regexes.flatMap((regex) => {
        return allIcons?.filter((x) => regex.test(x));
      });
    }

    icons = icons.filter((_, i) => i < 30);

    icons = [
      ...state.staticUrls.filter((x) => x.type === "icon").map((x) => x.id),
      ...icons,
    ];
    icons = Array.from(new Set(icons)); // remove duplicates

    let filteredIcons = icons.map((x) => ({
      id: x,
      url: getSvgUrl(x, state.iconFilter),
      type: "icon",
    }));

    setState({ filteredIcons });
  };

  return (
    <div className="mediaComplex">
      <div className="mediaSearch">
        <img
          className="mediaSearchIco"
          src={require("../../../Assets/img/user/search.png")}
          alt="chevron"
        ></img>
        <SearchInput
          className="mediaSearchInput"
          placeholder="Search here"
          value={q}
          onChange={setQ}
        />
      </div>

      <CustomSelect
        onChange={(option) => setFilter({ fill: option.value === "fill" })}
        value={filter.fill ? "fill" : "line"}
        options={[
          { value: "fill", label: "Fill" },
          { value: "line", label: "Line" },
        ]}
        classNames={{
          head: "mediaFillLabel",
          label: "mediaFillDecor",
          chevron: "optionDatabaseSelectChevron",
        }}
      />
      <div className="mediaColor">
        <div className="mediaColorInner">
          <ColorButton
            className="mediaColorInner"
            style={{ width: "100%", height: "100%" }}
            value={filter.iconFillColor || "#000000"}
            onChange={(val) => setFilter({ iconFillColor: val })}
          />
        </div>
      </div>
    </div>
  );
};

const IconGallery = (props) => {
  const { appendStaticUrls } = props.parent;
  const { state, setState } = props;
  const [ts, setTs] = useState(0);

  const iconFillColor = state.iconFilter.iconFillColor;

  useEffect(() => {
    load();
  }, []);

  useEffect(() => {
    state.filteredIcons.forEach((iconData) => {
      loadSvgData(iconData);
      setTs(Date.now());
    });
  }, [state.filteredIcons]);

  const loadSvgData = async (iconData) => {
    try {
      if (!svgData?.[iconData.url]) {
        const svgString = await api.get(iconData.url, {}, { fullUrl: true });
        svgData = { ...svgData, [iconData.url]: svgString };
      }
    } catch (e) {
      console.warn(e.message);
    }
  };

  const load = async () => {
    try {
      setState({ iconLoading: true });
      const { symbols } = await api.get("v1/icon/material");
      setState({
        allIcons: symbols,
        iconLoading: false,
      });
    } catch (e) {
      setState({ iconLoading: false });
      toast.error("Error loading icon: ", e.message);
    }
  };

  return (
    <div className="mediaGalleryGrid">
      {state.iconLoading ? <div>Loading...</div> : null}
      {state.filteredIcons?.map((iconData) => {
        const link = iconData.url;
        const selected = state.staticUrls?.find((x) => x.id === iconData.id);

        const select = () => {
          appendStaticUrls([iconData]);
        };

        const onClick = () =>
          selected
            ? setState({
                staticUrls: state.staticUrls.filter((x) => x.url !== link),
              })
            : select();

        const svgString = svgData?.[link];

        return (
          <div
            key={link}
            className={
              "uploadPhotoItem iconImgWrapper" + (selected ? " active" : "")
            }
            onClick={onClick}
          >
            {svgString ? (
              <img
                className="uploadPhoto"
                src={getIconSvgDataString(svgString, iconFillColor)}
              />
            ) : (
              <img className="uploadPhoto" src={link} alt="uploadedPhoto" />
            )}
          </div>
        );
      })}
    </div>
  );
};

const dataURLToBlob = (dataUrl) => {
  const byteString = atob(dataUrl.split(",")[1]);
  const mimeString = dataUrl.split(",")[0].split(":")[1].split(";")[0];

  const ab = new ArrayBuffer(byteString.length);
  const ia = new Uint8Array(ab);
  for (let i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }

  return new Blob([ab], { type: mimeString });
};

const modifySVGSize = (svgString, newSize) => {
  return svgString
    ?.replace(/width="\d+"/, `width="${newSize}"`)
    ?.replace(/height="\d+"/, `height="${newSize}"`);
};

const modifySVGColor = (svgString, newColor) => {
  return svgString?.replace(/(<path .*?)(\s?\/?>)/, `$1 fill="${newColor}"$2`);
};

const getIconSvgDataString = (svgString, fillColor) => {
  return `data:image/svg+xml;utf8,${encodeURIComponent(
    modifySVGSize(modifySVGColor(svgString, fillColor), ICON_SIZE)
  )}`;
};

const getSvgUrl = (symbol, filter) => {
  const symbolPath = api.getApiUrl() + "v1/materialsymbols";

  const weight = filter.weight === 400 ? "" : "wght" + filter.weight;
  const grad = filter.grad
    ? filter.grad === -1
      ? "gradN25"
      : "grad" + 200
    : "";
  const fill = filter.fill ? "fill1" : "";

  const varient = weight || grad || fill ? "_" + weight + grad + fill : "";

  const src = `${symbolPath}/${symbol}/${filter.style}/${symbol}${varient}_${filter.size}px.svg?mime_type=image/svg`;
  return src;
};

export default FileManagerModal;
